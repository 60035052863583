import Enterprise01Img from '../../../../assets/awsProductCard/awsLambda/lambda01.png';
import Enterprise02Img from '../../../../assets/awsProductCard/awsLambda/lambda02.jpg';
import Enterprise03Img from '../../../../assets/awsProductCard/awsLambda/lambda03.jpg';
import Enterprise04Img from '../../../../assets/awsProductCard/awsLambda/lambda04.jpg';

import {
  LambdaContentData,
  AwsLambdaContentLayout,
} from './AwsLambdaModel';

export const lambdaContentDatasKo: LambdaContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsLambdaContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'LG CNS는 AWS Lambda를 활용하여 다양한 서비스를 제공합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `다양한 시장 변화와 요구사항을 대응하기 위해 많은 고객이 Serverless 아키텍처를 도입하고 있습니다. LG CNS에서는 Lambda를 활용하여 일반적인 웹애플리케이션 백엔드부터, 마이크로서비스 용 아키텍처, 데이터레이크 용 ETL, 시스템 운영 등 다양한 환경 구성을 지원하고 있습니다. 또한, Lambda를 편리하게 개발하고 수정할 수 있도록 자동화된 CICD Platform도 함께 구축해드리고 있습니다.`,
              options: {
                //size: 'content-lg',
                //bold: true,
                //color: 'primary',
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsLambdaContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Infra 고민 없이 코드 실행',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS Lambda는 가용성이 뛰어나고 내결함성을 갖춘 인프라에서 코드를 실행할 수 있도록 모든 인프라를 관리하므로 고객은 차별화된 백엔드 서비스를 구축하는 데 집중할 수 있습니다. Lambda를 사용하면 패치가 출시되어도 기본 운영 체제(OS)를 업데이트할 필요가 없으며 사용량 증가에 따른 서버 규모 조정이나 새로운 서버 추가에 대해 걱정할 필요가 없습니다. AWS Lambda는 코드를 원활하게 배포하고 모든 관리, 유지 관리 및 보안 패치를 처리하며 Amazon CloudWatch를 통해 기본 제공 로깅 및 모니터링 기능을 제공합니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsLambdaContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '하루에 수십 개의 이벤트에서 초당 수십만 개에 이르기까지 어떤 규모의 요청에도 자동으로 대응합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS Lambda는 필요한 경우에만 코드를 호출하고 수동 구성 없이 수신되는 요청 속도를 지원할 수 있도록 자동 확장됩니다. 코드에서 처리할 수 있는 요청 수에는 제한이 없습니다. AWS Lambda는 대개 이벤트가 발생한 후 몇 밀리초 내에 코드 실행을 시작합니다. Lambda는 자동으로 크기를 조정하므로, 이벤트 빈도가 늘어나도 성능은 일관적으로 높게 유지됩니다. 코드는 무상태이므로 Lambda는 배포와 구성에 대한 지연 없이 필요한 만큼 많은 인스턴스를 시작할 수 있습니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsLambdaContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: '최대 사용량에 대비하여 사전에 인프라를 만드는 대신, 밀리초 기준으로 사용하는 컴퓨팅 시간에 대해서만 요금을 지불하여 비용을 절감합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS Lambda를 사용하면 서버 단위가 아닌 실행 기간에 대해 요금을 지불합니다. Lambda 함수를 사용하면 코드 실행에 필요한 컴퓨팅 시간 및 지원된 요청에 대해서만 비용을 지불합니다. 1밀리초 단위로 청구 금액이 정산되기 때문에 간편하고 비용 효율적으로 일 몇 개의 요청에서 초당 수천 개의 요청으로 자동 크기 조정됩니다. 프로비저닝된 동시성을 사용할 때는 구성한 동시성 크기와 구성 기간에 대한 요금을 지불합니다. 프로비저닝된 동시성을 사용하고 함수를 실행하는 경우에는 요청 및 실행 기간에 대해서도 요금을 지불해야 합니다. ',
            },
          ],
        },
      ],
    },
  },
];

