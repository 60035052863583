import './NewsBoardEditContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import NewsBoardEditForm from './components/NewsBoardEditForm';

const NewsBoardEditContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="NewsBoardEditContainer">
        <NewsBoardEditForm />
      </div>
    </Container>
  );
};

export default NewsBoardEditContainer;
