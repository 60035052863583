import React from 'react';
import {
  RedShiftContentData,
  AwsRedShiftContentLayout,
} from '../../../../service/data/awsProductCard/awsRedShift/AwsRedShiftModel';
import { getIsMobile } from '../../../../service/utils/utils';
import AwsRedShiftContentTextItem from './AwsRedShiftContentTextItem';

interface Props {
  content: RedShiftContentData;
}

const AwsRedShiftContentArea: React.FC<Props> = (props: Props) => {
  const { content } = props;
  const isMobile = getIsMobile();

  const renderContents = () => {
    if (content.layout === AwsRedShiftContentLayout.TXT_IMG) {
      return (
        <>
          <AwsRedShiftContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      );
    } else if (content.layout === AwsRedShiftContentLayout.IMG_TXT) {
      //mobile은 TXT-IMG 배치로 고정
      return isMobile ? (
        <>
          <AwsRedShiftContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      ) : (
        <>
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
          <AwsRedShiftContentTextItem content={content.content} />
        </>
      );
    } else if (content.layout === AwsRedShiftContentLayout.TXT_TXT) {
      return (
        <>
          <AwsRedShiftContentTextItem content={content.content} />
          <AwsRedShiftContentTextItem content={content.content2} />
        </>
      );
    } else if (content.layout === AwsRedShiftContentLayout.TXT_TXT_TXT) {
      return (
        <>
          <AwsRedShiftContentTextItem content={content.content} />
          <AwsRedShiftContentTextItem content={content.content2} />
          <AwsRedShiftContentTextItem content={content.content3} />
        </>
      );
    } else if (content.layout === AwsRedShiftContentLayout.TXT) {
      return (
        <>
          <AwsRedShiftContentTextItem content={content.content} />
        </>
      );
    }

  };

  return (
    <div
      className="eks-anywhere-content-cont"
      data-testid="testAwsRedShiftContentArea"
    >
      {renderContents()}
    </div>
  );
};

export default AwsRedShiftContentArea;
