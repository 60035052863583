import './NewsTempRegistSection.scss';
import React, { useEffect, useState } from 'react';
import { News } from '../../../../service/news/model/News';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  close: () => void;
  tempNewsList: News[];
}

const NewsTempRegistSection = (props: Props) => {
  const { open, close, tempNewsList } = props;
  const navigator = useNavigate();

  const handlerTempNewsItem = (url: string) => {
    navigator('/admin/news/' + url, { state: true });
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <section>
          <header>
            News 임시저장 목록
            <button className="close" onClick={close}>
              &times;
            </button>
          </header>
          <main>
            {tempNewsList
              ? tempNewsList.map((news, key) => (
                  <div
                    key={key}
                    className="news-temp-regist-item"
                    onClick={() => handlerTempNewsItem(news.newsUrl)}
                  >
                    {key + 1}. {news.newsBoard.ko.newsCategory}-
                    {news.newsBoard.ko.newsTitle} ({news.newsUrl},{' '}
                    {news.registerAdmin})
                  </div>
                ))
              : null}
          </main>
          <footer>
            <button className="close" onClick={close}>
              close
            </button>
          </footer>
        </section>
      ) : null}
    </div>
  );
};

export default NewsTempRegistSection;
