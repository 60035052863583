import './BlogDetailContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import BlogDetailSection from './components/BlogDetailSection';

const BlogDetailContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="AdminBlogDetailContainer">
        <BlogDetailSection />
      </div>
    </Container>
  );
};

export default BlogDetailContainer;
