import React, { ReactElement, createRef, useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';

import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

interface Props {
  text: string;
}
export const ToastViewer: React.FC<Props> = ({ text }: Props): ReactElement => {
  const viewerRef = createRef<Viewer>();

  useEffect(() => {
    viewerRef.current?.getInstance().setMarkdown(text);
  }, [text]);

  return (
    <Viewer
      // initialValue={text}
      plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
      ref={viewerRef}
    />
  );
};
export default ToastViewer;
