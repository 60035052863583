import './BusinessBoardContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import BusinessBoardSection from './components/BusinessBoardSection';

const BusinessBoardContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="AdminBusinessBoardContainer">
        <BusinessBoardSection />
      </div>
    </Container>
  );
};

export default BusinessBoardContainer;
