import './CaseBoardRegistForm.scss';

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import CaseService from '../../../../service/case/CaseService';
import S3UploadService from '../../../../service/S3Service';
import {
  Case,
  CaseBoard,
  SummaryContent,
  CaseDetailContent,
} from '../../../../service/case/model/Case';
import {
  State,
  Language,
} from '../../../../service/common/model/enum/Common.enum';
import CustomRadioBtn from '../../../common/CustomRadioBtn';

import CaseSummary from './CaseSummary';
import CaseDetail from './CaseDetail';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import ProgressBar from '../../../../layouts/ProgressBar';
import { v4 as uuidv4 } from 'uuid';
import {
  S3FileCopyRequest,
  S3FileDeleteRequest,
  S3FileUploadRequest,
} from '../../../../service/common/model/s3BucketRequest.model';
import { ContentDispositionType } from '../../../../service/common/model/enum/s3.enum';

const defaultSummary: SummaryContent = {
  summaryInfo: '',
  summaryDescription: '',
};
const defaultDetail: CaseDetailContent = {
  detailTitle: '',
  detailText: '',
  detailContent: '',
  detailContentUrl: '',
};

const CaseBoardRegistForm: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as unknown as { state: string };

  const caseService = new CaseService();
  const s3UploadService = new S3UploadService();

  const loading_allPage = useSelector(
    (state: RootState) => state.loading.loading
  );
  const dispatch = useDispatch();
  const dirKey = useRef<string>(uuidv4());
  const [uploadedFileKeyList, setUploadedFileKeyList] = useState<string[]>([]);
  const uploadList = useRef<string[]>([]);

  const [isCheck, setIsCheck] = useState<boolean>(false);

  /* set State for common input data */
  const [caseUrl, setCaseUrl] = useState<string>('');
  const [caseState, setCaseState] = useState<State>(State.ALL);
  const [currLang, setCurrLang] = useState<Language>(Language.KO);

  /* 임시저장 데이터를 수정할 시 담아 놓을 변수목록들 */
  const [caseId, setCaseId] = useState<string>('');
  const [caseOrderIdx, setCaseOrderIdx] = useState<number>(-1);
  const [registerAdmin, setRegisterAdmin] = useState<string>('');
  const [registeredDate, setRegisteredDate] = useState<string>('');
  const [modifyAdmin, setModifyAdmin] = useState<string>('');
  const [modifiedDate, setModifiedDate] = useState<string>('');

  /* set State for input data by Language */
  const [koThumbnail, setKoThumbnail] = useState<string>('');
  const [enThumbnail, setEnThumbnail] = useState<string>('');
  const [koBgImg, setKoBgImg] = useState<string>('');
  const [enBgImg, setEnBgImg] = useState<string>('');
  const [koCompLogo, setKoCompLogo] = useState<string>('');
  const [enCompLogo, setEnCompLogo] = useState<string>('');
  const [koInputs, setKoInputs] = useState<CaseBoard>({
    caseLang: Language.KO,
    caseTag: '',
    caseTitle: '',
    caseThumbnail: '',
    caseDescription: '',
    caseDetail: {
      caseTitleBg: '',
      caseCompany: '',
      caseCompanyLogo: '',
      caseOutline: '',
      caseDetailSummary: {
        aboutCompany: [
          {
            summaryInfo: '',
            summaryDescription: '',
          },
        ],
        theBenefit: [
          {
            summaryInfo: '',
            summaryDescription: '',
          },
        ],
        whatWeDid: [
          {
            summaryInfo: '',
            summaryDescription: '',
          },
        ],
      },
      caseDetailContentList: [
        {
          detailTitle: '',
          detailContent: '',
          detailContentUrl: '',
          detailText: '',
        },
      ],
    },
  });
  const [enInputs, setEnInputs] = useState<CaseBoard>({
    caseLang: Language.EN,
    caseTag: '',
    caseTitle: '',
    caseThumbnail: '',
    caseDescription: '',
    caseDetail: {
      caseTitleBg: '',
      caseCompany: '',
      caseCompanyLogo: '',
      caseOutline: '',
      caseDetailSummary: {
        aboutCompany: [
          {
            summaryInfo: '',
            summaryDescription: '',
          },
        ],
        theBenefit: [
          {
            summaryInfo: '',
            summaryDescription: '',
          },
        ],
        whatWeDid: [
          {
            summaryInfo: '',
            summaryDescription: '',
          },
        ],
      },
      caseDetailContentList: [
        {
          detailTitle: '',
          detailContent: '',
          detailContentUrl: '',
          detailText: '',
        },
      ],
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state) {
      getTempCaseDetail(state);
    }
  }, []);

  useEffect(() => {
    setKoInputs({
      ...koInputs,
      ['caseThumbnail']: koThumbnail,
      ['caseDetail']: {
        ...koInputs.caseDetail,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...koInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...koInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  }, [koThumbnail]);

  useEffect(() => {
    setEnInputs({
      ...enInputs,
      ['caseThumbnail']: enThumbnail,
      ['caseDetail']: {
        ...enInputs.caseDetail,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...enInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...enInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  }, [enThumbnail]);

  useEffect(() => {
    setKoInputs({
      ...koInputs,
      ['caseDetail']: {
        ...koInputs.caseDetail,
        ['caseTitleBg']: koBgImg,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...koInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...koInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  }, [koBgImg]);

  useEffect(() => {
    setEnInputs({
      ...enInputs,
      ['caseDetail']: {
        ...enInputs.caseDetail,
        ['caseTitleBg']: enBgImg,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...enInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...enInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  }, [enBgImg]);

  useEffect(() => {
    setKoInputs({
      ...koInputs,
      ['caseDetail']: {
        ...koInputs.caseDetail,
        ['caseCompanyLogo']: koCompLogo,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...koInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...koInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  }, [koCompLogo]);

  useEffect(() => {
    setEnInputs({
      ...enInputs,
      ['caseDetail']: {
        ...enInputs.caseDetail,
        ['caseCompanyLogo']: enCompLogo,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...enInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...enInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  }, [enCompLogo]);

  const handleCancelBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (confirm('case 등록을 취소하시겠습니까?')) {
      alert('case 등록이 취소되었습니다.');
      navigate('/admin/case');
    }
  };

  const getRequestCaseData = (isTmpSave: boolean) => {
    let request: Case = {
      caseId: caseId ? caseId : '',
      caseUrl: caseUrl,
      caseOrderIdx: caseOrderIdx ? caseOrderIdx : -1,
      caseState: caseState,
      registerAdmin: registerAdmin ? registerAdmin : '',
      registeredDate: registeredDate ? registeredDate : '',
      modifyAdmin: '',
      modifiedDate: '',
      caseBoard: {
        ko: koInputs,
        en: enInputs,
      },
    };
    if (!isTmpSave) {
      koInputs.caseDetail.caseTitleBg =
        koInputs.caseDetail.caseTitleBg.replaceAll('tmp/image', '/image');
      if (koInputs.caseDetail.caseCompanyLogo) {
        koInputs.caseDetail.caseCompanyLogo =
          koInputs.caseDetail.caseCompanyLogo.replaceAll('tmp/image', '/image');
      }
      koInputs.caseThumbnail = koInputs.caseThumbnail.replaceAll(
        'tmp/image',
        '/image'
      );

      if (enInputs.caseDetail.caseTitleBg) {
        enInputs.caseDetail.caseTitleBg =
          enInputs.caseDetail.caseTitleBg.replaceAll('tmp/image', '/image');
      }
      if (enInputs.caseDetail.caseCompanyLogo) {
        enInputs.caseDetail.caseCompanyLogo =
          enInputs.caseDetail.caseCompanyLogo.replaceAll('tmp/image', '/image');
      }
      if (enInputs.caseThumbnail) {
        enInputs.caseThumbnail = enInputs.caseThumbnail.replaceAll(
          'tmp/image',
          '/image'
        );
      }
      request = {
        caseId: '',
        caseUrl: caseUrl,
        caseOrderIdx: -1,
        caseState: caseState,
        registerAdmin: '',
        registeredDate: '',
        modifyAdmin: '',
        modifiedDate: '',
        caseBoard: {
          ko: koInputs,
          en: enInputs,
        },
      };
    }
    // 시스템 필수값 확인
    if (
      !isCheck ||
      caseUrl === '' ||
      caseUrl === undefined ||
      caseUrl === null
    ) {
      alert('case URL 중복확인이 필요합니다.');
      return;
    }
    return request;
  };

  const setRequestImgData = async () => {
    const routeStr = 'tmp/image/case/' + dirKey.current + '/';
    await uploadedFileKeyList.map(async (key) => {
      if (
        koBgImg.includes(key) ||
        koCompLogo.includes(key) ||
        koThumbnail.includes(key) ||
        enBgImg.includes(key) ||
        enCompLogo.includes(key) ||
        enThumbnail.includes(key)
      ) {
        uploadList.current.push(routeStr + key);
      }
    });
  };

  const handleTempRegistBtn = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const request = getRequestCaseData(true);
    await setRequestImgData();
    if (request) {
      registTempCase(request);
    }
  };

  const registTempCase = async (request: Case) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = state
      ? await caseService.putTempCaseEdit(request, state)
      : await caseService.postTempCaseRegist(request);

    if (response) {
      alert('임시저장이 완료되었습니다.');
      navigate('/admin/case');
    } else {
      alert('임시저장 중 오류가 발생했습니다.');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const handleRegistBtn = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const request = getRequestCaseData(false);
    await setRequestImgData();
    let copyResponse;

    if (uploadList.current.length > 0) {
      const fileCopyRequest: S3FileCopyRequest = {
        uploadFileKeyList: uploadList.current,
      };
      copyResponse = await s3UploadService.copyTmpFiles(fileCopyRequest);
    }

    if (copyResponse && copyResponse.successOrNot == 'N') {
      alert('case 등록 중 오류가 발생했습니다.');
    } else if (
      !copyResponse ||
      (copyResponse && copyResponse.successOrNot == 'Y')
    ) {
      if (request) createCase(request);
    }
  };

  const createCase = async (request: Case) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await caseService.registCase(request);
    if (response.successOrNot == 'Y') {
      deleteImgFile();
      if (state) {
        const delResponse = await caseService.deleteTempCase(state);
        if (delResponse) {
          alert('case 등록이 완료 되었습니다.');
          navigate('/admin/case');
        } else {
          alert('case 등록 중 오류가 발생했습니다.');
        }
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      } else {
        alert('case 등록이 완료 되었습니다.');
        navigate('/admin/case');
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      }
    } else {
      deleteImgFile();
      alert('case 등록 중 오류가 발생했습니다.');
    }
  };

  const deleteImgFile = () => {
    const deleteFileRequest: S3FileDeleteRequest = {
      dirName: 'case',
      dirKey: dirKey.current,
      contentDispositionType: ContentDispositionType.IMAGE,
    };

    s3UploadService.deleteFiles(deleteFileRequest);
  };

  const handleOnAddBtnKo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    const defaultSummary: SummaryContent = {
      summaryInfo: '',
      summaryDescription: '',
    };
    const defaultDetail: CaseDetailContent = {
      detailTitle: '',
      detailText: '',
      detailContent: '',
      detailContentUrl: '',
    };
    if (id == 'about') {
      setKoInputs({
        ...koInputs,
        ['caseDetail']: {
          ...koInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
              defaultSummary,
            ],
            ['theBenefit']: [
              ...koInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...koInputs.caseDetail.caseDetailContentList,
          ],
        },
      });
    } else if (id == 'benefit') {
      setKoInputs({
        ...koInputs,
        ['caseDetail']: {
          ...koInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...koInputs.caseDetail.caseDetailSummary.theBenefit,
              defaultSummary,
            ],
            ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...koInputs.caseDetail.caseDetailContentList,
          ],
        },
      });
    } else if (id == 'whatWeDid') {
      setKoInputs({
        ...koInputs,
        ['caseDetail']: {
          ...koInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...koInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [
              ...koInputs.caseDetail.caseDetailSummary.whatWeDid,
              defaultSummary,
            ],
          },
          ['caseDetailContentList']: [
            ...koInputs.caseDetail.caseDetailContentList,
          ],
        },
      });
    } else if (id == 'detail') {
      setKoInputs({
        ...koInputs,
        ['caseDetail']: {
          ...koInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...koInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...koInputs.caseDetail.caseDetailContentList,
            defaultDetail,
          ],
        },
      });
    }
  };

  const handleOnAddBtnEn = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    const defaultSummary: SummaryContent = {
      summaryInfo: '',
      summaryDescription: '',
    };
    const defaultDetail: CaseDetailContent = {
      detailTitle: '',
      detailText: '',
      detailContent: '',
      detailContentUrl: '',
    };
    if (id == 'about') {
      setEnInputs({
        ...enInputs,
        ['caseDetail']: {
          ...enInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
              defaultSummary,
            ],
            ['theBenefit']: [
              ...enInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...enInputs.caseDetail.caseDetailContentList,
          ],
        },
      });
    } else if (id == 'benefit') {
      setEnInputs({
        ...enInputs,
        ['caseDetail']: {
          ...enInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...enInputs.caseDetail.caseDetailSummary.theBenefit,
              defaultSummary,
            ],
            ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...enInputs.caseDetail.caseDetailContentList,
          ],
        },
      });
    } else if (id == 'whatWeDid') {
      setEnInputs({
        ...enInputs,
        ['caseDetail']: {
          ...enInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...enInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [
              ...enInputs.caseDetail.caseDetailSummary.whatWeDid,
              defaultSummary,
            ],
          },
          ['caseDetailContentList']: [
            ...enInputs.caseDetail.caseDetailContentList,
          ],
        },
      });
    } else if (id == 'detail') {
      setEnInputs({
        ...enInputs,
        ['caseDetail']: {
          ...enInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...enInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...enInputs.caseDetail.caseDetailContentList,
            defaultDetail,
          ],
        },
      });
    }
  };

  const onChangeCaseUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsCheck(false);
    setCaseUrl(event.target.value);
  };

  const handleCaseURL = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\-|]+$/;
    if (!regex.test(caseUrl)) {
      alert('URL에는 한글/영어대소문자/숫자/-기호만 사용할 수 있습니다.');
      return false;
    }
    if (caseUrl) {
      dispatch(changeLoadingStatus(true));
      dispatch(changeProgressStatus(50));
      caseService.checkCaseStudyCaseUrl(caseUrl).then((response) => {
        if (response) {
          setCaseUrl('');
          alert('사용할 수 없는 url 입니다.');
        } else {
          setCaseUrl(caseUrl);
          setIsCheck(true);
          alert('사용 가능한 url 입니다.');
        }
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      });
    }
  };

  const handleOnInputChangeKo = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, name } = event.target;
    setKoInputs({
      ...koInputs,
      [name]: value,
      ['caseDetail']: {
        ...koInputs.caseDetail,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...koInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...koInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  };

  const handleOnInputChangeEn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, name } = event.target;
    setEnInputs({
      ...enInputs,
      [name]: value,
      ['caseDetail']: {
        ...enInputs.caseDetail,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...enInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...enInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  };

  const handleOnInputChangeDetailKo = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, name } = event.target;
    setKoInputs({
      ...koInputs,
      ['caseDetail']: {
        ...koInputs.caseDetail,
        [name]: value,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...koInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...koInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  };

  const handleOnInputChangeDetailEn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, name } = event.target;
    setEnInputs({
      ...enInputs,
      ['caseDetail']: {
        ...enInputs.caseDetail,
        [name]: value,
        ['caseDetailSummary']: {
          ['aboutCompany']: [
            ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
          ],
          ['theBenefit']: [...enInputs.caseDetail.caseDetailSummary.theBenefit],
          ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
        },
        ['caseDetailContentList']: [
          ...enInputs.caseDetail.caseDetailContentList,
        ],
      },
    });
  };

  const handleCustomRadioLangChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target; // value is string
    if (value == Language.KO) {
      setCurrLang(() => Language.KO);
    } else if (value == Language.EN) {
      setCurrLang(() => Language.EN);
    }
  };

  const handleCustomRadioStateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (value == State.ALL) {
      setCaseState(State.ALL);
    } else if (value == State.ADMIN) {
      setCaseState(State.ADMIN);
    }
  };

  const handleImgUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : undefined;
      const fileKey = uuidv4();
      const loadCase = event.target.name;

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async function () {
          if (reader.result) {
            const baseString = String(reader.result).split(';base64,')[1];
            const uploadRequest: S3FileUploadRequest = {
              dirName: 'case',
              dirKey: dirKey.current,
              fileKey: fileKey,
              fileBody: baseString,
              contentType: file.type,
              fileName: file.name,
              contentDispositionType: ContentDispositionType.IMAGE,
            };
            try {
              const uploadResponse = await s3UploadService.uploadToS3(
                uploadRequest
              );
              if (uploadResponse && uploadResponse.successOrNot == 'Y') {
                setUploadedFileKeyList((prevList) => [fileKey, ...prevList]);
                if (currLang == Language.KO) {
                  if (loadCase == 'bg') {
                    setKoBgImg(uploadResponse.data?.uploadKey);
                  } else if (loadCase == 'logo') {
                    setKoCompLogo(uploadResponse.data?.uploadKey);
                  } else {
                    setKoThumbnail(uploadResponse.data?.uploadKey);
                  }
                } else {
                  if (loadCase == 'bg') {
                    setEnBgImg(uploadResponse.data?.uploadKey);
                  } else if (loadCase == 'logo') {
                    setEnCompLogo(uploadResponse.data?.uploadKey);
                  } else {
                    setEnThumbnail(uploadResponse.data?.uploadKey);
                  }
                }
                return uploadResponse.data?.uploadKey;
              } else {
                throw new Error();
              }
            } catch (err) {
              alert('이미지 첨부 중 오류가 발생하였습니다. 다시 시도하세요.');
            } finally {
              dispatch(changeLoadingStatus(false));
              dispatch(changeProgressStatus(100));
              event.target.value = '';
              event.target.files = null;
            }
          }
        };
      }
      return;
    },
    [loading_allPage, currLang]
  );

  const handleImgUploadCancel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const { name } = event.currentTarget;
      if (currLang === Language.KO) {
        if (name == 'caseThumbnail') {
          setKoThumbnail('');
        } else if (name == 'caseTitleBg') {
          setKoBgImg('');
        } else if (name == 'caseCompanyLogo') {
          setKoCompLogo('');
        }
      } else {
        if (name == 'caseThumbnail') {
          setEnThumbnail('');
        } else if (name == 'caseTitleBg') {
          setEnBgImg('');
        } else if (name == 'caseCompanyLogo') {
          setEnCompLogo('');
        }
      }
    },
    [currLang]
  );

  const onUpdateSummaryKo = (
    name: string,
    id: number,
    data: SummaryContent
  ) => {
    if (name == 'about') {
      setKoInputs((koInputs) => {
        const updated: CaseBoard = {
          ...koInputs,
          ['caseDetail']: {
            ...koInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...koInputs.caseDetail.caseDetailSummary.aboutCompany.map(
                  (item, idx) => {
                    if (idx === id) {
                      return { ...data };
                    }
                    return item;
                  }
                ),
              ],
              ['theBenefit']: [
                ...koInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...koInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...koInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'benefit') {
      setKoInputs((koInputs) => {
        const updated: CaseBoard = {
          ...koInputs,
          ['caseDetail']: {
            ...koInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...koInputs.caseDetail.caseDetailSummary.theBenefit.map(
                  (item, idx) => {
                    if (idx === id) {
                      return { ...data };
                    }
                    return item;
                  }
                ),
              ],
              ['whatWeDid']: [
                ...koInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...koInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'whatWeDid') {
      setKoInputs((koInputs) => {
        const updated: CaseBoard = {
          ...koInputs,
          ['caseDetail']: {
            ...koInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...koInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...koInputs.caseDetail.caseDetailSummary.whatWeDid.map(
                  (item, idx) => {
                    if (idx === id) {
                      return { ...data };
                    }
                    return item;
                  }
                ),
              ],
            },
            ['caseDetailContentList']: [
              ...koInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    }
  };

  const onUpdateSummaryEn = (
    name: string,
    id: number,
    data: SummaryContent
  ) => {
    if (name == 'about') {
      setEnInputs((enInputs) => {
        const updated: CaseBoard = {
          ...enInputs,
          ['caseDetail']: {
            ...enInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...enInputs.caseDetail.caseDetailSummary.aboutCompany.map(
                  (item, idx) => {
                    if (idx === id) {
                      return { ...data };
                    }
                    return item;
                  }
                ),
              ],
              ['theBenefit']: [
                ...enInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...enInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...enInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'benefit') {
      setEnInputs((enInputs) => {
        const updated: CaseBoard = {
          ...enInputs,
          ['caseDetail']: {
            ...enInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...enInputs.caseDetail.caseDetailSummary.theBenefit.map(
                  (item, idx) => {
                    if (idx === id) {
                      return { ...data };
                    }
                    return item;
                  }
                ),
              ],
              ['whatWeDid']: [
                ...enInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...enInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'whatWeDid') {
      setEnInputs((enInputs) => {
        const updated: CaseBoard = {
          ...enInputs,
          ['caseDetail']: {
            ...enInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...enInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...enInputs.caseDetail.caseDetailSummary.whatWeDid.map(
                  (item, idx) => {
                    if (idx === id) {
                      return { ...data };
                    }
                    return item;
                  }
                ),
              ],
            },
            ['caseDetailContentList']: [
              ...enInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    }
  };

  const onRemoveSummaryKo = (name: string, id: number) => {
    if (name == 'about') {
      setKoInputs((koInputs) => {
        const updated: CaseBoard = {
          ...koInputs,
          ['caseDetail']: {
            ...koInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...koInputs.caseDetail.caseDetailSummary.aboutCompany.filter(
                  (item, idx) => {
                    if (idx !== id) return item;
                  }
                ),
              ],
              ['theBenefit']: [
                ...koInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...koInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...koInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'benefit') {
      setKoInputs((koInputs) => {
        const updated: CaseBoard = {
          ...koInputs,
          ['caseDetail']: {
            ...koInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...koInputs.caseDetail.caseDetailSummary.theBenefit.filter(
                  (item, idx) => {
                    if (idx !== id) return item;
                  }
                ),
              ],
              ['whatWeDid']: [
                ...koInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...koInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'whatWeDid') {
      setKoInputs((koInputs) => {
        const updated: CaseBoard = {
          ...koInputs,
          ['caseDetail']: {
            ...koInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...koInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...koInputs.caseDetail.caseDetailSummary.whatWeDid.filter(
                  (item, idx) => {
                    if (idx !== id) return item;
                  }
                ),
              ],
            },
            ['caseDetailContentList']: [
              ...koInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    }
  };

  const onRemoveSummaryEn = (name: string, id: number) => {
    if (name == 'about') {
      setEnInputs((enInputs) => {
        const updated: CaseBoard = {
          ...enInputs,
          ['caseDetail']: {
            ...enInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...enInputs.caseDetail.caseDetailSummary.aboutCompany.filter(
                  (item, idx) => {
                    if (idx !== id) return item;
                  }
                ),
              ],
              ['theBenefit']: [
                ...enInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...enInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...enInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'benefit') {
      setEnInputs((enInputs) => {
        const updated: CaseBoard = {
          ...enInputs,
          ['caseDetail']: {
            ...enInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...enInputs.caseDetail.caseDetailSummary.theBenefit.filter(
                  (item, idx) => {
                    if (idx !== id) return item;
                  }
                ),
              ],
              ['whatWeDid']: [
                ...enInputs.caseDetail.caseDetailSummary.whatWeDid,
              ],
            },
            ['caseDetailContentList']: [
              ...enInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    } else if (name == 'whatWeDid') {
      setEnInputs((enInputs) => {
        const updated: CaseBoard = {
          ...enInputs,
          ['caseDetail']: {
            ...enInputs.caseDetail,
            ['caseDetailSummary']: {
              ['aboutCompany']: [
                ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
              ],
              ['theBenefit']: [
                ...enInputs.caseDetail.caseDetailSummary.theBenefit,
              ],
              ['whatWeDid']: [
                ...enInputs.caseDetail.caseDetailSummary.whatWeDid.filter(
                  (item, idx) => {
                    if (idx !== id) return item;
                  }
                ),
              ],
            },
            ['caseDetailContentList']: [
              ...enInputs.caseDetail.caseDetailContentList,
            ],
          },
        };
        return updated;
      });
    }
  };

  const onUpdateDetailKo = (id: number, data: CaseDetailContent) => {
    setKoInputs((koInputs) => {
      const updated: CaseBoard = {
        ...koInputs,
        ['caseDetail']: {
          ...koInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...koInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...koInputs.caseDetail.caseDetailContentList.map((item, idx) => {
              if (idx === id) {
                return { ...data };
              }
              return item;
            }),
          ],
        },
      };
      return updated;
    });
  };

  const onUpdateDetailEn = (id: number, data: CaseDetailContent) => {
    setEnInputs((enInputs) => {
      const updated: CaseBoard = {
        ...enInputs,
        ['caseDetail']: {
          ...enInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...enInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...enInputs.caseDetail.caseDetailContentList.map((item, idx) => {
              if (idx === id) {
                return { ...data };
              }
              return item;
            }),
          ],
        },
      };
      return updated;
    });
  };

  const onRemoveDetailKo = (id: number) => {
    setKoInputs((koInputs) => {
      const updated: CaseBoard = {
        ...koInputs,
        ['caseDetail']: {
          ...koInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...koInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...koInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...koInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...koInputs.caseDetail.caseDetailContentList.filter((item, idx) => {
              if (idx !== id) return item;
            }),
          ],
        },
      };
      return updated;
    });
  };

  const onRemoveDetailEn = (id: number) => {
    setEnInputs((enInputs) => {
      const updated: CaseBoard = {
        ...enInputs,
        ['caseDetail']: {
          ...enInputs.caseDetail,
          ['caseDetailSummary']: {
            ['aboutCompany']: [
              ...enInputs.caseDetail.caseDetailSummary.aboutCompany,
            ],
            ['theBenefit']: [
              ...enInputs.caseDetail.caseDetailSummary.theBenefit,
            ],
            ['whatWeDid']: [...enInputs.caseDetail.caseDetailSummary.whatWeDid],
          },
          ['caseDetailContentList']: [
            ...enInputs.caseDetail.caseDetailContentList.filter((item, idx) => {
              if (idx !== id) return item;
            }),
          ],
        },
      };
      return updated;
    });
  };

  const getTempCaseDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await caseService.getTempCaseDetail(id);
    const uploadedList: string[] = [];
    if (response) {
      setKoThumbnail(response.caseBoard.ko.caseThumbnail);
      //기등록된 key획득
      const strTmp = response.caseBoard.ko.caseThumbnail.split('/');
      const prevDirKey = strTmp[3];
      dirKey.current = prevDirKey;
      uploadedList.push(strTmp[4]);
      setKoBgImg(response.caseBoard.ko.caseDetail.caseTitleBg);
      setKoCompLogo(response.caseBoard.ko.caseDetail.caseCompanyLogo || '');

      setCaseUrl(() => response.caseUrl);
      setCaseId(() => response.caseId);
      setCaseOrderIdx(() => response.caseOrderIdx);
      setCaseState(() => response.caseState);
      setRegisterAdmin(() => response.registerAdmin);
      setRegisteredDate(() => response.registeredDate);
      setModifyAdmin(() => response.modifyAdmin);
      setModifiedDate(() => response.modifiedDate);
      const aboutItem = response.caseBoard.ko.caseDetail.caseDetailSummary
        .aboutCompany
        ? [...response.caseBoard.ko.caseDetail.caseDetailSummary.aboutCompany]
        : [defaultSummary];
      const benefitItem = response.caseBoard.ko.caseDetail.caseDetailSummary
        .theBenefit
        ? [...response.caseBoard.ko.caseDetail.caseDetailSummary.theBenefit]
        : [defaultSummary];
      const whatWeDidItem = response.caseBoard.ko.caseDetail.caseDetailSummary
        .whatWeDid
        ? [...response.caseBoard.ko.caseDetail.caseDetailSummary.whatWeDid]
        : [defaultSummary];
      const detailItem = response.caseBoard.ko.caseDetail.caseDetailContentList
        ? [...response.caseBoard.ko.caseDetail.caseDetailContentList]
        : [defaultDetail];
      setKoInputs({
        caseLang: Language.KO,
        caseTag: response.caseBoard.ko.caseTag,
        caseTitle: response.caseBoard.ko.caseTitle,
        caseThumbnail: response.caseBoard.ko.caseThumbnail,
        caseDescription: response.caseBoard.ko.caseDescription,
        ['caseDetail']: {
          caseTitleBg: response.caseBoard.ko.caseDetail.caseTitleBg,
          caseCompany: response.caseBoard.ko.caseDetail.caseCompany || '',
          caseCompanyLogo:
            response.caseBoard.ko.caseDetail.caseCompanyLogo || '',
          caseOutline: response.caseBoard.ko.caseDetail.caseOutline,
          ['caseDetailSummary']: {
            aboutCompany: aboutItem,
            theBenefit: benefitItem,
            whatWeDid: whatWeDidItem,
          },
          ['caseDetailContentList']: detailItem,
        },
      });
      //기등록된 key획득
      let titleBgStrTmp =
        response.caseBoard.ko.caseDetail.caseTitleBg.split('/');
      uploadedList.push(titleBgStrTmp[4]);
      if (response.caseBoard.ko.caseDetail.caseCompanyLogo) {
        const companyLogoStrTmp =
          response.caseBoard.ko.caseDetail.caseCompanyLogo.split('/');
        uploadedList.push(companyLogoStrTmp[4]);
      }
      setEnThumbnail(response.caseBoard.en?.caseThumbnail || '');
      setEnBgImg(response.caseBoard.en?.caseDetail.caseTitleBg || '');
      setEnCompLogo(response.caseBoard.en?.caseDetail.caseCompanyLogo || '');
      if (response.caseBoard.en) {
        // state update 를 다시 하지 않으면 영문 데이터가 뜨지 않음.
        setEnThumbnail(response.caseBoard.en.caseThumbnail);
        //기등록된 key획득
        const strTmpEn = response.caseBoard.en.caseThumbnail.split('/');
        uploadedList.push(strTmpEn[4]);
        setEnBgImg(response.caseBoard.en.caseDetail.caseTitleBg);
        setEnCompLogo(response.caseBoard.en.caseDetail.caseCompanyLogo || '');
        const aboutItem = response.caseBoard.en.caseDetail.caseDetailSummary
          .aboutCompany
          ? [...response.caseBoard.en.caseDetail.caseDetailSummary.aboutCompany]
          : [defaultSummary];
        const benefitItem = response.caseBoard.en.caseDetail.caseDetailSummary
          .theBenefit
          ? [...response.caseBoard.en.caseDetail.caseDetailSummary.theBenefit]
          : [defaultSummary];
        const whatWeDidItem = response.caseBoard.en.caseDetail.caseDetailSummary
          .whatWeDid
          ? [...response.caseBoard.en.caseDetail.caseDetailSummary.whatWeDid]
          : [defaultSummary];
        const detailItem = response.caseBoard.en.caseDetail
          .caseDetailContentList
          ? [...response.caseBoard.en.caseDetail.caseDetailContentList]
          : [defaultDetail];
        setEnInputs({
          caseLang: Language.EN,
          caseTag: response.caseBoard.en.caseTag,
          caseTitle: response.caseBoard.en.caseTitle,
          caseThumbnail: response.caseBoard.en.caseThumbnail,
          caseDescription: response.caseBoard.en.caseDescription,
          ['caseDetail']: {
            caseTitleBg: response.caseBoard.en.caseDetail.caseTitleBg,
            caseCompany: response.caseBoard.en.caseDetail.caseCompany || '',
            caseCompanyLogo:
              response.caseBoard.en.caseDetail.caseCompanyLogo || '',
            caseOutline: response.caseBoard.en.caseDetail.caseOutline,
            ['caseDetailSummary']: {
              aboutCompany: aboutItem,
              theBenefit: benefitItem,
              whatWeDid: whatWeDidItem,
            },
            ['caseDetailContentList']: detailItem,
          },
        });
        //기등록된 key획득
        titleBgStrTmp = response.caseBoard.en.caseDetail.caseTitleBg.split('/');
        uploadedList.push(titleBgStrTmp[4]);
        if (response.caseBoard.en.caseDetail.caseCompanyLogo) {
          const companyLogoStrTmp =
            response.caseBoard.en.caseDetail.caseCompanyLogo.split('/');
          uploadedList.push(companyLogoStrTmp[4]);
        }
      }
    }
    setUploadedFileKeyList((prevList) => [...uploadedList, ...prevList]);
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  return (
    <div id="CaseBoardRegister">
      <ProgressBar />
      <div className="container-case-board-register">
        <Form id="CaseBoardRegistForm" name="CaseBoardRegistForm">
          <div className="item-case-board-register">
            <span>Case Study 등록 (</span>
            <span className="red-star"> </span>
            <span>은 필수값 입니다.)</span>
          </div>
          <hr />
          <div className="item-case-board-register">
            <div className="head red-star">case URL</div>
            <input
              id="caseUrl"
              className="have-btn"
              name="caseUrl"
              value={caseUrl}
              type="text"
              onChange={onChangeCaseUrl}
            />
            <button onClick={handleCaseURL}>중복확인</button>
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">공개범위</div>
            <CustomRadioBtn
              radioBtnValuesAndNames={[
                { value: State.ALL, name: '모두공개', disabled: false },
                { value: State.ADMIN, name: '비공개', disabled: true },
              ]}
              handleRadioBtn={handleCustomRadioStateChange}
              checkedCondition={caseState}
            />
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">언어</div>
            <CustomRadioBtn
              radioBtnValuesAndNames={[
                { value: Language.KO, name: '한국어', disabled: false },
                { value: Language.EN, name: 'English', disabled: false },
              ]}
              handleRadioBtn={handleCustomRadioLangChange}
              checkedCondition={currLang}
            />
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">Case Title</div>
            <Form.Control
              className="input"
              type="text"
              id="caseTitle"
              name="caseTitle"
              data-testid="caseTitle"
              value={
                currLang === Language.KO
                  ? koInputs.caseTitle ?? ''
                  : enInputs.caseTitle ?? ''
              }
              onChange={
                currLang === Language.KO
                  ? handleOnInputChangeKo
                  : handleOnInputChangeEn
              }
              maxLength={40}
            />
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">Case Tag</div>
            <Form.Control
              className="input"
              type="text"
              id="caseTag"
              name="caseTag"
              data-testid="caseTag"
              value={
                currLang === Language.KO
                  ? koInputs.caseTag ?? ''
                  : enInputs.caseTag ?? ''
              }
              onChange={
                currLang === Language.KO
                  ? handleOnInputChangeKo
                  : handleOnInputChangeEn
              }
              maxLength={10}
            />
          </div>
          <div className="item-case-board-register-upload">
            <div className="head red-star">Thumbnail</div>
            <div className="case-upload-detail">
              <div className="upload-image-input-wrapper case-img-input">
                <label
                  htmlFor="upload-image-caseThumbnail"
                  className="btn btn-primary"
                >
                  파일선택
                </label>
                <input
                  type="file"
                  accept="image/jpg,impge/png,image/jpeg,image/gif"
                  id="upload-image-caseThumbnail"
                  name="thumbnail"
                  onChange={handleImgUpload}
                  style={{ display: 'none' }}
                />
              </div>
              <input
                id="caseThumbnail"
                name="caseThumbnail"
                className="case-img-input-result"
                data-testid="caseThumbnail"
                value={currLang === Language.KO ? koThumbnail : enThumbnail}
                readOnly
              />
            </div>
            <button
              className="case-upload-detail-cancel-btn"
              name="caseThumbnail"
              onClick={handleImgUploadCancel}
            >
              업로드 취소
            </button>
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">Case Description</div>
            <Form.Control
              className="input"
              type="text"
              id="caseDescription"
              name="caseDescription"
              data-testid="caseDescription"
              value={
                currLang === Language.KO
                  ? koInputs.caseDescription ?? ''
                  : enInputs.caseDescription ?? ''
              }
              onChange={
                currLang === Language.KO
                  ? handleOnInputChangeKo
                  : handleOnInputChangeEn
              }
              maxLength={100}
            />
          </div>
          <div className="item-case-board-register">
            <span>Case Detail Infomation</span>
          </div>
          <hr />
          <div className="item-case-board-register-upload">
            <div className="head red-star">Case Title Background Image</div>
            <div className="case-upload-detail">
              <div className="upload-image-input-wrapper case-img-input">
                <label
                  htmlFor="upload-image-caseTitleBg"
                  className="btn btn-primary"
                >
                  파일선택
                </label>
                <input
                  type="file"
                  accept="image/jpg,impge/png,image/jpeg,image/gif"
                  id="upload-image-caseTitleBg"
                  name="bg"
                  onChange={handleImgUpload}
                  style={{ display: 'none' }}
                />
              </div>
              <input
                id="caseTitleBg"
                name="caseTitleBg"
                className="case-img-input-result"
                data-testid="caseTitleBg"
                value={currLang === Language.KO ? koBgImg : enBgImg}
                readOnly
              />
            </div>
            <button
              className="case-upload-detail-cancel-btn"
              name="caseTitleBg"
              onClick={handleImgUploadCancel}
            >
              업로드 취소
            </button>
          </div>
          <div className="item-case-board-register">
            <div className="head">Case Company</div>
            <Form.Control
              className="input"
              type="text"
              id="caseCompany"
              name="caseCompany"
              data-testid="caseCompany"
              value={
                currLang === Language.KO
                  ? koInputs.caseDetail.caseCompany ?? ''
                  : enInputs.caseDetail.caseCompany ?? ''
              }
              onChange={
                currLang === Language.KO
                  ? handleOnInputChangeDetailKo
                  : handleOnInputChangeDetailEn
              }
              maxLength={30}
            />
          </div>
          <div className="item-case-board-register-upload">
            <div className="head">Case Company Logo</div>
            <div className="case-upload-detail">
              <div className="upload-image-input-wrapper case-img-input">
                <label
                  htmlFor="upload-image-caseCompanyLogo"
                  className="btn btn-primary"
                >
                  파일선택
                </label>
                <input
                  type="file"
                  accept="image/jpg,impge/png,image/jpeg,image/gif"
                  id="upload-image-caseCompanyLogo"
                  name="logo"
                  onChange={handleImgUpload}
                  style={{ display: 'none' }}
                />
              </div>
              <input
                id="case"
                name="caseCompanyLogo"
                className="case-img-input-result"
                data-testid="caseCompanyLogo"
                value={currLang === Language.KO ? koCompLogo : enCompLogo}
                readOnly
              />
            </div>
            <button
              className="case-upload-detail-cancel-btn"
              name="caseCompanyLogo"
              onClick={handleImgUploadCancel}
            >
              업로드 취소
            </button>
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">Case Outline</div>
            <Form.Control
              as="textarea"
              className="input"
              type="text"
              id="caseOutline"
              name="caseOutline"
              data-testid="caseOutline"
              value={
                currLang === Language.KO
                  ? koInputs.caseDetail.caseOutline ?? ''
                  : enInputs.caseDetail.caseOutline ?? ''
              }
              onChange={
                currLang === Language.KO
                  ? handleOnInputChangeDetailKo
                  : handleOnInputChangeDetailEn
              }
              maxLength={400}
            />
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">aboutCompany</div>
            <div className="wrap-item-case-board">
              <div className="btnArea add">
                <button
                  id="about"
                  className="add-btn"
                  onClick={
                    currLang == Language.KO
                      ? handleOnAddBtnKo
                      : handleOnAddBtnEn
                  }
                >
                  +
                </button>
              </div>
              {currLang == Language.KO
                ? koInputs.caseDetail.caseDetailSummary.aboutCompany.map(
                    (item, idx) => (
                      <CaseSummary
                        key={'about' + idx}
                        i={idx}
                        name="about"
                        summaryContentArray={item}
                        updateSummary={onUpdateSummaryKo}
                        removeSummary={onRemoveSummaryKo}
                      />
                    )
                  )
                : enInputs.caseDetail.caseDetailSummary.aboutCompany.map(
                    (item, idx) => (
                      <CaseSummary
                        key={'about' + idx}
                        i={idx}
                        name="about"
                        summaryContentArray={item}
                        updateSummary={onUpdateSummaryEn}
                        removeSummary={onRemoveSummaryEn}
                      />
                    )
                  )}
            </div>
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">theBenefit</div>
            <div className="wrap-item-case-board">
              <div className="btnArea add">
                <button
                  id="benefit"
                  className="add-btn"
                  onClick={
                    currLang == Language.KO
                      ? handleOnAddBtnKo
                      : handleOnAddBtnEn
                  }
                >
                  +
                </button>
              </div>
              {currLang == Language.KO
                ? koInputs.caseDetail.caseDetailSummary.theBenefit.map(
                    (item, idx) => (
                      <CaseSummary
                        key={'benefit' + idx}
                        i={idx}
                        name="benefit"
                        summaryContentArray={item}
                        updateSummary={onUpdateSummaryKo}
                        removeSummary={onRemoveSummaryKo}
                      />
                    )
                  )
                : enInputs.caseDetail.caseDetailSummary.theBenefit.map(
                    (item, idx) => (
                      <CaseSummary
                        key={'benefit' + idx}
                        i={idx}
                        name="benefit"
                        summaryContentArray={item}
                        updateSummary={onUpdateSummaryEn}
                        removeSummary={onRemoveSummaryEn}
                      />
                    )
                  )}
            </div>
          </div>
          <div className="item-case-board-register">
            <div className="head red-star">whatWeDid</div>
            <div className="wrap-item-case-board">
              <div className="btnArea add">
                <button
                  id="whatWeDid"
                  className="add-btn"
                  onClick={
                    currLang == Language.KO
                      ? handleOnAddBtnKo
                      : handleOnAddBtnEn
                  }
                >
                  +
                </button>
              </div>
              {currLang == Language.KO
                ? koInputs.caseDetail.caseDetailSummary.whatWeDid.map(
                    (item, idx) => (
                      <CaseSummary
                        key={'whatWeDid' + idx}
                        i={idx}
                        name="whatWeDid"
                        summaryContentArray={item}
                        updateSummary={onUpdateSummaryKo}
                        removeSummary={onRemoveSummaryKo}
                      />
                    )
                  )
                : enInputs.caseDetail.caseDetailSummary.whatWeDid.map(
                    (item, idx) => (
                      <CaseSummary
                        key={'whatWeDid' + idx}
                        i={idx}
                        name="whatWeDid"
                        summaryContentArray={item}
                        updateSummary={onUpdateSummaryEn}
                        removeSummary={onRemoveSummaryEn}
                      />
                    )
                  )}
            </div>
          </div>
          <div className="item-case-board-register">
            <div className="head">Detail</div>
            <div className="wrap-item-case-board">
              <div className="btnArea add">
                <button
                  id="detail"
                  className="add-btn"
                  onClick={
                    currLang == Language.KO
                      ? handleOnAddBtnKo
                      : handleOnAddBtnEn
                  }
                >
                  +
                </button>
              </div>
              {currLang == Language.KO
                ? koInputs.caseDetail.caseDetailContentList.map((item, idx) => (
                    <CaseDetail
                      key={'detail' + idx}
                      i={idx}
                      caseDetailContentArray={item}
                      updateDetail={onUpdateDetailKo}
                      removeDetail={onRemoveDetailKo}
                    />
                  ))
                : enInputs.caseDetail.caseDetailContentList.map((item, idx) => (
                    <CaseDetail
                      key={'detail' + idx}
                      i={idx}
                      caseDetailContentArray={item}
                      updateDetail={onUpdateDetailEn}
                      removeDetail={onRemoveDetailEn}
                    />
                  ))}
            </div>
          </div>
          <div className="item-case-board-register">
            <div className="btnArea regist">
              <button
                className="btn"
                id="tempRegistBtn"
                onClick={handleTempRegistBtn}
              >
                임시저장
              </button>
              <button className="btn" id="registBtn" onClick={handleRegistBtn}>
                등록
              </button>
              <button className="btn" onClick={handleCancelBtn}>
                취소
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CaseBoardRegistForm;
