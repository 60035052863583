import React from 'react';
import './MainContact.scss';
import { useTranslation } from 'react-i18next';
import Footer from '../../../layouts/Footer';
import contactUsArrow from '../../../assets/main/mainContactUs/contactUsArrow.svg';
import contactUsUnderLine from '../../../assets/main/mainContactUs/contactUsUnderLine.svg';

const MainContact = () => {
  const { t } = useTranslation();
  return (
    <div className="fullpage-slide main-contact">
      <div className="end-page">
        <span className="main-contact-item">{t('main.MainContact.txt1')}</span>
        <span className="main-contact-item">
          <span className="main-contact-item-strong">
            {t('main.MainContact.txt2')}
          </span>
          {t('main.MainContact.txt3')}
        </span>
        <div
          className="main-contact-us"
          onClick={() => window.open('https://lgcns.com/contact-us', '_blank')}
        >
          <span>{t('main.MainContact.contact')}</span>{' '}
          <img src={contactUsArrow} />
          <img className="main-contact-underline" src={contactUsUnderLine} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainContact;
