import { ProgressAction, CHANGE_PROGRESS_STATUS } from '../actions/progress';

type ProgressStatus = {
  progress: number;
};

const initialState: ProgressStatus = {
  progress: 0,
};

const progress = (
  state: ProgressStatus = initialState,
  action: ProgressAction
): ProgressStatus => {
  switch (action.type) {
    case CHANGE_PROGRESS_STATUS:
      return { progress: action.progress };
    default:
      return state;
  }
};

export default progress;
