import { AboutData } from './AboutModel';
import microserviceArchitecture from '../../../assets/about/microserviceArchitecture.png';
import modernUX from '../../../assets/about/modernUX.png';
import modernWeb from '../../../assets/about/modernWeb.png';
import testAutomation from '../../../assets/about/testAutomation.png';
import agileDevOps from '../../../assets/about/agileDevOps.png';
import cicdPipelines from '../../../assets/about/cicdPipelines.png';
import devOps from '../../../assets/about/devOps.png';
import digitalTechnology from '../../../assets/about/digitalTechnology.png';
import iac from '../../../assets/about/iac.png';

export const aboutKo: AboutData[] = [
  {
    title: 'Modern Web 개발',
    desc: `Cloud Managed 서비스 및 오픈소스 S/W를 최대한 활용한 Modern Web 개발`,
    icon: modernWeb,
  },
  {
    title: 'DevOps 체계 구축',
    desc: `AM 서비스에 최적화된 클라우드 아키텍처 설계/구축 및 IaC, GitOps를 적용한 DevOps 체계 구축`,
    icon: devOps,
  },
  {
    title: 'Test Automation 적용',
    desc: `개발부터 배포까지의 모든 과정에 Test Automation을 적용하여 품질 확보`,
    icon: testAutomation,
  },
  {
    title: 'MSA (Microservice Architecture)',
    desc: `기능별 독립적인 서비스로 개발하여 사용량 증가에 따른 유연한 확장과 장애 영향을 최소화 합니다.`,
    icon: microserviceArchitecture,
  },
  {
    title: 'Agile / DevOps',
    desc: `짧은 기간에 기능을 개발하고 고객에게 피드백을 받아 지속적으로 개선하는 방식으로 업무를 진행합니다.`,
    icon: agileDevOps,
  },
  {
    title: 'Digital Technology',
    desc: `M/L, D/L, Data analytics, IoT 등의 디지털 기술을 Cloud 서비스를 통하여 효과적으로 활용 가능합니다.`,
    icon: digitalTechnology,
  },
  {
    title: 'Modern UX',
    desc: `제품 아이디어 탐색 단계부터 최종 사용자 경험 관점에서 업무를 분석하고 설계합니다.`,
    icon: modernUX,
  },
  {
    title: 'CI/CD Pipelines',
    desc: `개발된 제품의 품질을 체크하고 빠르게 고객에게 제공하는 Mechanism을 제공합니다.`,
    icon: cicdPipelines,
  },
  {
    title: 'IAC (Infra As Code)',
    desc: `Container, Serverless 인프라를 코드로 구축하고 몇 번을 실행하더라도 동일한 결과를 만들어 낼 수 있습니다.`,
    icon: iac,
  },
];
