import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import './CaseBoardRegistForm.scss';

import { SummaryContent } from '../../../../service/case/model/Case';

export interface Props {
  i: number;
  name: string;
  summaryContentArray: SummaryContent;
  updateSummary: (name: string, id: number, data: SummaryContent) => void;
  removeSummary: (name: string, id: number) => void;
}

const CaseSummary: React.FC<Props> = (props: Props): ReactElement => {
  const { summaryInfo, summaryDescription } = props.summaryContentArray;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    props.updateSummary(props.name, props.i, {
      ...props.summaryContentArray,
      [event.target.name]: event.target.value,
    });
  };

  const onDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    props.removeSummary(props.name, props.i);
  };

  return (
    <div className="bodyInfo">
      <div className="case-btnArea">
        {props.i === 0 ? (
          <></>
        ) : (
          <button className="remove-btn" key={props.i} onClick={onDelete}>
            x
          </button>
        )}
      </div>
      <div className="case-summary-wrap">
        <div className="case-summary-item-wrap">
          <div className="head red-star">summaryInfo</div>
          <Form.Control
            as="textarea"
            className="input"
            type="text"
            id="summaryInfo"
            name="summaryInfo"
            data-testid="summaryInfo"
            onChange={onChange}
            value={summaryInfo}
          ></Form.Control>
        </div>
      </div>
      <div className="case-summary-wrap">
        <div className="case-summary-item-wrap">
          <div className="head">summaryDescription</div>
          <Form.Control
            as="textarea"
            className="input"
            type="text"
            id="summaryDescription"
            name="summaryDescription"
            data-testid="summaryDescription"
            onChange={onChange}
            value={summaryDescription}
          ></Form.Control>
        </div>
      </div>
    </div>
  );
};

export default CaseSummary;
