import Enterprise01Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds01.png';
import Enterprise02Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds02.png';
import Enterprise03Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds03.png';
import Enterprise04Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds04.png';

import { RdsContentData, AwsRdsContentLayout } from './AwsRdsModel';

export const RdsContentDatasKo: RdsContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsRdsContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'LG CNS는 Amazon RDS를 통해 다양한 관계형 데이터베이스 서비스를 제공합니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Amazon RDS는 관계형 데이터베이스를 제공하는 관리형 서비스입니다. Amazon Aurora, PostgreSQL, MySQL, MariaDB, Oracle, MS SQL, Db2 엔진을 지원합니다. OS와 데이터베이스 소프트웨어 설치 및 패치, 데이터 백업 및 복구, 스케일링 등 복잡한 데이터베이스 관리를 대신하고, 손쉬운 마이그레이션 또한 가능합니다. 고객은 간편하게 데이터베이스를 구성하여 어플리케이션 개발에 집중할 수 있습니다. LG CNS는 Amazon RDS를 이용하여 유연하게 확장 가능하고 비용 효율적인 관계형 데이터베이스 구축과 마이그레이션을 지원합니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsRdsContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '높은 가용성과 확장성을 통해 데이터베이스가 안정적으로 운영됩니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: '자동 백업을 통한 특정 시점으로 복구가 가능하며, 수동 스냅샷을 생성하고 장기간 보관할 수 있습니다. 인스턴스 클래스를 변경하여 몇 분 내로 컴퓨팅을 스케일 업 하고, 중단 없이 추가 스토리지를 프로비저닝하고, 읽기 복제본을 추가하여 읽기 트래픽의 처리량을 스케일 아웃할 수 있습니다. 다른 가용 영역에 데이터를 동기식 복제하는 다중 AZ 배포를 사용하여 데이터베이스 가용성 및 내구성을 높일 수 있습니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsRdsContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: '모든 데이터에 대해 강력한 보안을 보장합니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon RDS는 다양한 보안 인증을 취득하였으며 네트워크 보안, 접근 제어, 암호화를 통해 강력한 보안을 제공합니다. 독립된 네트워크에 DB를 구축하여 원하는 서버에서만 접근할 수 있도록 하거나, 각 DB마다 IP 방화벽을 구축할 수 있습니다. 또한 AWS IAM를 통해 엄격한 접근 제어가 가능합니다. 모든 이벤트와 API 호출은 Amazon CloudTrail에 저장할 수 있습니다. Amazon KMS를 사용하여 스토리지는 물론 백업과 복제본, 스냅샷까지 강력하게 암호화할 수 있습니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsRdsContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '사용한 리소스 양에 대해서만 요금이 책정되므로 비용을 효율적으로 관리할 수 있습니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon RDS는 최소 비용 없이 사용한 만큼만 지불합니다. 인스턴스 비용과 스토리지 용량, 백업 용량, 네트워크 비용이 월별 비용으로 합산됩니다. 1년 또는 3년 기간으로 인스턴스를 예약해서 사용하면 인스턴스 비용을 크게 절감할 수 있습니다. 기존에 보유한 라이선스가 있는 경우에는 추가 비용을 지불하지 않고 라이선스를 RDS와 함께 사용할 수 있습니다. 비용은 리전별로 상이합니다.',
            },
          ],
        },
      ],
    },
  }
];
