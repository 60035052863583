import Enterprise01Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront01.png';
import Enterprise02Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront02.png';
import Enterprise03Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront03.png';
import Enterprise04Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront04.png';

import { CloudFrontContentData, AwsCloudFrontContentLayout } from './AwsCloudFrontModel';

export const CloudFrontContentDatasKo: CloudFrontContentData[] = [  
  {
    img: Enterprise01Img,
    layout: AwsCloudFrontContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'LG CNS는 Amazon CloudFront를 통해 다양한 서비스를 제공합니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: '글로벌한 시장에 빠른 서비스를 딜리버리하기 위해 고객들은 CDN(Contents Delivery Network)을 도입하고 있습니다. LG CNS에서는 Amazon CloudFront를 활용하여 고객의 웹 콘텐츠 및 애플리케이션 서비스를 전세계로 빠르고 안전하게 최종 사용자들에게 전달할 수 있도록 설계부터 환경 구성까지 지원하고 있습니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsCloudFrontContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '다양한 유형의 콘텐츠를 우수한 성능으로 제공',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Amazon CloudFront는 정적 및 동적 콘텐츠뿐만 아니라 비디오 스트리밍, API 호출 등 다양한 형식의 콘텐츠를 쉽게 제공할 수 있습니다. 또한, 전 세계에 분산된 엣지 로케이션을 통해 콘텐츠를 캐시하고 최종 사용자에게 최적의 경로로 전송하여 성능을 최적화 합니다. 이로 인해 고객은 우수한 서비스 로딩 속도와 뛰어난 가용성을 경험할 수 있습니다. ',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsCloudFrontContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'LG CNS는 AWS의 다양한 서비스들과 함께 고객의 데이터를 안전하게 보호합니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon CloudFront는 DDoS 공격 및 다른 보안 위협으로부터 웹 애플리케이션을 보호하는데 도움이 되는 다양한 보안 기능을 제공합니다. LG CNS는 AWS의 다양한 서비스들과 함께 CloudFront를 설계 및 구축하여 SSL/TLS 암호화, 웹 애플리케이션 방화벽, IP 주소 Whitelist 등을 활용하여 고객의 데이터를 안전하게 보호 할 수 있습니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsCloudFrontContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '유연한 비용 체계',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon CloudFront는 트래픽 볼륨에 따라 과금되므로 필요한 만큼의 리소스를 사용할 수 있습니다. 또한 캐시 컨트롤, 지역 별 엣지 로케이션 선택(클래스 선택), 비용 최적화된 캐싱 전략, 다양한 요금 체계와 유연한 가격 정책 등을 통해 비용 최적화된 서비스를 사용 할 수 있습니다.',
            },
          ],
        },
      ],
    },
  }
];
