import CommonResponse from '../common/model/CommonResponse';
import BaseService from '../BaseService';
import SessionService from '../SessionService';
import { Subject } from '../common/model/Service';
import { blogData, blogTempData } from './data/BlogData';
import { Blog } from './model/Blog';

const mockResponseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {},
};

const mockResponseFail: CommonResponse = {
  successOrNot: 'N',
  statusCode: 'FAIL',
  errorMessage: '',
};

const mockBlogDetailResponseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: undefined,
};

export default class BlogService extends BaseService {
  async getAllMockBlogList(
    offset?: number,
    pageSize?: number
  ): Promise<Blog[]> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/blog';
    const serviceName = Subject.BLOG;
    const params = pageSize;
    const body = null;

    mockResponseSuccess.data = blogData.slice(offset, pageSize);

    try {
      response = mockResponseSuccess;
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as Array<Blog>;
  }

  async getMockBlogDetail(param: string): Promise<Blog> {
    let response: CommonResponse;

    const method = 'GET';
    const url = param;
    const serviceName = Subject.BLOG;
    const params = null;
    const body = null;

    mockBlogDetailResponseSuccess.data = blogData.filter(
      (blog) => blog.blogUrl === url
    )[0];

    try {
      response = mockBlogDetailResponseSuccess;
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as Blog;
  }

  async getAllBlogList(
    offset?: number,
    pageSize?: number
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/blog';
    const serviceName = Subject.BLOG;
    const params = pageSize;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    // 세션 만료 처리
    if (
      response.successOrNot === 'N' &&
      (response.statusCode === 'BAD.REQUEST.ERR' ||
        response.statusCode === 'UNAUTHORIZED.ERR')
    ) {
      const sessionService = new SessionService();
      sessionService.deleteSessionInfo();
    }

    return response;
  }

  // 현재 페이지의 블로그 목록 가져오기
  async getAllBlogListUser(option: string): Promise<Blog[]> {
    let response: CommonResponse;
    const method = 'GET';
    const url =
      '/blog?direction=desc&key=' +
      (option === 'latest' ? 'registeredDate' : 'blogView');
    const serviceName = Subject.BLOG;
    const params = '';
    const body = null;

    try {
      response = await this.fnRest(
        this.userHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data.all : []
    ) as Array<Blog>;
  }

  async getBlogDetail(param: string): Promise<Blog> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/blog/' + param;
    const serviceName = Subject.BLOG;
    const params = null;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as Blog;
  }

  async createBlog(request: Blog): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/blog';
    const serviceName = Subject.BLOG;
    const params = null;
    const body = request;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async updateBlog(request: Blog, idx: string): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/blog/' + idx;
    const serviceName = Subject.BLOG;
    const params = null;
    const body = request;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async deleteBlog(blog: Blog): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/blog/' + blog.blogUrl;
    const serviceName = Subject.BLOG;
    const params = null;
    const body = blog;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempBlogList(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/blog/tmp';
    const serviceName = Subject.BLOG;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempBlogDetail(blogUrl: string): Promise<Blog> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/blog/tmp/' + blogUrl;
    const serviceName = Subject.BLOG;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async postTempBlogRegist(blogRequest: Blog): Promise<Blog[]> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/admin/blog/tmp';
    const serviceName = Subject.BLOG;
    const params = '';
    const body = blogRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async putTempBlogEdit(blogRequest: Blog, blogUrl: string): Promise<Blog[]> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/admin/blog/tmp/' + blogUrl;
    const serviceName = Subject.BLOG;
    const params = '';
    const body = blogRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async deleteTempBlog(blogUrl: string): Promise<Blog[]> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/admin/blog/tmp/' + blogUrl;
    const serviceName = Subject.BLOG;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }
}
