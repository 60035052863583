import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './CaseOrderSection.scss';
import { CaseOrderingData } from '../../../../service/case/model/Case';
import CaseService from '../../../../service/case/CaseService';

import { useDispatch } from 'react-redux';
import { changeLoadingStatus } from '../../../../redux/actions/loading';

type OrderProps = {
  open: boolean;
  close: () => void;
  caseList: CaseOrderingData[];
};

const CaseOrderSection = (props: OrderProps) => {
  const { open, close } = props;
  const [caseListData, setCaseListData] = useState<CaseOrderingData[]>([]);
  const [clicked, setClicked] = useState<boolean>(false);
  const [curClick, setCurClick] = useState<number>(-1);
  const caseService = new CaseService();
  const dispatch = useDispatch();

  const changeOrder = async () => {
    const tempList = caseListData.slice();
    tempList.map((temp, key) => {
      temp.caseOrderIdx = key + 1;
    });
    dispatch(changeLoadingStatus(true));
    await caseService.putCaseOrder(tempList);
    dispatch(changeLoadingStatus(false));
    alert('변경되었습니다.');
    close();
    window.location.replace('/admin/case');
  };

  useEffect(() => {
    setCaseListData(props.caseList);
    setCurClick(-1);
    setClicked(false);
  }, [open]);

  const checkItemClick = (index: number) => {
    if (index === curClick) {
      setClicked(false);
      setCurClick(-1);
    } else {
      setCurClick(index);
      setClicked(true);
    }
  };

  const changeOrderToUp = () => {
    if (clicked && curClick !== 0) {
      const tempList = caseListData.slice();
      const tempData = tempList[curClick];
      tempList.splice(curClick, 1);
      tempList.splice(curClick - 1, 0, tempData);
      setCaseListData(tempList);
      setCurClick(curClick - 1);
    }
  };

  const changeOrderToDown = () => {
    if (clicked && curClick !== caseListData.length - 1) {
      const tempList = caseListData.slice();
      const tempData = tempList[curClick];
      tempList.splice(curClick, 1);
      tempList.splice(curClick + 1, 0, tempData);
      setCaseListData(tempList);
      setCurClick(curClick + 1);
    }
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      <section>
        <header>
          Case-Study 순서 변경
          <Button className="close" onClick={close}>
            &times;
          </Button>
        </header>
        <main>
          {caseListData
            ? caseListData.map((caseData, key) => (
                <div
                  key={caseData.caseOrderIdx}
                  className={
                    curClick === key
                      ? 'case-order-item-clicked'
                      : 'case-order-item'
                  }
                  onClick={() => {
                    checkItemClick(key);
                  }}
                >
                  {caseData.caseOrderIdx}. {caseData.caseBoard.ko.caseTitle} (
                  {caseData.caseUrl})
                </div>
              ))
            : null}
        </main>
        <footer>
          <Button
            variant="secondary"
            className="case-order-button"
            onClick={changeOrderToUp}
          >
            ▲
          </Button>
          <Button
            variant="secondary"
            className="case-order-button"
            onClick={changeOrderToDown}
          >
            ▼
          </Button>
          <Button
            variant="secondary"
            className="case-order-button"
            onClick={changeOrder}
          >
            변경
          </Button>
          <Button
            variant="secondary"
            className="case-order-button"
            onClick={close}
          >
            취소
          </Button>
        </footer>
      </section>
    </div>
  );
};

export default CaseOrderSection;
