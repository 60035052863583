import Enterprise01Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation01.jpg';
import Enterprise02Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation02.jpg';
import Enterprise03Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation03.jpg';
import Enterprise04Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation04.jpg';

import {
  CloudFormationContentData,
  AwsCloudFormationContentLayout,
} from './AwsCloudFormationModel';

export const cloudformationContentDatasEn: CloudFormationContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsCloudFormationContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'LG CNS specializes in supporting customers infrastructure configurations using AWS CloudFormation.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `As cloud transitions, including AWS, are spreading, many companies are introducing Infrastructure as Code (IaC) for automation and stable operation of infrastructure configurations. AWS CloudFormation is a service that generates AWS resources as code. LG CNS uses AWS CloudFormation and Git to manage infrastructure resources as code. Through this, it supports the stable operation of the service's infrastructure through shape management of code and tracking of changes.`,
              options: {
                //size: 'content-lg',
                //bold: true,
                //color: 'primary',
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsCloudFormationContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Enables the implementation of Continuous Deployment.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Continuous deployment is a development environment in which changed code is automatically tested and deployed. Using AWS CloudFormation and the deployment pipeline together, you can automatically deploy and test infrastructure code changes. LG CNS enables customers to quickly deploy and manage their infrastructure with pipelines based on their requirements, and helps them realize continuous deployment.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsCloudFormationContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Effectively managing complex infrastructure needs with CloudFormation.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS CloudFormation provides a variety of advanced features, including nested stacks and conditional resource generation, to meet complex infrastructure requirements. It divides complex architectures into modules with nested stacks to simplify management and deploy resources only under specific conditions through conditional resource generation. In addition, a variety of features, such as custom resources and stack policies, enable more granular infrastructure management. These features enable CloudFormation to respond quickly and flexibly to diverse and complex infrastructure needs.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsCloudFormationContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Optimizes costs with a cost-effective payment structure based on defined resources.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Using AWS CloudFormation, only the infrastructure resources defined in the template are paid for. This approach reduces unnecessary infrastructure maintenance costs and allows for dynamic scaling of infrastructure as needed, without the necessity of pre-purchasing or maintaining a large volume of resources. Such flexibility significantly helps in managing budgets efficiently.',
            },
          ],
        },
      ],
    },
  },
];

