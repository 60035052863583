import Enterprise01Img from '../../../../assets/awsProductCard/awsLambda/lambda01.png';
import Enterprise02Img from '../../../../assets/awsProductCard/awsLambda/lambda02.jpg';
import Enterprise03Img from '../../../../assets/awsProductCard/awsLambda/lambda03.jpg';
import Enterprise04Img from '../../../../assets/awsProductCard/awsLambda/lambda04.jpg';

import {
  LambdaContentData,
  AwsLambdaContentLayout,
} from './AwsLambdaModel';

export const lambdaContentDatasEn: LambdaContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsLambdaContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'LG CNS supports the implementation of secure and scalable modern applications using AWS Lambda',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `As customers adapt various architectures for their business needs, many customers start to use serverless architecture. LG CNS uses lambda for application backend, one of a architecture for microservice, an ETL tool for data lake, a maintanance tool to operate systems, and so on.

              LG CNS build automated CICD platform to use Lambda seamlessly. `,
              options: {
                //size: 'content-lg',
                //bold: true,
                //color: 'primary',
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsLambdaContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Run code without provisioning or managing infrastructure.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS Lambda manages all the infrastructure to run your code on highly available, fault tolerant infrastructure, freeing you to focus on building differentiated backend services. With Lambda, you never have to update the underlying operating system (OS) when a patch is released, or worry about resizing or adding new servers as your usage grows. AWS Lambda seamlessly deploys your code, handles all the administration, maintenance, and security patches, and provides built-in logging and monitoring through Amazon CloudWatch.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsLambdaContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Automatically respond to code execution requests at any scale, from a dozen events per day to hundreds of thousands per second.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS Lambda invokes your code only when needed, and automatically scales to support the rate of incoming requests without any manual configuration. There is no limit to the number of requests your code can handle. AWS Lambda typically starts running your code within milliseconds of an event. Since Lambda scales automatically, the performance remains consistently high as the event frequency increases. Since your code is stateless, Lambda can start as many instances as needed without lengthy deployment and configuration delays. ',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsLambdaContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Save costs by paying only for the compute time you use-by per-millisecond-instead of provisioning infrastructure upfront for peak capacity.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'With AWS Lambda, you pay for execution duration rather than server unit. When using Lambda functions, you only pay for requests served and the compute time required to run your code. Billing is metered in increments of one millisecond, enabling easy and cost-effective automatic scaling from a few requests per day to thousands per second. With Provisioned Concurrency, you pay for the amount of concurrency you configure and the duration that you configure it. When Provisioned Concurrency is enabled and your function is executed, you also pay for requests and execution duration. ',
            },
          ],
        },
      ],
    },
  },
];

