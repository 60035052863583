import './AwsCloudFormationSection.scss';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { CloudFormationContentData } from '../../../../service/data/awsProductCard/awsCloudFormation/AwsCloudFormationModel';
import { cloudformationContentDatasKo } from '../../../../service/data/awsProductCard/awsCloudFormation/AwsCloudFormationDataKo';
import { cloudformationContentDatasEn } from '../../../../service/data/awsProductCard/awsCloudFormation/AwsCloudFormationDataEn';
import AwsCloudFormationContentArea from './AwsCloudFormationContentArea';
import { Language } from '../../../../service/common/model/enum/Common.enum';

const AwsCloudFormationSection: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const [awsCloudFormationContents, setAwsCloudFormationContents] = useState<
    CloudFormationContentData[]
  >(i18n.language === Language.KO ? cloudformationContentDatasKo : cloudformationContentDatasEn);

  useEffect(() => {
    setAwsCloudFormationContents(
      i18n.language === Language.KO ? cloudformationContentDatasKo : cloudformationContentDatasEn
    );
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }

  }, []);

  return (
    <div id="AwsCloudFormationSection" data-testid="testAwsCloudFormationSection">
      <div className="eks-anywhere fullpage-slide">
        <div className="header-top" data-testid="header-top">
          <div className="top-tit detail-container">
            <h2>Amazon CloudFormation</h2>
          </div>
        </div>
        <div className="eks-anywhere-body">
          <div className="eks-anywhere-title-cont">
            <div className="title-txt-box">

              <span className="title-lg">
                {'Amazon CloudFormation'}
              </span>
            </div>
          </div>
          {awsCloudFormationContents.map((content, idx) => {
            return (
              <React.Fragment key={idx}>
                <AwsCloudFormationContentArea content={content} />
              </React.Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AwsCloudFormationSection;
