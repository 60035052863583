import eksAnywhereOverallImg from '../../../assets/eksAnywhere/eksAnywhereOverallImg.png';
import eksAnywhereArchitectureImg from '../../../assets/eksAnywhere/eksAnywhereArchitectureImg.png';
import eksAnywhereFunctionImg from '../../../assets/eksAnywhere/eksAnywhereFunctionImg.png';
import eksValue01Img from '../../../assets/eksAnywhere/eksValue01Img.png';
import eksValue02Img from '../../../assets/eksAnywhere/eksValue02Img.png';
import eksValue03Img from '../../../assets/eksAnywhere/eksValue03Img.png';
import eksValue04Img from '../../../assets/eksAnywhere/eksValue04Img.png';

import {
  EksCaseData,
  EksContentData,
  EksContentLayout,
  EksValueData,
} from './EksAnywhereModel';

export const eksContentDatasEn: EksContentData[] = [
  {
    img: eksAnywhereOverallImg,
    layout: EksContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Amazon EKS Anywhere',
        },
        {
          text: ' is ',
          options: {
            size: 'title-sm',
          },
        },
        {
          text: 'the new deployment option for Amazon EKS.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `Following the VMware release in September 21, the Bare metal release in June 22, enabling EKS Anywhere to be deployed in a wider range of customer environments. EKS runs on Amazon's own cloud, but EKS Anywhere can also run on private infrastructure. EKS Anywhere allows you to use all the services and management tools available on AWS for EKS Anywhere and uses the same Kubernetes distribution ( `,
            },
            {
              text: 'Amazon ',
              options: {
                bold: true,
              },
            },
            {
              text: 'EKS Distro',
              options: {
                bold: true,

                color: 'primary',
                underline: 'underline',
              },
            },
            {
              text: ' ) as EKS.',
            },
          ],
        },
        {
          type: 'definition',
          contents: [
            {
              text: '*EKS Distro - ',
              options: {
                color: 'primary',
                bold: true,
              },
            },
            {
              text: 'An open-source Kubernetes distribution used by Amazon EKS, consisting of Kubernetes control unit components (kube-controller-manager, etcd and CoreDNS) and Kubernetes operator node components (kubelet, CNI plug-in, CSI Sidecar image, Metrics Server, and AWS-AM-authenticator).',
            },
          ],
        },
      ],
    },
  },
  {
    img: eksAnywhereArchitectureImg,
    layout: EksContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Architecture',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'EKS Anywhere is structured as a managed environment to create and operate Control and Data Plan for Kubernetes clusters on the Bare metal server or VMware environment in the customer data center. ',
              options: {
                bold: true,
              },
            },
            {
              text: 'The Bare metal server deployment uses Tinkerbell to control servers and configure clusters, and in a VMware environment, VMs are created and clusters are configured through the vSphere API. Both versions can be configured for your deployment with eks anywhere-only cli.You can also selectively configure the EKS Connector to use the existing Amazon EKS console in EKS Anywhere.',
            },
          ],
        },
        {
          type: 'default',
          contents: [
            {
              text: `EKS Anywhere provides an automated upgrade environment on top of the customer's resources to update the Kubernetes Cluster, various AWS services, Kubernetes, and various support tools. It consists of Admin Machine, EKS-A Control Plane Node, and EKS-A Data Plane Node for cluster installation and kubectl commands, and provides convenience to quickly configure the environment by referring to the image required to configure the container environment.`,
              options: {
                bold: true,
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: eksAnywhereFunctionImg,
    layout: EksContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Functions',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
              options: {
                bold: true,
              },
            },
            {
              text: 'You can create clusters based on EKS Distro and provide the foundation for efficient and reliable management of Kubernetes cluster operations such as cluster automated management and package upgrades.',
            },
          ],
        },
        {
          type: 'default',
          contents: [
            {
              text: 'In particular, EKS Anywhere validates and provides the various tools required in an enterprise environment. These tools can be easily installed and managed through the EKS Anywhere curating package. In addition to the Harbor currently available, various tools such as Emissary-Ingress and MetalLB will be added. You can also manage and monitor clusters and environments with the AWS Console connected by EKS Connector.',
            },
          ],
        },
      ],
    },
  },
  {
    layout: EksContentLayout.TXT_TXT,
    content: {
      title: [
        {
          text: 'Advantage',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'The advantage of EKS Anywhere is that',
              options: {
                bold: true,
                size: 'content-lg',
              },
            },
            {
              text: `customers can use Amazon's Kubernetes software`,
              options: {
                bold: true,
                size: 'content-lg',
                prevLine: true,
                underline: 'primary-underline',
              },
            },
            {
              text: 'within the On-Premise data center.',
              options: {
                bold: true,
                size: 'content-lg',
                prevLine: true,
                underline: 'primary-underline',
              },
            },
          ],
        },
      ],
    },
    content2: {
      title: [
        {
          text: '',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `If you need EKS in an environment where Amazon's public cloud infrastructure is unavailable, EKS Anywhere provides the solution. This allows EKS Anywhere to lower the total cost of ownership if an enterprise hosts workloads on its own servers. EKS Anywhere can also be useful if you need to keep your workloads on-premise for compliance.`,
              options: {
                bold: true,
              },
            },
          ],
        },
        {
          type: 'default',
          contents: [
            {
              text: 'EKS Anywhere also reduces the complexity of management tools by providing installable software packages for creating and operating an On-Premise Kubernetes cluster and automation tools for supporting the cluster lifecycle. EKS Anywhere is fully supported by AWS and utilizes the EKS console to view all Kubernetes clusters running anywhere, making it easy to manage.',
            },
          ],
        },
      ],
    },
  },
];

export const eksValueDatasEn: EksValueData[] = [
  {
    img: eksValue01Img,
    index: '01',
    main: [
      {
        text: [
          {
            val: 'Provides operational convenience',
          },
        ],
      },
      {
        text: [
          {
            val: 'to customers running',
          },
        ],
      },
      {
        text: [
          {
            val: 'Kubernetes ',
            bold: true,
          },
          {
            val: 'On-Premise',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: 'EKS Anywhere enables the creation of Kubernetes clusters, configuration of production environments, software updates, backup and recovery processes, ',
            bold: true,
          },
          {
            val: 'eliminating the need for customers to purchase and operate a number of management solutions as usual.',
          },
        ],
      },
    ],
  },
  {
    img: eksValue02Img,
    index: '02',
    main: [
      {
        text: [
          {
            val: 'Eliminates the burden on customers',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: 'to maintain upstream Kubernetes',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: 'and security patches.',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: 'Addresses the ultimate challenges of application modernization',
          },
        ],
      },
      {
        text: [
          {
            val: 'by providing an environment where you can focus on business value.',
          },
        ],
      },
    ],
  },
  {
    img: eksValue03Img,
    index: '03',
    main: [
      {
        text: [
          {
            val: 'Updates critical security patches',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: 'for expired versions',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: 'of community support.',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: 'The community version of Kubernetes offers three Minor versions for nine months, but EKS Anywhere offers four Minor versions for 14 months, extending the Kubernetes version and allowing customers to extend the upgrade period relative to the community.',
          },
        ],
      },
    ],
  },
  {
    img: eksValue04Img,
    index: '04',
    main: [
      {
        text: [
          {
            val: 'Reducing',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: 'container maintenance complexity',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: 'in a hybrid cloud environment.',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: 'If the container solutions operating in Public and On-premise are different, each operation management method and management solution must be different and operated separately. With EKS Anywhere, the AWS console dashboard provides a comprehensive control and operational management of the on-premise container environment. In addition, AWS-provided curating packages make it easy to install and manage AWS-validated compatible software.',
          },
        ],
      },
    ],
  },
];

export const eksCaseDatasEn: EksCaseData[] = [
  {
    index: '01',
    title: [
      { txt: 'A convenient way to implement AI/ML services' },
      {
        txt: '- Train models on AWS and run inferences on-premises',
      },
    ],
    summary:
      'You can train ML models using AWS GPUs, and run inference by importing trained ML models into On-premise. Customers are free to leverage and return AWS GPUs when they need them, without having to buy expensive GPUs.',
  },
  {
    index: '02',
    title: [
      { txt: 'EKS Anywhere with future workload migrations in mind' },
      {
        txt: 'when your pre-invested infrastructure needs to be used for the time being',
      },
    ],
    summary:
      'EKS Anywhere allows you to use the same EKS tool on-premise. By consistently configuring the on-premise infrastructure environment with AWS, you can move Kubernetes-based workloads to AWS easier and faster when equipment depreciation ends in the future.',
  },
  {
    index: '03',
    title: [{ txt: 'In case you need to keep large data sets on-premise' }],
    summary:
      'If you need to keep your data on-premise due to legal regulations, EKS Anywhere helps you move only stateless applications to the cloud while keeping your data in its original location.',
  },
  {
    index: '04',
    title: [{ txt: 'Leverage Auto-Scaling, a Public Cloud Advantage' }],
    summary: `Seasonal workloads can require large amounts of computing (five to 10 times the baseline) for days or weeks. Private Cloud can also implement Auto-Scaling, but it's bound to be limited in capacity expansion. When configured with EKS Anywhere, you can instantly scale to the Public Cloud, providing temporary, timely delivery of the compute capacity you need, enabling your customers to run their workloads cost-effectively based on On-premise and Public Cloud.`,
  },
  {
    index: '05',
    title: [{ txt: 'Leverage the benefits of distributed clouds' }],
    summary: `In recent years, data location dependencies, network latency issues, and security compliance are taking into account the adoption of distributed clouds. EKS Anywhere can be used to connect IoT devices within a customer's data center and deploy network latency-sensitive applications.`,
  },
];
