import moment from 'moment';

export const getIsMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const parseStringToDate = (str: string): string => {
  const reg = /\d{14}/; // 숫자로 이루어진 14자리 string 만
  if (str.match(reg)) {
    const year = str?.substring(0, 4);
    const month = str?.substring(4, 6);
    const day = str?.substring(6, 8);
    const hour = str?.substring(8, 10);
    const min = str?.substring(10, 12);
    const sec = str?.substring(12, 14);
    return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
  } else {
    return '';
  }
};

export const isEmptyObject = (obj: any): boolean => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) return true;
  return false;
};

export const getSessiontExpirationTime = (): string => {
  return new Date().getTime() + 60 * 1000 * 30 + '';
};

export const getStringDateFormat = (
  date: string,
  fromFormat: string,
  toFormat: string
): string => {
  return moment(date, fromFormat).format(toFormat);
};

export const isFieldEmpty = (str: string) => {
  return str === '' || str === null;
};
export const isBlank = (str: string) => {
  return (str || '').trim() === '';
};
