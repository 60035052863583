import './AwsProductPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import AwsProductSection from './components/AwsProductSection';

const AwsProductPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsProductPageContainer">
        <AwsProductSection />
      </div>
    </Container>
  );
};

export default AwsProductPageContainer;
