import { LogoutAction, CHANGE_LOGOUT_STATUS } from '../actions/logout';

type LogoutStatus = {
  logout: boolean;
};

const initialState: LogoutStatus = {
  logout: false,
};

const logout = (
  state: LogoutStatus = initialState,
  action: LogoutAction
): LogoutStatus => {
  switch (action.type) {
    case CHANGE_LOGOUT_STATUS:
      return { logout: action.logout };
    default:
      return state;
  }
};

export default logout;
