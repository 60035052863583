import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import GlobalStyles from './style/GlobalStyles';
import MainPage from './pages/main/MainPage';
import AdminPage from './pages/admin/AdminPage';
import { getIsMobile } from './service/utils/utils';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeAuthorizationStatus } from './redux/actions/authorization';
import { Session } from './service/common/model/SessionInfo';
import AdminService from './service/admin/AdminService';
import { changeLoadingStatus } from './redux/actions/loading';
import MemberService from './service/member/MemberService';
import SessionService from './service/SessionService';

// import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

// datadogLogs.init({
//   clientToken: 'pubf9a42d19bc604e36b65276b65e1fbb23',
//   site: 'datadoghq.com',
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sampleRate: 100,
// });

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.DATADOG_CLIENT_TOKEN) {
      datadogRum.init({
        applicationId: '51caf330-268a-4905-bb8c-a55b513fcbd0',
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'lcpr-rum-monitor',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }
  }, []);

  // datadogLogs.logger.info('Button clicked', { name: 'buttonName', id: 123 });

  const [isLandscape, setLandscape] = useState<boolean>(false);
  const body = document.querySelector('body') as HTMLElement;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sessionService = new SessionService();
  const memberService = new MemberService();

  const resizeHandler = () => {
    const isMobile = getIsMobile();

    if (isMobile && window.matchMedia('(orientation: landscape)').matches) {
      // Landscape 모드(가로모드)
      setLandscape(true);
      body.style.position = 'fixed';
      body.style.overflow = 'hidden';
    } else {
      // Portrait 모드(세로모드)
      setLandscape(false);
      body.style.removeProperty('position');
      body.style.removeProperty('overflow');
    }
  };

  const reqSessionStorage = () => {
    if (!sessionStorage.getItem(Session.SESSION_ID)) {
      localStorage.setItem('reqSessionStorage', 'trigger');
      localStorage.removeItem('reqSessionStorage');
    } else {
      dispatch(changeAuthorizationStatus(true));
    }
  };
  const respSessionStorage = () => {
    localStorage.setItem('respSessionStorage', JSON.stringify(sessionStorage));
    localStorage.removeItem('respSessionStorage');
  };
  const recvSessionStorage = (newValue: string) => {
    const data = JSON.parse(newValue);
    for (const key in data) {
      if (!sessionStorage.getItem(key)) sessionStorage.setItem(key, data[key]);
    }
  };

  let logoutTimer: any;

  const transSessionStorage = (event: StorageEvent) => {
    if (!event.newValue) return; // removeItem

    if (event.key == 'reqSessionStorage') {
      respSessionStorage(); // another tab asked for the sessionStorage -> send it
    } else if (
      event.key == 'respSessionStorage' &&
      !sessionService.getSessionId()
    ) {
      recvSessionStorage(event.newValue); // another tab sent data <- get it

      if (sessionService.getSessionId()) {
        dispatch(changeAuthorizationStatus(true));
        const startTime = Number(sessionService.getSessionExpirationDate());
        const remainTime = new Date(startTime).getTime();
        -new Date().getTime();
        logoutTimer = setTimeout(reqSessionStorage, remainTime);
      } else {
        dispatch(changeAuthorizationStatus(false));
      }
    }
  };

  useEffect(() => {
    checkIE();
    window.addEventListener('resize', resizeHandler);
    window.addEventListener('storage', transSessionStorage, false); // listen for changes to localStorage
    reqSessionStorage(); // Ask other tabs for session storage

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const checkIE = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (
      //IE 환경이라면
      (navigator.appName === 'Netscape' &&
        navigator.userAgent.search('Trident') !== -1) ||
      agent.indexOf('msie') !== -1
    ) {
      if (
        confirm(
          `Internet Explorer 브라우저를 공식 지원하지 않는 페이지입니다.\n크롬 혹은 엣지 브라우저 사용을 권장드립니다.\n엣지 브라우저로 전환하시겠습니까?`
        )
      ) {
        //확인을 누른 경우 엣지로 전환시킴, 기존 창을 닫지는 않음
        window.location.href = 'microsoft-edge:' + window.location;
      }
    }
  };

  const handleLogout = async () => {
    dispatch(changeLoadingStatus(true));
    const response = await memberService.handleLogout();
    dispatch(changeLoadingStatus(false));
    clearTimeout(logoutTimer);
    dispatch(changeAuthorizationStatus(false));
  };

  if (sessionStorage.getItem(Session.SESSION_ID)) {
    // console.log('set Logout : ', new Date());
    const startTime = Number(
      sessionStorage.getItem(Session.SESSION_EXPIRATEION_DATE)
    );
    const remainTime = new Date(startTime).getTime() - new Date().getTime();

    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(handleLogout, remainTime);
  }

  return (
    <div className="App">
      <GlobalStyles />
      <CssBaseline />
      {isLandscape && (
        <div className="LandscapeNotAvailable">
          <p>{t('app.landscape')}</p>
        </div>
      )}
      <Routes>
        <Route path="/admin/*" element={<AdminPage />} />
        <Route path="/*" element={<MainPage />} />
      </Routes>
    </div>
  );
};

export default App;
