import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bannerImg from '../../../assets/awsproduct/awsproductBackground.png';
import { getIsMobile } from '../../../service/utils/utils';

const AwsPartnerIntroPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = getIsMobile();

  return (
    <React.Fragment>
      <div className="header-top" data-testid="header-top">
        <div className="top-tit detail-container">
          <h2>AWS Product</h2>
        </div>
      </div>
      <div
        className="aws-partner-intro-cont"
        data-testid="testAwsPartnerIntroContainer"
      >
        <div className="intro-box">
          <div className="intro-top">
            <strong className="intro-title">
              {t('awsproduct.AwsPartnerIntroPage.title')}
            </strong>
          </div>
          <p className="intro-summary summary-cont">
            {t('awsproduct.AwsPartnerIntroPage.summary.text1')}
            <br />
            {t('awsproduct.AwsPartnerIntroPage.summary.text2')}
          </p>
        </div>
        <div className="intro-banner-box">
          <div className="banner-img-box">
            <div className="banner-arrow" />
            <div className="banner-cursor" />
            <img src={bannerImg} className="banner-img" />
            <div
              className={`banner-overlay-box ${
                isMobile && 'mobile-' + i18n.language
              }`}
            >
              <div className="banner-img-title">
                <span className="banner-img-txt">
                  {t('awsproduct.AwsPartnerIntroPage.banner.img.title1')}
                  <br />
                  {t('awsproduct.AwsPartnerIntroPage.banner.img.title2')}
                </span>
              </div>
              <div className="banner-img-contents">
                <div>
                  <span className="banner-img-summary">
                    <span className="summary-txt1">
                      {t('awsproduct.AwsPartnerIntroPage.banner.img.text1')}
                    </span>
                    <span className="summary-txt2">
                      {t('awsproduct.AwsPartnerIntroPage.banner.img.text2')}
                      <span className="summary-txt3">
                        {t('awsproduct.AwsPartnerIntroPage.banner.img.text3')}
                        <br />
                        {t('awsproduct.AwsPartnerIntroPage.banner.img.text4')}
                      </span>
                    </span>
                  </span>
                </div>
                <div className="banner-read-more-box">
                  <span
                    className="banner-img-read-more"
                    onClick={() => {
                      navigate('/awsproduct/eks-anywhere');
                    }}
                  >
                    {t('awsproduct.AwsPartnerIntroPage.banner.img.readMore')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-sub-box">
            <strong className="banner-sub-txt">
              {t('awsproduct.AwsPartnerIntroPage.banner.sub.text1')}
              <br />
              {t('awsproduct.AwsPartnerIntroPage.banner.sub.text2')}
            </strong>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AwsPartnerIntroPage;
