import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { eksCaseDatasKo } from '../../../service/data/eksAnywhere/EksAnywhereDataKo';
import { eksCaseDatasEn } from '../../../service/data/eksAnywhere/EksAnywhereDataEn';
import { EksCaseData } from '../../../service/data/eksAnywhere/EksAnywhereModel';
import EksAnywhereCaseItem from './EksAnywhereCaseItem';
import { Language } from '../../../service/common/model/enum/Common.enum';

const EksAnywhereCaseArea: React.FC = () => {
  const { i18n, t } = useTranslation();

  const [eksAnywhereCases, setEksAnywhereCases] = useState<EksCaseData[]>(
    i18n.language === Language.KO ? eksCaseDatasKo : eksCaseDatasEn
  );

  useEffect(() => {
    setEksAnywhereCases(
      i18n.language === Language.KO ? eksCaseDatasKo : eksCaseDatasEn
    );
  }, [i18n.language]);

  const rendering = () => {
    const result = [];
    for (let i = 0; i < eksAnywhereCases.length; i += 2) {
      result.push(
        <div className="case-content-area" key={i}>
          <div className="content-row-box">
            {eksAnywhereCases.slice(i, i + 2).map((caseItem, idx) => {
              return (
                <div className="content-text-area" key={idx}>
                  <EksAnywhereCaseItem caseItem={caseItem} />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return result;
  };

  return (
    <div className="eks-anywhere-case-cont">
      <div className="case-txt-box">
        <span className="case-title-txt">
          {t('eksAnywhere.EksAnywhereCaseArea.title')}
        </span>
      </div>
      {rendering()}
    </div>
  );
};

export default EksAnywhereCaseArea;
