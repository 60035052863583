import eksAnywhereOverallImg from '../../../assets/eksAnywhere/eksAnywhereOverallImg.png';
import eksAnywhereArchitectureImg from '../../../assets/eksAnywhere/eksAnywhereArchitectureImg.png';
import eksAnywhereFunctionImg from '../../../assets/eksAnywhere/eksAnywhereFunctionImg.png';
import eksValue01Img from '../../../assets/eksAnywhere/eksValue01Img.png';
import eksValue02Img from '../../../assets/eksAnywhere/eksValue02Img.png';
import eksValue03Img from '../../../assets/eksAnywhere/eksValue03Img.png';
import eksValue04Img from '../../../assets/eksAnywhere/eksValue04Img.png';

import {
  EksCaseData,
  EksContentData,
  EksContentLayout,
  EksValueData,
} from './EksAnywhereModel';

export const eksContentDatasKo: EksContentData[] = [
  {
    img: eksAnywhereOverallImg,
    layout: EksContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Amazon EKS Anywhere',
        },
        {
          text: '는 ',
          options: {
            size: 'title-sm',
          },
        },
        {
          text: 'Amazon EKS의 새로운 배포 옵션입니다.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: '21년 9월에 출시된VMware 버전에 이어 22년 6월에 Bare metal 버전이 출시되어 더욱 다양한 고객 환경에서 EKS Anywhere를 배포할 수 있습니다. EKS는 Amazon 자체 클라우드에서 실행되지만 EKS Anywhere는 사설 인프라에서도 실행할 수 있습니다. EKS Anywhere를 사용하면 AWS에서 제공되는 EKS Anywhere를 위한 모든 서비스 및 관리 도구를 사용할 수 있으며 EKS와 동일한 Kubernetes 배포판( ',
            },
            {
              text: 'Amazon ',
              options: {
                bold: true,
              },
            },
            {
              text: 'EKS Distro',
              options: {
                bold: true,

                color: 'primary',
                underline: 'underline',
              },
            },
            {
              text: ' )을 사용합니다.',
            },
          ],
        },
        {
          type: 'definition',
          contents: [
            {
              text: '*EKS Distro - ',
              options: {
                color: 'primary',
                bold: true,
              },
            },
            {
              text: 'Amazon EKS에서 사용하는 오픈소스 Kubernetes 배포판으로, Kubernetes 제어부 구성 요소(kube-controller-manager, etcd 및 CoreDNS)와 Kubernetes 작업자 노드 구성 요소(kubelet, CNI 플러그인, CSI Sidecar 이미지, Metrics Server 및 AWS-IAM-authenticator)로 구성되어 있음.',
            },
          ],
        },
      ],
    },
  },
  {
    img: eksAnywhereArchitectureImg,
    layout: EksContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: '아키텍처',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: '고객 데이터센터의 Bare metal 서버 혹은 VMware 환경 위에 Kubernetes 클러스터를 위한 Control Plane과 Data Plane을 생성하고 운영할 수 있는 관리형 환경으로 구조화되어 있습니다. ',
              options: {
                bold: true,
              },
            },
            {
              text: 'Bare metal 서버 배포 버전은 Tinkerbell을 통해 서버를 제어하고 클러스터를 구성하며, VMware 환경에서는 vSphere API를 통해 VM을 생성하고 클러스터를 구성합니다. 두 버전 모두 eks anywhere만의 cli를 통해 배포 환경에 맞게 구성할 수 있습니다.또한, EKS Connector를 선택적으로 구성하여 기존 Amazon EKS 콘솔을 EKS Anywhere에서도 사용할 수 있습니다.',
            },
          ],
        },
        {
          type: 'default',
          contents: [
            {
              text: 'EKS Anywhere는 고객의 자원 위에 Kubernetes Cluster, 다양한 AWS의 서비스, Kubernetes 및 각종 지원 툴들을 최신화하기 위한 자동화된 upgrade 환경을 제공합니다. 클러스터 설치 및 kubectl 명령어 수행을 위한 Admin Machine, EKS-A Control Plane Node, EKS-A Data Plane Node로 이루어져 있으며, Container 환경 구성에 필요한 Image를 참조하여 신속하게 환경을 구성할 수 있는 편의성을 제공합니다.',
              options: {
                bold: true,
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: eksAnywhereFunctionImg,
    layout: EksContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '기능',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'EKS Anywhere는 On-Premise 인프라에서 Kubernetes 클러스터를 손쉽게 생성하고 운영할 수 있습니다. ',
              options: {
                bold: true,
              },
            },
            {
              text: 'EKS Distro를 기반으로 클러스터를 생성할 수 있으며, 클러스터 자동화된 관리, 패키지 upgrade와 같은 Kubernetes 클러스터 운영을 효율화하고 안정적으로 관리할 수 있는 기반을 제공합니다.',
            },
          ],
        },
        {
          type: 'default',
          contents: [
            {
              text: '특히 EKS Anywhere는 Enterprise 환경에서 필요한 다양한 Tool을 검증하여 제공합니다. 해당 Tool들은 EKS Anywhere 큐레이팅 패키지를 통해 손쉽게 설치 및 관리를 할 수 있습니다. 현재 제공 중인 Harbor 외에도 Emissary-Ingress, MetalLB 등 다양한 Tool 들이 추가될 예정입니다. 또한, EKS Connector로 연결한 AWS Console로 Cluster 및 환경을 관리, 모니터링할 수 있습니다.',
            },
          ],
        },
      ],
    },
  },
  {
    layout: EksContentLayout.TXT_TXT,
    content: {
      title: [
        {
          text: '장점',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'EKS Anywhere의 장점은',
              options: {
                bold: true,

                size: 'content-lg',
              },
            },
            {
              text: '고객이 On-Premise 데이터 센터 내에서 Amazon의',
              options: {
                bold: true,
                size: 'content-lg',
                prevLine: true,
                underline: 'primary-underline',
              },
            },
            {
              text: 'Kubernetes 소프트웨어를 사용할 수 있다는 것입니다.',
              options: {
                bold: true,
                size: 'content-lg',
                prevLine: true,
                underline: 'primary-underline',
              },
            },
          ],
        },
      ],
    },
    content2: {
      title: [
        {
          text: '',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Amazon의 퍼블릭 클라우드 인프라를 사용할 수 없는 환경에서 EKS가 필요한 경우 EKS Anywhere가 솔루션을 제공합니다. 따라서 EKS Anywhere는 기업이 자체 서버에 워크로드를 호스팅 하는 경우 총 소유 비용을 더 낮출 수 있습니다. EKS Anywhere는 규정 준수를 위해 워크로드를 On-Premise로 유지해야 하는 경우에도 유용할 수 있습니다.',
              options: {
                bold: true,
              },
            },
          ],
        },
        {
          type: 'default',
          contents: [
            {
              text: '또한 EKS Anywhere는 On-Premise Kubernetes 클러스터의 생성 및 운영을 위한 설치 가능 소프트웨어 패키지와 클러스터 수명 주기 지원을 위한 자동화 도구를 제공하여 관리 도구의 복잡성을 줄여줍니다. EKS Anywhere는 AWS를 통해 완벽하게 지원하고 EKS 콘솔을 활용하여 모든 위치에서 실행되는 모든 Kubernetes 클러스터를 볼 수 있어 관리도 편리합니다.',
            },
          ],
        },
      ],
    },
  },
];

export const eksValueDatasKo: EksValueData[] = [
  {
    img: eksValue01Img,
    index: '01',
    main: [
      {
        text: [
          {
            val: 'Kubernetes',
            bold: true,
          },
          {
            val: '를 ',
          },
          {
            val: 'On-Premise',
            bold: true,
          },
          {
            val: '에서',
          },
        ],
      },
      {
        text: [
          {
            val: '운영하고 있는 고객에게 운영상의',
          },
        ],
      },
      {
        text: [
          {
            val: '편의성을 제공합니다.',
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: 'EKS Anywhere는 Kubernetes 클러스터 생성, 운영환경 구성, 소프트웨어 업데이트, 백업 및 복구 처리가 가능',
            bold: true,
          },
          {
            val: '하게 함으로써 고객은 기존처럼 다수의 관리 솔루션을 구매하고 운영해야 하는 일을 하지 않아도 됩니다.',
          },
        ],
      },
    ],
  },
  {
    img: eksValue02Img,
    index: '02',
    main: [
      {
        text: [
          {
            val: '업스트림 Kubernetes 및',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: '보안 패치를 유지해야 하는',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: '고객의 부담을 제거합니다.',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: '비즈니스 가치에 집중할 수 있는 환경을 제공함으로써',
          },
        ],
      },
      {
        text: [
          {
            val: '애플리케이션 현대화의 최종적인 문제를 해결합니다.',
          },
        ],
      },
    ],
  },
  {
    img: eksValue03Img,
    index: '03',
    main: [
      {
        text: [
          {
            val: '커뮤니티 지원 만료 버전의',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: '중요 보안패치를',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: '업데이트해 줍니다.',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: '커뮤니티 버전의 Kubernetes는 3개의 Minor 버전을 9개월간 제공하지만, EKS Anywhere는 4개의 Minor 버전을 14개월간 제공함으로써 kubernetes 버전을 연장하고 고객은 커뮤니티 대비 업그레이드 기간을 연장할 수 있습니다.',
          },
        ],
      },
    ],
  },
  {
    img: eksValue04Img,
    index: '04',
    main: [
      {
        text: [
          {
            val: 'Hybrid Cloud 환경에서의',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: '컨테이너 유지관리 복잡성을',
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            val: '완화시킬 수 있습니다.',
            bold: true,
          },
        ],
      },
    ],
    detail: [
      {
        text: [
          {
            val: 'Public와 On-premise에서 운영중인 컨테이너 솔루션이 다르면 각각의 운영관리 방법과 관리 솔루션이 다르고, 개별적으로 운영해야 합니다. EKS Anywhere를 사용하게 되면 AWS 콘솔 대시보드를 통해 On-premise의 컨테이너 환경까지 통합적으로 관제 및 운영 관리할 수 있습니다. 또한, AWS에서 제공하는 큐레이팅 패키지를 통해 AWS에서 검증한 호환 소프트웨어를 쉽게 설치하고 관리할 수 있습니다.',
          },
        ],
      },
    ],
  },
];

export const eksCaseDatasKo: EksCaseData[] = [
  {
    index: '01',
    title: [
      { txt: 'AI/ML 서비스를 편리하게 구현하기 위한 방법' },
      {
        txt: '- AWS에서 모델을 훈련하고 온프레미스에서 추론을 실행',
      },
    ],
    summary:
      'AWS GPU를 사용하여 ML 모델을 훈련하고, 훈련된 ML 모델을 On-premise로 가져와서 추론을 실행할 수 있습니다. 고객은 값비싼 GPU를 구매할 필요 없이 필요한 시점에 AWS GPU를 활용하고 반납하는 것을 자유롭게 할 수 있습니다.',
  },
  {
    index: '02',
    title: [
      { txt: '기투자된 인프라를 당분간 사용해야 할 때 향후의' },
      { txt: 'Workload Migration을 감안한 EKS Anywhere 활용' },
    ],
    summary:
      'EKS Anywhere를 사용하면 동일한 EKS 도구를 온프레미스에서 사용할 수 있습니다. On-premise의 인프라 환경을 AWS와 일관성 있게 구성함으로써 향후 장비의 감가상각이 종료되었을 때 Kubernetes 기반 워크로드를 AWS로 더 쉽고 빠르게 이전할 수 있습니다.',
  },
  {
    index: '03',
    title: [{ txt: '대규모 데이터 세트를 On-premise에 유지해야 하는 경우' }],
    summary:
      '법적인 규제 등으로 인해 데이터를 반드시 On-premise를 유지해야 하는 경우, EKS Anywhere는 데이터는 원래 위치에 유지하면서 stateless 애플리케이션만 클라우드로 이전하는 데 도움이 됩니다.',
  },
  {
    index: '04',
    title: [{ txt: 'Public Cloud의 장점인 Auto-Scaling 활용' }],
    summary:
      '계절별 워크로드에는 며칠 또는 몇 주간 대량의 컴퓨팅(기준선의 5~10배)이 필요할 수 있습니다. Private Cloud도 Auto-Scaling을 구현할 수 있으나 용량 확장에 있어 제한적일 수밖에 없습니다.  EKS Anywhere로 구성되어 있으면 Public Cloud로 순간 확장할 수 있으므로 일시적으로 필요한 컴퓨팅 용량을 적시에 제공할 수 있어 고객은 On-premise와 Public Cloud를 기반으로 워크로드를  비용 효율적으로 운영할 수 있습니다.',
  },
  {
    index: '05',
    title: [{ txt: '분산 클라우드의 이점 활용' }],
    summary:
      '최근 데이터 위치 종속성, 네트워크 지연 문제, 보안 컴플라이언스를 고려하여 분산형 클라우드의 도입이 확대되고 있습니다. EKS Anywhere를 활용하여 고객의 데이터 센터 내에서 IoT 디바이스 연결에 활용할 수 있으며 네트워크 지연에 민감한 애플리케이션을 배포할 수 있습니다.',
  },
];
