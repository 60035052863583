import React, { useEffect, useState } from 'react';
import { EksCaseData } from '../../../service/data/eksAnywhere/EksAnywhereModel';
import { getIsMobile } from '../../../service/utils/utils';

interface Props {
  caseItem: EksCaseData;
}

const EksAnywhereCaseItem: React.FC<Props> = (props: Props) => {
  const caseItem = props.caseItem;

  const isMobile = getIsMobile();

  return (
    <div className="case-box" data-testid="testEksAnywhereCaseItem">
      <div className="index-box">
        <span className="index-txt">{caseItem.index}</span>
      </div>
      <div className="case-content-box">
        <div className="content-title-box">
          {caseItem.title.map((item, idx) => {
            return isMobile ? (
              <React.Fragment key={idx}>
                <span>{`${item.txt} `}</span>
              </React.Fragment>
            ) : (
              <div key={idx}>
                <span>{item.txt}</span>
              </div>
            );
          })}
        </div>
        <div className="content-summary-box">{caseItem.summary}</div>
      </div>
    </div>
  );
};

export default EksAnywhereCaseItem;
