import React from 'react';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { Blog } from '../../../../service/blog/model/Blog';
import {
  State,
  DateFormat,
  StringDateFormat,
} from '../../../../service/common/model/enum/Common.enum';
import { getStringDateFormat } from '../../../../service/utils/utils';

import './BlogBoardSection.scss';

type Columns = {
  index: string;
  value: string;
};

type TableProps = {
  columns: Columns[];
  boardList: Blog[];
  itemStateFilter: State | 'default';
  textSearchFilter: string;
  handleSelectStateChange: React.ChangeEventHandler;
  handleSearchChange: React.ChangeEventHandler;
  clickHandler: React.MouseEventHandler;
};

const BlogTableSection = (props: TableProps) => {
  return (
    <div>
      <Row className="search">
        <Col xs="7">
          <span id="title">Blog 목록</span>
        </Col>
        <Col xs="auto">
          <Form.Select
            className="filter"
            onChange={props.handleSelectStateChange}
          >
            <option value={'default'} data-type="number">
              Default
            </option>
            <option value={State.ALL} data-type="number">
              All
            </option>
            <option value={State.ADMIN} data-type="number">
              Admin
            </option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Control
            className="filter"
            defaultValue={''}
            placeholder="한/영 제목 입력"
            onChange={props.handleSearchChange}
          />
        </Col>
      </Row>
      <Row className="list">
        <Table className="table">
          <thead>
            <tr>
              {props.columns
                ? props.columns.map((column) => (
                    <th key={column.index}>{column.value}</th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {props.boardList
              ? props.boardList
                  .filter((board) => {
                    if (props.itemStateFilter === 'default') return true;
                    return board.blogState === props.itemStateFilter;
                  })
                  .filter((board) => {
                    if (props.textSearchFilter !== '') {
                      if (
                        board.blogBoard.ko.blogTitle
                          .toLowerCase()
                          .indexOf(props.textSearchFilter.toLowerCase()) !==
                          -1 ||
                        (board.blogBoard.en &&
                          board.blogBoard.en.blogTitle &&
                          board.blogBoard.en.blogTitle
                            .toLowerCase()
                            .indexOf(props.textSearchFilter.toLowerCase()) !==
                            -1)
                      )
                        return true;
                      return false;
                    } else return true;
                  })
                  .map((blog, idx) => (
                    <tr
                      key={blog.blogUrl.toString()}
                      id={blog.blogUrl.toString()}
                      onClick={props.clickHandler}
                    >
                      <td>{idx + 1}</td>
                      <td className="blog-title">
                        {blog.blogBoard.ko.blogTitle}
                      </td>
                      <td className="blog-url">{blog.blogUrl}</td>
                      <td className="blog-state">{blog.blogState}</td>
                      <td className="blog-register-admin">
                        {blog.registerAdmin}
                      </td>
                      <td className="blog-registered-date">
                        {getStringDateFormat(
                          blog.registeredDate,
                          DateFormat.DATE,
                          StringDateFormat.DATE
                        )}
                      </td>
                      <td className="blog-modify-admin">{blog.modifyAdmin}</td>
                      <td className="blog-modified-date">
                        {getStringDateFormat(
                          blog.modifiedDate,
                          DateFormat.DATE,
                          StringDateFormat.DATE
                        )}
                      </td>
                      <td className="blog-view">{blog.blogView}</td>
                    </tr>
                  ))
              : null}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default BlogTableSection;
