import './AwsDirectConnectPageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';
import AwsDirectConnectSection from './components/AwsDirectConnectSection';

const AwsDirectConnectPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsDirectConnectPageContainer">
        <AwsDirectConnectSection />
      </div>
    </Container>
  );
};

export default AwsDirectConnectPageContainer;
