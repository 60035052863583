import React, { useState, useEffect } from 'react';

import { CaseDetailContent } from '../../../service/case/model/Case';

const AdditionItem = ({ ...caseDetailContent }: CaseDetailContent) => {
  const [videoUrl, setVideoUrl] = useState<string>('');
  const checkVideoUrl = (url?: string) => {
    if (url === undefined || url === null) return '';
    if (url.includes('www.youtube.com')) {
      url = url.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/');
      // remove querystring
      if (url.includes('&')) {
        const idx = url.indexOf('&');
        url = url.substring(0, idx);
      }
    }
    setVideoUrl(url);
  };
  useEffect(() => {
    if (caseDetailContent.detailContent == 'video') {
      checkVideoUrl(caseDetailContent.detailContentUrl);
    }
  }, [caseDetailContent.detailContent]);

  return (
    <div className="addition-item">
      {caseDetailContent.detailContentUrl ? (
        caseDetailContent.detailContent == 'image' ? (
          <img
            src={caseDetailContent.detailContentUrl}
            alt="addition case 이미지"
            className="addition-image"
          />
        ) : (
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="addition-video"
          ></iframe>
        )
      ) : (
        <></>
      )}

      <div className="addition-info">
        <h2 className="detail-tit">{caseDetailContent.detailTitle}</h2>
        <p className="detail-cont">{caseDetailContent.detailText}</p>
      </div>
    </div>
  );
};

export default AdditionItem;
