import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './MainAwsPartner.scss';

import solutionProvider from '../../../assets/main/mainAwsPartner/AWSSolutionProviderIcon.svg';
import publicSector from '../../../assets/main/mainAwsPartner/AWSPublicSectorIcon.svg';
import premierTierService from '../../../assets/main/mainAwsPartner/AWSPremierTierServiceIcon.svg';
import managedServiceProvider from '../../../assets/main/mainAwsPartner/AWSManagedServiceProviderIcon.svg';
import migrationServiceCompetency from '../../../assets/main/mainAwsPartner/AWSMigrationServiceCompetencyIcon.svg';
import securityServiceCompetency from '../../../assets/main/mainAwsPartner/AWSSecurityServiceCompetencyIcon.svg';
import devOpsConsultingCompetency from '../../../assets/main/mainAwsPartner/AWSDevOpsConsultingCompetencyIcon.svg';
import eksDelivery from '../../../assets/main/mainAwsPartner/AWSEksDeliveryIcon.png';
import immersionDay from '../../../assets/main/mainAwsPartner/AWSImmersionDayIcon.png';
import amazonConnect from '../../../assets/main/mainAwsPartner/AmazonConnect.png';
import amazonDirectConnect from '../../../assets/main/mainAwsPartner/AmazonDirectConnect.png';
import awsLambdaDelivery from '../../../assets/main/mainAwsPartner/AWSLambdaDelivery.png';
import wellArchitectedProgram from '../../../assets/main/mainAwsPartner/WAPP.png';
import publicSolutionProvider from '../../../assets/main/mainAwsPartner/AWSSolutionProvider.svg';
import genAI from '../../../assets/main/mainAwsPartner/GenAI.png';
import ACD from '../../../assets/main/mainAwsPartner/ACD.png';
import cloudfrontSDP from '../../../assets/main/mainAwsPartner/AWSCloudfrontSDP.png';
import awsRDSDelivery from '../../../assets/main/mainAwsPartner/AmazonRDSDelivery.png';
import { useTranslation } from 'react-i18next';
interface ArrowProps {
  className?: any;
  style?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const NextArrow: React.FC<ArrowProps> = (props: ArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
};

const PrevArrow: React.FC<ArrowProps> = (props: ArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
};

const MainAwsPartner = () => {
  const { t } = useTranslation();
  // SlickSlider 세팅
  const innerWidth = window.innerWidth;
  const MOBILE_SIZE = 767;
  const TABLET_SIZE = 1023;
  const BROWSER_SIZE = 1350;
  let slidesToShow = 5;

  //breakPoint별 화면에 노출되는 카드의 수
  if (innerWidth <= MOBILE_SIZE) {
    slidesToShow = 1;
  } else if (innerWidth <= TABLET_SIZE) {
    slidesToShow = 2;
  } else if (innerWidth <= BROWSER_SIZE) {
    slidesToShow = 4;
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: 'linear',
  };

  return (
    <div className="fullpage-slide main-aws-partner">
      <div className="main-aws-partner-header-container">
        <div className="main-aws-partner-header">
          <div className="title">{t('main.MainAwsPartner.title')}</div>
          <div className="content">
            {t('main.MainAwsPartner.txt1')}
            <br />
            {t('main.MainAwsPartner.txt2')}
            <br />
            {t('main.MainAwsPartner.txt3')}
          </div>
        </div>
      </div>
      <div className="main-aws-partner-aws-badge-container">
        <Slider {...settings}>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={solutionProvider} />
            </div>
            <div className="title">
              <span>Solution Provider</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={publicSector} />
            </div>
            <div className="title">
              <span>Public Sector</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={premierTierService} />
            </div>
            <div className="title">
              <span>Premier Tier Service</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={managedServiceProvider} />
            </div>
            <div className="title">
              <span>Managed Service Provider</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={migrationServiceCompetency} />
            </div>
            <div className="title">
              <span>Migration Services Competency</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={securityServiceCompetency} />
            </div>
            <div className="title">
              <span>Security Services Competency</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={devOpsConsultingCompetency} />
            </div>
            <div className="title">
              <span>DevOps Consulting Competency</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={eksDelivery} />
            </div>
            <div className="title">
              <span>Amazon EKS Delivery</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={immersionDay} />
            </div>
            <div className="title">
              <span>Immersin Day</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={awsLambdaDelivery} />
            </div>
            <div className="title">
              <span>AWS Lambda Delivery</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={awsRDSDelivery} />
            </div>
            <div className="title">
              <span>Amazon RDS Delivery</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={wellArchitectedProgram} />
            </div>
            <div className="title">
              <span>Well-Architected Partner Program</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={amazonDirectConnect} />
            </div>
            <div className="title">
              <span>Amazon Direct Connect Service Delivery</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={amazonConnect} />
            </div>
            <div className="title">
              <span>Amazon Connect Service Delivery</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={publicSolutionProvider} />
            </div>
            <div className="title">
              <span>Public Sector Solution Provider</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={cloudfrontSDP} />
            </div>
            <div className="title">
              <span>Amazon CloudFront SDP</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={ACD} />
            </div>
            <div className="title">
              <span>Amazon Cloudfront Delivery</span>
            </div>
          </div>
          <div className="aws-badge-slider">
            <div className="aws-img">
              <img src={genAI} />
            </div>
            <div className="title">
              <span>Generative AI Services Competency</span>
            </div>
          </div>
        </Slider>
      </div>
      <div className="main-aws-partner-aws-contents-container">
        <div className="aws-contents">
          <h1 className="contents-number">3</h1>
          <p>
            AWS <br /> Competency
          </p>
        </div>
        <div className="aws-contents">
          <h1 className="contents-number">7</h1>
          <p>
            Partner <br /> Program
          </p>
        </div>
        <div className="aws-contents">
          <h1 className="contents-number">6</h1>
          <p>
            AWS <br /> Service Validation
          </p>
        </div>
        <div className="aws-contents">
          <h1 className="contents-number">700+</h1>
          <p>
            AWS <br /> Certificates
          </p>
        </div>
        <div className="aws-contents">
          <h1 className="contents-number">100+</h1>
          <p>
            AWS <br /> Customer Launches
          </p>
        </div>
      </div>
    </div>
  );
};
export default MainAwsPartner;
