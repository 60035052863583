import './ErrorPage.scss';

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../../layouts/Footer';

const ErrorPage = () => {
  const { i18n, t } = useTranslation();
  const navigator = useNavigate();
  const handleOnClickGoHome = () => {
    navigator('/');
  };

  return (
    <div id="ErrorPage">
      <div className="fullpage-slide error-slide">
        <div className="icon"></div>
        <h2 className="title2">
          {t('error.text1')}
          <br />
          {t('error.text2')}
        </h2>
        <p className="txt-con">
          {t('error.text3')}
          <br />
          {t('error.text4')}
          <br />
          {t('error.text5')}
        </p>
        <button onClick={handleOnClickGoHome} data-testid="ErrorPageHomeBtn">
          {t('error.goHomeBtn')}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
