import './DropdownBox.scss';
import React, { useEffect, useState, useRef } from 'react';

type DropdownBoxType = {
  latest: string;
  view: string;
};

export interface DropdownBoxProps {
  optionList: DropdownBoxType;
  setSelectedOption: (option: string) => void;
}

const DropdownBox: React.FC<DropdownBoxProps> = (props: DropdownBoxProps) => {
  const [selectedName, setSelectedName] = useState<string>(
    props.optionList.latest
  );
  const [isActive, setIsActive] = useState<boolean>(false);
  const dropdownContainerRef = useRef<any>();

  useEffect(() => {
    if (props.optionList) {
      setSelectedName(
        selectedName === '조회순' || selectedName === 'View'
          ? props.optionList.view
          : props.optionList.latest
      );
    }
  }, [props.optionList]); // 언어가 바뀌는 경우

  useEffect(() => {
    props.setSelectedOption(
      selectedName === '조회순' || selectedName === 'View' ? 'view' : 'latest'
    ); // NewsSection에서 언어만 바꾸는 경우 최신순/조회순 옵션을 유지하기 위해 소문자 view,latest로 고정
  }, [selectedName]); // 옵션값이 바뀌는 경우

  useEffect(() => {
    window.addEventListener('click', handleClickOutsideOfDropdown);
    () => {
      window.removeEventListener('click', handleClickOutsideOfDropdown);
    };

    window.addEventListener('keydown', handleEscKeyToCloseDropdown);
    () => window.removeEventListener('keydown', handleEscKeyToCloseDropdown);
  }, [isActive]);

  const handleClickOutsideOfDropdown = (event: any) => {
    if (
      isActive &&
      dropdownContainerRef.current &&
      !dropdownContainerRef.current.contains(event.target)
    ) {
      setIsActive(false);
    }
  };

  const handleEscKeyToCloseDropdown = (event: any) => {
    if (isActive && event.keyCode === 27) {
      setIsActive(false);
    }
  };

  const handleDropdownOnClick = () => {
    setIsActive(!isActive);
  };

  const handleDropMenuOnClick = (item: string) => {
    setSelectedName(item); // TO-DO 정렬 조건이 바뀔 때 DB에서 정렬해서 값 가져오기
  };

  return (
    <div className="dropdown-container" ref={dropdownContainerRef}>
      <button className="dropdown-button" onClick={handleDropdownOnClick}>
        <p className={`selected ${isActive ? 'active' : 'inactive'}`}>
          {selectedName}{' '}
        </p>
        <nav className={`list ${isActive ? 'active' : 'inactive'}`}>
          <ul>
            {/* {props.optionList //&& props.optionList.length > 0
              ? props.optionList.map((item, idx) => {
                  return (
                    <li key={idx} onClick={() => handleDropMenuOnClick(item)}>
                      {item}
                    </li>
                  );
                })
              : null} */}
            <li onClick={() => handleDropMenuOnClick(props.optionList.latest)}>
              {props.optionList.latest}
            </li>
            <li onClick={() => handleDropMenuOnClick(props.optionList.view)}>
              {props.optionList.view}
            </li>
          </ul>
        </nav>
      </button>
    </div>
  );
};

export default DropdownBox;
