import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getIsMobile } from '../../../service/utils/utils';

import {
  Case,
  CaseBoard,
  CaseDetailContent,
} from '../../../service/case/model/Case';
import SummaryItem from './SummaryItem';
import AdditionItem from './AdditionItem';

import RightArrow from '../../../assets/caseDetail/right_arrow.png';

import './CaseDetailPage.scss';
import { Language } from '../../../service/common/model/enum/Common.enum';

interface IProps {
  caseBoard: CaseBoard | undefined;
  index: number;
  limit: number;
  registeredDate?: string | undefined;
}

const CaseDetailPage = ({
  caseBoard,
  index,
  limit,
  registeredDate,
}: IProps) => {
  const navigator = useNavigate();
  const { i18n, t } = useTranslation();
  const caseTitle = caseBoard?.caseTitle;
  const caseDetail = caseBoard?.caseDetail;
  let additionalSectionIsOpen = false;

  const isMobile = getIsMobile();
  const additionalSectionRef = useRef<HTMLDivElement>(null);
  const showAdditionalSection = () => {
    if (additionalSectionIsOpen) {
      additionalSectionRef.current?.classList.add('hide');
    } else {
      additionalSectionRef.current?.classList.remove('hide');
    }
    additionalSectionIsOpen = !additionalSectionIsOpen;
  };

  const handleOnClickGoBackBtn = () => {
    navigator('/case', {
      state: {
        limit: limit,
        index: index,
      },
    });
  };

  return (
    <div className="case-detail-new">
      <div
        className="detail-top"
        data-testid="detail-top"
        style={{
          backgroundImage: `url(${caseDetail?.caseTitleBg})`,
        }}
      >
        <div className="top-tit detail-container">
          <h2>{caseTitle}</h2>
          <h1 className="registered-date">
            {registeredDate
              ? i18n.language === Language.KO
                ? `작성일자 ${registeredDate.substring(
                    0,
                    4
                  )}-${registeredDate.substring(
                    4,
                    6
                  )}-${registeredDate.substring(6, 8)}`
                : `Published Date ${registeredDate.substring(
                    0,
                    4
                  )}-${registeredDate.substring(
                    4,
                    6
                  )}-${registeredDate.substring(6, 8)}`
              : ''}
          </h1>
        </div>
      </div>
      <div className="detail-container">
        <div className="detail-summary">
          <div className="summary-top">
            <strong className="detail-tit">
              {t('case.CaseDetailSection.PreviewCase')}
            </strong>
            {caseDetail?.caseCompanyLogo ? (
              <img src={caseDetail?.caseCompanyLogo} alt="Company Logo" />
            ) : (
              ''
            )}
          </div>
          <p className="summary-main detail-cont">{caseDetail?.caseOutline}</p>
          <div className="summary-contents">
            <div className="summary-content">
              <strong>About Company</strong>
              <SummaryItem
                SummaryContentList={caseDetail?.caseDetailSummary.aboutCompany}
              />
            </div>
            <div className="summary-content">
              <strong>The Benefit</strong>
              <SummaryItem
                SummaryContentList={caseDetail?.caseDetailSummary.theBenefit}
              />
            </div>
            <div className="summary-content">
              <strong>What we did</strong>
              <SummaryItem
                SummaryContentList={caseDetail?.caseDetailSummary.whatWeDid}
              />
            </div>
          </div>
        </div>
        <div className="learn-more">
          <hr />
          <span className="detail-cont" onClick={showAdditionalSection}>
            {t('case.CaseDetailSection.LearnMore')}
          </span>
          <hr />
        </div>
        <div
          className={'detail-addition ' + (isMobile ? 'hide' : '')}
          ref={additionalSectionRef}
        >
          {caseDetail?.caseDetailContentList &&
            caseDetail?.caseDetailContentList.map(
              (caseDetailContent: CaseDetailContent, idx: number) => (
                <AdditionItem {...caseDetailContent} key={idx} />
              )
            )}
        </div>
        <div
          className="go-back"
          onClick={handleOnClickGoBackBtn}
          data-testid="go-back-btn"
        >
          <img src={RightArrow} alt="all Case Studies" />
          <span className="detail-sub-cont">all Case Studies</span>
        </div>
      </div>
    </div>
  );
};

export default CaseDetailPage;
