import { AboutData } from './AboutModel';
import microserviceArchitecture from '../../../assets/about/microserviceArchitecture.png';
import modernUX from '../../../assets/about/modernUX.png';
import modernWeb from '../../../assets/about/modernWeb.png';
import testAutomation from '../../../assets/about/testAutomation.png';
import agileDevOps from '../../../assets/about/agileDevOps.png';
import cicdPipelines from '../../../assets/about/cicdPipelines.png';
import devOps from '../../../assets/about/devOps.png';
import digitalTechnology from '../../../assets/about/digitalTechnology.png';
import iac from '../../../assets/about/iac.png';

export const aboutEn: AboutData[] = [
  {
    title: 'Modern Web Development',
    desc: `Modern web development with cloud managed services and open source S/W`,
    icon: modernWeb,
  },
  {
    title: 'DevOps scheme',
    desc: `Design/build cloud architecture optimized for AM services and build DevOps scheme with IaC, GitOps`,
    icon: devOps,
  },
  {
    title: 'Apply Test Automation',
    desc: `Provide quality by applying Test Automation to all processes from development to deployment`,
    icon: testAutomation,
  },
  {
    title: 'MSA (Microservice Architecture)',
    desc: `Develop functionally independent services to minimize the flexibility of scale and impact of failures as usage grows.`,
    icon: microserviceArchitecture,
  },
  {
    title: 'Agile / DevOps',
    desc: `Develop capabilities in a short period of time, receive feedback from customers, and continue to improve.`,
    icon: agileDevOps,
  },
  {
    title: 'Digital Technology',
    desc: `Digital technologies such as M/L, D/L, Data Analytics, and IoT can be used effectively through Cloud services.`,
    icon: digitalTechnology,
  },
  {
    title: 'Modern UX',
    desc: `Analyze and design tasks from the end-user experience point of view from the product idea exploration stage.`,
    icon: modernUX,
  },
  {
    title: 'CI/CD Pipelines',
    desc: `It provides a mechanism that checks the quality of the developed product and delivers it to customers quickly.`,
    icon: cicdPipelines,
  },
  {
    title: 'IAC (Infra As Code)',
    desc: `Build your Container, Serverless infrastructure with code and run it over and over again to produce the same results.`,
    icon: iac,
  },
];
