import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { Fragment, ReactElement } from 'react';

interface Props {
  limit: number;
}

const caseList = [0, 0, 0, 0];

const CaseSectionSkeletonPC: React.FC<Props> = ({
  limit,
}: Props): ReactElement => {
  return (
    <Fragment>
      <div className="h-wrap">
        <div className="image-skeleton-PC">
          <Skeleton width={'100%'} height={'100%'} />
        </div>
        <div className="text-skeleton-PC">
          <Skeleton className="span-skeleton-PC" />
          <Skeleton className="content-skeleton-PC" count={2} />
        </div>
      </div>
      <div className="end-page-caseStudy">
        <div className="v-wrap">
          {caseList.slice(1, limit).map((caseItem, index) => {
            return (
              <div className="v-box" key={index}>
                <div className="image-skeleton">
                  <Skeleton width={'100%'} height={'100%'} />
                </div>
                <div className="text">
                  <Skeleton count={2} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
export default CaseSectionSkeletonPC;
