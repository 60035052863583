import React, { useEffect, useState } from 'react';
import { EksValueData } from '../../../service/data/eksAnywhere/EksAnywhereModel';

interface Props {
  key: number;
  value: EksValueData;
}

const EksAnywhereValueItem: React.FC<Props> = (props: Props) => {
  const value = props.value;

  return (
    <div className="value-box" data-testid="testEksAnywhereValueItem">
      <div className="value-box-cursor" />
      <img src={value.img} className="value-img" />
      <div>
        <span className="index-txt">{value.index}</span>
      </div>
      <div className="value-img-overlay-box">
        <div className="value-img-main">
          {value.main.map((item, idx) => {
            return (
              <div key={idx}>
                {item.text.map((textVal, idx2) => {
                  return (
                    <React.Fragment key={idx2}>
                      <span
                        className={`${textVal?.bold ? 'main-bold' : ''}`}
                        key={idx2}
                        data-testid="testEksAnywhereValueItemMain"
                      >
                        {textVal.val}
                      </span>
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="value-img-detail">
          {value.detail.map((item, idx) => {
            return (
              <div key={idx}>
                {item.text.map((textVal, idx2) => {
                  return (
                    <React.Fragment key={idx2}>
                      <span
                        className={`${textVal?.bold ? 'detail-bold' : ''}`}
                        data-testid="testEksAnywhereValueItemDetail"
                      >
                        {textVal.val}
                      </span>
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EksAnywhereValueItem;
