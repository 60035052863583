/**
 * S3 업로드 유형
 * attachment : 별도의 input으로 추가한 첨부파일
 * image : blog, news 본문이나 case, blog, news 썸네일 업로드
 */
export enum ContentDispositionType {
  ATTACHMENT = 'attachment',
  IMAGE = 'image',
  TEXT = 'text',
}
