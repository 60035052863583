import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || ''
    );
  }, []);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send('pageview');
  }, [location]);
};

export default RouteChangeTracker;
