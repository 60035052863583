import './NewsBoardRegistContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import NewsBoardRegistForm from './components/NewsBoardRegistForm';

const NewsBoardRegistContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="NewsBoardRegistContainer">
        <NewsBoardRegistForm />
      </div>
    </Container>
  );
};

export default NewsBoardRegistContainer;
