import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { Fragment, ReactElement } from 'react';
import { Row } from 'react-bootstrap';

const newsList = [0, 0, 0, 0, 0];

const NewsSectionSkeleton: React.FC = (): ReactElement => {
  return (
    <Fragment>
      {newsList.slice(0, 5).map((newsItem, index) => {
        return (
          <Row key={index}>
            <div id="news-section-skeleton" className="news-item">
              <div className="news-thumbnail-area">
                <Skeleton className="news-thumbnail-skeleton" />
              </div>
              <div id="news-info-area" className="news-info-area">
                <Skeleton className="news-title-skeleton" />
              </div>
            </div>
          </Row>
        );
      })}
    </Fragment>
  );
};
export default NewsSectionSkeleton;
