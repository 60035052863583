import './NewsBoardSection.scss';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { News } from '../../../../service/news/model/News';
import NewsService from '../../../../service/news/NewsService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import { State } from '../../../../service/common/model/enum/Common.enum';

import NewsTempRegistSection from './NewsTempRegistSection';
import NewsTableSection from './NewsTableSection';
import CommonResponse from '../../../../service/common/model/CommonResponse';
import { changeAuthorizationStatus } from '../../../../redux/actions/authorization';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';

const NewsBoardSection: React.FC = () => {
  const [newsList, setNewsList] = useState<News[]>([]);
  const [newsTempRegistList, setNewsTempRegistList] = useState<News[]>([]);
  const [newsStateFilter, setNewsStateFilter] = useState<State | 'default'>('default');
  const [textSearchFilter, setTextSearchFilter] = useState<string>('');
  const newsService = new NewsService();
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  const columns = [
    {
      index: '0',
      value: 'No.',
    },
    {
      index: '1',
      value: '제목',
    },
    {
      index: '2',
      value: 'URL',
    },
    {
      index: '3',
      value: '상태',
    },
    {
      index: '4',
      value: '등록자',
    },
    {
      index: '5',
      value: '등록 일시',
    },
    {
      index: '6',
      value: '수정자',
    },
    {
      index: '7',
      value: '수정 일시',
    },
    {
      index: '8',
      value: '조회수',
    },
  ];
  const navigator = useNavigate();

  useEffect(() => {
    getAllNewsList();
    getAllNewsTempRegistList();
    return () => {
      setNewsList([]);
      setNewsTempRegistList([]);
    };
  }, []);

  const getAllNewsList = async () => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response: CommonResponse = await newsService.getAllNewsList(); // TO-DO 정렬 조건

    if (response.successOrNot === 'Y') {
      if (response.data.admin) {
        setNewsList([...response.data.all, ...response.data.admin]);
      }else{
        setNewsList([...response.data.all]);
      }
      
    } else {
      if (response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
      ) {
        dispatch(changeAuthorizationStatus(false));
      }
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  // 임시저장 목록 조회
  const getAllNewsTempRegistList = async () => {
    const response: CommonResponse = await newsService.getTempNewsList();
    if (response.successOrNot === 'Y') {
      setNewsTempRegistList(response.data);
    } else {
      if (response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
      ) {
        dispatch(changeAuthorizationStatus(false));
      }
    }
  };

  const newsHandler = (e: React.SyntheticEvent) => {
    const key = e.currentTarget.getAttribute('id');
    navigator('/admin/news/' + key);
  };

  const newsRegisterBtnHandler = () => {
    navigator('/admin/news/regist');
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSelectStateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    if (value == State.ALL) {
      setNewsStateFilter(State.ALL);
    } else if (value == State.ADMIN) {
      setNewsStateFilter(State.ADMIN);
    } else {
      setNewsStateFilter('default');
    }
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setTextSearchFilter(value);
  };

  return (
    <div className="AdminNewsBoardSection">
      <ProgressBar/>
      <div className="fullpage pc">
        <NewsTableSection
          columns={columns}
          boardList={newsList}
          itemStateFilter={newsStateFilter}
          textSearchFilter={textSearchFilter}
          handleSelectStateChange={handleSelectStateChange}
          handleSearchChange={handleSearchChange}
          clickHandler={newsHandler} />
        <div className="btn-container">
          <Button
            className="btn btn-temp-regist"
            data-testid="temp-register-button"
            onClick={openModal}
          >
            news 임시저장 목록
          </Button>
          <Button
            className="btn btn-secondary"
            data-testid="register-button"
            onClick={newsRegisterBtnHandler}
          >
            news 등록
          </Button>
        </div>
      </div>
      <NewsTempRegistSection
        open={modalOpen}
        close={closeModal}
        tempNewsList={newsTempRegistList}
      />
    </div >
  );
};

export default NewsBoardSection;
