import { Language, State } from '../../common/model/enum/Common.enum';
import { Blog } from '../model/Blog';
import newThumbnail1 from '../../../assets/blog/blog-thum-1.png';
import newThumbnail2 from '../../../assets/blog/blog-thum-2.png';
import newThumbnail4 from '../../../assets/blog/blog-thum-4.png';
import newThumbnail5 from '../../../assets/blog/blog-thum-5.png';
import newThumbnail6 from '../../../assets/blog/blog-thum-6.jpg';
import newThumbnail7 from '../../../assets/blog/blog-thum-7.jpg';
import blogContentImage1 from '../../../assets/blog/blog-content-1-1.png';
import blogContentImage2 from '../../../assets/blog/blog-content-1-2.png';
import blogContentImage4 from '../../../assets/blog/blog-content-1-4.png';

export const blogData: Blog[] = [
  {
    registerAdmin: '관리자',
    registeredDate: '2022-02-08 00:00:00',
    blogBoard: {
      ko: {
        blogThumbnail: '테스트 썸네일',
        blogSubtitle:
          '‘AM(현대화된 애플리케이션)’ 구축·운영 사업 위한 ‘전략적 협력 계약(SCA)’ 체결',
        blogText: '테스트 내용',
        blogLang: Language.KO,
        blogWriter: '작성자',
        blogTitle: 'LG CNS, AWS와 손잡고 미래 클라우드 사업 나선다',
      },
      en: {
        blogThumbnail: 'blog thumbnail test data',
        blogSubtitle: 'blog subtitle test data',
        blogText: 'blog text test data',
        blogLang: Language.EN,
        blogWriter: '작성자',
        blogTitle: 'blog title test data',
      },
    },
    blogUrl: 'press000001',
    blogState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-02-08 00:00:00',
    blogView: 1,
  },
];

export const blogTempData: Blog[] = [
  {
    registerAdmin: '관리자',
    registeredDate: '2022-07-05 00:00:00',
    blogBoard: {
      ko: {
        blogThumbnail: '임시저장 썸네일',
        blogSubtitle: '임시저장 된 blog subtitle',
        blogText: '임시저장 된 blog text',
        blogLang: Language.KO,
        blogWriter: '작성자',
        blogTitle: '임시저장 된 blog main title',
      },
      en: {
        blogThumbnail: 'temp regist blog thumbnail test data',
        blogSubtitle: 'temp regist blog subtitle test data',
        blogText: 'temp regist blog text test data',
        blogLang: Language.EN,
        blogWriter: 'writer',
        blogTitle: 'temp regist blog title test data',
      },
    },
    blogUrl: 'press000044',
    blogState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-07-05 00:00:00',
    blogView: 0,
  },
  {
    registerAdmin: '관리자',
    registeredDate: '2022-07-05 01:00:00',
    blogBoard: {
      ko: {
        blogThumbnail: '임시저장 썸네일2222',
        blogSubtitle: '임시저장 된 blog subtitle 22222',
        blogText: '임시저장 된 blog text 22222',
        blogLang: Language.KO,
        blogWriter: '작성자',
        blogTitle: '임시저장 된 blog main title 222222',
      },
      en: {
        blogThumbnail: 'temp regist blog thumbnail test data22222',
        blogSubtitle: 'temp regist blog subtitle test data222222',
        blogText: 'temp regist blog text test data22222',
        blogLang: Language.EN,
        blogWriter: 'writer',
        blogTitle: 'temp regist blog title test data22222222',
      },
    },
    blogUrl: 'press000444',
    blogState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-07-05 01:00:00',
    blogView: 0,
  },
  {
    registerAdmin: '관리자',
    registeredDate: '2022-07-05 02:00:00',
    blogBoard: {
      ko: {
        blogThumbnail: '임시저장 썸네일',
        blogSubtitle: '임시저장 된 blog subtitle 3333333',
        blogText: '임시저장 된 blog text333333',
        blogLang: Language.KO,
        blogWriter: '작성자',
        blogTitle: '임시저장 된 blog main title33333333',
      },
      en: {
        blogThumbnail: 'temp regist blog thumbnail test data3333',
        blogSubtitle: 'temp regist blog subtitle test data3333333',
        blogText: 'temp regist blog text test data333333',
        blogLang: Language.EN,
        blogWriter: 'writer',
        blogTitle: 'temp regist blog title test data33333333',
      },
    },
    blogUrl: 'press000445',
    blogState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-07-05 02:00:00',
    blogView: 0,
  },
];
