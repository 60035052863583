import './CaseDetailPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import CaseDetailSection from './components/CaseDetailSection';

const CaseDetailPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="CaseDetailPageContainer">
        <CaseDetailSection />
      </div>
    </Container>
  );
};

export default CaseDetailPageContainer;
