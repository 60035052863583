import './BusinessSection.scss';
import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import Footer from '../../../layouts/Footer';

import BusinessJourney from './BusinessJourney';

import BusinessItem from './BusinessItem';
import BusinessSectionSkeleton from './BusinessSectionSkeleton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/modules';
import { useLocation } from 'react-router-dom';
import BusinessService from '../../../service/business/BusinessService';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import { Business } from '../../../service/business/model/Business';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Language } from '../../../service/common/model/enum/Common.enum';

const LIMIT_COUNT = 4;

const BusinessSection = () => {
  const loading = useSelector((state: RootState) => state.loading.loading);
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const businessRef = useRef<any>([]);
  const routeIndex = location.state
    ? (location.state as { index: number }).index
    : -1;

  const [limit, setLimit] = useState(
    location.state ? (location.state as { limit: number }).limit : LIMIT_COUNT
  );
  const businessService = new BusinessService();
  const [businessList, setBusinessList] = useState<Business[]>([]);

  useEffect(() => {
    getAllBusinessList();
  }, [i18n.language])

  const getAllBusinessList = useCallback(async () => {
    dispatch(changeLoadingStatus(true));
    const res = await businessService.getAllBusinessList();
    if(res.successOrNot === 'Y') {
      if(res.data) {
        setBusinessList([...res.data.all]);
      }
    }
    dispatch(changeLoadingStatus(false));
  },[]);

  const showMoreDocuments = () => {
    setLimit(limit + 4);
  };

  //초기 헤더세팅
  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);


  return (
    <div
      id="BusinessSection"
      data-testid="BusinessSection"
    >
      <div className="fullpage-slide business-slide1">
        <div className="h-wrap">
                <BusinessJourney />
            </div>
            <div className="content-body">
                <div className="title-content">
                  <div className="title-box">
                      <strong className="title-txt">
                        {t('business.BusinessTabPage.businessSection.title')}
                      </strong>
                  </div>
                </div>
        {loading ? (
          <BusinessSectionSkeleton />
        ) : (
          <Fragment>

              <div className="end-page-business">
                <div className="business-tab-cont">
                  {businessList.slice(0, limit).map((business, index) => {
                    const businessBoard = business.businessBoard[i18n.language == 'ko' ? 'ko' : 'en']
                    if(i18n.language === Language.KO || (i18n.language === Language.EN && business.businessBoard.en?.businessTitle)) {
                      return (<div
                        key={index}
                        ref={(element: any) => {
                          businessRef.current[index] - element;
                          if (
                            routeIndex === index &&
                            businessRef?.current[routeIndex]
                          ) {
                            businessRef?.current[index].scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            });
                          }
                        }}
                        className="product-contents-area"
                          >
                          <BusinessItem
                            key={business.businessUrl}
                            business={business}
                            businessBoard={businessBoard}
                            limit={limit}
                            index={index}
                            language={i18n.language}
                            />

                          </div>)
                    }
                  })}

                </div>
                {businessList.length > LIMIT_COUNT && businessList.length > limit ? (
                  <button
                    className="more-btn"
                    data-testid="more-btn-pc"
                    onClick={() => {
                      showMoreDocuments();
                    }}
                  >
                    {t('business.BusinessTabPage.businessSection.more')}
                  </button>
                ) : null}
                </div>
          </Fragment>
        )}
        </div>
        <Footer />
      </div>


    </div>
  );
};

export default BusinessSection;
function dispatch(arg0: { type: "loading/CHANGE_LOADING_STATUS"; loading: boolean; }) {
  throw new Error('Function not implemented.');
}

