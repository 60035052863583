import './BusinessDetailPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';
import BusinessDetailSection from './components/BusinessDetailSection';

const BusinessDetailPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="BusinessDetailPageContainer">
        <BusinessDetailSection />
      </div>
    </Container>
  );
};

export default BusinessDetailPageContainer;
