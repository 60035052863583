import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { ReactElement } from 'react';

const BusinessDetailPageSkeleton: React.FC = (): ReactElement => {
  return (
    <div className="business-detail-area-skeleton">
      <div>
        <Skeleton className="business-detail-businessTitle-skeleton" />
      </div>

      <div id="business-detail-business-registerDate-view">
        <Skeleton className="business-info-box-skeleton" />
        <div className="view-box-skeleton">
          <Skeleton className="view-box-skeleton" />
        </div>
      </div>
      <div className="business-detail-businessText-area-skeleton">
        <Skeleton className="business-detail-businessText-skeleton" />
      </div>
      <div className="case-container">
        <Skeleton className="case-title" />
      </div>
    </div>
  );
};
export default BusinessDetailPageSkeleton;
