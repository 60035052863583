import CommonResponse from '../common/model/CommonResponse';
import BaseService from '../BaseService';
import SessionService from '../SessionService';
import { Subject } from '../common/model/Service';
import { businessData, businessTempData } from './data/BusinessData';
import { Business } from './model/Business';

const mockResponseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {all: [], admin: []},
};

const mockResponseFail: CommonResponse = {
  successOrNot: 'N',
  statusCode: 'FAIL',
  errorMessage: '',
};

export default class BusinessService extends BaseService {
  async getAllMockBusinessList(
    offset?: number,
    pageSize?: number
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/business';
    const serviceName = Subject.BUSINESS;
    const params = pageSize;
    const body = null;

    console.log(businessData.slice(offset, pageSize));
    mockResponseSuccess.data.all = [...businessData.slice(offset, pageSize)];

    try {
      response = mockResponseSuccess;
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response
  }

  async getMockTempBusinessList(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/business/tmp';
    const serviceName = Subject.BUSINESS;
    const params = '';
    const body = '';

    mockResponseSuccess.data = businessTempData;

    try {
      response = mockResponseSuccess;
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getAllBusinessList(
    offset?: number,
    pageSize?: number
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/business';
    const serviceName = Subject.BUSINESS;
    const params = pageSize;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    // 세션 만료 처리
    if (
      response.successOrNot === 'N' &&
      (response.statusCode === 'BAD.REQUEST.ERR' ||
        response.statusCode === 'UNAUTHORIZED.ERR')
    ) {
      const sessionService = new SessionService();
      sessionService.deleteSessionInfo();
    }

    return response;
  }

  async getBusinessDetail(param: string): Promise<Business> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/business/' + param;
    const serviceName = Subject.BUSINESS;
    const params = null;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as Business;
  }

  async createBusiness(request: Business): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/business';
    const serviceName = Subject.BUSINESS;
    const params = null;
    const body = request;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async updateBusiness(business: Business, businessUrl:string): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/business/' + businessUrl;
    const serviceName = Subject.BUSINESS;
    const params = null;
    const body = business;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async deleteBusiness(business: Business): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/business/' + business.businessUrl;
    const serviceName = Subject.BUSINESS;
    const params = null;
    const body = business;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempBusinessList(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/business/tmp';
    const serviceName = Subject.BUSINESS;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempBusinessDetail(businessUrl: string): Promise<Business> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/business/tmp/' + businessUrl;
    const serviceName = Subject.BUSINESS;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async postTempBusinessRegist(businessRequest: Business): Promise<Business[]> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/admin/business/tmp';
    const serviceName = Subject.BUSINESS;
    const params = '';
    const body = businessRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async putTempBusinessEdit(businessRequest: Business, businessUrl: string): Promise<Business[]> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/admin/business/tmp/' + businessUrl;
    const serviceName = Subject.BUSINESS;
    const params = '';
    const body = businessRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async deleteTempBusiness(businessUrl: string): Promise<Business[]> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/admin/business/tmp/' + businessUrl;
    const serviceName = Subject.BUSINESS;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }
}
