import React, { useState, useEffect, useCallback, ReactElement, Fragment } from 'react';
import {} from '../../../service/business/data/BusinessData'
import { Language } from '../../../service/common/model/enum/Common.enum';
import { Business, BusinessBoard } from '../../../service/business/model/Business';
import { useNavigate } from 'react-router-dom';
// import BusinessDetailEditorSection from '../../businessDetail/components/BusinessDetailEditorSection";

interface Props {
  business: Business;
  businessBoard?: BusinessBoard;
  limit: number;
  index: number;
  language: string;
}

const BusinessItem: React.FC<Props> = ({
  business,
  businessBoard,
  limit,
  index,
  language
}: Props): ReactElement => {
  const navigator = useNavigate();
  const handleOnClickBusinessItemBox = (url: string) => {
    navigator('/business/' + url, {
      state: {
        limit: limit,
        index: index
      }
    })
  }
  return (
    <Fragment>
        {language === Language.KO
        ? (<div className="content-box" data-testid="business-tab-item" onClick={() => handleOnClickBusinessItemBox(business.businessUrl)}>

        <div className="content-title">
            {business.businessBoard.ko.businessTitle}
        </div>
        <div className="content-subtitle">
          {business.businessBoard.ko.businessSubtitle}
        </div>
        </div>)
        : business.businessBoard.en?.businessTitle
        ? (<div className="content-box" data-testid="business-tab-item" onClick={() => handleOnClickBusinessItemBox(business.businessUrl)}>

        <div className="content-title">
          {business.businessBoard.en.businessTitle}
        </div>
        <div className="content-subtitle">
          <span>{business.businessBoard.en.businessSubtitle}</span>
        </div>
      </div>)
        : null}
    </Fragment>
  );
};

export default BusinessItem;
