import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { ReactElement } from 'react';

const BlogDetailPageSkeleton: React.FC = (): ReactElement => {
  return (
    <div className="blog-detail-area-skeleton">
      <div>
        <Skeleton className="blog-detail-blogTitle-skeleton" />
      </div>

      <div id="blog-detail-blog-registerDate-view">
        <Skeleton className="blog-info-box-skeleton" />
        <div className="view-box-skeleton">
          <Skeleton className="view-box-skeleton" />
        </div>
      </div>

      <div className="blog-detail-blogText-area-skeleton">
        <Skeleton className="blog-detail-blogText-skeleton" />
      </div>
    </div>
  );
};
export default BlogDetailPageSkeleton;
