import './AboutPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import AboutSection from './components/AboutSection';

const AboutPageContainer: React.FC = () => {
  return (
    <Container id="AboutContainer" fluid={true} style={{ padding: '0' }}>
      <AboutSection />
    </Container>
  );
};

export default AboutPageContainer;
