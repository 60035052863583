import { LanguageAction } from '../actions/language';
import { CHANGE_LANG } from '../actions/language';
import { Language } from '../../service/common/model/enum/Common.enum';

type LanguageState = {
  lang: Language;
};

const initialState: LanguageState = {
  lang: Language.KO,
};

const language = (
  state: LanguageState = initialState,
  action: LanguageAction
): LanguageState => {
  switch (action.type) {
    case CHANGE_LANG:
      return { lang: action.lang };
    default:
      return state;
  }
};

export default language;
