import './BlogDetailSection.scss';

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { ToastViewer } from '../../../../pages/common/ToastViewer';
import {
  Language,
  DateFormat,
  StringDateFormat,
} from '../../../../service/common/model/enum/Common.enum';
import { Blog } from '../../../../service/blog/model/Blog';
import BlogService from '../../../../service/blog/BlogService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import { getStringDateFormat } from '../../../../service/utils/utils';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import BlogDetailEditorSection from '../../../blogDetail/components/BlogDetailEditorSection';

const BlogDetailSection = () => {
  const { id } = useParams();
  const { state } = useLocation() as unknown as { state: boolean }; // true : 임시저장 데이터 조회, false|null : 등록된 뉴스데이터 조회
  const navigate = useNavigate();
  const [blog, setBlog] = useState<Blog>();
  const blogService = new BlogService();
  const [blogLang, setBlogLang] = useState<Language>(Language.KO);
  const language = useSelector((state: RootState) => state.language.lang);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      state ? getTempBlogDetail(id) : getBlogDetail(id);
    }
  }, []);

  const getBlogDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await blogService.getBlogDetail(id);
    if (response) {
      setBlog(response);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const getTempBlogDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await blogService.getTempBlogDetail(id);
    if (response) {
      setBlog(response);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const modifyBtnHandler = () => {
    state
      ? navigate('/admin/blog/regist', { state: id })
      : navigate('/admin/blog/edit/' + id);
  };

  const deleteBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (
      blog &&
      confirm('삭제된 blog는 복구 할 수 없습니다.\nblog를 삭제하시겠습니까?')
    ) {
      blogService.deleteBlog(blog).then((response) => {
        if (response) {
          alert('blog가 삭제되었습니다.');
          navigate('/admin/blog');
        } else {
          alert('blog 삭제 중 오류가 발생하였습니다.');
        }
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      });
    }
  };

  const blogListBtnHandler = () => {
    navigate('/admin/blog');
  };

  const handleLanguageBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (value == Language.KO) {
      setBlogLang(Language.KO);
      console.log(blog?.blogBoard.ko);
    } else if (value == Language.EN) {
      setBlogLang(Language.EN);
      console.log(blog?.blogBoard.en);
    }
  };

  return (
    <div id="AdminBlogDetailSection">
      <ProgressBar />
      <Row>
        <div className="radio-buttons">
          <label className="language">
            <Col>
              <input
                id="ko"
                value={Language.KO}
                name="language"
                type="radio"
                onChange={handleLanguageBtn}
                checked={blogLang == Language.KO}
              />
              <span>한국어</span>
            </Col>
          </label>
          <label className="language">
            <Col>
              <input
                id="en"
                value={Language.EN}
                name="language"
                type="radio"
                onChange={handleLanguageBtn}
                checked={blogLang == Language.EN}
              />
              <span>English</span>
            </Col>
          </label>
        </div>
      </Row>
      {blog ? (
        blogLang == Language.KO ? (
          <>
            <div className="detail-header">
              <div className="title" data-testid="title-text">
                {blog.blogBoard.ko.blogTitle}
              </div>
              <div className="sub-title">{blog.blogBoard.ko.blogSubtitle}</div>
              <div className="category">{blog.blogBoard.ko.blogWriter}</div>
              <div className="title-info">
                <div className="register-admin">
                  등록 : {blog.registerAdmin}
                </div>
                <div className="registered-date">
                  {getStringDateFormat(
                    blog.registeredDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="modify-admin">수정 : {blog.modifyAdmin}</div>
                <div className="modified-date">
                  {getStringDateFormat(
                    blog.modifiedDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="view">조회수 : {blog.blogView}</div>
                <div className="state">공개범위 : {blog.blogState}</div>
              </div>
            </div>
          </>
        ) : blog.blogBoard.en ? (
          <>
            <div className="detail-header">
              <div className="title" data-testid="title-text">
                {blog.blogBoard.en.blogTitle}
              </div>
              <div className="sub-title">{blog.blogBoard.en.blogSubtitle}</div>
              <div className="category">{blog.blogBoard.en.blogWriter}</div>
              <div className="title-info">
                <div className="register-admin">
                  등록 : {blog.registerAdmin}
                </div>
                <div className="registered-date">
                  {getStringDateFormat(
                    blog.registeredDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="modify-admin">수정 : {blog.modifyAdmin}</div>
                <div className="modified-date">
                  {getStringDateFormat(
                    blog.modifiedDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="view">조회수 : {blog.blogView}</div>
                <div className="state">공개범위 : {blog.blogState}</div>
              </div>
            </div>
          </>
        ) : null
      ) : null}
      <hr />
      <div className="detail-body" data-testid="body-text">
        {blog ? (
          blogLang == Language.KO ? (
            <BlogDetailEditorSection blogText={blog.blogBoard.ko.blogText} />
          ) : blog.blogBoard.en ? (
            <BlogDetailEditorSection blogText={blog.blogBoard.en.blogText} />
          ) : null
        ) : null}
      </div>
      <hr />
      <div className="btn-container">
        <Button
          className="btn btn-light"
          data-testid="modify-button"
          onClick={modifyBtnHandler}
        >
          수정
        </Button>
        <Button
          className="btn btn-light"
          data-testid="delete-button"
          onClick={deleteBtnHandler}
        >
          삭제
        </Button>
        <Button
          className="btn btn-secondary"
          data-testid="list-button"
          onClick={blogListBtnHandler}
        >
          목록
        </Button>
      </div>
    </div>
  );
};

export default BlogDetailSection;
