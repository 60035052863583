import './BusinessBoardSection.scss';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { Business } from '../../../../service/business/model/Business';
import BusinessService from '../../../../service/business/BusinessService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import { State } from '../../../../service/common/model/enum/Common.enum';

import BusinessTempRegistSection from './BusinessTempRegistSection';
import BusinessTableSection from './BusinessTableSection';
import { changeAuthorizationStatus } from '../../../../redux/actions/authorization';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import ProgressBar from '../../../../layouts/ProgressBar';

const BusinessBoardSection: React.FC = () => {
  const [businessList, setBusinessList] = useState<Business[]>([]);
  const [businessTempRegistList, setBusinessTempRegistList] = useState<Business[]>([]);
  const [businessStateFilter, setBusinessStateFilter] = useState<State | 'default'>(
    'default'
  );
  const [textSearchFilter, setTextSearchFilter] = useState<string>('');
  const businessService = new BusinessService();
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  const columns = [
    {
      index: '0',
      value: 'No.',
    },
    {
      index: '1',
      value: '제목',
    },
    {
      index: '2',
      value: 'URL',
    },
    {
      index: '3',
      value: '상태',
    },
    {
      index: '4',
      value: '등록자',
    },
    {
      index: '5',
      value: '등록 일자',
    },
    {
      index: '6',
      value: '수정자',
    },
    {
      index: '7',
      value: '수정 일자',
    },
    {
      index: '8',
      value: '조회수',
    }
  ];
  const navigator = useNavigate();

  useEffect(() => {
    getAllBusinessList();
    getAllBusinessTempRegistList();

    return() => {
      setBusinessList([]);
      setBusinessTempRegistList([]);
    }
  }, []);

  const getAllBusinessList = async () => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await businessService.getAllBusinessList(); // Test
    if (response.successOrNot === 'Y') {
      if(response.data.admin){
        setBusinessList([...response.data.all, ...response.data.admin])
      }else{
        setBusinessList([...response.data.all]);
      }

    } else {
      if (response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
      ) {
        dispatch(changeAuthorizationStatus(false));
      }
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  // 임시저장 목록 조회
  const getAllBusinessTempRegistList = async () => {
    const response = await businessService.getTempBusinessList(); //Test
    if (response.successOrNot === 'Y') {
      setBusinessTempRegistList(response.data);
    } else {
      if (response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
      ) {
        dispatch(changeAuthorizationStatus(false));
      }
    }
  };

  const businessHandler = (e: React.SyntheticEvent) => {
    const url = e.currentTarget.getAttribute('id');
    navigator('/admin/business/' + url);
  };

  const businessRegisterBtnHandler = () => {
    navigator('/admin/business/regist');
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSelectStateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    if (value == State.ALL) {
      setBusinessStateFilter(State.ALL);
    } else if (value == State.ADMIN) {
      setBusinessStateFilter(State.ADMIN);
    } else {
      setBusinessStateFilter('default');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTextSearchFilter(value);
  };

  return (
    <div className="AdminBusinessBoardSection">
      <ProgressBar/>
      <div className="fullpage pc">
        <BusinessTableSection
          columns={columns}
          boardList={businessList}
          itemStateFilter={businessStateFilter}
          textSearchFilter={textSearchFilter}
          handleSelectStateChange={handleSelectStateChange}
          handleSearchChange={handleSearchChange}
          clickHandler={businessHandler}
        />
        <div className="btn-container">
          <Button
            className="btn btn-temp-regist"
            data-testid="temp-register-button"
            onClick={openModal}
          >
            business 임시저장 목록
          </Button>
          <Button
            className="btn btn-secondary"
            data-testid="register-button"
            onClick={businessRegisterBtnHandler}
          >
            business 등록
          </Button>
        </div>
      </div>
      <BusinessTempRegistSection
        open={modalOpen}
        close={closeModal}
        tempBusinessList={businessTempRegistList}
      />
    </div>
  );
};

export default BusinessBoardSection;
