import './Header.scss';
import logo from '../../../../src/assets/common/headerLogo.png';
import homeIcon from '../../../../src/assets/common/homeIcon.png';

import React, { useState } from 'react';
import MemberService from '../../../service/member/MemberService';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import { changeAuthorizationStatus } from '../../../redux/actions/authorization';
import { changeLogoutStatus } from '../../../redux/actions/logout';

function Header() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const memberService = new MemberService();
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(changeLoadingStatus(true));
    const response = await memberService.handleLogout();
    dispatch(changeLoadingStatus(false));
    dispatch(changeLogoutStatus(true));
    dispatch(changeAuthorizationStatus(false));
    navigator('/admin/login');
    dispatch(changeLogoutStatus(false));
  };

  return (
    <header className="admin">
      <div className="header">
        <div className="logo">
          <Link to="/admin/" onClick={scrollToTop}>
            <div className="logo-item">
              <img src={logo} />
              <div className="logo-str">관리자</div>
            </div>
          </Link>
        </div>
        <div className="menu-container">
          <div className="menu-item go-main-page">
            <NavLink to="/" target="_blank">
              <img src={homeIcon} />
              <span>메인으로</span>
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink to="/admin/business">Business</NavLink>
          </div>
          <div className="menu-item">
            <NavLink to="/admin/case">Case</NavLink>
          </div>
          <div className="menu-item">
            <NavLink to="/admin/news">News</NavLink>
          </div>
          <div className="menu-item">
            <NavLink to="/admin/blog">Tech Blog</NavLink>
          </div>
          <div className="menu-item">
            <Button
              id="logoutButton"
              name="logoutButton"
              className="logout-button"
              onClick={handleLogout}
              data-testid="logoutButton"
            >
              logout
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
