export enum AuthenticateMode {
    INIT_REQUEST,
    QR_REGIST_REQUEST,
    MFA_REQUEST,
}

export enum AuthenticateState {
    MFA = 'MFA', //1차 로그인(ID, PW 검증) 완료 후 MFA 확인 요청
    NOMFA = 'NOMFA', //1차 로그인 시도 후 MFA 등록 요청
    SUCCESS = 'SUCCESS', //2차 로그인(MFA 검증) 완료, MFA 등록 완료,
    FAILMFA = 'FAILMFA', //MFA 등록 실패
    FAIL = 'FAIL', //2차 로그인 실패
}
