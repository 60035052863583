import './NewsBoardContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import NewsBoardSection from './components/NewsBoardSection';

const NewsBoardContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="AdminNewsBoardContainer">
        <NewsBoardSection />
      </div>
    </Container>
  );
};

export default NewsBoardContainer;
