import React from 'react';
import './CustomRadioBtn.scss';

export interface CustomRadioBtnProps {
  radioBtnValuesAndNames: Array<{
    value: string;
    name: string;
    disabled: boolean;
  }>;
  handleRadioBtn: React.ChangeEventHandler<HTMLInputElement>;
  checkedCondition: string;
}

const CustomRadioBtn: React.FC<CustomRadioBtnProps> = (
  props: CustomRadioBtnProps
) => {
  return (
    <div className="radio-buttons">
      {props.radioBtnValuesAndNames.map(({ value, name, disabled }, idx) => {
        return disabled ? (
          <label className="radio-button-label" key={idx}>
            <input
              id={String(idx)}
              value={value}
              type="radio"
              onChange={props.handleRadioBtn}
              checked={value === props.checkedCondition}
              disabled
            ></input>
            <span className="inputName">{name}</span>
          </label>
        ) : (
          <label className="radio-button-label" key={idx}>
            <input
              id={String(idx)}
              value={value}
              type="radio"
              onChange={props.handleRadioBtn}
              checked={value === props.checkedCondition}
            ></input>
            <span className="inputName">{name}</span>
          </label>
        );
      })}
    </div>
  );
};

export default CustomRadioBtn;
