import './BlogPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import BlogSection from './components/BlogSection';

const BlogPageContainer: React.FC = () => {
  return (
    <Container id="BlogPageContainer" fluid={true} style={{ padding: '0' }}>
      <div id="BlogPageContainer">
        <BlogSection />
      </div>
    </Container>
  );
};

export default BlogPageContainer;
