import './BlogDetailSection.scss';
import '../../../layouts/prism.scss';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../../layouts/Footer';
import BlogService from '../../../service/blog/BlogService';
import { useParams } from 'react-router-dom';
import { Blog } from '../../../service/blog/model/Blog';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/modules/index';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import {
  DateFormat,
  Language,
  StringDateFormat,
} from '../../../service/common/model/enum/Common.enum';
import { getStringDateFormat } from '../../../service/utils/utils';
import { getIsMobile } from '../../../service/utils/utils';
import BlogDetailPageSkeleton from './BlogDetailPageSkeleton';
import { useNavigate } from 'react-router-dom';
import BlogDetailEditorSection from './BlogDetailEditorSection';

const BlogDetailSection: React.FC = () => {
  const blogService = new BlogService();
  const [blog, setBlog] = useState<Blog>();
  const { url } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.language.lang);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const isMobile = getIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const limit = location.state
    ? (location.state as { limit: number }).limit
    : isMobile
    ? 3
    : 6;
  const index = location.state
    ? (location.state as { index: number }).index
    : 0;

  useEffect(() => {
    if (url) {
      getBlogDetail(url);
    }
  }, []);

  useEffect(() => {
    if (language === Language.EN && blog && !blog?.blogBoard.en) {
      alert('There is no english content.');
      navigate('/blog');
    }
  }, [language]);

  const getBlogDetail = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    const res: Blog = await blogService.getBlogDetail(url);
    setBlog(res);
    dispatch(changeLoadingStatus(false));
  };

  return (
    <div className="BlogDetailSection" data-testid="testBlogDetailSection">
      <div className="h-wrap">
        {loading ? (
          <BlogDetailPageSkeleton />
        ) : blog ? (
          <div className="blog-detail-area">
            {language === Language.KO ? (
              <div id="blog-tag">
                {blog.blogBoard.ko.blogObject !== undefined
                  ? blog.blogBoard.ko.blogObject.map((element, key) => {
                      return (
                        <span key={key} className="red-tag">
                          {element}
                        </span>
                      );
                    })
                  : ''}
                {blog.blogBoard.ko.blogCategory !== undefined
                  ? blog.blogBoard.ko.blogCategory.map((element, key) => {
                      return (
                        <span key={key} className="orange-tag">
                          {element}
                        </span>
                      );
                    })
                  : ''}
              </div>
            ) : (
              <div id="blog-tag">
                {blog.blogBoard.en?.blogObject !== undefined
                  ? blog.blogBoard.en?.blogObject.map((element, key) => {
                      return (
                        <span key={key} className="red-tag">
                          {element}
                        </span>
                      );
                    })
                  : ''}
                {blog.blogBoard.en?.blogCategory !== undefined
                  ? blog.blogBoard.en?.blogCategory.map((element, key) => {
                      return (
                        <span key={key} className="orange-tag">
                          {element}
                        </span>
                      );
                    })
                  : ''}
              </div>
            )}
            <div>
              <p id="blog-detail-blogTitle">
                {language === Language.KO
                  ? blog.blogBoard.ko.blogTitle
                  : blog.blogBoard.en?.blogTitle}
              </p>
            </div>
            {isMobile ? (
              <div id="blog-detail-blog-registerDate-view">
                <div className="blog-info-box">
                  <span id="blog-detail-category">
                    {t('blog.blogSection.registeredDate')} &nbsp;
                  </span>
                  <span id="blog-detail-blog-registerDate">
                    {getStringDateFormat(
                      blog.registeredDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <br></br>
                  <span id="blog-detail-category">
                    {t('blog.blogSection.modifiedDate')} &nbsp;
                  </span>
                  <span id="blog-detail-blog-modifiedDate">
                    {getStringDateFormat(
                      blog.modifiedDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <br></br>
                  <span id="blog-detail-category">
                    {language === Language.KO
                      ? blog.blogBoard.ko.blogWriter
                      : blog.blogBoard.en?.blogWriter}
                  </span>
                </div>
                <div className="view-box">
                  <span id="blog-detail-view">
                    {t('blog.blogDetailSection.view')} &nbsp;
                  </span>
                  <span id="blog-detail-blog-view">{blog.blogView}</span>
                </div>
              </div>
            ) : (
              <div id="blog-detail-blog-registerDate-view">
                <div className="blog-info-box">
                  <span id="blog-detail-category">
                    {t('blog.blogSection.registeredDate')} &nbsp;
                  </span>
                  <span id="blog-detail-blog-registerDate">
                    {getStringDateFormat(
                      blog.registeredDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <span id="blog-detail-category">
                    {t('blog.blogSection.modifiedDate')} &nbsp;
                  </span>
                  <span id="blog-detail-blog-modifiedDate">
                    {getStringDateFormat(
                      blog.modifiedDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <span id="blog-detail-category">
                    {language === Language.KO
                      ? blog.blogBoard.ko.blogWriter
                      : blog.blogBoard.en?.blogWriter}
                  </span>
                </div>
                <div className="view-box">
                  <span id="blog-detail-view">
                    {t('blog.blogDetailSection.view')} &nbsp;
                  </span>
                  <span id="blog-detail-blog-view">{blog.blogView}</span>
                </div>
              </div>
            )}

            <div className="blog-detail-blogText-area">
              <div id="blog-detail-blogText">
                {blog ? (
                  language === Language.KO ? (
                    <BlogDetailEditorSection
                      blogText={blog.blogBoard.ko.blogText}
                    />
                  ) : blog.blogBoard.en ? (
                    <BlogDetailEditorSection
                      blogText={blog.blogBoard.en.blogText}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="blog-detail-listbtn-area">
              <Link
                to="/blog"
                state={{
                  limit: limit,
                  index: index,
                }}
              >
                <Button id="backToListBnt" data-testid="back-to-list-button">
                  {t('blog.blogDetailSection.listBtn')}
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <div className="v-wrap">
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetailSection;
