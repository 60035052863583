import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { ReactElement } from 'react';
const blogList = [0, 0, 0, 0, 0, 0];

const BlogSectionSkeleton: React.FC = (): ReactElement => {
  return (
    <div id="blog-section-skeleton" className="box-wrap">
      {blogList.map((blog, key) => {
        return (
          <Skeleton
            className="blog-item-skeleton"
            data-testid="blog-item-area"
            key={key}
          />
        );
      })}
    </div>
  );
};

export default BlogSectionSkeleton;
