import CommonResponse from '../common/model/CommonResponse';
import BaseService from '../BaseService';
import SessionService from '../SessionService';
import { Case, CaseOrderingData } from '../case/model/Case';
import { Subject } from '../common/model/Service';
import { caseTempData } from './data/CaseData';

const mockResponseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {},
};

const mockResponseFail: CommonResponse = {
  successOrNot: 'N',
  statusCode: 'FAIL',
  errorMessage: '',
};

export default class CaseService extends BaseService {
  async getCaseList(pageSize?: number): Promise<Case[]> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case-study';
    const serviceName = Subject.CASE;
    const params = pageSize;
    const body = null;

    try {
      response = await this.fnRest(
        this.userHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (response.successOrNot === 'Y' ? response.data : []) as Array<Case>;
  }

  async getAllCaseList(pageSize?: number): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case-study';
    const serviceName = Subject.CASE;
    const params = pageSize;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    // 세션 만료 처리
    if (
      response.successOrNot === 'N' &&
      (response.statusCode === 'BAD.REQUEST.ERR' ||
        response.statusCode === 'UNAUTHORIZED.ERR')
    ) {
      const sessionService = new SessionService();
      sessionService.deleteSessionInfo();
    }

    return response;
  }

  async getCaseByUrl(caseUrl: string): Promise<Case> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case-study/' + caseUrl;
    const serviceName = Subject.CASE;
    const params = '';
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot == 'Y'
      ? Promise.resolve(response.data?.Items[0])
      : {};
  }

  async checkCaseStudyCaseUrl(caseUrl: string): Promise<boolean> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case-study/' + caseUrl + '/duplicate';
    const serviceName = Subject.CASE;
    const params = '';
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y'
      ? Promise.resolve(response.data['isExist'])
      : true;
  }

  async registCase(caseRequest: Case): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/case-study';
    const serviceName = Subject.CASE;
    const params = null;
    const body = caseRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async editCaseByUrl(
    caseUrl: string,
    caseRequest: Case
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/case-study/' + caseUrl;
    const serviceName = Subject.CASE;
    const params = '';
    const body = caseRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getCaseOrder(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case/order';
    const serviceName = Subject.CASE;
    const params = '';
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async putCaseOrder(data: CaseOrderingData[]): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/case/order';
    const serviceName = Subject.CASE;
    const params = '';
    const body = data;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async deleteCaseByUrl(caseStudy: Case): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/case-study/' + caseStudy.caseUrl;
    const serviceName = Subject.CASE;
    const params = '';
    const body = caseStudy;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempCaseList(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case/tmp';
    const serviceName = Subject.CASE;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempCaseDetail(caseUrl: string): Promise<Case> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/case/tmp/' + caseUrl;
    const serviceName = Subject.CASE;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async postTempCaseRegist(caseRequest: Case): Promise<Case[]> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/case/tmp';
    const serviceName = Subject.CASE;
    const params = '';
    const body = caseRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async putTempCaseEdit(caseRequest: Case, caseUrl: string): Promise<Case[]> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/case/tmp/' + caseUrl;
    const serviceName = Subject.CASE;
    const params = '';
    const body = caseRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async deleteTempCase(caseUrl: string): Promise<Case[]> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/case/tmp/' + caseUrl;
    const serviceName = Subject.CASE;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }
}
