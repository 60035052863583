import './CaseDetailPageAdmin.scss';

import React from 'react';
import {
  Case,
  CaseBoard,
  CaseDetailContent,
} from '../../../../service/case/model/Case';
import SummaryItem from '../../../caseDetail/components/SummaryItem';
import AdditionItem from '../../../caseDetail/components/AdditionItem';

interface IProps {
  caseBoard: CaseBoard | undefined;
}

const CaseDetailPageAdmin = ({ caseBoard }: IProps) => {
  return (
    <div className="case-detail-new">
      <div
        className="detail-top"
        data-testid="detail-top"
        style={{
          backgroundImage: `url(${caseBoard?.caseDetail.caseTitleBg})`,
        }}
      >
        <div className="top-tit detail-container">
          <h2>{caseBoard?.caseTitle}</h2>
        </div>
      </div>
      <div className="detail-container">
        <div className="detail-summary">
          <div className="summary-top">
            <strong className="detail-tit">
              한눈에 보는 Case(Case preview)
            </strong>
            {caseBoard?.caseDetail.caseCompanyLogo ? (
              <img
                src={caseBoard?.caseDetail.caseCompanyLogo}
                alt="Company Logo"
              />
            ) : (
              ''
            )}
          </div>

          <p className="summary-main detail-cont">
            {caseBoard?.caseDetail.caseOutline}
          </p>

          <div className="summary-contents">
            <div className="summary-content">
              <strong>About Company</strong>
              <SummaryItem
                SummaryContentList={
                  caseBoard?.caseDetail.caseDetailSummary.aboutCompany
                }
              />
            </div>

            <div className="summary-content">
              <strong>The Benefit</strong>
              <SummaryItem
                SummaryContentList={
                  caseBoard?.caseDetail.caseDetailSummary.theBenefit
                }
              />
            </div>

            <div className="summary-content">
              <strong>What we did</strong>
              <SummaryItem
                SummaryContentList={
                  caseBoard?.caseDetail.caseDetailSummary.whatWeDid
                }
              />
            </div>
          </div>
        </div>

        <div className="learn-more">
          <hr />
          <span className="detail-cont">더 알아보기(Learn more)</span>
          <hr />
        </div>

        <div className="detail-addition ">
          {caseBoard?.caseDetail.caseDetailContentList &&
            caseBoard?.caseDetail.caseDetailContentList.map(
              (caseDetailContent: CaseDetailContent, idx: number) => (
                <AdditionItem {...caseDetailContent} key={idx} />
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default CaseDetailPageAdmin;
