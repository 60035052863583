import './BusinessBoardRegistForm.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  BusinessWriterKor,
  BusinessWriterEn,
  Language,
  State,
  BusinessObjectKor,
  BusinessObjectEn,
} from '../../../../service/common/model/enum/Common.enum';
import { Business, BusinessBoard } from '../../../../service/business/model/Business';
import BusinessService from '../../../../service/business/BusinessService';
import S3UploadService from '../../../../service/S3Service';
import { useDispatch, useSelector } from 'react-redux';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import { Editor } from '@tinymce/tinymce-react';
import { TinyMCE } from 'tinymce';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import {
  S3FileCopyRequest,
  S3FileDeleteRequest,
  S3FileUploadRequest,
} from '../../../../service/common/model/s3BucketRequest.model';
import { ContentDispositionType } from '../../../../service/common/model/enum/s3.enum';
import { CircularProgress } from '@mui/material';
import { RootState } from '../../../../redux/modules';
import CaseService from '../../../../service/case/CaseService';
declare const tinymce: TinyMCE;

const BusinessBoardRegistForm: React.FC = () => {
  const { state } = useLocation() as unknown as { state: string };
  const navigate = useNavigate();

  const [businessTitle, setBusinessTitle] = useState('');
  const [businessSubtitle, setBusinessSubtitle] = useState('');
  const [businessWriterKorLnk, setBusinessWriterKorLnk] = useState<string>('');
  const [businessWriter, setBusinessWriter] = useState<string>(businessWriterKorLnk);
  const [businessText, setBusinessText] = useState<string>('');
  const [businessObject, setBusinessObject] = useState<string[]>([]);
  const [businessCaseUrl, setBusinessCaseUrl] = useState<string[]>([]);

  const [businessTitleEn, setBusinessTitleEn] = useState('');
  const [businessSubtitleEn, setBusinessSubtitleEn] = useState('');
  const [businessWriterEnLnk, setBusinessWriterEnLnk] = useState<string>('');
  const [businessWriterEn, setBusinessWriterEn] = useState<string>(businessWriterEnLnk);
  const [businessTextEn, setBusinessTextEn] = useState<string>('');
  const [businessObjectEn, setBusinessObjectEn] = useState<string[]>([]);
  // const [businessCaseUrlEn, setBusinessCaseUrlEn] = useState<string[]>([]);
  // const [businessCaseUrlStrEn, setBusinessCaseUrlStrEn] = useState<string>();


  const [isUrlCheck, setIsUrlCheck] = useState<boolean>(true);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const [uploadedFileKeyList, setUploadedFileKeyList] = useState<string[]>([]);
  const uploadList = useRef<string[]>([]);
  const deleteList = useRef<string[]>([]);
  const [businessState, setBusinessState] = useState<State>(State.ALL);
  const [businessLang, setBusinessLang] = useState<Language>(Language.KO);
  const dirKey = useRef<string>(uuidv4());
  const businessService = new BusinessService();
  const caseService = new CaseService();
  const s3UploadService = new S3UploadService();

  const dispatch = useDispatch();
  const s3Domain = process.env.REACT_APP_S3_BUCKET
    ? process.env.REACT_APP_S3_BUCKET
    : '';

  const uploadToS3 = async (blobInfo: any): Promise<string> => {
    // uploadKey = /image/business/{dirKey}/{fileKey}
    const fileKey = uuidv4();
    const uploadRequest: S3FileUploadRequest = {
      dirName: 'business',
      dirKey: dirKey.current,
      fileKey: fileKey,
      fileBody: blobInfo?.base64(),
      contentType: blobInfo?.blob()?.type,
      fileName: blobInfo?.blob()?.name,
      contentDispositionType: ContentDispositionType.IMAGE,
    };

    const uploadResponse = await s3UploadService.uploadToS3(uploadRequest);
    if (uploadResponse && uploadResponse.successOrNot == 'Y') {
      setUploadedFileKeyList((prevList) => [fileKey, ...prevList]);
      return uploadResponse.data?.uploadKey;
    } else {
      return '';
    }
  };

  const image_upload_handler = (
    blobInfo: any,
    progress: any
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      if (
        blobInfo?.blobUri().includes(s3Domain + '/image/business') ||
        blobInfo?.blobUri().includes(s3Domain + '/tmp/image/business')
      ) {
        reject();
      } else {
        uploadToS3(blobInfo).then((val) => {
          resolve(s3Domain + val);
        });
      }
    });

  const handleBusinessWriterBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (businessLang == Language.KO) {
      setBusinessWriter(value);
      transLangWriter(String(event.target.attributes[1].nodeValue));
    } else {
      setBusinessWriterEn(value);
      transLangWriter(String(event.target.attributes[1].nodeValue));
    }
  };

  const transLangWriter = (key: string) => {
    type userType = {
      [key: string]: string;
    };
    const BusinessWriterKorList: userType = BusinessWriterKor;
    const BusinessWriterEnList: userType = BusinessWriterEn;

    if (businessLang == Language.KO) {
      setBusinessWriterEnLnk(BusinessWriterEnList[key]);
      setBusinessWriterEn(BusinessWriterEnList[key]);
    } else {
      setBusinessWriterKorLnk(BusinessWriterKorList[key]);
      setBusinessWriter(BusinessWriterKorList[key]);
    }
  };

  const businessWriterListKor = (
    Object.keys(BusinessWriterKor) as (keyof typeof BusinessWriterKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="businessWriteKor"
            name={key}
            data-testid="businessWriterKor"
            value={BusinessWriterKor[key]}
            type="radio"
            checked={businessWriter === BusinessWriterKor[key]}
            onChange={handleBusinessWriterBtn}
          ></input>
          <span>{BusinessWriterKor[key]}</span>
        </Col>
      </label>
    );
  });

  const businessWriterListEn = (
    Object.keys(BusinessWriterEn) as (keyof typeof BusinessWriterEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="businessWriterEn"
            name={key}
            data-testid="businessWriterEn"
            value={BusinessWriterEn[key]}
            type="radio"
            checked={businessWriterEn === BusinessWriterEn[key]}
            onChange={handleBusinessWriterBtn}
          ></input>
          <span>{BusinessWriterEn[key]}</span>
        </Col>
      </label>
    );
  });

  const handleBusinessObjectBtn = (
    key: keyof typeof BusinessObjectKor | keyof typeof BusinessObjectEn
  ): void => {
    if (businessLang == Language.KO) {
      const keyEn = ('E' + key.slice(1)) as keyof typeof BusinessObjectEn;
      const valueKor = BusinessObjectKor[key as keyof typeof BusinessObjectKor];
      const valueEn = BusinessObjectEn[keyEn];
      setStateObject(valueKor, valueEn);
    } else {
      const keyKor = ('K' + key.slice(1)) as keyof typeof BusinessObjectKor;
      const valueKor = BusinessObjectKor[keyKor];
      const valueEn = BusinessObjectEn[key as keyof typeof BusinessObjectEn];
      setStateObject(valueKor, valueEn);
    }
  };

  const setStateObject = (
    valueKor: BusinessObjectKor,
    valueEn: BusinessObjectEn
  ): void => {
    if (businessObject.includes(valueKor)) {
      setBusinessObject(businessObject.filter((obj) => obj !== valueKor));
      setBusinessObjectEn(businessObjectEn.filter((obj) => obj !== valueEn));
    } else {
      setBusinessObject([valueKor, ...businessObject]);
      setBusinessObjectEn([valueEn, ...businessObjectEn]);
    }
  };

  const businessObjectListKor = (
    Object.keys(BusinessObjectKor) as (keyof typeof BusinessObjectKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="businessObjectKor"
            name="businessObjectKor"
            data-testid="businessObjectKor"
            value={BusinessObjectKor[key]}
            type="checkbox"
            onChange={() => handleBusinessObjectBtn(key)}
          ></input>
          <span>{BusinessObjectKor[key]}</span>
        </Col>
      </label>
    );
  });

  const businessObjectListEn = (
    Object.keys(BusinessObjectEn) as (keyof typeof BusinessObjectEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="businessObjectEn"
            name="businessObjectEn"
            data-testid="businessObjectEn"
            value={BusinessObjectEn[key]}
            type="checkbox"
            onChange={() => handleBusinessObjectBtn(key)}
          ></input>
          <span>{BusinessObjectEn[key]}</span>
        </Col>
      </label>
    );
  });

  useEffect(() => {
    if (state) {
      getTempBusinessDetail(state);
    }
  }, [])

  const getTempBusinessDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await businessService.getTempBusinessDetail(id);
    if (response) {
      if (response.businessBoard.en) {
        setBusinessLang(response.businessBoard.en.businessLang);
        setBusinessWriterEn(response.businessBoard.en.businessWriter);
        setBusinessTitleEn(response.businessBoard.en.businessTitle);
        if (response.businessBoard.en.businessSubtitle) {
          setBusinessSubtitleEn(response.businessBoard.en.businessSubtitle);
        }
        if (response.businessBoard.en.businessObject) {
          setBusinessObjectEn(response.businessBoard.en.businessObject);
        }
        if (response.businessBoard.en.caseStudyUrl) {
          setBusinessCaseUrl(response.businessBoard.en.caseStudyUrl);
        }
        setBusinessTextEn(response.businessBoard.en.businessText);
        setTextImgList(response.businessBoard.en.businessText);
      }
      setBusinessState(response.businessState);
      setBusinessLang(response.businessBoard.ko.businessLang);
      setBusinessWriter(response.businessBoard.ko.businessWriter);
      setBusinessTitle(response.businessBoard.ko.businessTitle);
      if (response.businessBoard.ko.businessSubtitle) {
        setBusinessSubtitle(response.businessBoard.ko.businessSubtitle);
      }
      if (response.businessBoard.ko.businessObject) {
        setBusinessObject(response.businessBoard.ko.businessObject);
      }
      if (response.businessBoard.ko.caseStudyUrl) {
        setBusinessCaseUrl(response.businessBoard.ko.caseStudyUrl);
      }
      setBusinessText(response.businessBoard.ko.businessText);
      setTextImgList(response.businessBoard.ko.businessText);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const setTextImgList = (businessText: string) => {
    //업로드 완료된 이미지 리스트 만들기
    const html = document.createElement('html');
    html.innerHTML = businessText;
    const imgList: HTMLImageElement[] = Array.prototype.slice.call(
      html.getElementsByTagName('img')
    );
    const uploadedList: string[] = [];
    imgList.map((img, idx) => {
      const tmp = img.getAttribute('src')?.split('/');
      if (tmp) {
        const key = tmp[tmp?.length - 1];
        if (idx === 0) {
          const prevDirKey = tmp[tmp?.length - 2];
          if (prevDirKey) dirKey.current = prevDirKey;
        }
        uploadedList.push(key);
      }
    });

    setUploadedFileKeyList((prevList) => [...uploadedList, ...prevList]);
  };

  const handleLanguageBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (value == Language.KO) {
      setBusinessLang(Language.KO);
    } else if (value == Language.EN) {
      setBusinessLang(Language.EN);
    }
  };

  const handleOnBusinessTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (businessLang == Language.KO) {
      setBusinessTitle(value);
    } else {
      setBusinessTitleEn(value);
    }
  };

  const handleOnBusinessSubtitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (businessLang == Language.KO) {
      setBusinessSubtitle(value);
    } else {
      setBusinessSubtitleEn(value);
    }
  };

  const handleOnCaseUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setIsUrlCheck(true);
      setBusinessCaseUrl([])
    } else {
      setIsUrlCheck(false);
      setBusinessCaseUrl(e.target.value.replace(' ', '').split(','));
    }
  };

  const handleOnCaseUrlValidClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    let invalidList: string[] = []
    e.preventDefault();
    if (businessCaseUrl.length == 0) {
      alert('URL을 입력해주세요.')
      return false;
    } if (businessCaseUrl.length > 5) {
      alert('case url은 5개까지 등록 가능합니다.')
      return;
    } else {

      const promises = businessCaseUrl.map(caseUrl => {
        if (caseUrl) {
          dispatch(changeLoadingStatus(true));
          dispatch(changeProgressStatus(50));

          return caseService.checkCaseStudyCaseUrl(caseUrl).then((response) => {
            if (!response) {
              setIsUrlCheck(false);
              invalidList.push(caseUrl);
            }

          })
        } else {
          return Promise.resolve(true);
        }
      });

      await Promise.all(promises);

      if (invalidList.length === 0) {
        setIsUrlCheck(true);
        alert('사용 가능한 url 입니다.');
      } else {
        setIsUrlCheck(false);
        alert(`${invalidList.join(',')} 은 존재하지 않는 url 입니다.`);
        invalidList = [];
        return false;
      }
    }

    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  }


  const handleStateBtn = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value === State.ALL) {
      setBusinessState(State.ALL);
    } else if (value === State.ADMIN) {
      setBusinessState(State.ADMIN);
    }
  };

  const handleCancelBtn = () => {
    if (confirm('business 등록을 취소하시겠습니까?')) {
      alert('business 등록이 취소되었습니다.');
      navigate('/admin/business');
    }
  };

  const setRequestImgData = async () => {
    const routeStr = 'tmp/image/business/' + dirKey.current + '/';
    await uploadedFileKeyList.map(async (key) => {
      if (businessText.includes(key) || businessTextEn.includes(key)) {
        uploadList.current.push(routeStr + key);
      }
    });
  };

  const getRequestBusinessData = (isTmpSave: boolean) => {
    if (businessTitle == '') {
      alert('한글 제목을 입력해주세요.');
      return;
    }
    if (businessText == '') {
      alert('한글 내용을 입력해주세요.');
      return;
    }
    if (businessWriter == '') {
      alert('작성자를 입력해주세요.');
      return;
    }
    if (!isUrlCheck) {
      alert('CaseStudy URL 유효성 검증이 필요합니다.')
      return;
    }

    const businessBoard: BusinessBoard = {
      businessLang: Language.KO,
      businessWriter: businessWriter,
      businessObject: businessObject,
      businessTitle: businessTitle,
      businessSubtitle: businessSubtitle,
      businessText: isTmpSave
        ? businessText
        : businessText.replaceAll('/tmp/image', '/image'),
      caseStudyUrl: businessCaseUrl,
    };

    const businessBoardEn: BusinessBoard = {
      businessLang: Language.EN,
      businessWriter: businessWriterEn,
      businessObject: businessObjectEn,
      businessTitle: businessTitleEn,
      businessSubtitle: businessSubtitleEn,
      businessText: isTmpSave
        ? businessTextEn
          ? businessTextEn.replaceAll('/tmp/image', '/image')
          : businessTextEn
        : businessTextEn,
      caseStudyUrl: businessCaseUrl,
    };

    const requestData: Business = {
      businessUrl: '', //백엔드에서 처리
      businessState: businessState,
      businessView: 0, //백엔드에서 처리
      registerAdmin: '', //백엔드에서 처리
      registeredDate: '', //백엔드에서 처리
      modifyAdmin: '', //백엔드에서 처리
      modifiedDate: '', //백엔드에서 처리
      businessBoard: { ko: businessBoard, en: businessBoardEn },
    };

    return requestData;
  };

  const handleRegistBtn = async () => {
    const registRequest = getRequestBusinessData(false);
    await setRequestImgData();
    let copyResponse;

    if (uploadList.current.length > 0) {
      const fileCopyRequest: S3FileCopyRequest = {
        uploadFileKeyList: uploadList.current,
      };
      copyResponse = await s3UploadService.copyTmpFiles(fileCopyRequest);
    }

    if (copyResponse && copyResponse.successOrNot == 'N') {
      alert('business 등록 중 오류가 발생했습니다.');
    } else if (
      !copyResponse ||
      (copyResponse && copyResponse.successOrNot == 'Y')
    ) {
      if (registRequest) createBusiness(registRequest);
    }
  };

  const handleTempRegistBtn = async () => {
    const request = getRequestBusinessData(true);
    await setRequestImgData();

    if (request) {
      registTempBusiness(request);
    }
  };

  const createBusiness = async (request: Business) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await businessService.createBusiness(request);
    if (response.successOrNot == 'Y') {
      deleteImgFile();
      if (state) {
        const delResponse = await businessService.deleteTempBusiness(state);
        if (delResponse) {
          alert('business 등록이 완료 되었습니다.');
          navigate('/admin/business');
        } else {
          alert('business 등록 중 오류가 발생했습니다.');
        }
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      } else {
        alert('business 등록이 완료 되었습니다.');
        navigate('/admin/business');
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      }
    } else {
      deleteImgFile();
      alert('business 등록 중 오류가 발생했습니다.');
    }
  };

  const deleteImgFile = () => {
    const deleteFileRequest: S3FileDeleteRequest = {
      dirName: 'business',
      dirKey: dirKey.current,
      contentDispositionType: ContentDispositionType.IMAGE,
    };

    s3UploadService.deleteFiles(deleteFileRequest);
  };

  const registTempBusiness = async (request: Business) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = state
      ? await businessService.putTempBusinessEdit(request, state)
      : await businessService.postTempBusinessRegist(request);

    if (response) {
      alert('임시저장이 완료되었습니다.');
      navigate('/admin/business');
    } else {
      alert('임시저장 중 오류가 발생했습니다.');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };



  return (
    <div id="BusinessBoardRegist">
      <ProgressBar />
      <Form id="BusinessBoardRegistForm" name="BusinessBoardRegistForm">
        <div className="fullpage pc">
          <Row className="search">
            <Col>
              <span className="business-admin-title">Business 등록 (</span>
              <span className="red-star"></span>
              <span className="business-admin-title"> 은 필수값 입니다.)</span>
            </Col>
          </Row>
          <div className="business-info">
            <Row>
              <Col className="business-col red-star">언어</Col>
              <div className="radio-buttons">
                <label className="language">
                  <Col>
                    <input
                      id="ko"
                      value={Language.KO}
                      name="language"
                      type="radio"
                      onChange={handleLanguageBtn}
                      checked={businessLang == Language.KO}
                    />
                    <span>한국어</span>
                  </Col>
                </label>
                <label className="language">
                  <Col>
                    <input
                      id="en"
                      value={Language.EN}
                      name="language"
                      type="radio"
                      onChange={handleLanguageBtn}
                      checked={businessLang == Language.EN}
                    />
                    <span>English</span>
                  </Col>
                </label>
              </div>
            </Row>
            {businessLang == Language.KO ? (
              <>
                <Row className="radio-area">
                  <Col className="business-col red-star">공개 여부</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="businessState"
                          name="businessState"
                          data-testid="businessState"
                          value={State.ALL}
                          type="radio"
                          checked={businessState == State.ALL}
                          onChange={handleStateBtn}
                        ></input>
                        <span>모두공개</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="businessState"
                          name="businessState"
                          data-testid="businessState"
                          value={State.ADMIN}
                          type="radio"
                          checked={businessState == State.ADMIN}
                          onChange={handleStateBtn}
                          disabled
                        ></input>
                        <span>비공개</span>
                      </Col>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col className="business-col red-star">작성자</Col>
                  <div className="radio-buttons">{businessWriterListKor}</div>
                </Row>
                <Row>
                  <Col className="business-col">목적</Col>
                  <div className="radio-buttons">{businessObjectListKor}</div>
                </Row>

                <Row>
                  <Col className="business-col red-star">제목</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="businessTitle"
                      name="businessTitle"
                      data-testid="businessTitle"
                      value={businessTitle}
                      onChange={handleOnBusinessTitleChange}
                      maxLength={40}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="business-col">소제목</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="businessSubtitle"
                      name="businessSubtitle"
                      data-testid="businessSubtitle"
                      value={businessSubtitle}
                      onChange={handleOnBusinessSubtitleChange}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="business-col">CaseStudy URL</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="businessCaseUrl"
                      name="businessCaseUrl"
                      data-testid="businessCaseUrl"
                      value={businessCaseUrl}
                      onChange={handleOnCaseUrlChange}
                      placeholder="쉼표(,)로 구분하여 입력해주세요.(최대 5개)"
                      style={{ display: "inline-block" }}
                    ></Form.Control>
                    <Button className="btn btn-primary" onClick={handleOnCaseUrlValidClick}>URL 유효성 검증</Button>
                  </Col>
                </Row>

              </>
            ) : (
              <>
                <Row>
                  <Col className="business-col red-star">State</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="businessState"
                          name="businessState"
                          data-testid="businessState"
                          value={State.ALL}
                          type="radio"
                          checked={businessState == State.ALL}
                          onChange={handleStateBtn}
                        ></input>
                        <span>ALL</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="businessState"
                          name="businessState"
                          data-testid="businessState"
                          value={State.ADMIN}
                          type="radio"
                          checked={businessState == State.ADMIN}
                          onChange={handleStateBtn}
                          disabled
                        ></input>
                        <span>ADMIN</span>
                      </Col>
                    </label>
                  </div>
                </Row>

                <Row>
                  <Col className="business-col red-star">Writer</Col>
                  <div className="radio-buttons">{businessWriterListEn}</div>
                </Row>
                <Row>
                  <Col className="business-col">Object</Col>
                  <div className="radio-buttons">{businessObjectListEn}</div>
                </Row>
                <Row>
                  <Col className="business-col red-star">Title</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="businessTitleEn"
                      name="businessTitleEn"
                      data-testid="businessTitleEn"
                      value={businessTitleEn}
                      onChange={handleOnBusinessTitleChange}
                      maxLength={40}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="business-col">SubTitle</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="businessSubtitleEn"
                      name="businessSubtitleEn"
                      data-testid="businessSubtitleEn"
                      value={businessSubtitleEn}
                      onChange={handleOnBusinessSubtitleChange}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="business-col">CaseStudy URL</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="businessCaseUrl"
                      name="businessCaseUrl"
                      data-testid="businessCaseUrl"
                      value={businessCaseUrl}
                      onChange={handleOnCaseUrlChange}
                      placeholder="Please separate them with commas (,) (up to 5)"
                      style={{ display: "inline-block" }}
                    ></Form.Control>
                    <Button className="btn btn-primary" onClick={handleOnCaseUrlValidClick}>URL 유효성 검증</Button>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="regist-body">
            {businessLang == Language.KO ? (
              <Editor
                onEditorChange={(txt, editor) => {
                  setBusinessText(txt);
                }}
                initialValue=""
                value={businessText}
                apiKey="gmmjng74ulc7k28dufw17avjz1eboa5744k1jacji2q6xard"
                init={{
                  toggleNativeEvent: '',
                  height: 500,
                  menubar: 'file edit view insert format tools table help',
                  placeholder: '내용을 입력해주세요.',
                  plugins: [
                    'lists',
                    'advlist',
                    'anchor',
                    'autolink',
                    'charmap',
                    'code',
                    'codesample',
                    'directionality',
                    'emoticons',
                    'fullscreen',
                    'help',
                    'image',
                    'importcss',
                    'insertdatetime',
                    'link',
                    'media',
                    'nonbreaking',
                    'pagebreak',
                    'preview',
                    'save',
                    'searchreplace',
                    'table',
                    'template',
                    'visualblocks',
                    'visualchars',
                    'wordcount',
                  ],
                  textcolor_rows: '4',
                  toolbar: [
                    'undo redo | bold italic underline strikethrough | fontsize fontfamily forecolor backcolor removeformat | restoredraft | ltr rtl | help',
                    'link image media emoticons charmap  subscript superscript insertdatetime codesample | alignleft aligncenter alignright alignjustify lineheight | outdent indent | blockquote numlist bullist | pagebreak | fullscreen preview code wordcount | searchreplace',
                    'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                  ],
                  font_family_formats:
                    'Arial=arial,helvetica;sans-serif;NanumSquare;Noto Sans KR; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n',
                  font_size_formats:
                    '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                  line_height_formats: '1 1.2 1.4 1.6 2',
                  toolbar_sticky: true,
                  automatic_uploads: true,
                  file_browser_callback_types: 'image',
                  image_advtab: true,
                  image_title: true,
                  file_picker_types: 'image',
                  content_css: [
                    'https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css',
                    'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap',
                    'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
                  ],
                  forced_root_block_attrs: {
                    style: 'font-family: arial;font-size:12px',
                  },
                  content_style:
                    'body { font-family:arial,NanumSquare,sans-serif;}',
                  codesample_languages: [
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'CSS', value: 'css' },
                    { text: 'PHP', value: 'php' },
                    { text: 'Ruby', value: 'ruby' },
                    { text: 'Python', value: 'python' },
                    { text: 'Java', value: 'java' },
                    { text: 'C', value: 'c' },
                    { text: 'C#', value: 'csharp' },
                    { text: 'C++', value: 'cpp' },
                    { text: 'bash', value: 'bash' },
                    { text: 'git', value: 'git' },
                    { text: 'javadoc', value: 'javadoc' },
                    { text: 'sql', value: 'sql' },
                    { text: 'yaml', value: 'yaml' },
                  ],
                  relative_urls: false,
                  images_upload_handler: image_upload_handler,
                  file_picker_callback: function (callback, value, meta) {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = function () {
                      const file = (input as any)?.files[0];
                      const reader = new FileReader();

                      reader.onload = function () {
                        const id = 'blobid' + new Date().getTime();
                        const blobCache =
                          tinymce?.activeEditor?.editorUpload.blobCache;
                        const base64 = reader.result?.toString().split(',')[1];
                        const blobInfo = base64
                          ? blobCache?.create(id, file, base64)
                          : undefined;
                        blobInfo ? blobCache?.add(blobInfo) : {};
                        blobInfo
                          ? callback(blobInfo?.blobUri(), { title: file.name })
                          : {};
                      };
                      reader.readAsDataURL(file);
                    };

                    input.click();
                  },
                  invalid_elements: 'iframe'
                }}
              />
            ) : (
              <Editor
                onEditorChange={(txt, editor) => {
                  setBusinessTextEn(txt);
                }}
                init={{
                  invalid_elements: 'iframe'
                }}
                initialValue=""
                value={businessTextEn}
                apiKey="gmmjng74ulc7k28dufw17avjz1eboa5744k1jacji2q6xard"
              />
            )}
          </div>
          <div className="btn-container">
            <Button
              className="btn btn-temp-list"
              id="tempListBtn"
              onClick={handleTempRegistBtn}
              data-testid="temp-list-button"
            >
              임시저장
            </Button>

            <Button
              className="btn btn-danger"
              onClick={handleCancelBtn}
              data-testid="cancel-button"
            >
              취소
            </Button>
            <Button
              className="btn btn-light"
              id="registBtn"
              onClick={handleRegistBtn}
              data-testid="regist-button"
            >
              등록
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BusinessBoardRegistForm;
