import 'bootstrap/dist/css/bootstrap.min.css';
import './ChangePasswordContainer.scss';

import React, { ReactElement, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';

import ChangePasswordSection from './component/ChangePasswordSection';

const ChangePasswordContainer: React.FC = (): ReactElement => {
  return (
    <div id="changePasswordContainer">
      <Container fluid={true}>
        <ChangePasswordSection />
      </Container>
    </div>
  );
};

export default ChangePasswordContainer;
