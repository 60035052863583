import Enterprise01Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation01.jpg';
import Enterprise02Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation02.jpg';
import Enterprise03Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation03.jpg';
import Enterprise04Img from '../../../../assets/awsProductCard/awsCloudFormation/cloudformation04.jpg';

import {
  CloudFormationContentData,
  AwsCloudFormationContentLayout,
} from './AwsCloudFormationModel';

export const cloudformationContentDatasKo: CloudFormationContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsCloudFormationContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'LG CNS는 AWS CloudFormation을 활용하여 고객의 인프라 구성을 안정적으로 지원합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `AWS를 포함한 클라우드 전환이 확산되면서 인프라 구성 자동화와 안정적인 운영을 위해 많은 기업들이 IaC(Infrastructure as Code)를 도입하고 있습니다. AWS CloudFormation은 AWS 리소스를 코드로 생성하는 서비스입니다. LG CNS는 AWS CloudFormation와 Git을 이용하여 인프라 자원을 코드로 관리하고 있습니다. 이를 통해 코드의 형상 관리, 변경 내역 추적 등을 하여, 서비스의 인프라를 안정적으로 운영할 수 있도록 지원합니다.`,
              options: {
                //size: 'content-lg',
                //bold: true,
                //color: 'primary',
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsCloudFormationContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: '지속적 배포(Continuous Deployment)를 실현합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: '지속적 배포란 변경된 코드가 자동으로 테스트 및 배포되는 개발 환경입니다. AWS CloudFormation과 배포 파이프라인을 함께 사용하면 인프라 코드 변경 사항을 자동으로 구축하고 테스트할 수 있습니다. LG CNS는 고객의 요구사항에 기반하여 다양한 브랜치 전략을 활용해 파이프라인 구축하고, 고객이 신속한 배포와 안정적인 인프라 관리가 가능하게 하며, 지속적 배포를 실현하도록 지원합니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsCloudFormationContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: '복잡한 인프라 요구사항에도 CloudFormation으로 효율적으로 대응합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS CloudFormation은 복잡한 인프라 요구사항에 대응하기 위해 중첩 스택과 조건부 리소스 생성을 포함한 다양한 고급 기능을 제공합니다. 중첩 스택으로 복잡한 아키텍처를 모듈로 나눠 관리를 간소화하고 조건부 리소스 생성을 통해 특정 조건에서만 리소스를 배포합니다. 이외에도, 사용자 정의 리소스와 스택 정책 등 다양한 기능들을 통해 보다 세밀한 인프라 관리가 가능합니다. 이러한 기능들로 CloudFormation은 다양하고 복잡한 인프라 요구에 빠르고 유연하게 대응할 수 있게 해줍니다.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsCloudFormationContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: '정의된 자원에 기반한 비용 효율적인 지불 구조로 비용을 최적화합니다.',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'AWS CloudFormation의 템플릿에 정의된 인프라 자원에 대해서만 비용을 지불하여, 불필요한 인프라 유지 관리 비용을 줄일 수 있습니다. 리소스를 사전에 대량 구매하거나 유지할 필요 없이, 필요에 따라 동적으로 인프라를 확장하고 축소할 수 있으며 예산을 효율적으로 관리할 수 있게 합니다. ',
            },
          ],
        },
      ],
    },
  },
];

