import './BlogBoardContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import BlogBoardSection from './components/BlogBoardSection';

const BlogBoardContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="AdminBlogBoardContainer">
        <BlogBoardSection />
      </div>
    </Container>
  );
};

export default BlogBoardContainer;
