import './BlogItem.scss';

import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStringDateFormat } from '../../../service/utils/utils';
import {
  DateFormat,
  StringDateFormat,
} from '../../../service/common/model/enum/Common.enum';

export interface BlogItemProps {
  blogUrl: string;
  blogTitle: string;
  blogRegisterDate: string;
  blogWriter: string;
  blogView: number;
  blogObject?: string[];
  blogCategory?: string[];
  limit: number;
  index: number;
}

const BlogItem: React.FC<BlogItemProps> = (props: BlogItemProps) => {
  const navigator = useNavigate();
  const [isHovering, setIsHovering] = useState(0);
  const blogDetailUrl: string = '/blog/' + props.blogUrl;
  const { t } = useTranslation();

  const handleBlogInfoOnClick = () => {
    navigator(blogDetailUrl, {
      state: {
        limit: props.limit,
        index: props.index,
      },
    });
  };
  return (
    <div
      className="blog-item"
      onClick={handleBlogInfoOnClick}
      data-testid="blog-item-area"
    >
      <div
        id="blog-info-area"
        className="blog-info-area"
        onMouseOver={() => setIsHovering(1)}
        onMouseOut={() => setIsHovering(0)}
      >
        {!isHovering ? (
          <Fragment>
            <div id="blog-tag">
              {props.blogObject !== undefined
                ? props.blogObject.map((element, key) => {
                    return (
                      <span key={key} className="red-tag">
                        {element}
                      </span>
                    );
                  })
                : ''}
              {props.blogCategory !== undefined
                ? props.blogCategory.map((element, key) => {
                    return (
                      <span key={key} className="orange-tag">
                        {element}
                      </span>
                    );
                  })
                : ''}
            </div>
            <p id="blog-title">{props.blogTitle}</p>
            <p id="blog-writer">{props.blogWriter}</p>
            <div id="blog-modifiedDate">
              <div id="blog-modifiedDate-date">
                <span>
                  {getStringDateFormat(
                    props.blogRegisterDate,
                    DateFormat.DATE,
                    StringDateFormat.DATE
                  )}
                </span>
              </div>
              <div id="blog-modifiedDate-view">
                <span className="blog-view">
                  {t('blog.blogSection.view')} {props.blogView}
                </span>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div id="blog-tag">
              {props.blogObject !== undefined
                ? props.blogObject.map((element, key) => {
                    return (
                      <span key={key} className="white-tag">
                        {element}
                      </span>
                    );
                  })
                : ''}
              {props.blogCategory !== undefined
                ? props.blogCategory.map((element, key) => {
                    return (
                      <span key={key} className="white-tag">
                        {element}
                      </span>
                    );
                  })
                : ''}
            </div>
            <p id="blog-title">{props.blogTitle}</p>
            <p id="blog-writer">{props.blogWriter}</p>
            <div id="blog-modifiedDate">
              <span>
                {getStringDateFormat(
                  props.blogRegisterDate,
                  DateFormat.DATE,
                  StringDateFormat.DATE
                )}
              </span>
              <span className="blog-view">
                {t('blog.blogSection.view')} {props.blogView}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default BlogItem;
