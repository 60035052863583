import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { Fragment, ReactElement } from 'react';

const NewsDetailPageSkeleton: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <div className="news-detail-area">
        <div>
          <Skeleton width={'10rem'} height={'3rem'} />
        </div>
        <div>
          <Skeleton height={'5rem'} />
        </div>
        <div className="news-detail-newsText-area">
          <Skeleton
            className="news-detail-newsText-skeleton"
            height={'10rem'}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default NewsDetailPageSkeleton;
