import './CaseBoardRegistForm.scss';

import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';

import CustomRadioBtn from '../../../common/CustomRadioBtn';
import S3UploadService from '../../../../service/S3Service';
import S3ErrorAlert from '../../../../service/common/S3ErrorAlert';

import { CaseDetailContent } from '../../../../service/case/model/Case';
export interface Props {
  i: number;
  caseDetailContentArray: CaseDetailContent;
  updateDetail: (id: number, data: CaseDetailContent) => void;
  removeDetail: (id: number) => void;
}

const CaseDetail: React.FC<Props> = (props: Props): ReactElement => {
  const { detailTitle, detailContent, detailContentUrl, detailText } =
    props.caseDetailContentArray;

  const [type, setType] = useState(detailContent ? detailContent : 'image');
  const [fileUrl, setFileUrl] = useState<string>(detailContentUrl || '');
  const loading_allPage = useSelector(
    (state: RootState) => state.loading.loading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setType(
      props.caseDetailContentArray.detailContent == '' ||
        !props.caseDetailContentArray.detailContent
        ? 'image'
        : props.caseDetailContentArray.detailContent
    );
    setFileUrl(props.caseDetailContentArray.detailContentUrl || '');
  }, [props.caseDetailContentArray]);

  const s3UploadService = new S3UploadService();

  const handleImgUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changeLoadingStatus(true));
      const file = event.target.files ? event.target.files[0] : undefined;
      if (file && s3UploadService.checkExtension(file.name)) {
        s3UploadService.uploadImage('case', file).then((response) => {
          dispatch(changeLoadingStatus(false));
          if (response.includes('http')) {
            // response is image URL
            setFileUrl(() => response);
            alert('이미지 업로드가 완료되었습니다.');
          } else {
            S3ErrorAlert(response);
          }
        });
      } else {
        dispatch(changeLoadingStatus(false));
        alert('잘못된 파일입니다. 다시 시도해주세요.');
      }
      event.target.files = null;
      event.target.value = '';
      return;
    },
    [loading_allPage]
  );

  useEffect(() => {
    props.updateDetail(props.i, {
      ...props.caseDetailContentArray,
      ['detailContent']: type,
      ['detailContentUrl']: '',
    });
  }, [type]);

  useEffect(() => {
    props.updateDetail(props.i, {
      ...props.caseDetailContentArray,
      ['detailContent']: type,
      ['detailContentUrl']: fileUrl,
    });
  }, [fileUrl]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    props.updateDetail(props.i, {
      ...props.caseDetailContentArray,
      [event.target.name]: event.target.value,
    });
  };

  const onDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    props.removeDetail(props.i);
  };

  const onChangeFileUrl = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFileUrl(event.target.value);
  };

  const handleOnRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setType(event.target.value);
  };

  const handleImgUploadCancel = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setFileUrl('');
  };

  return (
    <div key={props.i} className="bodyInfo">
      <div className="case-btnArea">
        {props.i === 0 ? (
          <></>
        ) : (
          <button className="remove-btn" key={props.i} onClick={onDelete}>
            x
          </button>
        )}
      </div>
      <div className="case-detail-wrap">
        <div className="case-detail-item-wrap">
          <div className="head red-star">detailTitle</div>
          <Form.Control
            className="input"
            type="text"
            id="detailTitle"
            name="detailTitle"
            data-testid="detailTitle"
            onChange={onChange}
            value={detailTitle}
          ></Form.Control>
        </div>
        <div className="case-detail-item-wrap">
          <div className="head">detailText</div>
          <Form.Control
            as="textarea"
            className="input"
            id="detailText"
            name="detailText"
            data-testid="detailText"
            onChange={onChange}
            value={detailText}
          ></Form.Control>
        </div>
        <div className="case-detail-item-wrap">
          <div className="head">detailContent</div>
          <div className="case-detail-upload-item">
            <CustomRadioBtn
              radioBtnValuesAndNames={[
                { value: 'image', name: '사진', disabled: false },
                { value: 'video', name: '영상', disabled: false },
              ]}
              handleRadioBtn={handleOnRadioChange}
              checkedCondition={type}
            />
            {type === 'image' ? (
              <div className="case-detail-upload-result">
                <div className="upload-image-input-wrapper">
                  <input
                    type="file"
                    id={'upload-image-detail-content-' + props.i}
                    onChange={handleImgUpload}
                  />
                </div>
                <input
                  id={'upload-image-detail-content-result-' + props.i}
                  name="detailContentUrl"
                  className="upload-image-detail-content-result"
                  data-testid="detailContentUrl"
                  value={fileUrl}
                  readOnly
                />
                <button
                  className="case-upload-detail-cancel-btn"
                  name="detailContentUrl"
                  onClick={handleImgUploadCancel}
                >
                  업로드 취소
                </button>
              </div>
            ) : (
              <div className="case-detail-upload-result">
                <input
                  id={'upload-video-detail-content-result-' + props.i}
                  name="detailContentUrl"
                  className="upload-video-detail-content-result"
                  data-testid="detailContentUrl"
                  value={fileUrl}
                  onChange={onChangeFileUrl}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDetail;
