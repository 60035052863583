import React from 'react';
import { getIsMobile } from '../../../service/utils/utils';
import { AwsServiceData } from '../../../service/data/awsProduct/AwsProductModel';
import { Router, Routes, useNavigate } from 'react-router-dom';

interface Props {
  awsServiceCode: string;
  awsProductName: string;
  awsServiceUrl?: string;
  awsTabItem: AwsServiceData;
}

const AwsProductTabItem: React.FC<Props> = (props: Props) => {
  const isMobile = getIsMobile();
  const navigate = useNavigate();

  return (
    <div
      className="content-box"
      data-testid="awsproduct-tab-item"
      onClick={() => {
        props?.awsServiceUrl && navigate('/awsproduct/' + props.awsServiceCode);
      }}
    >
      <div className="content-title">
        {!isMobile && (
          <span className="content-product">{props.awsProductName}</span>
        )}
        <span className="content-service">{props.awsTabItem.serviceName}</span>
      </div>
      <div className="content-summary">
        <span className="content-summary-txt">
          {props.awsTabItem.serviceSummary}
        </span>
      </div>
    </div>
  );
};

export default AwsProductTabItem;
