import './BusinessTempRegistSection.scss';
import React from 'react';
import { Business } from '../../../../service/business/model/Business';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  close: () => void;
  tempBusinessList: Business[];
}

const BusinessTempRegistSection = (props: Props) => {
  const { open, close, tempBusinessList } = props;
  const navigator = useNavigate();

  const handlerTempBusinessItem = (url: string) => {
    navigator('/admin/business/' + url, { state: true });
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <section>
          <header>
            Business 임시저장 목록
            <button className="close" onClick={close}>
              &times;
            </button>
          </header>
          <main>
            {tempBusinessList
              ? tempBusinessList.map((business, key) => (
                  <div
                    key={key}
                    className="business-temp-regist-item"
                    onClick={() => handlerTempBusinessItem(business.businessUrl)}
                  >
                    {key + 1}. {business.businessBoard.ko.businessWriter}-
                    {business.businessBoard.ko.businessTitle} ({business.businessUrl},{' '}
                    {business.registerAdmin})
                  </div>
                ))
              : null}
          </main>
          <footer>
            <button className="close" onClick={close}>
              close
            </button>
          </footer>
        </section>
      ) : null}
    </div>
  );
};

export default BusinessTempRegistSection;
