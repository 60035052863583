import './BlogBoardRegistContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import BlogBoardRegistForm from './components/BlogBoardRegistForm';

const BlogBoardRegistContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="BlogBoardRegistContainer">
        <BlogBoardRegistForm />
      </div>
    </Container>
  );
};

export default BlogBoardRegistContainer;
