import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutCaseArea from './AboutCaseArea';

const About02: React.FC = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div
        id="about-slide2"
        className="fullpage-slide"
        data-testid="testAbout02Section"
      >
        <div className="about-cases">
          <div className="about-cases-body">
            <div className="about-cases-title-cont">
              <div className="title-txt-box">
                <div className="title-lg title-primary">
                  {t(
                    'about.About02.bestModernAppImplementation.title.content1'
                  )}
                </div>
                <div className="title-lg">
                  {t(
                    'about.About02.bestModernAppImplementation.title.content2'
                  )}
                </div>
                <p className="txt-con">
                  {t('about.About02.bestModernAppImplementation.content1')}
                  {t('about.About02.bestModernAppImplementation.content2')}
                  <br />
                  {t('about.About02.bestModernAppImplementation.content3')}{' '}
                  {t('about.About02.bestModernAppImplementation.content4')}
                  <br />
                  {t('about.About02.bestModernAppImplementation.content5')}
                  {t('about.About02.bestModernAppImplementation.content6')}
                </p>
              </div>
            </div>

            <div
              className="about-cases-case-cont"
              style={{ paddingTop: '5vh' }}
            >
              <AboutCaseArea startIdx={0} offset={2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About02;
