import './MainSection.scss';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import MainEntrance from './MainEntrance';
import MainLaunch from './MainLaunch';
import MainAwsPartner from './MainAwsPartner';
import MainCaseList from './MainCaseList';
import MainContact from './MainContact';
import smoothscroll from 'smoothscroll-polyfill';
import ReactPageScroller from 'react-page-scroller';
import { getIsMobile } from '../../../service/utils/utils';

const MainSection: React.FC = () => {
  const isMobile = getIsMobile();
  const [pageIdx, setPageIdx] = useState(Number);
  const [click, setClick] = useState<boolean>();

  //pc용 arrow-btn 동작 정의
  const handlePcArrowBtn = () => {
    setClick(true);
  };

  const handlePageChange = (page: number) => {
    if (click) {
      setPageIdx(1);
      setClick(false);
    } else {
      setPageIdx(page);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //fullpage-controls 형제 노드-자식 노드 찾기
    const element = document.querySelector('.fullpage-controls')?.nextSibling
      ?.firstChild as HTMLElement;

    if (pageIdx === 0 && !isMobile) {
      element.style.transform = '';
    }
  }, [pageIdx]);

  return (
    <div className="MainSection" data-testid="MainSection">
      {isMobile ? (
        <Fragment>
          <MainEntrance />
          <MainAwsPartner />
          <MainCaseList />
          <MainLaunch />
          <MainContact />
        </Fragment>
      ) : (
        <Fragment>
          <div className="fullpage-controls" data-testid="main-pc">
            <button onClick={handlePcArrowBtn}></button>
          </div>
          <ReactPageScroller
            onBeforePageScroll={(page) => {
              setPageIdx(page);
              if (page !== 0) {
                document.getElementsByTagName('header')[0].className = '';
                document.getElementsByClassName('gnb')[0].className = 'gnb';
              } else {
                document.getElementsByTagName('header')[0].className = 'fixed';
                document.getElementsByClassName('gnb')[0].className =
                  'gnb fixed';
              }
            }}
            renderAllPagesOnFirstRender={true}
            pageOnChange={(page) => handlePageChange(page)}
            customPageNumber={pageIdx}
          >
            <MainEntrance />
            <MainAwsPartner />
            <MainCaseList />
            <MainLaunch />
            <MainContact />
          </ReactPageScroller>
        </Fragment>
      )}
    </div>
  );
};

export default MainSection;
