import './AwsRedShiftSection.scss';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { RedShiftContentData } from '../../../../service/data/awsProductCard/awsRedShift/AwsRedShiftModel';
import { redShiftContentDatasKo } from '../../../../service/data/awsProductCard/awsRedShift/AwsRedShiftDataKo';
import { redShiftContentDatasEn } from '../../../../service/data/awsProductCard/awsRedShift/AwsRedShiftDataEn';
import AwsRedShiftContentArea from './AwsRedShiftContentArea';
import { Language } from '../../../../service/common/model/enum/Common.enum';

const AwsRedShiftSection: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const [awsRedShiftContents, setAwsRedShiftContents] = useState<
    RedShiftContentData[]
  >(i18n.language === Language.KO ? redShiftContentDatasKo : redShiftContentDatasEn);

  useEffect(() => {
    setAwsRedShiftContents(
      i18n.language === Language.KO ? redShiftContentDatasKo : redShiftContentDatasEn
    );
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }

  }, []);

  return (
    <div id="AwsRedShiftSection" data-testid="testAwsRedShiftSection">
      <div className="eks-anywhere fullpage-slide">
        <div className="header-top" data-testid="header-top">
          <div className="top-tit detail-container">
            <h2>Amazon RedShift</h2>
          </div>
        </div>
        <div className="eks-anywhere-body">
          <div className="eks-anywhere-title-cont">
            <div className="title-txt-box">

              <span className="title-lg">
                {'Amazon RedShift'}
              </span>
            </div>
          </div>
          {awsRedShiftContents.map((content, idx) => {
            return (
              <React.Fragment key={idx}>
                <AwsRedShiftContentArea content={content} />
              </React.Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AwsRedShiftSection;
