import './CaseDetailSection.scss';

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Footer from '../../../layouts/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/modules';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import { useTranslation } from 'react-i18next';

import { CaseBoard } from '../../../service/case/model/Case';
import CaseService from '../../../service/case/CaseService';
import CaseDetailPage from './CaseDetailPage';
import defaultImg from '../../../assets/common/headerLogo.png';
import RightArrow from '../../../assets/caseDetail/right_arrow.png';

// aws 페이지 링크를 위한 이미지 데이터
import CaseDetailSsangYong from '../../../assets/caseDetail/CaseDetailFullImg/CaseDetailSsangYong.png';
import CaseDetailGSRetail from '../../../assets/caseDetail/CaseDetailFullImg/CaseDetailGSRetail.png';
import CaseDetailDataHub from '../../../assets/caseDetail/CaseDetailFullImg/CaseDetailDataHub.png';
import CaseDetailTwosome from '../../../assets/caseDetail/CaseDetailFullImg/CaseDetailTwosome.png';
import CaseDetailHanwha from '../../../assets/caseDetail/CaseDetailFullImg/CaseDetailHanwha.jpg';
import CaseDetailPageSkeleton from './CaseDetailPageSkeleton';

const CaseDetailSection: React.FC = () => {
  const { i18n } = useTranslation();
  const { url } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [registeredDate, setRegisteredDate] = useState<string | undefined>();
  const [caseBoard, setCaseBoard] = useState<CaseBoard>();
  const [detailItem, setDetailItem] = useState<{
    caseDetailUrl: string;
    isFullImg: string;
    content?: string;
    component?: any;
  }>({
    caseDetailUrl: 'default',
    isFullImg: '',
    content: defaultImg,
  });
  const caseService = new CaseService();
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const limit = location.state
    ? (location.state as { limit: number }).limit
    : 4;
  const index = location.state
    ? (location.state as { index: number }).index
    : 0;

  // prettier-ignore
  const caseDetailHtml = [
    { caseDetailUrl: 'case-cloud-SsangYong', isFullImg: 'Y', content: CaseDetailSsangYong,},
    { caseDetailUrl: 'case-cloud-GSRetail', isFullImg: 'Y', content: CaseDetailGSRetail, },
    { caseDetailUrl: 'case-cloud-insuranceDataHub', isFullImg: 'Y', content: CaseDetailDataHub,},
    { caseDetailUrl: 'case-cloud-Twosome', isFullImg: 'Y', content: CaseDetailTwosome,},
    { caseDetailUrl: 'case-cloud-Hanwha', isFullImg: 'Y', content: CaseDetailHanwha,},
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (url) {
      getCase(url);
    } else {
      navigate('/case');
    }
  }, [i18n.language]);

  const getCase = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    const response = await caseService.getCaseByUrl(url);
    dispatch(changeLoadingStatus(false));
    if (
      response &&
      response.caseBoard &&
      response.caseBoard[i18n.language == 'ko' ? 'ko' : 'en']
    ) {
      const caseBoard =
        response?.caseBoard[i18n.language == 'ko' ? 'ko' : 'en'];
      setLoadedData(caseBoard);
      setRegisteredDate(response.registeredDate);
    } else {
      navigate('/case');
    }
    dispatch(changeLoadingStatus(false));
  };

  const setLoadedData = (data: CaseBoard | undefined) => {
    setCaseBoard(data);
    setDetailItem(
      caseDetailHtml.find(
        (detail) => detail.caseDetailUrl === url && detail.isFullImg === 'Y'
      ) || {
        caseDetailUrl: url || '',
        isFullImg: 'N',
        component: (
          <CaseDetailPage
            caseBoard={data}
            limit={limit}
            index={index}
            registeredDate={registeredDate}
          />
        ),
      }
    );
  };

  const handleOnClickGoBackBtn = () => {
    navigate('/case', {
      state: {
        limit: limit,
        index: index,
      },
    });
  };

  return loading ? (
    <CaseDetailPageSkeleton />
  ) : (
    <div id="CaseDetailSection" data-testid="testCaseDetailSection">
      {/* AWS 레퍼런스 링크가 걸려있는 url의 경우, 상세 데이터로 FE에 저장된 fullPageImage 한 장을 보여준다. */}
      {detailItem.isFullImg === 'N' ? (
        <CaseDetailPage
          caseBoard={caseBoard}
          limit={limit}
          index={index}
          registeredDate={registeredDate}
        />
      ) : (
        <div className="fullPage-slide" key={detailItem.caseDetailUrl}>
          <div className="endPage">
            <div className="title-wrap"></div>
            <img src={detailItem.content} />
            <div className="go-back">
              <div
                className="go-back-full-img"
                onClick={handleOnClickGoBackBtn}
              >
                <img src={RightArrow} alt="all Case Studies" />
                <span className="detail-sub-cont">all Case Studies</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CaseDetailSection;
