import {
  AwsProductCode,
  AwsProductEng,
} from '../../common/model/enum/Common.enum';
import { AwsProductData, AwsProductListData } from './AwsProductModel';

export const awsDefaultProductsEn: AwsProductData = {
  productCode: AwsProductCode.CMCT,
  productName: AwsProductEng.ECMCT,
};

export const awsProductsEn: AwsProductListData[] = [
  {
    productCode: AwsProductCode.CMCT,
    productName: AwsProductEng.ECMCT,
    service: [
      {
        serviceCode: 'amazon-ec2',
        serviceName: 'Amazon EC2',
        serviceSummary: 'Virtual Servers in the Cloud',
      },
      {
        serviceCode: 'amazon-elastic-kubernetes',
        serviceName: 'Amazon Elastic Kubernetes Service',
        serviceSummary: 'The most reliable way to run Kubernetes',
      },
      {
        serviceCode: 'amazon-ec2-auto-scaling',
        serviceName: 'Amazon EC2 Auto Scaling',
        serviceSummary:
          'Add or remove compute capacity to meet changing demand',
      },
      {
        serviceCode: 'amazon-lambda',
        serviceUrl: 'amazon-lambda',
        serviceName: 'Amazon Lambda',
        serviceSummary: 'Run code without thinking about servers or clusters',
      },
    ],
  },
  {
    productCode: AwsProductCode.ST,
    productName: AwsProductEng.EST,
    service: [
      {
        serviceCode: 'amazon-ebs',
        serviceName: 'Amazon Elastic Block Store (EBS)',
        serviceSummary: 'EC2 Block Storage Volume',
      },
      {
        serviceCode: 'amazon-s3-glacier',
        serviceName: 'Amazon S3 Glacier',
        serviceSummary: 'Affordable archive storage on the cloud',
      },
      {
        serviceCode: 'amazon-s3',
        serviceName: 'Amazon Simple Storage Service (S3)',
        serviceSummary: 'Storage Service (S3) scalable storage in the Cloud',
      },
    ],
  },
  {
    productCode: AwsProductCode.DB,
    productName: AwsProductEng.EDB,
    service: [
      {
        serviceCode: 'amazon-elasticache',
        serviceName: 'Amazon ElastiCache',
        serviceSummary: 'In-memory caching service',
      },
      {
        serviceCode: 'amazon-dynamo-db',
        serviceName: 'Amazon DynamoDB',
        serviceSummary: 'Managed NoSQL database',
      },
      {
        serviceCode: 'amazon-rds',
        serviceName: 'Amazon RDS',
        serviceUrl: 'amazon-rds',
        serviceSummary:
          'Managed relational database services for MySQL, PostgreSQL, Oracle, SQL Server, and MariaDB',
      },
      {
        serviceCode: 'amazon-redshift',
        serviceUrl: 'amazon-redshift',
        serviceName: 'Amazon Redshift',
        serviceSummary: 'Best price-performance for cloud data warehousing',
      },
    ],
  },
  {
    productCode: AwsProductCode.NW,
    productName: AwsProductEng.ENW,
    service: [
      {
        serviceCode: 'amazon-cloudfront',
        serviceName: 'Amazon CloudFront',
        serviceUrl: 'amazon-cloudFront',
        serviceSummary: 'Global Content Delivery Network (CDN)',
      },
      {
        serviceCode: 'amazon-route-53',
        serviceName: 'Amazon Route 53',
        serviceSummary: '53 Scalable Domain Name System (DNS)',
      },
      {
        serviceCode: 'amazon-elb',
        serviceName: 'Amazon Elastic Load Balancing (ELB)',
        serviceSummary:
          'Distribute incoming traffic across multiple destinations',
      },
      {
        serviceCode: 'amazon-dx',
        serviceUrl: 'amazon-dx',
        serviceName: 'Amazon-Direct-Connect(DX)',
        serviceSummary:
          'Reliable and cost-effective leased line service to access AWS resources',
      },
    ],
  },
  {
    productCode: AwsProductCode.MG,
    productName: AwsProductEng.EMG,
    service: [
      {
        serviceCode: 'amazon-control-tower',
        serviceName: 'Amazon Control Tower',
        serviceSummary:
          'Set up and govern a secure, multi-account AWS environment',
      },
      {
        serviceCode: 'amazon-cloudformation',
        serviceUrl: 'amazon-cloudformation',
        serviceName: 'Amazon CloudFormation',
        serviceSummary:
          'Create and manage AWS resources more efficiently and reliably using code',
      }
    ],
  },
  {
    productCode: AwsProductCode.CC,
    productName: AwsProductEng.ECC,
    service: [
      {
        serviceCode: 'amazon-connect',
        serviceUrl: 'amazon-connect',
        serviceName: 'Amazon Connect',
        serviceSummary:
          'Provide superior customer service at a lower cost with an easy-to-use cloud contact center',
      },
    ],
  },
  {
    productCode: AwsProductCode.SIC,
    productName: AwsProductEng.ESIC,
    service: [
      {
        serviceCode: 'amazon-iam',
        serviceName: 'AWS Identity and Access Management (IAM)',
        serviceSummary:
          'Securely manage identities and access to AWS services and resources',
      },
    ],
  },
];
