import './AwsConnectPageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';

import AwsConnectSection from './components/AwsConnectSection';

const AwsConnectPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsConnectPageContainer">
        <AwsConnectSection />
      </div>
    </Container>
  );
};

export default AwsConnectPageContainer;
