import './MainCaseItem.scss';

import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Case, CaseBoard } from '../../../service/case/model/Case';

import readmoreIcon from '../../../assets/main/mainCaseList/caseReadmoreIcon.svg';

interface Props {
  caseData: Case;
  caseBoard?: CaseBoard;
}

const MainCaseItem: React.FC<Props> = ({
  caseData,
  caseBoard,
}): ReactElement => {
  const navigator = useNavigate();
  const caseDetailOnClickHandler = (url: string) => {
    navigator('/case/' + url);
  };

  return (
    <li>
      <div className="main-case-item" data-testid="case-item-div">
        <div className="case-item-txt">
          <div className="case-item-company" data-testid="case-item-company">
            {caseBoard?.caseDetail.caseCompany == '' ? (
              <div>&nbsp;</div>
            ) : (
              <div>#{caseBoard?.caseDetail.caseCompany}</div>
            )}
          </div>
          <div className="case-item-title" data-testid="case-item-title">
            {caseBoard?.caseTitle}
          </div>
          {/* 부제목은 주석처리 */}
          {/* <div className="case-img-description" data-testid="case-img-p">
            {caseBoard?.caseDescription}
          </div> */}
          <div
            className="main-contact-button"
            onClick={() => {
              caseDetailOnClickHandler(caseData.caseUrl);
            }}
            data-testid="main-contact-button"
          >
            <span>Read more</span>
            <img src={readmoreIcon} />
          </div>
        </div>
        <div className="case-item-thumbnail" data-testid="case-item-thumbnail">
          <img src={caseBoard?.caseThumbnail} alt={caseBoard?.caseTitle} />
        </div>
        <div className="gradation"></div>
      </div>
    </li>
  );
};
export default MainCaseItem;
