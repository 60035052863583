import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import language from './language';
import loading from './loading';
import authorization from './authorization';
import logout from './logout';
import progress from './progress';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['authorization'],
};

const authorizationConfig = {
  key: 'authorization',
  storage: storageSession,
  blacklist: ['loading', 'language', 'logout', 'progress'],
};

export const rootReducer = combineReducers({
  language,
  loading,
  logout,
  progress,
  authorization: persistReducer(authorizationConfig, authorization),
});

export default persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
