import './CaseSection.scss';

import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Case, CaseBoard } from '../../../service/case/model/Case';

interface Props {
  caseData: Case;
  caseBoard?: CaseBoard;
  limit: number;
  index: number;
}

const CaseSectionBoxCaseItem: React.FC<Props> = ({
  caseData,
  caseBoard,
  limit,
  index,
}: Props): ReactElement => {
  const navigator = useNavigate();
  const handleOnClickCaseItemBox = (url: string) => {
    navigator('/case/' + url, {
      state: {
        limit: limit,
        index: index,
      },
    });
  };

  return (
    <div
      className="v-box"
      onClick={() => handleOnClickCaseItemBox(caseData.caseUrl)}
    >
      <div className="image">
        <span>{caseBoard?.caseTag}</span>
        <img src={caseBoard?.caseThumbnail} alt={caseBoard?.caseTitle} />
      </div>
      <div className="text">
        <h2>{caseBoard?.caseTitle}</h2>
      </div>
    </div>
  );
};
export default CaseSectionBoxCaseItem;
