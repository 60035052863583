import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { Fragment, ReactElement } from 'react';


const CaseDetailPageSkeleton: React.FC= (): ReactElement => {
  return (
    <div className="case-detail-new">
      <div className="detail-top" style={{backgroundColor: '#f9f9f9'}}>
        <div className="top-tit detail-container">
          <Skeleton className="top-tit-skeleton"/>
        </div>
      </div>
      <div className="detail-container">
        <div className="detail-summary">
          <div className="summary-top-skeleton">
            <Skeleton height={'5rem'} containerClassName="detail-tit-skeleton" />
            <Skeleton height={'5rem'} containerClassName="detail-logo-skeleton" />
          </div>
          <Skeleton className="summary-main detail-cont-skeleton" />
          <div className="summary-contents">
            <div className="summary-content">
              <Skeleton height={'10rem'}/>
              </div>
              <div className="summary-content">
              <Skeleton height={'10rem'}/>
              </div>
              <div className="summary-content">
              <Skeleton height={'10rem'}/>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaseDetailPageSkeleton;
