import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="info">
        <div className="company">
          <div className="logo"></div>
          <div
            className="shortcut"
            onClick={() => window.open('https://lgcns.co.kr', '_blank')}
          >
            {t('footer.link')}
          </div>
        </div>
        <div className="address">{t('footer.address')}</div>
        <div className="contactUs">{t('footer.contactUs')}</div>
      </div>
      <div className="copy">Copyright ©LG CNS. All right reserved.</div>
    </div>
  );
}

export default Footer;
