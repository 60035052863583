import './NewsDetailContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import NewsDetailSection from './components/NewsDetailSection';

const NewsDetailContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="AdminNewsDetailContainer">
        <NewsDetailSection />
      </div>
    </Container>
  );
};

export default NewsDetailContainer;
