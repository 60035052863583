import React, { ReactElement, useState, KeyboardEvent } from 'react';
import { Image } from 'react-bootstrap';
import logo from '../../../../assets/common/headerLogo.png';

interface Props {
  handleLogin: (username: string, password: string) => void;
}

const LoginRequestForm: React.FC<Props> = ({
  handleLogin,
}: Props): ReactElement => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      handleLogin(username, password);
    }
  };

  return (
    <>
      <div>
        <div className="form-group">
          <div id="logo" className="logo">
            <Image src={logo} alt="LG CNS launch center 로고 이미지" />
          </div>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            placeholder="아이디를 입력하세요"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control form-control-lg"
            placeholder="비밀번호를 입력하세요"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={onKeyPress}
          />
        </div>
        <div className="form-group">
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => handleLogin(username, password)}
            >
              로그인
            </button>
          </div>
        </div>
        <div className="form-group">
          <div className="loginContact">
            <p className="forgot-password text-center">
              ID 또는 비밀번호 분실 시 담당자에게 연락하세요 <br /> 담당자 :
              AWS서비스Scale팀 김행화(haenghwa.kim@lgcns.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginRequestForm;
