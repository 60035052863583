import CommonResponse from '../common/model/CommonResponse';
import BaseService from '../BaseService';
import { Subject } from '../common/model/Service';
import SessionService from '../SessionService';
import {
  AuthenticateMode,
  AuthenticateState,
} from '../common/model/enum/Login.enum';
import { AuthenticateRequest, MfaProps } from '../common/model/Login';

const mockResponseLoginSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {
    result: AuthenticateState.NOMFA,
    url: 'testurl',
  },
};

const mockResponseFail: CommonResponse = {
  successOrNot: 'N',
  statusCode: 'FAIL',
  errorMessage: '',
  data: {},
};

export default class MemberService extends BaseService {
  async handleMockLogin(
    authMode: AuthenticateMode,
    authRequest: AuthenticateRequest
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/authenticate';
    const serviceName = Subject.MEMBER;
    const params = null;
    const body = authRequest;

    return mockResponseLoginSuccess;
  }

  async handleChangePassword(
    username: string,
    currentPassword: string,
    newPassword: string
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/reset-password';
    const serviceName = Subject.MEMBER;
    const params = null;
    const body = { username, currentPassword, newPassword };

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    if (
      response.data &&
      response.successOrNot === 'Y' &&
      response.statusCode === 'SUCCESS'
    ) {
      return response;
    } else {
      mockResponseFail.statusCode = response.statusCode;
      mockResponseFail.errorMessage = response.data.msg;
      mockResponseFail.data['attemptCount'] = response.data.attemptCount;
      return mockResponseFail;
    }
  }

  async handleLogin(
    username: string,
    password: string
    // authMode: AuthenticateMode,
    // authRequest: AuthenticateRequest
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/login';
    // const url = '/authenticate';
    const serviceName = Subject.MEMBER;
    const params = null;
    const body = { username, password };
    // const body = authRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    if (
      response.data &&
      response.successOrNot === 'Y' &&
      // response.statusCode === 'SUCCESS' &&
      // response.data.sessionId &&
      // response.data.sessionInfo &&
      // response.data.sessionInfo.memberId
      response.statusCode === 'SUCCESS'
    ) {
      return response;
      // if (
      //   authMode === AuthenticateMode.INIT_REQUEST ||
      //   authMode === AuthenticateMode.QR_REGIST_REQUEST ||
      //   (authMode === AuthenticateMode.MFA_REQUEST &&
      //     response.data.sessionId &&
      //     response.data.sessionInfo &&
      //     response.data.sessionInfo.memberId)
      // ) {
      //   return response;
      // } else {
      //   mockResponseFail.statusCode = response.statusCode;
      //   mockResponseFail.errorMessage = response.data.msg;
      //   mockResponseFail.data['attemptCount'] = response.data.attemptCount;
      //   return mockResponseFail;
      // }
    } else {
      mockResponseFail.statusCode = response.statusCode;
      mockResponseFail.errorMessage = response.data.msg;
      mockResponseFail.data['attemptCount'] = response.data.attemptCount;
      return mockResponseFail;
    }
  }

  async handleMfaLogin(
    authMode: AuthenticateMode,
    authRequest: AuthenticateRequest
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/authenticate';
    const serviceName = Subject.MEMBER;
    const params = null;
    const body = authRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    if (
      response.data &&
      response.successOrNot === 'Y' &&
      // response.statusCode === 'SUCCESS' &&
      // response.data.sessionId &&
      // response.data.sessionInfo &&
      // response.data.sessionInfo.memberId
      response.statusCode === 'SUCCESS'
    ) {
      if (
        authMode === AuthenticateMode.INIT_REQUEST ||
        authMode === AuthenticateMode.QR_REGIST_REQUEST ||
        (authMode === AuthenticateMode.MFA_REQUEST &&
          response.data.sessionId &&
          response.data.sessionInfo &&
          response.data.sessionInfo.memberId)
      ) {
        return response;
      } else {
        mockResponseFail.statusCode = response.statusCode;
        mockResponseFail.errorMessage = response.data.msg;
        mockResponseFail.data['attemptCount'] = response.data.attemptCount;
        return mockResponseFail;
      }
    } else {
      mockResponseFail.statusCode = response.statusCode;
      mockResponseFail.errorMessage = response.data.msg;
      mockResponseFail.data['attemptCount'] = response.data.attemptCount;
      return mockResponseFail;
    }
  }

  async handleLogout(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/logout';
    const serviceName = Subject.MEMBER;
    const params = null;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );

      const sessionService = new SessionService();
      sessionService.deleteSessionInfo();
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.data && response.successOrNot === 'Y'
      ? response.data
      : null;
  }
}
