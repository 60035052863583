import './NewsSection.scss';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { News } from '../../../service/news/model/News';
import NewsService from '../../../service/news/NewsService';
import NewsItem from './NewsItem';
import DropdownBox from '../../common/DropdownBox';
import Footer from '../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/modules/index';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import NewsSectionSkeleton from './NewsSectionSkeleton';
import { Language } from '../../../service/common/model/enum/Common.enum';
import { useLocation } from 'react-router-dom';

type DropdownBoxType = {
  latest: string;
  view: string;
};

const NewsSection: React.FC = () => {
  const language = useSelector((state: RootState) => state.language.lang);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const location = useLocation();

  const { t } = useTranslation();
  const LIMIT = 5;
  const [newsItemsKo, setNewsItemsKo] = useState<News[]>([]);
  const [newsItemsEn, setNewsItemsEn] = useState<News[]>([]);
  const [dropdownBoxOption, setDropdownBoxOption] = useState<DropdownBoxType>({
    latest: t('news.newsSection.orderOption.latest'),
    view: t('news.newsSection.orderOption.view'),
  });
  const [allNewsCount, setAllNewsCount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>('latest');
  const newsService = new NewsService();
  const [limit, setLimit] = useState<number>(LIMIT);

  const newsRefs = useRef<any>([]);
  const index = location.state
    ? (location.state as { index: number }).index
    : -1;

  useEffect(() => {
    getDropdownBoxOptions();
    setAllNewsCount(
      language === Language.KO ? newsItemsKo.length : newsItemsEn.length
    );
  }, [language]);

  useEffect(() => {
    getAllNewsListUser();
  }, [selectedOption]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  const getAllNewsListUser = async () => {
    dispatch(changeLoadingStatus(true));
    const response = await newsService.getAllNewsListUser(selectedOption);
    if (response) {
      setNewsItemsKo(response);
      setNewsItemsEn(
        response.filter(
          (res) =>
            res.newsBoard.en !== undefined &&
            res.newsBoard.en.newsTitle.trim() !== '' &&
            res.newsBoard.en.newsText.trim() !== ''
        )
      );
      setLimit(
        location.state ? (location.state as { limit: number }).limit : LIMIT
      );
      setAllNewsCount(
        language === Language.KO
          ? response.length
          : response.filter(
              (res) =>
                res.newsBoard.en != undefined &&
                res.newsBoard.en.newsTitle.trim() !== '' &&
                res.newsBoard.en.newsText.trim() !== ''
            ).length
      );
    }
    dispatch(changeLoadingStatus(false));
  };

  const getDropdownBoxOptions = () => {
    setDropdownBoxOption((state: DropdownBoxType) => {
      return {
        ...state,
        latest: t('news.newsSection.orderOption.latest'),
        view: t('news.newsSection.orderOption.view'),
      };
    });
  };

  const showMoreDocuments = () => {
    setLimit(limit + LIMIT);
  };

  return (
    <div id="fullpage" className="fullpage">
      <div id="h-wrap" className="h-wrap">
        <div className="news-section-title-area">
          <p id="news-section-title">{t('news.newsHeader.title')}</p>
        </div>
        <div className="news-section-header">
          <div id="total-number-of-news">
            {t('news.newsSection.total')}
            <span id="total-number">{allNewsCount}</span>
            {t('news.newsSection.count')}
          </div>
          <div id="view-condition">
            <DropdownBox
              optionList={dropdownBoxOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>
        {loading ? (
          <NewsSectionSkeleton />
        ) : (
          <Fragment>
            {language === Language.KO
              ? newsItemsKo
                ? newsItemsKo.slice(0, limit).map((element, key) => {
                    return (
                      <div
                        ref={(element: any) => {
                          newsRefs.current[key] = element;
                          if (key === index && newsRefs?.current[index]) {
                            newsRefs?.current[index].scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            });
                          }
                        }}
                        key={key}
                      >
                        <NewsItem
                          newsUrl={element.newsUrl}
                          newsRegisterDate={element.registeredDate}
                          newsView={element.newsView}
                          newsThumbnail={element.newsBoard.ko.newsThumbnail}
                          newsTitle={element.newsBoard.ko.newsTitle}
                          newsSubtitle={element.newsBoard.ko.newsSubtitle}
                          newsText={element.newsBoard.ko.newsText}
                          newsCategory={element.newsBoard.ko.newsCategory}
                          limit={limit}
                          index={key}
                        />
                      </div>
                    );
                  })
                : ''
              : newsItemsEn
              ? newsItemsEn.slice(0, limit).map((element, key) => {
                  return (
                    <div
                      ref={(element: any) => {
                        newsRefs.current[key] = element;
                        if (
                          key === index &&
                          newsRefs &&
                          newsRefs.current[index]
                        ) {
                          newsRefs.current[index].scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          });
                        }
                      }}
                      key={key}
                    >
                      <NewsItem
                        key={key}
                        newsUrl={element.newsUrl}
                        newsRegisterDate={element.registeredDate}
                        newsView={element.newsView}
                        newsThumbnail={element.newsBoard.en?.newsThumbnail}
                        newsTitle={
                          element.newsBoard.en
                            ? element.newsBoard.en.newsTitle
                            : ''
                        }
                        newsSubtitle={
                          element.newsBoard.en
                            ? element.newsBoard.en.newsSubtitle
                            : ''
                        }
                        newsText={
                          element.newsBoard.en
                            ? element.newsBoard.en.newsText
                            : ''
                        }
                        newsCategory={
                          element.newsBoard.en
                            ? element.newsBoard.en.newsCategory
                            : ''
                        }
                        limit={limit}
                        index={key}
                      />
                    </div>
                  );
                })
              : ''}
            {limit < allNewsCount && (
              <button
                className="more-btn"
                data-testid="more-btn-mobile"
                onClick={() => {
                  showMoreDocuments();
                }}
              >
                {t('news.newsSection.more')}
              </button>
            )}
          </Fragment>
        )}
      </div>
      <div className="v-wrap">
        <Footer />
      </div>
    </div>
  );
};

export default NewsSection;
