import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { Fragment, ReactElement } from 'react';

const caseList = [0, 0, 0, 0];

const CaseSectionSkeletonMobile: React.FC= (): ReactElement => {
  return (
    <Fragment>
      {caseList.slice(0, 4).map((caseItem, index) => {
        return(
        <Fragment key={index}>
          <div className="image">
            <Skeleton width={'100%'} height={'100%'} />
          </div>
          <div className="text-skeleton-Mobile">
            <Skeleton className="span-skeleton-Mobile" />
            <Skeleton className="content-skeleton-Mobile" />
          </div>
        </Fragment>
        );
      })}
    </Fragment>
  );
};
export default CaseSectionSkeletonMobile;
