
import Enterprise01Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront01.png'
import Enterprise02Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront02.png'
import Enterprise03Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront03.png'
import Enterprise04Img from '../../../../../src/assets/awsProductCard/awsCloudFront/AmazonCloudFront04.png'


import {
  CloudFrontContentData,
  AwsCloudFrontContentLayout,
} from './AwsCloudFrontModel';

export const CloudFrontContentDatasEn: CloudFrontContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsCloudFrontContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'LG CNS offers a variety of services through Amazon CloudFront.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Customers are adopting Contents Delivery Networks (CDNs) to deliver fast services to global markets. At LG CNS, we utilize Amazon CloudFront to help our customers deliver their web content and application services to end users quickly and securely around the world, from design to environment configuration.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsCloudFrontContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Deliver different types of content with great performance',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [

            {
              text: 'Amazon CloudFront makes it easy to serve content in a variety of formats, including static and dynamic content, as well as video streaming, API calls, and more. And with globally distributed edge locations, we optimize performance by caching content and delivering it in the best path to end users. This ensures that our customers experience superior service loading speeds and high availability.',
            },
          ],
        },

      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsCloudFrontContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'LG CNS works with a variety of services on AWS to keep your data safe and secure.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon CloudFront offers a variety of security features to help protect web applications from DDoS attacks and other security threats. By designing and building CloudFront with various services from AWS, LG CNS can utilize SSL/TLS encryption, web application firewalls, IP address whitelisting, and more to keep your data safe and secure.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsCloudFrontContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Flexible cost structure',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon CloudFront is billed by traffic volume, so we can use as many resources as we need. LG CNS also get a cost-optimized service with cache controls, regional edge location selection (class selection), cost-optimized caching strategies, multiple pricing schemes, and flexible pricing policies.',
            },
          ],
        },
      ],
    },
  }
];
