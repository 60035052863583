import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../service/common/model/enum/Common.enum';
import { AboutData } from '../../../service/data/about/AboutModel';
import { aboutKo } from '../../../service/data/about/AboutDataKo';
import { aboutEn } from '../../../service/data/about/AboutDataEn';

interface Props {
  startIdx: number;
  offset?: number;
}

const AboutCaseArea: React.FC<Props> = (props: Props) => {
  const { i18n, t } = useTranslation();
  const [aboutData, setAboutData] = useState<AboutData[]>(
    i18n.language === Language.KO ? aboutKo : aboutEn
  );

  useEffect(() => {
    setAboutData(i18n.language === Language.KO ? aboutKo : aboutEn);
  }, [i18n.language]);

  const render = () => {
    const result = [];
    let endIdx = aboutData.length;
    if (props.offset) {
      endIdx = props.startIdx + props.offset;
    }

    // for (let i = 0; i < aboutData.length; i += 2) {
    for (let i = props.startIdx; i < endIdx; i += 2) {
      result.push(
        <div className="case-content-area" key={i}>
          <div className="content-row-box" key={i}>
            {aboutData.slice(i, i + 2).map((data, idx) => {
              return (
                <div className="content-text-area" key={idx}>
                  <div
                    className={`case-box ${
                      props.startIdx > 0 && i % 4 === 2
                        ? 'case-between'
                        : props.startIdx > 0
                        ? 'case-end'
                        : 'case-start'
                    }`}
                  >
                    <div className="index-box">
                      <span className="index-txt">{'0' + (i + 1 + idx)}</span>
                    </div>
                    <div className="case-content-box">
                      <div className="content-title-box">
                        <div>
                          <span>{data.title}</span>
                        </div>
                      </div>
                      <div className="content-summary-box">{data.desc}</div>
                      <div className="content-icon-box">
                        <img src={data.icon} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return result;
  };

  return <>{render()}</>;
};

export default AboutCaseArea;
