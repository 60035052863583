import React, { ReactElement, createRef, useState, useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

interface Props {
  setEditorText: React.Dispatch<React.SetStateAction<string>>;
  text: string;
  language: string;
}

export const ToastEditor: React.FC<Props> = ({
  setEditorText,
  text,
  language,
}): ReactElement => {
  const editorRef = createRef<Editor>();
  const onChange = () => {
    setEditorText(editorRef.current?.getInstance().getMarkdown() as string);
  };
  React.useEffect(() => {
    if ((editorRef.current?.getInstance().getMarkdown() as string) === '') {
      editorRef.current?.getInstance().setMarkdown(text, false);
    }
  }, [text]);
  React.useEffect(() => {
    editorRef.current?.getInstance().setMarkdown(text, false);
  }, [language]);
  return (
    <Editor
      // initialValue={text}
      previewStyle="vertical"
      plugins={[colorSyntax, [codeSyntaxHighlight, { highlighter: Prism }]]}
      height="100%"
      ref={editorRef}
      onChange={onChange}
    />
  );
};

export default ToastEditor;
