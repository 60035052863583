import { Language, State } from '../../common/model/enum/Common.enum';
import { Business } from '../model/Business';

export const businessData: Business[] = [
  {
    registerAdmin: '관리자',
    registeredDate: '2023-04-27 00:00:00',
    businessBoard: {
      ko: {
        businessSubtitle:
          '클라우드의 가상서버',
        businessText: '테스트 내용',
        businessLang: Language.KO,
        businessWriter: '작성자',
        businessTitle: 'Amazon EC2',
        caseStudyUrl: ['case000001']
      }
    },
    businessUrl: 'press000001',
    businessState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2023-04-27 00:00:00',
    businessView: 1,
  },
];

export const businessTempData: Business[] = [
  {
    registerAdmin: '관리자',
    registeredDate: '2023-04-27 00:00:00',
    businessBoard: {
      ko: {
        businessThumbnail: '임시저장 썸네일1',
        businessSubtitle: '임시저장 - Kubernates를 실행하는 가장 신뢰성 높은 방법',
        businessText: '임시저장 내용 1',
        businessLang: Language.KO,
        businessWriter: '작성자',
        businessTitle: '임시저장 - Amazon Elastic Kubernates Service',
        caseStudyUrl: ['case000002', 'case000003']
      }
    },
    businessUrl: 'press000044',
    businessState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2023-04-27 00:00:00',
    businessView: 1,
  },
  {
    registerAdmin: '관리자',
    registeredDate: '2023-04-27 01:00:00',
    businessBoard: {
      ko: {
        businessThumbnail: '임시저장 썸네일2',
        businessSubtitle: '임시저장 - 수요에 맞춰 컴퓨팅 용량 조절',
        businessText: '임시저장 내용 2',
        businessLang: Language.KO,
        businessWriter: '작성자',
        businessTitle: '임시저장 - Amazon EC2 Auto Scaling',
        caseStudyUrl: ['case000004']
      }
    },
    businessUrl: 'press000444',
    businessState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2023-04-27 01:00:00',
    businessView: 1,
  },
  {
    registerAdmin: '관리자',
    registeredDate: '2023-04-27 02:00:00',
    businessBoard: {
      ko: {
        businessThumbnail: '임시저장 썸네일3',
        businessSubtitle: '임시저장 - 서버에 대한 걱정없이 코드 실행',
        businessText: '임시저장 내용 3',
        businessLang: Language.KO,
        businessWriter: '작성자',
        businessTitle: '임시저장 - Amazon Lambda',
        caseStudyUrl: ['case000005']
      },
    },
    businessUrl: 'press000445',
    businessState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2023-04-27 02:00:00',
    businessView: 1,
  },
];
