export enum StringDateFormat {
  DATETIME = 'YYYY-MM-DD hh:mm:ss',
  DATE = 'YYYY-MM-DD',
}

export enum DateFormat {
  DATETIME = 'YYYYMMDDHHmmss',
  DATE = 'YYYYMMDD',
}

export enum State {
  ALL = 'all', // amazon-web-services.lgcns.com, 관리자 페이지에서 보임
  ADMIN = 'admin', // 관리자 페이지에서만 보임
}

export enum Language {
  KO = 'ko',
  EN = 'en',
}

export enum NewsCategory {
  EP = 'Press',
  EV = 'Video',
  EW = 'Webinar',
  EE = 'ETC',
  KP = '보도기사',
  KV = '영상',
  KW = '웨비나',
  KE = '기타',
}

export enum BlogWriterKor {
  JHS = '정희석',
  CMG = '추민규',
  YCS = '유치송',
  KDI = '권동인',
  JSH = '정수혜',
  KWI = '김원일',
  KYG = '권영갑',
  KKH = '김광호',
}

export enum BlogWriterEn {
  JHS = 'Jung Heeseok',
  CMG = 'Chu Minkyu',
  YCS = 'Yoo Chisong',
  KDI = 'Kwon Dongin',
  JSH = 'Jung Suhye',
  KWI = 'Kim Wonil',
  KYG = 'Kwon Younggab',
  KKH = 'Kim Kwangho',
}

export enum BlogObjectKor {
  KC = '비용 절감',
  KO = '최적화',
  KS = '신기술',
}

export enum BlogObjectEn {
  EC = 'Cost Reduction',
  EO = 'Optimization Method',
  ES = 'Cutting-edge Skill',
}

export enum BlogCategoryKor {
  KCM = '컴퓨팅',
  KCT = '컨테이너',
  KST = '스토리지',
  KDB = '데이터베이스',
  KNW = '네트워킹',
}

export enum BlogCategoryEn {
  ECM = 'Computing',
  ECT = 'Container',
  EST = 'Storage',
  EDB = 'Database',
  ENW = 'Networking',
}

export enum BlogFilterKor {
  KC = '비용 절감',
  KO = '최적화',
  KS = '신기술',
  KCM = '컴퓨팅',
  KCT = '컨테이너',
  KST = '스토리지',
  KDB = '데이터베이스',
  KNW = '네트워킹',
}

export enum BlogFilterEn {
  EC = 'Cost Reduction',
  EO = 'Optimization Method',
  ES = 'Cutting-edge Skill',
  ECM = 'Computing',
  ECT = 'Container',
  EST = 'Storage',
  EDB = 'Database',
  ENW = 'Networking',
}

export enum AwsProductCode {
  CMCT = 'computing-container',
  ST = 'storage',
  DB = 'database',
  NW = 'networking',
  MG = 'management-Governance',
  CC = 'contact-center',
  SIC = 'security-identity-compliance'
}

export enum AwsProductKor {
  KCMCT = '컴퓨팅 / 컨테이너',
  KST = '스토리지',
  KDB = '데이터베이스',
  KNW = '네트워킹',
  KMG = '관리 및 거버넌스',
  KCC = '콜센터',
  KSIC = '보안 자격 증명 및 규정 준수'
}

export enum AwsProductEng {
  ECMCT = 'Computing / Container',
  EST = 'Storage',
  EDB = 'Database',
  ENW = 'Networking',
  EMG = 'Management & Governance',
  ECC = 'Contact Center',
  ESIC = 'Security, Identity, and Compliance'
}

export enum BusinessWriterKor {
  JHS = '정희석',
  CMG = '추민규',
  YCS = '유치송',
  KDI = '권동인',
  JSH = '정수혜',
  KWI = '김원일',
  KYG = '권영갑',
  KKH = '김광호',
}

export enum BusinessWriterEn {
  JHS = 'Jung Heeseok',
  CMG = 'Chu Minkyu',
  YCS = 'Yoo Chisong',
  KDI = 'Kwon Dongin',
  JSH = 'Jung Suhye',
  KWI = 'Kim Wonil',
  KYG = 'Kwon Younggab',
  KKH = 'Kim Kwangho',
}

export enum BusinessObjectKor {
  KC = '비용 절감',
  KO = '최적화',
  KS = '신기술',
}

export enum BusinessObjectEn {
  EC = 'Cost Reduction',
  EO = 'Optimization Method',
  ES = 'Cutting-edge Skill',
}
