export interface Error {
  successOrNot: string;
  statusCode: string;
  mandatoryParameter?: string;
  data?: any;
}

export class StatusCode {
  public static readonly BAD_REQUEST_ERR = 'BAD_REQUEST_ERR';
  public static readonly DUPLICATION_DATA_ERR = 'DUPLICATION_DATA_ERR';
  public static readonly MANDATORY_PARAM_ERR = 'MANDATORY_PARAM_ERR';
  public static readonly NO_RESPONSE = 'NO_RESPONSE';
  public static readonly NOT_FOUND_ERR = 'NOT_FOUND_ERR';
  public static readonly PARAMETER_VALUE_ERR = 'PARAMETER_VALUE_ERR';
  public static readonly FAIL = 'FAIL';
  public static readonly LOCK = 'LOCK';
  public static readonly EXPIRE_PW = 'EXPIRE_PW';
  public static readonly DORMANT = 'DORMANT';
  public static readonly INITIAL = 'INITIAL';
  public static readonly SUCCESS = 'SUCCESS';
  public static readonly UNAUTHORIZED_ERR = 'UNAUTHORIZED_ERR';
  public static readonly UNKNOWN_SERVER = 'UNKNOWN_SERVER';
  public static readonly INVALID_PASSWORD = 'INVALID_PASSWORD';
  public static readonly UNAUTHORIZED_MEMBER = 'UNAUTHORIZED_MEMBER';
  public static readonly UPLOAD_ERROR = 'UPLOAD_ERROR';
  public static readonly NO_PRESIGNED_URL = 'NO_PRESIGNED_URL';
  public static readonly UNKNOWN_S3_ERR = 'UNKNOWN_S3_ERR';
}
