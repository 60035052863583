import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from '../../layouts/Header';
import FloatingTop from '../../layouts/FloatingTop';
import MainPageContainer from './MainPageContainer';
import AboutPageContainer from '../about/AboutPageContainer';
import BusinessPageContainer from '../business/BusinessPageContainer';
import BusinessDetailPageContainer from '../businessDetail/BusinessDetailPageContainer';
import EksAnywherePageContainer from '../eksAnywhere/EksAnywherePageContainer';
import AwsProductPageContainer from '../awsProduct/AwsProductPageContainer';
import CasePageContainer from '../case/CasePageContainer';
import CaseDetailPageContainer from '../caseDetail/CaseDetailPageContainer';
import ErrorPageContainer from '../error/ErrorPageContainer';
import NewsPageContainer from '../news/NewsPageContainer';
import NewsDetailPageContainer from '../newsDetail/NewsDetailPageContainer';
import BlogPageContainer from '../blog/BlogPageContainer';
import BlogDetailPageContainer from '../blogDetail/BlogDetailPageContainer';
import RouteChangeTracker from '../../RouteChangeTracker';
import AwsConnectPageContainer from '../awsProductCard/awsConnect/AwsConnectPageContainer';
import AwsRedShiftPageContainer from '../awsProductCard/awsRedShift/AwsRedShiftPageContainer';
import AwsLambdaPageContainer from '../awsProductCard/awsLambda/AwsLambdaPageContainer';
import AwsCloudFrontPageContainer from '../awsProductCard/awsCloudFront/AwsCloudFrontPageContainer';
import AwsDirectConnectPageContainer from '../awsProductCard/awsDirectConnect/AwsDirectConnectPageContainer';
import AwsCloudFormationPageContainer from '../awsProductCard/awsCloudFormation/AwsCloudFormationPageContainer';
import AwsRdsPageContainer from '../awsProductCard/awsRds/AwsRdsPageContainer';

const MainPage: React.FC = () => {
  RouteChangeTracker();
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<MainPageContainer />} />
        <Route path="/about" element={<AboutPageContainer />} />
        <Route path="/business" element={<BusinessPageContainer />} />

        <Route
          path="/business/:url"
          element={<BusinessDetailPageContainer />}
        />
        <Route
          path="/awsproduct/eks-anywhere"
          element={<EksAnywherePageContainer />}
        />
        <Route
          path="/awsproduct/amazon-dx"
          element={<AwsDirectConnectPageContainer />}
        />
        <Route
          path="/awsproduct/amazon-connect"
          element={<AwsConnectPageContainer />}
        />
        <Route
          path="/awsproduct/amazon-redshift"
          element={<AwsRedShiftPageContainer />}
        />
        <Route
          path="/awsproduct/amazon-lambda"
          element={<AwsLambdaPageContainer />}
        />
        <Route
          path="/awsproduct/amazon-cloudformation"
          element={<AwsCloudFormationPageContainer />}
        />
        <Route
          path="/awsproduct/amazon-cloudFront"
          element={<AwsCloudFrontPageContainer />}
        />
        <Route
          path="/awsproduct/amazon-rds"
          element={<AwsRdsPageContainer />}
        />

        <Route path="/awsproduct" element={<AwsProductPageContainer />} />
        <Route path="/case" element={<CasePageContainer />} />
        <Route path="/case/:url" element={<CaseDetailPageContainer />} />
        <Route path="/news" element={<NewsPageContainer />} />
        <Route path="/news/:url" element={<NewsDetailPageContainer />} />
        <Route path="/blog" element={<BlogPageContainer />} />
        <Route path="/blog/:url" element={<BlogDetailPageContainer />} />
        <Route path="*" element={<ErrorPageContainer />} />
      </Routes>
      <FloatingTop />
    </>
  );
};

export default MainPage;
