import { Session, SessionInfo } from './common/model/SessionInfo';
import { getSessiontExpirationTime } from './utils/utils';

export default class SessionService {
  public getSessionInfo = (): SessionInfo => {
    let sessionInfo: SessionInfo = {} as SessionInfo;
    if (this.getSessionId() !== '') {
      sessionInfo = {
        sessionId: this.getSessionStorageValue('sessionId'),
        sessionExpirationDate: getSessiontExpirationTime(),
      };
    }
    return sessionInfo;
  };

  public setSessionInfo = (sessionInfo: SessionInfo): void => {
    for (const [key, value] of Object.entries(sessionInfo)) {
      this.setSessionStorageValue(key, value);
    }
  };

  public deleteSessionInfo = (): void => {
    this.deleteSessionStorage();
  };

  public getSessionId = (): string => {
    return this.getSessionStorageValue(Session.SESSION_ID);
  };

  public setSessionId = (sessionId: string): void => {
    this.setSessionStorageValue(Session.SESSION_ID, sessionId);
  };

  public getSessionExpirationDate = (): string => {
    return this.getSessionStorageValue(Session.SESSION_EXPIRATEION_DATE);
  };

  public setSessionExpirationDate = (expirationDate: string): void => {
    this.setSessionStorageValue(
      Session.SESSION_EXPIRATEION_DATE,
      expirationDate
    );
  };

  private getSessionStorageValue = (key: string): any => {
    if (!key) return '';
    const value = sessionStorage.getItem(key);
    return value && value !== 'undefined' ? sessionStorage.getItem(key) : '';
  };

  private setSessionStorageValue = (key: string, value: any): void => {
    if (key) {
      sessionStorage.setItem(key, value);
    }
  };

  private deleteSessionStorage = (): void => {
    sessionStorage.clear();
  };
}
