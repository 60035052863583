import './AwsLambdaSection.scss';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { LambdaContentData } from '../../../../service/data/awsProductCard/awsLambda/AwsLambdaModel';
import { lambdaContentDatasKo } from '../../../../service/data/awsProductCard/awsLambda/AwsLambdaDataKo';
import { lambdaContentDatasEn } from '../../../../service/data/awsProductCard/awsLambda/AwsLambdaDataEn';
import AwsLambdaContentArea from './AwsLambdaContentArea';
import { Language } from '../../../../service/common/model/enum/Common.enum';

const AwsLambdaSection: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const [awsLambdaContents, setAwsLambdaContents] = useState<
    LambdaContentData[]
  >(i18n.language === Language.KO ? lambdaContentDatasKo : lambdaContentDatasEn);

  useEffect(() => {
    setAwsLambdaContents(
      i18n.language === Language.KO ? lambdaContentDatasKo : lambdaContentDatasEn
    );
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }

  }, []);

  return (
    <div id="AwsLambdaSection" data-testid="testAwsLambdaSection">
      <div className="eks-anywhere fullpage-slide">
        <div className="header-top" data-testid="header-top">
          <div className="top-tit detail-container">
            <h2>Amazon Lambda</h2>
          </div>
        </div>
        <div className="eks-anywhere-body">
          <div className="eks-anywhere-title-cont">
            <div className="title-txt-box">

              <span className="title-lg">
                {'Amazon Lambda'}
              </span>
            </div>
          </div>
          {awsLambdaContents.map((content, idx) => {
            return (
              <React.Fragment key={idx}>
                <AwsLambdaContentArea content={content} />
              </React.Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AwsLambdaSection;
