import CommonResponse from './common/model/CommonResponse';
import BaseService from './BaseService';
import { Subject } from './common/model/Service';
import { StatusCode } from './common/model/Error';
import {
  S3FileCopyRequest,
  S3FileDeleteRequest,
  S3FileUploadRequest,
} from './common/model/s3BucketRequest.model';

const responseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: '',
};

const responseFail: CommonResponse = {
  successOrNot: 'N',
  statusCode: 'FAIL',
  errorMessage: '',
};

export default class S3UploadService extends BaseService {
  async uploadImage(dirName: string, file: File): Promise<string> {
    let response: CommonResponse;
    const method = 'GET';
    const url = '/upload/' + dirName;
    const serviceName = Subject.S3;
    const params = null;
    const body = null;

    try {
      // get pre signed URL for image upload
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      responseFail.errorMessage = error;
      response = responseFail;
    }
    if (response && response.data && response.successOrNot === 'Y') {
      return this.uploadImageToS3(response.data['uploadURL'], file);
    } else if (
      response &&
      response.successOrNot === 'N' &&
      response.statusCode == 'UNAUTHORIZED.ERR'
    ) {
      return StatusCode.UNAUTHORIZED_ERR;
    } else {
      return StatusCode.FAIL;
    }
  }

  async uploadImageToS3(presignedUrl: string, file: File): Promise<string> {
    if (!presignedUrl || presignedUrl === 'null') {
      // no PreSignedUrl
      return StatusCode.NO_PRESIGNED_URL;
    }
    try {
      const response = await fetch(
        new Request(presignedUrl, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'image/' + file.name.split('.').pop(),
          }),
          body: file,
        })
      );
      if (response.status == 200) {
        //upload image success
        return presignedUrl.split('?')[0];
      } else {
        return StatusCode.UPLOAD_ERROR;
      }
    } catch (error) {
      //unexpected error during upload
      return StatusCode.UNKNOWN_S3_ERR;
    }
  }

  checkExtension(fileName: string): boolean {
    const lastDot = fileName.lastIndexOf('.');
    const extension = fileName
      .substring(lastDot + 1, fileName.length)
      .toLowerCase();
    if (
      [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'svg',
        'tiff',
        'psd',
        'pdf',
        'raw',
      ].includes(extension)
    ) {
      return true;
    }
    return false;
  }

  async uploadToS3(
    uploadRequest: S3FileUploadRequest
  ): Promise<CommonResponse> {
    if (process.env.REACT_APP_NODE_ENV === 'local') {
      console.log('local : uploadToS3');
      responseSuccess.data = {
        uploadKey:
          'tmp/image/' +
          uploadRequest.dirName +
          '/' +
          uploadRequest.dirKey +
          '/' +
          uploadRequest.fileKey,
      };
      return responseSuccess;
    }

    let response: CommonResponse;
    const method = 'POST';
    const url = '/upload';
    const serviceName = Subject.S3;
    const params = null;
    const body = uploadRequest;
    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      responseFail.errorMessage = error;
      response = responseFail;
    }
    return response;
  }

  async copyTmpFiles(
    s3FileCopyRequest: S3FileCopyRequest
  ): Promise<CommonResponse> {
    if (process.env.REACT_APP_NODE_ENV === 'local') {
      console.log('local : copyTmpFiles');
      return responseSuccess;
    }
    let response: CommonResponse;
    const method = 'POST';
    const url = '/copyTmpFiles';
    const serviceName = Subject.S3;
    const params = null;
    const body = s3FileCopyRequest;
    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      responseFail.errorMessage = error;
      response = responseFail;
    }
    return response;
  }

  async deleteFiles(
    s3FileDeleteRequest: S3FileDeleteRequest
  ): Promise<CommonResponse> {
    if (process.env.REACT_APP_NODE_ENV === 'local') {
      console.log('local : deleteFiles');
      return responseSuccess;
    }
    let response: CommonResponse;
    const method = 'POST';
    const url = '/deleteFiles';
    const serviceName = Subject.S3;
    const params = null;
    const body = s3FileDeleteRequest;
    try {
      // get pre signed URL for image upload
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      responseFail.errorMessage = error;
      response = responseFail;
    }
    return response;
  }
}
