import './AwsConnectSection.scss';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { ConnectContentData } from '../../../../service/data/awsProductCard/awsConnect/AwsConnectModel';
import { connectContentDatasKo } from '../../../../service/data/awsProductCard/awsConnect/AwsConnectDataKo';
import { connectContentDatasEn } from '../../../../service/data/awsProductCard/awsConnect/AwsConnectDataEn';
import AwsConnectContentArea from './AwsConnectContentArea';
import { Language } from '../../../../service/common/model/enum/Common.enum';

const AwsConnectSection: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const [awsConnectContents, setAwsConnectContents] = useState<
    ConnectContentData[]
  >(i18n.language === Language.KO ? connectContentDatasKo : connectContentDatasEn);

  useEffect(() => {
    setAwsConnectContents(
      i18n.language === Language.KO ? connectContentDatasKo : connectContentDatasEn
    );
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  return (
    <div id="AwsConnectSection" data-testid="testAwsConnectSection">
      <div className="eks-anywhere fullpage-slide">
        <div className="header-top" data-testid="header-top">
          <div className="top-tit detail-container">
            <h2>Amazon Connect</h2>
          </div>
        </div>
        <div className="eks-anywhere-body">
          <div className="eks-anywhere-title-cont">
            <div className="title-txt-box">
              <span className="title-lg">
                {'Amazon Connect'}
              </span>
            </div>
          </div>
          {awsConnectContents.map((content, idx) => {
            return (
              <React.Fragment key={idx}>
                <AwsConnectContentArea content={content} />
              </React.Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AwsConnectSection;
