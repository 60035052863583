import './CasePageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import CaseSection from './components/CaseSection';

const CasePageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="CasePageContainer">
        <CaseSection />
      </div>
    </Container>
  );
};

export default CasePageContainer;
