import 'bootstrap/dist/css/bootstrap.min.css';
import './ChangePasswordSection.scss';

import React, { ReactElement, useState, KeyboardEvent, useEffect } from 'react';
import MemberService from '../../../../service/member/MemberService';
import { Image, Row } from 'react-bootstrap';
import logo from '../../../../assets/common/headerLogo.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import { changeAuthorizationStatus } from '../../../../redux/actions/authorization';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import ProgressBar from '../../../../layouts/ProgressBar';

const ChangePasswordSection: React.FC = (): ReactElement => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  const memberService = new MemberService();
  const navigator = useNavigate();

  const loading = useSelector((state: RootState) => state.loading.loading);

  const authorized = useSelector(
    (state: RootState) => state.authorization.authorized
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (authorized) {
      dispatch(changeLoadingStatus(false));
      dispatch(changeProgressStatus(100));
    }
  }, []);

  const handleChangePassword = async () => {
    if (newPassword === confirmNewPassword) {
      dispatch(changeLoadingStatus(true));
      dispatch(changeProgressStatus(50));
      const response = await memberService.handleChangePassword(
        username,
        password,
        newPassword
      );
      dispatch(changeLoadingStatus(false));
      dispatch(changeProgressStatus(100));

      if (response.successOrNot == 'Y') {
        alert('비밀번호 변경이 완료되었습니다. 로그인 화면으로 이동합니다.');
        navigator('/admin/login');
      } else {
        if (response.statusCode == 'UNAUTHORIZED.ERR') {
          alert(`계정 정보를 확인해주시기 바랍니다.`);
        } else if (response.statusCode == 'INVALID.PASSWORD.ERR') {
          alert(
            '비밀번호는 영문, 숫자, 특수문자 혼합하여 8자 이상이여야 합니다.'
          );
        } else if (response.statusCode == 'LIMIT.EXCEEDED.PASSWORD.ERR') {
          alert(
            '비밀번호 변경을 너무 자주 시도했습니다.\n잠시 후 다시 시도해주세요.'
          );
        } else {
          alert('서버 오류가 발생했습니다. 다시 시도해주세요.');
        }
      }
    } else {
      alert('신규 비밀번호를 확인해주세요.');
    }

    // dispatch(changeLoadingStatus(true));
    // dispatch(changeProgressStatus(50));
    // const response = await memberService.handleLogin(username, password);
    // dispatch(changeLoadingStatus(false));
    // dispatch(changeProgressStatus(100));

    // if (
    //   response.successOrNot == 'Y' ||
    //   response.statusCode == 'INIT_PASSWORD_ERR'
    // ) {
    //   if (newPassword === confirmNewPassword) {
    //     const response = await memberService.handleChangePassword(
    //       username,
    //       password,
    //       newPassword
    //     );
    //   } else {
    //     alert('신규 비밀번호를 확인해주세요.');
    //   }
    // } else {
    //   if (response.statusCode == 'UNAUTHORIZED.ERR') {
    //     alert(
    //       `계정 정보를 확인해주시기 바랍니다. (${response.data.attemptCount}/5)`
    //     );
    //   } else if (response.statusCode == 'LOGIN.ATTEMPTS.EXCEEDED') {
    //     alert(
    //       '로그인 실패 횟수 초과로 계정 사용이 중지됩니다.\n관리자에게 문의 부탁드립니다.'
    //     );
    //   } else {
    //     alert('서버 오류가 발생했습니다. 다시 시도해주세요.');
    //   }
    // }
  };

  return (
    <>
      <ProgressBar />
      <Row>
        <div className="AdminLoginSection" id="changePasswordSection">
          <div className="auth-wrapper">
            <div className="auth-inner">
              <form>
                <div className="form-group">
                  <div id="logo" className="logo">
                    <Image src={logo} alt="LG CNS launch center 로고 이미지" />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="아이디를 입력하세요"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="비밀번호를 입력하세요"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="신규 비밀번호를 입력하세요"
                    autoComplete="off"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="신규 비밀번호를 다시 입력하세요."
                    autoComplete="off"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={handleChangePassword}
                      disabled={loading}
                    >
                      비밀번호 변경
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <div className="loginContact">
                    <p className="forgot-password text-center">
                      ID 또는 비밀번호 분실 시 담당자에게 연락하세요 <br />{' '}
                      담당자 : AWS서비스Scale팀 김행화(haenghwa.kim@lgcns.com)
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};
export default ChangePasswordSection;
