import './FloatingTop.scss';

import React from 'react';
import useScrolling from '../hooks/useScrolling';
import smoothscroll from 'smoothscroll-polyfill';
import arrowUpMobile from '../assets/common/arrowUpMobile.png';

function FloatingTop() {
  const scrollFlag = useScrolling(false);

  const ScrollToTop = () => {
    smoothscroll.polyfill();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {scrollFlag && (
        <div className="floating-top">
          <button onClick={ScrollToTop}>
            <img src={arrowUpMobile} />
          </button>
        </div>
      )}
    </>
  );
}

export default FloatingTop;
