import React from 'react';
import {
  CloudFormationContentData,
  AwsCloudFormationContentLayout,
} from '../../../../service/data/awsProductCard/awsCloudFormation/AwsCloudFormationModel';
import { getIsMobile } from '../../../../service/utils/utils';
import AwsCloudFormationContentTextItem from './AwsCloudFormationContentTextItem';

interface Props {
  content: CloudFormationContentData;
}

const AwsCloudFormationContentArea: React.FC<Props> = (props: Props) => {
  const { content } = props;
  const isMobile = getIsMobile();

  const renderContents = () => {
    if (content.layout === AwsCloudFormationContentLayout.TXT_IMG) {
      return (
        <>
          <AwsCloudFormationContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      );
    } else if (content.layout === AwsCloudFormationContentLayout.IMG_TXT) {
      //mobile은 TXT-IMG 배치로 고정
      return isMobile ? (
        <>
          <AwsCloudFormationContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      ) : (
        <>
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
          <AwsCloudFormationContentTextItem content={content.content} />
        </>
      );
    } else if (content.layout === AwsCloudFormationContentLayout.TXT_TXT) {
      return (
        <>
          <AwsCloudFormationContentTextItem content={content.content} />
          <AwsCloudFormationContentTextItem content={content.content2} />
        </>
      );
    } else if (content.layout === AwsCloudFormationContentLayout.TXT_TXT_TXT) {
      return (
        <>
          <AwsCloudFormationContentTextItem content={content.content} />
          <AwsCloudFormationContentTextItem content={content.content2} />
          <AwsCloudFormationContentTextItem content={content.content3} />
        </>
      );
    } else if (content.layout === AwsCloudFormationContentLayout.TXT) {
      return (
        <>
          <AwsCloudFormationContentTextItem content={content.content} />
        </>
      );
    }

  };

  return (
    <div
      className="eks-anywhere-content-cont"
      data-testid="testAwsCloudFormationContentArea"
    >
      {renderContents()}
    </div>
  );
};

export default AwsCloudFormationContentArea;
