import './AwsCloudFrontSection.scss';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { CloudFrontContentData } from '../../../../service/data/awsProductCard/awsCloudFront/AwsCloudFrontModel';
import { CloudFrontContentDatasKo } from '../../../../service/data/awsProductCard/awsCloudFront/AwsCloudFrontDataKo';
import { CloudFrontContentDatasEn } from '../../../../service/data/awsProductCard/awsCloudFront/AwsCloudFrontDataEn';
import AwsCloudFrontContentArea from './AwsCloudFrontContentArea';
import { Language } from '../../../../service/common/model/enum/Common.enum';

const AwsCloudFrontSection: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const [awsCloudFrontContents, setAwsCloudFrontContents] = useState<
    CloudFrontContentData[]
  >(i18n.language === Language.KO ? CloudFrontContentDatasKo : CloudFrontContentDatasEn);

  useEffect(() => {
    setAwsCloudFrontContents(
      i18n.language === Language.KO ? CloudFrontContentDatasKo : CloudFrontContentDatasEn
    );
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  return (
    <div id="AwsCloudFrontSection" data-testid="testAwsCloudFrontSection">
      <div className="eks-anywhere fullpage-slide">
        <div className="header-top" data-testid="header-top">
          <div className="top-tit detail-container">
            <h2>Amazon CloudFront</h2>
          </div>
        </div>
        <div className="eks-anywhere-body">
          <div className="eks-anywhere-title-cont">
            <div className="title-txt-box">
              <span className="title-lg">
                {'Amazon CloudFront'}
              </span>
            </div>
          </div>
          {awsCloudFrontContents.map((content, idx) => {
            return (
              <React.Fragment key={idx}>
                <AwsCloudFrontContentArea content={content} />
              </React.Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AwsCloudFrontSection;
