import CommonResponse from '../common/model/CommonResponse';
import BaseService from '../BaseService';
import SessionService from '../SessionService';
import { Subject } from '../common/model/Service';
import { newsData, newsTempData } from './data/NewsData';
import { News } from './model/News';

const mockResponseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {},
};

const mockResponseFail: CommonResponse = {
  successOrNot: 'N',
  statusCode: 'FAIL',
  errorMessage: '',
};

const mockNewsDetailResponseSuccess: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: undefined,
};

export default class NewsService extends BaseService {
  async getAllMockNewsList(
    offset?: number,
    pageSize?: number
  ): Promise<News[]> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/news';
    const serviceName = Subject.NEWS;
    const params = pageSize;
    const body = null;

    mockResponseSuccess.data = newsData.slice(offset, pageSize);

    try {
      response = mockResponseSuccess;
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as Array<News>;
  }

  async getMockNewsDetail(param: string): Promise<News> {
    let response: CommonResponse;

    const method = 'GET';
    const url = param;
    const serviceName = Subject.NEWS;
    const params = null;
    const body = null;

    mockNewsDetailResponseSuccess.data = newsData.filter(
      (news) => news.newsUrl === url
    )[0];

    try {
      response = mockNewsDetailResponseSuccess;
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as News;
  }

  async getAllNewsList(
    offset?: number,
    pageSize?: number
  ): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/news';
    const serviceName = Subject.NEWS;
    const params = pageSize;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }

    // 세션 만료 처리
    if (
      response.successOrNot === 'N' &&
      (response.statusCode === 'BAD.REQUEST.ERR' ||
        response.statusCode === 'UNAUTHORIZED.ERR')
    ) {
      const sessionService = new SessionService();
      sessionService.deleteSessionInfo();
    }

    return response;
  }

  // 현재 페이지의 뉴스 목록 가져오기
  async getAllNewsListUser(option: string): Promise<News[]> {
    let response: CommonResponse;
    const method = 'GET';
    const url =
      '/news?direction=desc&key=' +
      (option === 'latest' ? 'registeredDate' : 'newsView');
    const serviceName = Subject.NEWS;
    const params = '';
    const body = null;

    try {
      response = await this.fnRest(
        this.userHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data.all : []
    ) as Array<News>;
  }

  async getNewsDetail(param: string): Promise<News> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/news/' + param;
    const serviceName = Subject.NEWS;
    const params = null;
    const body = null;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return (
      response.data && response.successOrNot === 'Y' ? response.data : []
    ) as News;
  }

  async createNews(request: News): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/news';
    const serviceName = Subject.NEWS;
    const params = null;
    const body = request;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async updateNews(request: News, idx: string): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/news/' + idx;
    const serviceName = Subject.NEWS;
    const params = null;
    const body = request;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async deleteNews(news: News): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/news/' + news.newsUrl;
    const serviceName = Subject.NEWS;
    const params = null;
    const body = news;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempNewsList(): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/news/tmp';
    const serviceName = Subject.NEWS;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response;
  }

  async getTempNewsDetail(newsUrl: string): Promise<News> {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/admin/news/tmp/' + newsUrl;
    const serviceName = Subject.NEWS;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async postTempNewsRegist(newsRequest: News): Promise<News[]> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/admin/news/tmp';
    const serviceName = Subject.NEWS;
    const params = '';
    const body = newsRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async putTempNewsEdit(newsRequest: News, newsUrl: string): Promise<News[]> {
    let response: CommonResponse;

    const method = 'PUT';
    const url = '/admin/news/tmp/' + newsUrl;
    const serviceName = Subject.NEWS;
    const params = '';
    const body = newsRequest;

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }

  async deleteTempNews(newsUrl: string): Promise<News[]> {
    let response: CommonResponse;

    const method = 'DELETE';
    const url = '/admin/news/tmp/' + newsUrl;
    const serviceName = Subject.NEWS;
    const params = '';
    const body = '';

    try {
      response = await this.fnRest(
        this.adminHeaders,
        method,
        url,
        params,
        body,
        serviceName
      );
    } catch (error) {
      mockResponseFail.errorMessage = error;
      response = mockResponseFail;
    }
    return response.successOrNot === 'Y' ? response.data : null;
  }
}
