import React, { useState, useEffect } from 'react';
import {
  awsDefaultProductsKo,
  awsProductsKo,
} from '../../../service/data/awsProduct/AwsProductDataKo';
import {
  awsDefaultProductsEn,
  awsProductsEn,
} from '../../../service/data/awsProduct/AwsProductDataEn';
import { useTranslation } from 'react-i18next';
import AwsProductTabItem from './AwsProductTabItem';
import { AwsProductData } from '../../../service/data/awsProduct/AwsProductModel';
import { Language } from '../../../service/common/model/enum/Common.enum';
import Footer from '../../../layouts/Footer';

const AwsProductTabPage: React.FC = () => {
  const { i18n, t } = useTranslation();

  const [curProduct, setCurProduct] = useState<AwsProductData>(
    i18n.language === Language.KO ? awsDefaultProductsKo : awsDefaultProductsEn
  );

  const [awsProducts, setAwsProducts] = useState(
    i18n.language === Language.KO ? awsProductsKo : awsProductsEn
  );

  useEffect(() => {
    setAwsProducts(
      i18n.language === Language.KO ? awsProductsKo : awsProductsEn
    );
    setCurProduct(
      i18n.language === Language.KO
        ? awsDefaultProductsKo
        : awsDefaultProductsEn
    );
  }, [i18n.language]);

  return (
    <>
      <div className="aws-product-tab-cont" data-testid="testAwsProductTabPage">
        <div className="title-box">
          <strong className="title-txt">
            {t('awsproduct.AwsProductTabPage.title')}
          </strong>
        </div>
        <div className="product-box">
          <div className="product-title-area">
            {awsProducts.map((product, idx) => {
              return (
                <div
                  className={`product-title-box${
                    product.productCode === curProduct.productCode
                      ? ' selected'
                      : ''
                  }`}
                  key={product.productCode}
                  onClick={() =>
                    setCurProduct({
                      productCode: product.productCode,
                      productName: product.productName,
                    })
                  }
                >
                  <div className="index-txt">
                    {(idx + 1).toString().padStart(2, '0')}
                  </div>
                  <div
                    className={`title-txt${
                      product.productCode === curProduct.productCode
                        ? ' selected'
                        : ''
                    }`}
                  >
                    <span>{product.productName}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="product-contents-area">
            {awsProducts
              .find((data) => {
                return data.productCode === curProduct.productCode;
              })
              ?.service.map((data, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <AwsProductTabItem
                      awsServiceCode={data.serviceCode}
                      awsServiceUrl={data?.serviceUrl}
                      awsProductName={curProduct.productName}
                      awsTabItem={data}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AwsProductTabPage;
