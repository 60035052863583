import './BlogDetailPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';
import BlogDetailSection from './components/BlogDetailSection';

const BlogDetailPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="BlogDetailPageContainer">
        <BlogDetailSection />
      </div>
    </Container>
  );
};

export default BlogDetailPageContainer;
