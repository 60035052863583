import './CaseBoxPageAdmin.scss';

import React, { ReactElement } from 'react';
import { CaseBoard } from '../../../../service/case/model/Case';

interface Props {
  caseBoard?: CaseBoard;
}

const CaseBoxPageAdmin: React.FC<Props> = ({
  caseBoard,
}: Props): ReactElement => {
  return (
    <div className="v-box-admin">
      <div className="image">
        <span>{caseBoard?.caseTag}</span>
        <img src={caseBoard?.caseThumbnail} alt={caseBoard?.caseTitle} />
      </div>
      <div className="text" data-testid="case-text">
        <h2>{caseBoard?.caseTitle}</h2>
        <p>{caseBoard?.caseDescription}</p>
      </div>
    </div>
  );
};
export default CaseBoxPageAdmin;
