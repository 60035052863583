import './BusinessDetailContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import BusinessDetailSection from './components/BusinessDetailSection';

const BusinessDetailContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="AdminBusinessDetailContainer">
        <BusinessDetailSection />
      </div>
    </Container>
  );
};

export default BusinessDetailContainer;
