import './BlogBoardSection.scss';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { Blog } from '../../../../service/blog/model/Blog';
import { blogData, blogTempData } from '../../../../service/blog/data/BlogData';
import BlogService from '../../../../service/blog/BlogService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import { State } from '../../../../service/common/model/enum/Common.enum';

import BlogTempRegistSection from './BlogTempRegistSection';
import BlogTableSection from './BlogTableSection';
import { changeAuthorizationStatus } from '../../../../redux/actions/authorization';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import ProgressBar from '../../../../layouts/ProgressBar';

const BlogBoardSection: React.FC = () => {
  const [blogList, setBlogList] = useState<Blog[]>([]);
  const [blogTempRegistList, setBlogTempRegistList] = useState<Blog[]>([]);
  const [blogStateFilter, setBlogStateFilter] = useState<State | 'default'>(
    'default'
  );
  const [textSearchFilter, setTextSearchFilter] = useState<string>('');
  const blogService = new BlogService();
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  const columns = [
    {
      index: '0',
      value: 'No.',
    },
    {
      index: '1',
      value: '제목',
    },
    {
      index: '2',
      value: 'URL',
    },
    {
      index: '3',
      value: '상태',
    },
    {
      index: '4',
      value: '등록자',
    },
    {
      index: '5',
      value: '등록 일자',
    },
    {
      index: '6',
      value: '수정자',
    },
    {
      index: '7',
      value: '수정 일자',
    },
    {
      index: '8',
      value: '조회수',
    },
  ];
  const navigator = useNavigate();

  useEffect(() => {
    getAllBlogList();
    getAllBlogTempRegistList();

    return() => {
      setBlogList([]);
      setBlogTempRegistList([]);
    }
  }, []);

  const getAllBlogList = async () => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await blogService.getAllBlogList(); // TO-DO 정렬 조건
    if (response.successOrNot === 'Y') {
      if(response.data.admin){
        setBlogList([...response.data.all, ...response.data.admin])
      }else{
        setBlogList([...response.data.all]);
      }
      
    } else {
      if (response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
      ) {
        dispatch(changeAuthorizationStatus(false));
      }
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  // 임시저장 목록 조회
  const getAllBlogTempRegistList = async () => {
    const response = await blogService.getTempBlogList();
    if (response.successOrNot === 'Y') {
      setBlogTempRegistList(response.data);
    } else {
      if (response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
      ) {
        dispatch(changeAuthorizationStatus(false));
      }
    }
  };

  const blogHandler = (e: React.SyntheticEvent) => {
    const key = e.currentTarget.getAttribute('id');
    navigator('/admin/blog/' + key);
  };

  const blogRegisterBtnHandler = () => {
    navigator('/admin/blog/regist');
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSelectStateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    if (value == State.ALL) {
      setBlogStateFilter(State.ALL);
    } else if (value == State.ADMIN) {
      setBlogStateFilter(State.ADMIN);
    } else {
      setBlogStateFilter('default');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTextSearchFilter(value);
  };

  return (
    <div className="AdminBlogBoardSection">
      <ProgressBar/>
      <div className="fullpage pc">
        <BlogTableSection
          columns={columns}
          boardList={blogList}
          itemStateFilter={blogStateFilter}
          textSearchFilter={textSearchFilter}
          handleSelectStateChange={handleSelectStateChange}
          handleSearchChange={handleSearchChange}
          clickHandler={blogHandler}
        />
        <div className="btn-container">
          <Button
            className="btn btn-temp-regist"
            data-testid="temp-register-button"
            onClick={openModal}
          >
            blog 임시저장 목록
          </Button>
          <Button
            className="btn btn-secondary"
            data-testid="register-button"
            onClick={blogRegisterBtnHandler}
          >
            blog 등록
          </Button>
        </div>
      </div>
      <BlogTempRegistSection
        open={modalOpen}
        close={closeModal}
        tempBlogList={blogTempRegistList}
      />
    </div>
  );
};

export default BlogBoardSection;
