import {
  Language,
  State,
  NewsCategory,
} from '../../common/model/enum/Common.enum';
import { News } from '../model/News';
import newThumbnail1 from '../../../assets/news/news-thum-1.png';
import newThumbnail2 from '../../../assets/news/news-thum-2.png';
import newThumbnail4 from '../../../assets/news/news-thum-4.png';
import newThumbnail5 from '../../../assets/news/news-thum-5.png';
import newThumbnail6 from '../../../assets/news/news-thum-6.jpg';
import newThumbnail7 from '../../../assets/news/news-thum-7.jpg';
import newsContentImage1 from '../../../assets/news/news-content-1-1.png';
import newsContentImage2 from '../../../assets/news/news-content-1-2.png';
import newsContentImage4 from '../../../assets/news/news-content-1-4.png';

export const newsData: News[] = [
  {
    registerAdmin: '관리자',
    registeredDate: '2022-02-08 00:00:00',
    newsBoard: {
      ko: {
        newsThumbnail: '테스트 썸네일',
        newsSubtitle:
          '‘AM(현대화된 애플리케이션)’ 구축·운영 사업 위한 ‘전략적 협력 계약(SCA)’ 체결',
        newsText: '테스트 내용',
        newsLang: Language.KO,
        newsCategory: NewsCategory.KP,
        newsTitle: 'LG CNS, AWS와 손잡고 미래 클라우드 사업 나선다',
      },
      en: {
        newsThumbnail: 'news thumbnail test data',
        newsSubtitle: 'news subtitle test data',
        newsText: 'news text test data',
        newsLang: Language.EN,
        newsCategory: NewsCategory.KP,
        newsTitle: 'news title test data',
      },
    },
    newsUrl: 'press000001',
    newsState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-02-08 00:00:00',
    newsView: 1,
  },
];

export const newsTempData: News[] = [
  {
    registerAdmin: '관리자',
    registeredDate: '2022-07-05 00:00:00',
    newsBoard: {
      ko: {
        newsThumbnail: '임시저장 썸네일',
        newsSubtitle: '임시저장 된 news subtitle',
        newsText: '임시저장 된 news text',
        newsLang: Language.KO,
        newsCategory: NewsCategory.KP,
        newsTitle: '임시저장 된 news main title',
      },
      en: {
        newsThumbnail: 'temp regist news thumbnail test data',
        newsSubtitle: 'temp regist news subtitle test data',
        newsText: 'temp regist news text test data',
        newsLang: Language.EN,
        newsCategory: NewsCategory.EP,
        newsTitle: 'temp regist news title test data',
      },
    },
    newsUrl: 'press000044',
    newsState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-07-05 00:00:00',
    newsView: 0,
  },
  {
    registerAdmin: '관리자',
    registeredDate: '2022-07-05 01:00:00',
    newsBoard: {
      ko: {
        newsThumbnail: '임시저장 썸네일2222',
        newsSubtitle: '임시저장 된 news subtitle 22222',
        newsText: '임시저장 된 news text 22222',
        newsLang: Language.KO,
        newsCategory: NewsCategory.KP,
        newsTitle: '임시저장 된 news main title 222222',
      },
      en: {
        newsThumbnail: 'temp regist news thumbnail test data22222',
        newsSubtitle: 'temp regist news subtitle test data222222',
        newsText: 'temp regist news text test data22222',
        newsLang: Language.EN,
        newsCategory: NewsCategory.EP,
        newsTitle: 'temp regist news title test data22222222',
      },
    },
    newsUrl: 'press000444',
    newsState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-07-05 01:00:00',
    newsView: 0,
  },
  {
    registerAdmin: '관리자',
    registeredDate: '2022-07-05 02:00:00',
    newsBoard: {
      ko: {
        newsThumbnail: '임시저장 썸네일',
        newsSubtitle: '임시저장 된 news subtitle 3333333',
        newsText: '임시저장 된 news text333333',
        newsLang: Language.KO,
        newsCategory: NewsCategory.KP,
        newsTitle: '임시저장 된 news main title33333333',
      },
      en: {
        newsThumbnail: 'temp regist news thumbnail test data3333',
        newsSubtitle: 'temp regist news subtitle test data3333333',
        newsText: 'temp regist news text test data333333',
        newsLang: Language.EN,
        newsCategory: NewsCategory.EP,
        newsTitle: 'temp regist news title test data33333333',
      },
    },
    newsUrl: 'press000445',
    newsState: State.ALL,
    modifyAdmin: '관리자',
    modifiedDate: '2022-07-05 02:00:00',
    newsView: 0,
  },
];
