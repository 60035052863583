import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Status } from './common/model/enum/Status.enum';
import { Error } from './common/model/Error';
import { Subject, ServicePort } from './common/model/Service';
import SessionService from './SessionService';
import { getSessiontExpirationTime } from './utils/utils';
import Cookies from 'js-cookie';
export default class BaseService {
  private environment: string;
  private apiLocation: string;
  private protocol = 'https://';
  private sessionService = new SessionService();
  private languageCode = 'ko';
  protected defaultPagingSize = 10;

  constructor() {
    this.apiLocation = process.env.REACT_APP_API_URL || '';
    this.environment = process.env.REACT_APP_NODE_ENV || '';
  }

  public getAPILocation = (): string => {
    return this.apiLocation;
  };

  public setEnvironment = (env: string): void => {
    this.environment = env;
  };

  public getEnvironment = (): string => {
    return this.environment;
  };

  private getCorrelationId = (): string => {
    return window.location.pathname === '/'
      ? 'root'.concat('_').concat(uuidv4())
      : window.location.pathname.concat('_').concat(uuidv4());
  };

  protected adminHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Session-Id': this.sessionService.getSessionId(),
    'X-Correlation-Id': this.getCorrelationId(),
    'X-Language-Code': this.languageCode,
  };

  protected userHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Correlation-Id': this.getCorrelationId(),
    'X-Language-Code': this.languageCode,
  };

  protected config: AxiosRequestConfig = {
    method: 'GET',
    url: '',
    data: {},
    timeout: 80000,
  };

  public getCookieOptions = () => {
    let cookieOptions: any = {
      path: '/',
    };
    if (process.env.REACT_APP_NODE_ENV === 'local') {
      cookieOptions = {
        ...cookieOptions,
        domain: 'localhost',
      };
    } else if (process.env.REACT_APP_NODE_ENV === 'dev') {
      cookieOptions = {
        ...cookieOptions,
        domain: 'dev.cloudlaunch.cloud',
      };
    } else if (process.env.REACT_APP_NODE_ENV === 'prod') {
      cookieOptions = {
        ...cookieOptions,
        domain: 'amazon-web-services.lgcns.com',
      };
    }
    return cookieOptions;
  };

  /* eslint-disable */
  public async fnRest(
    header: any,
    method: string,
    url: string,
    params?: any,
    requestBody?: any,
    service?: string,
    showLoadingBar: boolean = true
  ): Promise<any> {
    this.config.headers = header;
    this.config.method = this.fnCheckHTTPMethod(method);
    this.config.url = this.fnCheckServiceUrl(url, service ? service : 'etc');
    this.config.params = params ? params : {};
    this.config.data = requestBody ? requestBody : {};
    this.config.withCredentials = true;

    const csrfToken = uuidv4();

    try {
      if (this.config.method !== 'GET') {
        Cookies.set(`csrfToken-${csrfToken}`, csrfToken, {
          ...this.getCookieOptions(),
          maxAge: 0,
          secure: true,
          samesite: 'none',
        });

        this.config.headers = {
          ...this.config.headers,
          'X-CSRF-TOKEN': csrfToken,
        };
      }

      const response: AxiosResponse<any> = await axios.request(this.config);
      if (response && response.data) {
        if (response.data.successOrNot === 'Y') {
          if (response.data.data.sessionId) {
            this.sessionService.setSessionId(response.data.data.sessionId);
            this.sessionService.setSessionExpirationDate(
              getSessiontExpirationTime()
            );
          }
          return response.data;
        } else if (response.status === Status.RESPONSE_STATUS_200) {
          return response.data;
        } else {
          const noProperError: Error = {
            successOrNot: 'N',
            statusCode: 'NO_PROPER',
            data: {},
          };
          throw noProperError;
        }
      } else {
        const noResponseError: Error = {
          successOrNot: 'N',
          statusCode: 'NO_RESPONSE',
          data: {},
        };
        throw noResponseError;
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.status === Status.RESPONSE_STATUS_401
      ) {
        this.sessionService.deleteSessionInfo();
        window.location.assign('/unauthorized');
        return false;
      } else if (
        error.response &&
        error.response.status === Status.RESPONSE_STATUS_500
      ) {
        const internalServerError: Error = {
          successOrNot: 'N',
          statusCode: 'INTERNAL_SERVER',
          data: {},
        };
        throw internalServerError;
      } else if (error && (error as Error).successOrNot) {
        throw error;
      } else {
        const unknownError: Error = {
          successOrNot: 'N',
          statusCode: 'UNKNOWN_SERVER',
          data: {},
        };
        throw unknownError;
      }
    } finally {
      Cookies.remove(`csrfToken-${csrfToken}`, this.getCookieOptions());
    }
  }

  public fnCheckServiceUrl = (url: string, service: string) => {
    let serviceUrl = '';
    if (this.environment === 'local') {
      serviceUrl = this.apiLocation;
      switch (service) {
        case Subject.BUSINESS:
          serviceUrl += ':' + ServicePort.BUSINESS + url;
          break;
        case Subject.CASE:
          serviceUrl += ':' + ServicePort.CASE + url;
          break;
        case Subject.NEWS:
          serviceUrl += ':' + ServicePort.NEWS + url;
          break;
        case Subject.BLOG:
          serviceUrl += ':' + ServicePort.BLOG + url;
          break;
        case Subject.MEMBER:
          serviceUrl += ':' + ServicePort.MEMBER + url;
          break;
        case Subject.S3:
          serviceUrl += ':' + ServicePort.S3 + url;
          break;
        default:
          serviceUrl = url;
      }
    } else {
      switch (service) {
        case Subject.BUSINESS:
          serviceUrl = this.protocol + this.apiLocation + url;
          break;
        case Subject.CASE:
          serviceUrl = this.protocol + this.apiLocation + url;
          break;
        case Subject.NEWS:
          serviceUrl = this.protocol + this.apiLocation + url;
          break;
        case Subject.BLOG:
          serviceUrl = this.protocol + this.apiLocation + url;
          break;
        case Subject.MEMBER:
          serviceUrl = this.protocol + this.apiLocation + url;
          break;
        case Subject.S3:
          serviceUrl = this.protocol + this.apiLocation + url;
          break;
        default:
          serviceUrl = url;
      }
    }
    return serviceUrl;
  };
  /* eslint-enable */

  public fnCheckHTTPMethod = (method: string) => {
    let httpMethod: Method;
    switch (method) {
      case 'GET':
      case 'get':
        httpMethod = 'GET';
        break;
      case 'POST':
      case 'post':
        httpMethod = 'POST';
        break;
      case 'PUT':
      case 'put':
        httpMethod = 'PUT';
        break;
      case 'PATCH':
      case 'patch':
        httpMethod = 'PATCH';
        break;
      case 'DELETE':
      case 'delete':
        httpMethod = 'DELETE';
        break;
      default:
        httpMethod = 'GET';
    }
    return httpMethod;
  };

  public getQueryStringFormat = (queryParam: any) => {
    const keys = Object.keys(queryParam);
    let queryString = '?';
    for (const key of keys) {
      if (queryString.length > 1) {
        queryString = queryString.concat('&');
      }
      queryString = queryString
        .concat(key)
        .concat('=')
        .concat(encodeURIComponent(queryParam[key]));
    }
    return queryString;
  };
}
