import {
  AuthorizationAction,
  CHANGE_AUTHORIZATION_STATUS,
} from '../actions/authorization';

type AuthorizationStatus = {
  authorized: boolean;
};

const initialState: AuthorizationStatus = {
  authorized: false,
};

const authorization = (
  state: AuthorizationStatus = initialState,
  action: AuthorizationAction
): AuthorizationStatus => {
  switch (action.type) {
    case CHANGE_AUTHORIZATION_STATUS:
      return { authorized: action.authorized };
    default:
      return state;
  }
};

export default authorization;
