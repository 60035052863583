import './BusinessDetailSection.scss';
import '../../../layouts/prism.scss';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../../layouts/Footer';
import BusinessService from '../../../service/business/BusinessService';
import { useParams } from 'react-router-dom';
import { Business } from '../../../service/business/model/Business';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/modules/index';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import {
  DateFormat,
  Language,
  StringDateFormat,
} from '../../../service/common/model/enum/Common.enum';
import { getStringDateFormat } from '../../../service/utils/utils';
import { getIsMobile } from '../../../service/utils/utils';
import BusinessDetailPageSkeleton from './BusinessDetailPageSkeleton';
import { useNavigate } from 'react-router-dom';
import BusinessDetailEditorSection from './BusinessDetailEditorSection';
import { Case } from '../../../service/case/model/Case';
import CaseService from '../../../service/case/CaseService';

const BusinessDetailSection: React.FC = () => {
  const businessService = new BusinessService();
  const caseService = new CaseService();
  const [business, setBusiness] = useState<Business>();
  const { url } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.language.lang);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const isMobile = getIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [caseStudy, setCaseStudy] = useState<Case[]>();
  const limit = location.state
    ? (location.state as { limit: number }).limit
    : isMobile
    ? 3
    : 6;
  const index = location.state
    ? (location.state as { index: number }).index
    : 0;

  useEffect(() => {
    if (url) {
      getBusinessDetail(url);
    }
  }, []);


  useEffect(() => {
    if (language === Language.EN && business && !business?.businessBoard.en) {
      alert('There is no english content.');
      navigate('/business');
    }
  }, [language]);

  const getBusinessDetail = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    const response = await businessService.getBusinessDetail(url);
    const caseRes: Case[] = await caseService.getCaseList();

    if (response) {
      setBusiness(response);
      const caseList = caseRes?.filter(caseObj => response.businessBoard.ko.caseStudyUrl?.includes(caseObj.caseUrl))
      if(caseList && caseList.length !== 0) {
        setCaseStudy(caseList)
      }
    } else {
      navigate('/business');
    }
    dispatch(changeLoadingStatus(false));
  };

  const goCaseStudy = (caseUrl: string) => {
    navigate('/case/' + caseUrl);
  }

  return (
    <div className="BusinessDetailSection" data-testid="testBusinessDetailSection">
      <div className="h-wrap">
        {loading ? (
          <BusinessDetailPageSkeleton />
        ) : business ? (
          <div className="business-detail-area">

            <div>
              <p id="business-detail-businessTitle">
                {language === Language.KO
                  ? business.businessBoard.ko.businessTitle
                  : business.businessBoard.en?.businessTitle}
              </p>
            </div>
            {isMobile ? (
              <div id="business-detail-business-registerDate-view">
                <div className="business-info-box">
                  <span id="business-detail-business-registerDate">
                    {getStringDateFormat(
                      business.registeredDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <br></br>
                  <span id="business-detail-category">
                    {t('business.BusinessTabPage.businessSection.modifiedDate')} &nbsp;
                  </span>
                  <span id="business-detail-business-modifiedDate">
                    {getStringDateFormat(
                      business.modifiedDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <br></br>
                  <span id="business-detail-category">
                    {language === Language.KO
                      ? business.businessBoard.ko.businessWriter
                      : business.businessBoard.en?.businessWriter}
                  </span>
                </div>
                <div className="view-box">
                  <span id="business-detail-view">
                    {t('business.BusinessTabPage.businessDetailSection.view')} &nbsp;
                  </span>
                  <span id="business-detail-business-view">{business.businessView}</span>
                </div>
              </div>
            ) : (
              <div id="business-detail-business-registerDate-view">
                <div className="business-info-box">
                  <span id="business-detail-category">
                    {t('business.BusinessTabPage.businessSection.registeredDate')} &nbsp;
                  </span>
                  <span id="business-detail-business-registerDate">
                    {getStringDateFormat(
                      business.registeredDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <span id="business-detail-category">
                    {t('business.BusinessTabPage.businessSection.modifiedDate')} &nbsp;
                  </span>
                  <span id="business-detail-business-modifiedDate">
                    {getStringDateFormat(
                      business.modifiedDate,
                      DateFormat.DATE,
                      StringDateFormat.DATE
                    )}
                  </span>
                  <span id="business-detail-category">
                    {language === Language.KO
                      ? business.businessBoard.ko.businessWriter
                      : business.businessBoard.en?.businessWriter}
                  </span>
                </div>
                <div className="view-box">
                  <span id="business-detail-view">
                    {t('business.BusinessTabPage.businessDetailSection.view')} &nbsp;
                  </span>
                  <span id="business-detail-business-view">{business.businessView}</span>
                </div>
              </div>
            )}

            <div className="business-detail-businessText-area">
              <div id="business-detail-businessText">
                {business ? (
                  language === Language.KO ? (
                    <BusinessDetailEditorSection
                      businessText={business.businessBoard.ko.businessText}
                    />
                  ) : business.businessBoard.en ? (
                    <BusinessDetailEditorSection
                      businessText={business.businessBoard.en.businessText}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="case-container">
            <div className="case-container-title">
              {language == Language.KO ? "관련 Case Study": "Related Case Study"}
            </div>
            {caseStudy ? (
              language == Language.KO ? (

                caseStudy?.map((caseObj, idx) => {
                  return (
                    <div
                    key={idx}
                    className="case-title" onClick={() => goCaseStudy(caseObj.caseUrl)}>- {caseObj.caseBoard.ko.caseTitle}</div>
                  )
                })
              ) : (
                caseStudy?.map((caseObj, idx) => {
                  return (
                    <div key={idx} className="case-title" onClick={() => goCaseStudy(caseObj.caseUrl)}>- {caseObj.caseBoard.en?.caseTitle}</div>
                  )
                })
              )
        ) : <div>N/A</div>}
        </div>
            <div className="business-detail-listbtn-area">
              <Link
                to="/business"
                state={{
                  limit: limit,
                  index: index,
                }}
              >
                <Button id="backToListBnt" data-testid="back-to-list-button">
                  {t('business.BusinessTabPage.businessDetailSection.listBtn')}
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <div className="v-wrap">
        <Footer />
      </div>
    </div>
  );
};

export default BusinessDetailSection;
