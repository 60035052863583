import './AwsCloudFormationPageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';

import AwsCloudFormationSection from './components/AwsCloudFormationSection';

const AwsCloudFormationPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsCloudFormationPageContainer">
        <AwsCloudFormationSection />
      </div>
    </Container>
  );
};

export default AwsCloudFormationPageContainer;
