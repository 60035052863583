import Enterprise01Img from '../../../../../src/assets/awsProductCard/awsConnect/AmazonConnect01.png';
import Enterprise02Img from '../../../../../src/assets/awsProductCard/awsConnect/AmazonConnect02.png';
import Enterprise03Img from '../../../../../src/assets/awsProductCard/awsConnect/AmazonConnect03.png';

import { ConnectContentData, AwsConnectContentLayout } from './AwsConnectModel';

export const connectContentDatasKo: ConnectContentData[] = [
  {
    img: '',
    layout: AwsConnectContentLayout.TXT_TXT,
    content: {
      title: [],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'LG CNS',
              options: {
                size: 'content-lg',
                bold: true,
                color: 'primary',
                //underline: 'underline',
              },
            },
            {
              text: ` has developed over 20 Amazon Connect practices in North America, Europe, and Asia Pacific for a global innovator in technology and consumer electronics.

              With Amazon Connect,  `,
              options: {
                size: 'content-lg',
                //bold: true,
              },
            },
            {
              text: 'LG CNS',
              options: {
                size: 'content-lg',
                bold: true,
                color: 'primary',
                //underline: 'underline',
              },
            },
            {
              text: ` delivers differentiated customer service at scale through AI-powered conversations, predictive insights, and omni-channel journeys across sales, support, service, and more. This helps enterprise customers reduce costs, enhance security, and identify pain points for their customers.`,
              options: {
                size: 'content-lg',
                //bold: true,
              },
            },
          ],
        },
      ],
    },
    content2: {
      title: [],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'LG CNS',
              options: {
                size: 'content-lg',
                bold: true,
                color: 'primary',
                //underline: 'underline',
              },
            },
            {
              text: '  provides contact center and chatbot services to customers in various industries such as Shinhan Card, Toss Bank, Kyobo Life, NH Nonghyup Capital, LG Electronics, Ministry of Health & Welfare, Shinhan Bank, Nu Skin Korea, LGU+, and SERVEONE. Based on the experience of building these services, LG CNS optimizes the customer value provided by Amazon Connect. ',
              options: {
                size: 'content-lg',
                //bold: true,
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise01Img,
    layout: AwsConnectContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Scalability',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'As Amazon Connect is cloud-based, there is no need for complex calculations or expensive investments in phone systems. You can set it up anywhere with a computer and an internet connection, without heavy equipment. You can easily expand your contact center as needed based on growth or seasonal fluctuations. Your agents can connect with customers efficiently from home or anywhere else. Wherever your agent sits with a computer is your contact center. Setting up Amazon Connect is easy and the navigation of the contact center menus is intuitive, even without programming knowledge. Setting up Amazon Connect takes days, not weeks or months. With the help of the Contact Flow Designer, you can create your first customer interaction flow in just a few minutes. ',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsConnectContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Flexible & Cost-optimized Contact Center',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'With Amazon Connect, you pay as you go based on usage, meaning you are charged per second. Thanks to the on-demand service, there are no long-term contracts or high upfront payments, and the number of customer service agents deployed does not affect billing. You can increase or decrease the size of your contact center as you like. The service fee is charged on a monthly usage-by-minute basis. There is no upfront investment; you just pay as you go. ',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsConnectContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Customers Experience on multiple channels',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'You can seamlessly connect to customers on multiple channels including call, SMS and social media on one platform. You are able to recognize your customer with all past interaction data. ',
            },
          ],
        },
      ],
    },
  },
];
