import './NewsDetailSection.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from '../../../layouts/Footer';
import NewsService from '../../../service/news/NewsService';
import { useParams } from 'react-router-dom';
import { News } from '../../../service/news/model/News';
import { ToastViewer } from '../../../pages/common/ToastViewer';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/modules/index';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import NewsDetailPageSkeleton from './NewsDetailPageSkeleton';
import { Language } from '../../../service/common/model/enum/Common.enum';

const NewsDetailSection: React.FC = () => {
  const newsService = new NewsService();
  const [news, setNews] = useState<News>();
  const { url } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.language.lang);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const limit = location.state
    ? (location.state as { limit: number }).limit
    : 5;
  const index = location.state
    ? (location.state as { index: number }).index
    : 0;

  useEffect(() => {
    if (url) {
      getNewsDetail(url);
    }
  }, []);

  useEffect(() => {
    if (
      language === Language.EN &&
      news &&
      (!news?.newsBoard.en ||
        news.newsBoard.en.newsTitle.trim() === '' ||
        news.newsBoard.en.newsText.trim() === '')
    ) {
      alert('There is no english content.');
      navigate('/news');
    }
  }, [language]);

  const getNewsDetail = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    const res: News = await newsService.getNewsDetail(url);
    setNews(res);
    dispatch(changeLoadingStatus(false));
  };

  return (
    <div className="NewsDetailSection" data-testid="testNewsDetailSection">
      <div className="h-wrap">
        {loading ? (
          <NewsDetailPageSkeleton />
        ) : (
          <Fragment>
            {news ? (
              <div className="news-detail-area">
                <div>
                  <p id="news-detail-category">
                    {language === Language.KO
                      ? news.newsBoard.ko.newsCategory
                      : news.newsBoard.en?.newsCategory}
                  </p>
                </div>
                <div>
                  <p id="news-detail-newsTitle">
                    {language === Language.KO
                      ? news.newsBoard.ko.newsTitle
                      : news.newsBoard.en?.newsTitle}
                  </p>
                </div>
                <div>
                  <p id="news-detail-newsSubtitle">
                    {language === Language.KO
                      ? news.newsBoard.ko.newsSubtitle
                      : news.newsBoard.en?.newsSubtitle}
                  </p>
                </div>
                <div id="news-detail-news-registerDate-view">
                  <p id="news-detail-news-registerDate">
                    {news.registeredDate.slice(0, 10)}
                  </p>
                  <p id="news-detail-news-view">
                    {t('news.newsDetailSection.view')}&nbsp;{news.newsView}
                  </p>
                </div>
                <div className="news-detail-newsText-area">
                  <div id="news-detail-newsText">
                    {news ? (
                      language === Language.KO ? (
                        <ToastViewer text={news.newsBoard.ko.newsText} />
                      ) : news.newsBoard.en ? (
                        <ToastViewer text={news.newsBoard.en.newsText} />
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className="news-detail-listbtn-area">
                  <Link
                    to="/news"
                    state={{
                      limit: limit,
                      index: index,
                    }}
                  >
                    <Button
                      id="backToListBnt"
                      data-testid="back-to-list-button"
                    >
                      {t('news.newsDetailSection.listBtn')}
                    </Button>
                  </Link>
                </div>
              </div>
            ) : null}
          </Fragment>
        )}
      </div>
      <div className="v-wrap">
        <Footer />
      </div>
    </div>
  );
};

export default NewsDetailSection;
