import './MainCaseList.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  State,
  Language,
} from '../../../service/common/model/enum/Common.enum';

import { getIsMobile } from '../../../service/utils/utils';

import MainCaseItem from './MainCaseItem';
import { Case } from '../../../service/case/model/Case';
import CaseService from '../../../service/case/CaseService';

import caseImageDefault from '../../../assets/case/caseImageDefault.png';

interface ArrowProps {
  className?: any;
  style?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const NextArrow: React.FC<ArrowProps> = (props: ArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
};

const PrevArrow: React.FC<ArrowProps> = (props: ArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
};

const MainCaseList: React.FC = () => {
  const { i18n, t } = useTranslation();
  const isMobile = getIsMobile();
  const innerWidth = window.innerWidth;
  const MOBILE_SIZE = 767;
  const TABLET_SIZE = 1023;
  const BROWSER_SIZE = 1200;
  const VISIBLE_CASE_MAIN = 4; //main에서 노출되는 총 case의 수
  let slidesToShow = 3; //default : 3 = full-window

  const caseService = new CaseService();
  const [caseList, setCaseList] = useState<Case[]>([
    {
      caseId: '0',
      caseUrl: 'default',
      caseOrderIdx: 0,
      caseState: State.ALL,
      registerAdmin: '관리자',
      registeredDate: '00000000000000',
      modifyAdmin: '관리자',
      modifiedDate: '00000000000000',
      caseBoard: {
        ko: {
          caseLang: Language.KO,
          caseTag: 'LG CNS',
          caseTitle: '준비중입니다.',
          caseThumbnail: caseImageDefault,
          caseDescription:
            '준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.준비중입니다.',
          caseDetail: {
            caseTitleBg: '',
            caseCompany: 'LG CNS',
            caseCompanyLogo: '',
            caseOutline: '',
            caseDetailSummary: {
              aboutCompany: [{ summaryInfo: '' }],
              theBenefit: [{ summaryInfo: '' }],
              whatWeDid: [{ summaryInfo: '' }],
            },
            caseDetailContentList: [
              {
                detailTitle: '',
                detailText: '',
              },
            ],
          },
        },
      },
    },
    {
      caseId: '1',
      caseUrl: 'default',
      caseOrderIdx: 1,
      caseState: State.ALL,
      registerAdmin: '관리자',
      registeredDate: '00000000000000',
      modifyAdmin: '관리자',
      modifiedDate: '00000000000000',
      caseBoard: {
        ko: {
          caseLang: Language.KO,
          caseTag: 'LG CNS',
          caseTitle: '준비중입니다.',
          caseThumbnail: caseImageDefault,
          caseDescription: '준비중입니다.',
          caseDetail: {
            caseTitleBg: '',
            caseCompany: 'LG CNS',
            caseCompanyLogo: '',
            caseOutline: '',
            caseDetailSummary: {
              aboutCompany: [{ summaryInfo: '' }],
              theBenefit: [{ summaryInfo: '' }],
              whatWeDid: [{ summaryInfo: '' }],
            },
            caseDetailContentList: [
              {
                detailTitle: '',
                detailText: '',
              },
            ],
          },
        },
      },
    },
    {
      caseId: '2',
      caseUrl: 'default',
      caseOrderIdx: 2,
      caseState: State.ALL,
      registerAdmin: '관리자',
      registeredDate: '00000000000000',
      modifyAdmin: '관리자',
      modifiedDate: '00000000000000',
      caseBoard: {
        ko: {
          caseLang: Language.KO,
          caseTag: 'LG CNS',
          caseTitle: '준비중입니다.',
          caseThumbnail: caseImageDefault,
          caseDescription: '준비중입니다.',
          caseDetail: {
            caseTitleBg: '',
            caseCompany: 'LG CNS',
            caseCompanyLogo: '',
            caseOutline: '',
            caseDetailSummary: {
              aboutCompany: [{ summaryInfo: '' }],
              theBenefit: [{ summaryInfo: '' }],
              whatWeDid: [{ summaryInfo: '' }],
            },
            caseDetailContentList: [
              {
                detailTitle: '',
                detailText: '',
              },
            ],
          },
        },
      },
    },
    {
      caseId: '3',
      caseUrl: 'default',
      caseOrderIdx: 3,
      caseState: State.ALL,
      registerAdmin: '관리자',
      registeredDate: '00000000000000',
      modifyAdmin: '관리자',
      modifiedDate: '00000000000000',
      caseBoard: {
        ko: {
          caseLang: Language.KO,
          caseTag: 'LG CNS',
          caseTitle: '준비중입니다.',
          caseThumbnail: caseImageDefault,
          caseDescription: '준비중입니다.',
          caseDetail: {
            caseTitleBg: '',
            caseCompany: 'LG CNS',
            caseCompanyLogo: '',
            caseOutline: '',
            caseDetailSummary: {
              aboutCompany: [{ summaryInfo: '' }],
              theBenefit: [{ summaryInfo: '' }],
              whatWeDid: [{ summaryInfo: '' }],
            },
            caseDetailContentList: [
              {
                detailTitle: '',
                detailText: '',
              },
            ],
          },
        },
      },
    },
  ]);

  //화면 사이즈별로 노출되는 case study slide 수 지정
  if (innerWidth <= MOBILE_SIZE) {
    slidesToShow = 1;
  } else if (innerWidth <= TABLET_SIZE) {
    slidesToShow = 3;
  }

  //react-slick settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipe: isMobile,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: BROWSER_SIZE,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: TABLET_SIZE,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: MOBILE_SIZE,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getAllCaseList();
  }, [i18n.language]);

  const getAllCaseList = async () => {
    const response = await caseService.getCaseList();
    if (response && response.length > 0) {
      setCaseList(
        response
          .filter((caseItem) => i18n.language in caseItem.caseBoard)
          .slice(0, VISIBLE_CASE_MAIN)
          .filter(
            (caseItem) =>
              caseItem.caseState == State.ALL &&
              caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en']
                ?.caseTitle &&
              caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en']
                ?.caseThumbnail
          )
      );
    }
  };
  useEffect(() => {
    const firstHoverList = document.getElementsByClassName(
      'main-case-list-hover-container'
    );
    firstHoverList[0].classList.add('hover');
    firstHoverList[0].firstElementChild?.classList.add('hover');
    return () => {
      //firstHoverList[0].classList.remove('hover');
      //firstHoverList[0].firstElementChild?.classList.remove('hover');
    };
  }, []);

  return (
    <div className="fullpage-slide main-case-list">
      <div className="main-case-list-container">
        <span
          className="main-case-list-title"
          data-testid="main-case-list-title"
        >
          {t('main.MainCaseList.title')}
        </span>
        <div className="main-case-list-case-container">
          <ul className="main-case-list-hover-container">
            {caseList.map((caseItem) => {
              const caseBoard =
                caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en'];

              return (
                <MainCaseItem
                  key={caseItem.caseId}
                  caseData={caseItem}
                  caseBoard={caseBoard}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainCaseList;
