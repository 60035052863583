import './NewsPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import NewsSection from './components/NewsSection';

const NewsPageContainer: React.FC = () => {
  return (
    <Container id="NewsPageContainer" fluid={true} style={{ padding: '0' }}>
      <div id="NewsPageContainer">
        <NewsSection />
      </div>
    </Container>
  );
};

export default NewsPageContainer;
