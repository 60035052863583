import './AboutSection.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

const About01: React.FC = () => {
  const { i18n, t } = useTranslation();

  return (
    <div id="about-slide1" className="fullpage-slide about-slide1">
      <h1 className="title1">
        <span className="color-primary">{t('about.About01.intro.lgcns')}</span>
        {t('about.About01.intro.is')}
        <br />
        {t('about.About01.intro.content1')}
      </h1>
      <p className="txt-con">
        {t('about.About01.intro.content2')}
        <br />
        {t('about.About01.intro.content3')}
      </p>
      <div className="aws-wrap">
        <div className="aws-img">
          <ul className={'aws-list' + (i18n.language === 'ko' ? '' : '-en')}>
            <li>{t('about.About01.awsList.content1')}</li>
            <li>{t('about.About01.awsList.content2')}</li>
            <li>{t('about.About01.awsList.content3')}</li>
            <li>{t('about.About01.awsList.content4')}</li>
          </ul>
          <ul className={'aws-list' + (i18n.language === 'ko' ? '' : '-en')}>
            <li>{t('about.About01.awsList.content5')}</li>
            <li>{t('about.About01.awsList.content6')}</li>
            <li>{t('about.About01.awsList.content7')}</li>
            <li>{t('about.About01.awsList.content8')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About01;
