import './AwsDirectConnectSection.scss';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import AwsDirectConnectContentArea from './AwsDirectConnectContentArea';
import { Language } from '../../../../service/common/model/enum/Common.enum';
import { DirectConnectContentData } from '../../../../service/data/awsProductCard/awsConnect/AwsDirectConnectModel';
import { directConnectContentDatasKo } from '../../../../service/data/awsProductCard/awsConnect/AwsDirectConnectDataKo';
import { directConnectContentDatasEn } from '../../../../service/data/awsProductCard/awsConnect/AwsDirectConnectDataEn';

const awsDirectConnectSection: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const [awsDirectConnectContents, setAwsDirectConnectContents] = useState<
    DirectConnectContentData[]
  >(
    i18n.language === Language.KO
      ? directConnectContentDatasKo
      : directConnectContentDatasEn
  );

  useEffect(() => {
    setAwsDirectConnectContents(
      i18n.language === Language.KO
        ? directConnectContentDatasKo
        : directConnectContentDatasEn
    );
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  return (
    <div id="AwsDirectConnectSection" data-testid="testAwsDirectConnectSection">
      <div className="eks-anywhere fullpage-slide">
        <div className="header-top" data-testid="header-top">
          <div className="top-tit detail-container">
            <h2>Amazon Direct Connect</h2>
          </div>
        </div>
        <div className="eks-anywhere-body">
          <div className="eks-anywhere-title-cont">
            <div className="title-txt-box">
              <span className="title-lg">{'Amazon Direct Connect'}</span>
            </div>
          </div>
          {awsDirectConnectContents.map((content, idx) => {
            return (
              <React.Fragment key={idx}>
                <AwsDirectConnectContentArea content={content} />
              </React.Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default awsDirectConnectSection;
