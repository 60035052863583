import './AwsCloudFrontPageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';

import AwsCloudFrontSection from './components/AwsCloudFrontSection';

const AwsCloudFrontPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsCloudFrontPageContainer">
        <AwsCloudFrontSection />
      </div>
    </Container>
  );
};

export default AwsCloudFrontPageContainer;
