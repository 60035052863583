import './BusinessDetailEditorSection.scss';
import Prism from 'prismjs';
import '../../../../layouts/prism.scss'
import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { TinyMCE } from 'tinymce';
declare const tinymce: TinyMCE;
require('prismjs-components-importer/cjs/prism-php');
require('prismjs-components-importer/cjs/prism-ruby');
require('prismjs-components-importer/cjs/prism-python');
require('prismjs-components-importer/cjs/prism-java');
require('prismjs-components-importer/cjs/prism-c');
require('prismjs-components-importer/cjs/prism-cpp');
require('prismjs-components-importer/cjs/prism-csharp');
require('prismjs-components-importer/cjs/prism-git');
require('prismjs-components-importer/cjs/prism-javadoc');
require('prismjs-components-importer/cjs/prism-sql');
require('prismjs-components-importer/cjs/prism-yaml');
interface Props {
  businessText: string;
}

const BusinessDetailEditorSection: React.FC<Props> = (props: Props) => {
  return (
    <Editor
      initialValue={props.businessText}
      apiKey="gmmjng74ulc7k28dufw17avjz1eboa5744k1jacji2q6xard"
      inline={true}
      init={{
        skin: false,
        content_css: '/.BusinessDetailEditorSection.scss',
        readonly: true,
        toolbar: false,
        menubar: false,
        statusbar: false,
        object_resizing: false,
        init_instance_callback: () => {
          tinymce?.activeEditor
            ?.getBody()
            .setAttribute('contenteditable', 'false');
          Prism.highlightAll();
        },
        invalid_elements: 'iframe'
      }}
    />
  );
};

export default BusinessDetailEditorSection;
