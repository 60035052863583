import React from 'react';
import AboutCaseArea from './AboutCaseArea';

const About03: React.FC = () => {
  return (
    <>
      <div
        id="about-slide3"
        className="fullpage-slide"
        data-testid="testAbout03Section"
      >
        <div className="about-cases">
          <div className="about-cases-body">
            <div className="about-cases-case-cont">
              <AboutCaseArea startIdx={2} offset={4} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About03;
