import React from 'react';
import { Container, Row } from 'react-bootstrap';

import BusinessBoardEditForm from './components/BusinessBoardEditForm';

const BusinessBoardEditContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="BusinessBoardEditContainer">
        <BusinessBoardEditForm />
      </div>
    </Container>
  );
};

export default BusinessBoardEditContainer;
