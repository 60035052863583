export enum Subject {
  BUSINESS = 'business',
  CASE = 'case',
  NEWS = 'news',
  BLOG = 'blog',
  MEMBER = 'member',
  S3 = 's3',
}
export enum ServicePort {
  BUSINESS = 3003,
  CASE = 3003,
  NEWS = 3003,
  BLOG = 3003,
  MEMBER = 3003,
  S3 = 3003,
}
