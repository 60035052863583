import './BusinessDetailSection.scss';

import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import {
  Language,
  DateFormat,
  StringDateFormat,
} from '../../../../service/common/model/enum/Common.enum';
import { Business } from '../../../../service/business/model/Business';
import BusinessService from '../../../../service/business/BusinessService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import { getStringDateFormat } from '../../../../service/utils/utils';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import BusinessDetailEditorSection from './BusinessDetailEditorSection';
import CustomRadioBtn from '../../../common/CustomRadioBtn';
import CaseService from '../../../../service/case/CaseService';
import { Case } from '../../../../service/case/model/Case';

const BusinessDetailSection = () => {
  const { url } = useParams();
  const { state } = useLocation() as unknown as { state: boolean }; // true : 임시저장 데이터 조회, false|null : 등록 데이터 조회
  const navigate = useNavigate();

  const [business, setBusiness] = useState<Business>();
  const [businessLang, setBusinessLang] = useState<Language>(Language.KO);
  const [caseStudy, setCaseStudy] = useState<Case[]>();
  const businessService = new BusinessService();
  const caseService = new CaseService();
  const language = useSelector((state: RootState) => state.language.lang);
  const dispatch = useDispatch();

  useEffect(() => {
    if (url) {
      state ? getTempBusinessDetail(url) : getBusinessDetail(url);
    } else {
      navigate('/admin/business');
    }
  }, []);

  const getBusinessDetail = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await businessService.getBusinessDetail(url);
    const caseRes: Case[] = await caseService.getCaseList();

    if (response) {
      setBusiness(response);
      const caseList = caseRes?.filter(caseObj => response.businessBoard.ko.caseStudyUrl?.includes(caseObj.caseUrl))
      if(caseList && caseList.length !== 0) {
        setCaseStudy(caseList)
      }
    } else {
      navigate('/business');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const getTempBusinessDetail = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await businessService.getTempBusinessDetail(url);
    const caseRes: Case[] = await caseService.getCaseList();

    if (response) {
      setBusiness(response);
      const caseList = caseRes?.filter(caseObj => response.businessBoard.ko.caseStudyUrl?.includes(caseObj.caseUrl))
      if(caseList && caseList.length !== 0) {
        setCaseStudy(caseList)
      }
    } else {
      navigate('/business');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const modifyBtnHandler = () => {
    state
      ? navigate('/admin/business/regist', { state: url })
      : navigate('/admin/business/edit/' + url);
  };

  const deleteBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(changeProgressStatus(50));
    if (
      business &&
      confirm('삭제된 business는 복구 할 수 없습니다.\nbusiness를 삭제하시겠습니까?')
    ) {
      businessService.deleteBusiness(business).then((response) => {

        if (response) {
          alert('business가 삭제되었습니다.');
          navigate('/admin/business');
        } else {
          alert('business 삭제 중 오류가 발생하였습니다.');
        }
      });
    }
    dispatch(changeProgressStatus(100));
  };

  const businessListBtnHandler = () => {
    navigate('/admin/business');
  };

  const handleCustomRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (value == Language.KO) {
      setBusinessLang(Language.KO);
    } else if (value == Language.EN) {
      setBusinessLang(Language.EN);
    }
  };

  const goCaseStudy = (caseUrl: string) => {
    navigate('/admin/case/' + caseUrl);
  }

  return (
    <div id="AdminBusinessDetailSection">
      <ProgressBar />
      {business ? (
        <CustomRadioBtn
          radioBtnValuesAndNames={[
            {value: Language.KO, name: '한국어', disabled: false},
            {value: Language.EN, name: 'English', disabled: false},
          ]}
          handleRadioBtn={handleCustomRadioChange}
          checkedCondition={businessLang}
        />
      ): null}
      {business ? (
        businessLang == Language.KO ? (
          <>
            <div className="detail-header">
              <div className="title" data-testid="title-text">
                {business.businessBoard.ko.businessTitle}
              </div>
              <div className="sub-title">{business.businessBoard.ko.businessSubtitle}</div>
              <div className="category">{business.businessBoard.ko.businessWriter}</div>
              <div className="title-info">
                <div className="register-admin">
                  등록 : {business.registerAdmin}
                </div>
                <div className="registered-date">
                  {getStringDateFormat(
                    business.registeredDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="modify-admin">수정 : {business.modifyAdmin}</div>
                <div className="modified-date">
                  {getStringDateFormat(
                    business.modifiedDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="view">조회수 : {business.businessView}</div>
                <div className="state">공개범위 : {business.businessState}</div>
              </div>
            </div>
          </>
        ) : business.businessBoard.en ? (
          <>
            <div className="detail-header">
              <div className="title" data-testid="title-text">
                {business.businessBoard.en.businessTitle}
              </div>
              <div className="sub-title">{business.businessBoard.en.businessSubtitle}</div>
              <div className="category">{business.businessBoard.en.businessWriter}</div>
              <div className="title-info">
                <div className="register-admin">
                  등록 : {business.registerAdmin}
                </div>
                <div className="registered-date">
                  {getStringDateFormat(
                    business.registeredDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="modify-admin">수정 : {business.modifyAdmin}</div>
                <div className="modified-date">
                  {getStringDateFormat(
                    business.modifiedDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="view">조회수 : {business.businessView}</div>
                <div className="state">공개범위 : {business.businessState}</div>
              </div>
            </div>
          </>
        ) : null
      ) : null}
      <hr />
      <div className="detail-body" data-testid="body-text">
        {business ? (
          businessLang == Language.KO ? (
            <BusinessDetailEditorSection businessText={business.businessBoard.ko.businessText} />
          ) : business.businessBoard.en ? (
            <BusinessDetailEditorSection businessText={business.businessBoard.en.businessText} />
          ) : null
        ) : null}
      </div>
      <hr />
      <div className="case-container">
        <div className="case-container-title">
          관련 Case Study 항목
        </div>
        {caseStudy ? (
          businessLang == Language.KO ? (
            caseStudy?.map((caseObj, idx) => {
              return (
                <div
                key={idx}
                className="case-title" onClick={() => goCaseStudy(caseObj.caseUrl)}>- {caseObj.caseBoard.ko.caseTitle}(url:{caseObj.caseUrl})</div>
              )
            })
          ) : (
            caseStudy?.map((caseObj, idx) => {
              return (
                <div key={idx} className="case-title" onClick={() => goCaseStudy(caseObj.caseUrl)}>- {caseObj.caseBoard.en?.caseTitle}(url:{caseObj.caseUrl})</div>
              )
            })
          )
        ) : <div className="case-na">N/A</div>}
        </div>
      <div className="btn-container">
        <Button
          className="btn btn-light"
          data-testid="modify-button"
          onClick={() => modifyBtnHandler()}
        >
          수정
        </Button>
        <Button
          className="btn btn-light"
          data-testid="delete-button"
          onClick={deleteBtnHandler}
        >
          삭제
        </Button>
        <Button
          className="btn btn-secondary"
          data-testid="list-button"
          onClick={() => businessListBtnHandler()}
        >
          목록
        </Button>
      </div>
    </div>
  );
};

export default BusinessDetailSection;
