
import Enterprise01Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds01.png'
import Enterprise02Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds02.png'
import Enterprise03Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds03.png'
import Enterprise04Img from '../../../../../src/assets/awsProductCard/awsRds/AmazonRds04.png'


import {
  RdsContentData,
  AwsRdsContentLayout,
} from './AwsRdsModel';

export const RdsContentDatasEn: RdsContentData[] = [
  {
    img: Enterprise01Img,
    layout: AwsRdsContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'LG CNS offers a variety of relational database services through Amazon RDS.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Amazon RDS is a managed service that provides relational databases. Amazon Aurora, PostgreSQL, MySQL, MariaDB, Oracle, MS SQL, and Db2 engines are supported. It replaces complex database management such as OS and database software installation and patching, data backup and recovery, scaling, and provides easy migration. By easily configuring databases, customers can focus on application development. LG CNS utilizes Amazon RDS to support flexibly scalable and cost-effective relational database deployment and migration.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsRdsContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'High availability and scalability ensure reliable database operations.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [

            {
              text: 'Point-in-time recovery is possible through automatic backup, and manual snapshots can be created and stored for long periods of time. Computing can be scaled up in minutes by changing instance classes, additional storage can be provisioned without disruption, and read throughput can be scale out by adding read replicas. The availability and durability of the database can be improved by using multi-AZ deployments that synchronously replicate data to other available zones.',
            },
          ],
        },

      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsRdsContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Strong security is guaranteed for all data.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon RDS has obtained various security certifications and provides strong security through network security, access control, and encryption. Databases can be built on a separate network so that they can be accessed only by the server you want, or IP firewall can be built on each database. In addition, AWS IAM provides strict access control. All events and API calls can be stored on Amazon CloudTrail. Amazon KMS enables robust encryption of storage as well as backups, replicas, and snapshots.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise04Img,
    layout: AwsRdsContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Manage your costs efficiently by paying only for the amount of resources you use.',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'Amazon RDS pays only for what you use with no minimum fees. Instance cost, storage capacity, backup capacity, and network cost are combined into a monthly cost. Instance costs can significantly be reduced by reserving instances for one-year or three-year terms. If you have existing licenses, you can use them with RDS at no additional cost. Costs vary by region.',
            },
          ],
        },
      ],
    },
  }
];
