import React from 'react';
import { getIsMobile } from '../../../service/utils/utils';
import { useTranslation } from 'react-i18next';

const BusinessJourney = () => {
  const isMobile = getIsMobile();
  const [t] = useTranslation();

  return (
    <div id="BusinessJourney" className="business-journey">
      <h1 className="title1">
        <span className="color-primary">
          {t('business.JourneyMessageBox.title.first')}
        </span>
        {t('business.JourneyMessageBox.title.second')}
      </h1>
      <p className="txt-con">{t('business.JourneyMessageBox.txt-con')}</p>
    </div>
  );
};

export default BusinessJourney;
