import './Header.scss';
import logo from '../assets/common/headerLogo.png';
import whiteLogo from '../assets/common/headerWhiteLogo.png';
import logoMiddle from '../assets/common/headerLogoMiddle.svg';
import underbarIcon from '../assets/common/headerMenuUnderbarIcon.svg';

import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import headerMenuMobile from '../assets/common/headerMenuMobile.png';
import headerMenuCloseMobile from '../assets/common/headerMenuCloseMobile.png';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/modules';
import { changeLang } from '../redux/actions/language';
import { Language } from '../service/common/model/enum/Common.enum';

function Header() {
  const body = document.querySelector('body') as HTMLElement;
  const { i18n } = useTranslation();

  const [menuToggle, setMenuToggle] = useState<boolean>(false);
  const [lang, setLang] = useState<Language>(
    i18n.language === 'ko-KR' ? Language.KO : Language.EN //i18n.language
  );

  const dispatch = useDispatch();
  const langRedux = useSelector((state: RootState) => state.language.lang);

  const mobileMenuToggle = () => {
    if (menuToggle === false) {
      // mobile -> pc
      body.style.position = 'fixed';
    } else {
      // pc -> mobile
      body.style.removeProperty('position');
    }
    setMenuToggle(!menuToggle);
  };

  //메뉴 이동(메인)시 헤더 세팅
  const goToMain = () => {
    const href = location.href;
    const last = href.charAt(href.length - 1);
    if (last !== '/') {
      document.getElementsByTagName('header')[0].className = 'fixed';
      document.getElementsByClassName('gnb')[0].className = 'gnb fixed';
    }
  };

  //메뉴 이동(타 메뉴)시 헤더 세팅
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  //새로고침 시 헤더 세팅
  window.onload = function () {
    const href = location.href;
    const last = href.charAt(href.length - 1);
    const split = href.split('/');

    // UI 변경으로 주석
    // if (last === '/' || split[split.length - 1] === 'awsproduct') {
    //   document.getElementsByTagName('header')[0].className = 'fixed';
    //   document.getElementsByClassName('gnb')[0].className = 'gnb fixed';
    // } else {
    document.getElementsByTagName('header')[0].className = '';
    document.getElementsByClassName('gnb')[0].className = 'gnb';
    // }
  };

  const handleContactBtn = () => {
    window.open('https://www.lgcns.com/contact-us/');
  };

  // 스크롤 시, 헤더 Css 변경
  const [scrollPosition, setScrollPosition] = useState(0);
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  }, []);

  const changeLanguage = (event: SelectChangeEvent) => {
    dispatch(
      changeLang(event.target.value === Language.KO ? Language.KO : Language.EN)
    );
  };

  useEffect(() => {
    i18n.changeLanguage(langRedux);
    setLang(langRedux);
  }, [langRedux]);

  return (
    <>
      {/* for mobile */}
      <div className={menuToggle ? 'm-open-menu show' : 'menu-hidden'}>
        <div className="container">
          <div className="top">
            <div className="logo">
              <Link to="/" onClick={mobileMenuToggle}>
                <img src={logo} />
              </Link>
            </div>
            <button className="menu-close" onClick={mobileMenuToggle}>
              <img src={headerMenuCloseMobile} />
            </button>
          </div>
          <ul>
            <li>
              <NavLink to="/about" onClick={mobileMenuToggle}>
                <span>About</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/business" onClick={mobileMenuToggle}>
                <span>Business</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/awsproduct" onClick={mobileMenuToggle}>
                <span>AWS Product</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/case" onClick={mobileMenuToggle}>
                <span>Case Study</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/news" onClick={mobileMenuToggle}>
                <span>News</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" onClick={mobileMenuToggle}>
                <span>Tech Blog</span>
              </NavLink>
            </li>
            <li>
              <a onClick={handleContactBtn}>
                <span>Contact Us</span>
              </a>
            </li>
            <li className="m-shortcut">
              <a
                href="https://lgcns.co.kr"
                target="_blank"
                rel="noopener noreferrer"
                className="shortcut-link"
              >
                <span>LG CNS 바로가기</span>
              </a>
            </li>
          </ul>
          <div className="m-underBtn">
            <div className="m-langBtn">
              <FormControl>
                <Select
                  value={lang}
                  onChange={changeLanguage}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="ko">한국어</MenuItem>
                  <MenuItem value="en">ENG</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <section className="m-header">
        <div className="logo">
          <Link to="/" onClick={scrollToTop}>
            <img src={logo} />
          </Link>
        </div>
        <button className="menu-open" onClick={mobileMenuToggle}>
          <img src={headerMenuMobile} />
        </button>
      </section>
      {/* for mobile */}
      {/* for Desktop */}
      <header className="fixed">
        <div className="header">
          <div className="logo">
            <Link to="/" onClick={goToMain}>
              <img src={logo} />
            </Link>
          </div>
          <div className="gnb fixed">
            <div className="gnb-item">
              <NavLink to="/about" onClick={scrollToTop}>
                <span>About</span>
                <img src={underbarIcon} />
              </NavLink>
            </div>
            <div className="gnb-item">
              <NavLink to="/business" onClick={scrollToTop}>
                <span>Business</span>
                <img src={underbarIcon} />
              </NavLink>
            </div>
            <div className="gnb-item">
              <NavLink to="/awsproduct" onClick={scrollToTop}>
                <span>AWS Product</span>
                <img src={underbarIcon} />
              </NavLink>
            </div>
            <div className="gnb-item">
              <NavLink to="/case" onClick={scrollToTop}>
                <span>Case Study</span>
                <img src={underbarIcon} />
              </NavLink>
            </div>
            <div className="gnb-item">
              <NavLink to="/news" onClick={scrollToTop}>
                <span>News</span>
                <img src={underbarIcon} />
              </NavLink>
            </div>
            <div className="gnb-item">
              <NavLink to="/blog" onClick={scrollToTop}>
                <span>Tech Blog</span>
                <img src={underbarIcon} />
              </NavLink>
            </div>

            <div className="gnb-item">
              <a onClick={handleContactBtn}>
                <span>Contact Us</span>
              </a>
            </div>

            <div className="gnb-item langBtn">
              <FormControl>
                <Select
                  value={lang}
                  onChange={changeLanguage}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={Language.KO}>한국어</MenuItem>
                  <MenuItem value={Language.EN}>ENG</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </header>
      {/* //for Desktop */}
    </>
  );
}

export default Header;
