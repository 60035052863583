import './CaseBoardRegistContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import CaseBoardRegistForm from './components/CaseBoardRegistForm';

const CaseBoardRegistContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="CaseBoardRegistContainer">
        <CaseBoardRegistForm />
      </div>
    </Container>
  );
};

export default CaseBoardRegistContainer;
