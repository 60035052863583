import {
  AwsProductCode,
  AwsProductKor,
} from '../../common/model/enum/Common.enum';
import { AwsProductData, AwsProductListData } from './AwsProductModel';

export const awsDefaultProductsKo: AwsProductData = {
  productCode: AwsProductCode.CMCT,
  productName: AwsProductKor.KCMCT,
};

export const awsProductsKo: AwsProductListData[] = [
  {
    productCode: AwsProductCode.CMCT,
    productName: AwsProductKor.KCMCT,
    service: [
      {
        serviceCode: 'amazon-ec2',
        serviceName: 'Amazon EC2',
        serviceSummary: '클라우드의 가상 서버',
      },
      {
        serviceCode: 'amazon-elastic-kubernetes',
        serviceName: 'Amazon Elastic Kubernetes Service',
        serviceSummary: 'Kubernetes를 실행하는 가장 신뢰성 높은 방법',
      },
      {
        serviceCode: 'amazon-ec2-auto-scaling',
        serviceName: 'Amazon EC2 Auto Scaling',
        serviceSummary: '수요에 맞춰 컴퓨팅 용량 조절',
      },
      {
        serviceCode: 'amazon-lambda',
        serviceUrl: 'amazon-lambda',
        serviceName: 'Amazon Lambda',
        serviceSummary: '서버에 대한 걱정 없이 코드 실행',
      },
    ],
  },
  {
    productCode: AwsProductCode.ST,
    productName: AwsProductKor.KST,
    service: [
      {
        serviceCode: 'amazon-ebs',
        serviceName: 'Amazon Elastic Block Store (EBS)',
        serviceSummary: 'EC2 블록 스토리지 볼륨',
      },
      {
        serviceCode: 'amazon-s3-glacier',
        serviceName: 'Amazon S3 Glacier',
        serviceSummary: '클라우드상의 저렴한 아카이브 스토리지',
      },
      {
        serviceCode: 'amazon-s3',
        serviceName: 'Amazon Simple Storage Service (S3)',
        serviceSummary: '클라우드의 S3(Storage Service) 확장 가능한 스토리지',
      },
    ],
  },
  {
    productCode: AwsProductCode.DB,
    productName: AwsProductKor.KDB,
    service: [
      {
        serviceCode: 'amazon-elasticache',
        serviceName: 'Amazon ElastiCache',
        serviceSummary: '인 메모리 캐싱 서비스',
      },
      {
        serviceCode: 'amazon-dynamo-db',
        serviceName: 'Amazon DynamoDB',
        serviceSummary: '관리형 NoSQL 데이터베이스',
      },
      {
        serviceCode: 'amazon-rds',
        serviceName: 'Amazon RDS',
        serviceUrl: 'amazon-rds',
        serviceSummary:
          'MySQL, PostgreSQL, Oracle, SQL Server 및 MariaDB를 위한 관리형의 관계형 데이터베이스 서비스',
      },
      {
        serviceCode: 'amazon-redshift',
        serviceUrl: 'amazon-redshift',
        serviceName: 'Amazon Redshift',
        serviceSummary:
          '클라우드 데이터 웨어하우징을 위한 최고의 가격 대비 성능',
      },
    ],
  },
  {
    productCode: AwsProductCode.NW,
    productName: AwsProductKor.KNW,
    service: [
      {
        serviceCode: 'amazon-cloudfront',
        serviceName: 'Amazon CloudFront',
        serviceUrl: 'amazon-cloudFront',
        serviceSummary: '글로벌 콘텐츠 전송 네트워크',
      },
      {
        serviceCode: 'amazon-route-53',
        serviceName: 'Amazon Route 53',
        serviceSummary: '53 확장 가능한 도메인 이름 시스템 (DNS)',
      },
      {
        serviceCode: 'amazon-elb',
        serviceName: 'Amazon Elastic Load Balancing (ELB)',
        serviceSummary: '수신 트래픽을 여러 대상에 걸쳐 분산',
      },
      {
        serviceCode: 'amazon-dx',
        serviceUrl: 'amazon-dx',
        serviceName: 'Amazon-Direct-Connect(DX)',
        serviceSummary:
          'AWS 자원에 접근하기 위한 안정적이고 비용 효율적인 전용회선 서비스',
      },
    ],
  },
  {
    productCode: AwsProductCode.MG,
    productName: AwsProductKor.KMG,
    service: [
      {
        serviceCode: 'amazon-control-tower',
        serviceName: 'Amazon Control Tower',
        serviceSummary: '안전한 다중 계정 AWS 환경을 설정하고 통제',
      },
      {
        serviceCode: 'amazon-cloudformation',
        serviceUrl: 'amazon-cloudformation',
        serviceName: 'Amazon CloudFormation',
        serviceSummary:
          '코드를 사용하여 AWS 리소스를 보다 효율적이고 안정적으로 생성하고 관리',
      }
    ],
  },
  {
    productCode: AwsProductCode.CC,
    productName: AwsProductKor.KCC,
    service: [
      {
        serviceCode: 'amazon-connect',
        serviceUrl: 'amazon-connect',
        serviceName: 'Amazon Connect',
        serviceSummary:
          '클라우드 데이터 웨어하우징을 위한 최고의 가격 대비 성능',
      },
    ],
  },
  {
    productCode: AwsProductCode.SIC,
    productName: AwsProductKor.KSIC,
    service: [
      {
        serviceCode: 'amazon-iam',
        serviceName: 'AWS Identity and Access Management (IAM)',
        serviceSummary:
          'AWS 서비스 및 리소스에 대한 액세스와 ID를 안전하게 관리',
      },
    ],
  },
];
