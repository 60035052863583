import React from 'react';
import {
  EksContentData,
  EksContentLayout,
} from '../../../service/data/eksAnywhere/EksAnywhereModel';
import { getIsMobile } from '../../../service/utils/utils';
import EksAnywhereContentTextItem from './EksAnywhereContentTextItem';

interface Props {
  content: EksContentData;
}

const EksAnywhereContentArea: React.FC<Props> = (props: Props) => {
  const { content } = props;
  const isMobile = getIsMobile();

  const renderContents = () => {
    if (content.layout === EksContentLayout.TXT_IMG) {
      return (
        <>
          <EksAnywhereContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      );
    } else if (content.layout === EksContentLayout.IMG_TXT) {
      //mobile은 TXT-IMG 배치로 고정
      return isMobile ? (
        <>
          <EksAnywhereContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      ) : (
        <>
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
          <EksAnywhereContentTextItem content={content.content} />
        </>
      );
    } else if (content.layout === EksContentLayout.TXT_TXT) {
      return (
        <>
          <EksAnywhereContentTextItem content={content.content} />
          <EksAnywhereContentTextItem content={content.content2} />
        </>
      );
    }
  };

  return (
    <div
      className="eks-anywhere-content-cont"
      data-testid="testEksAnywhereContentArea"
    >
      {renderContents()}
    </div>
  );
};

export default EksAnywhereContentArea;
