import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { eksValueDatasKo } from '../../../service/data/eksAnywhere/EksAnywhereDataKo';
import { eksValueDatasEn } from '../../../service/data/eksAnywhere/EksAnywhereDataEn';
import { EksValueData } from '../../../service/data/eksAnywhere/EksAnywhereModel';
import EksAnywhereValueItem from './EksAnywhereValueItem';
import { Language } from '../../../service/common/model/enum/Common.enum';

const EksAnywhereValueArea: React.FC = () => {
  const { i18n, t } = useTranslation();

  const [eksAnywhereValues, setEksAnywhereValues] = useState<EksValueData[]>(
    i18n.language === Language.KO ? eksValueDatasKo : eksValueDatasEn
  );

  useEffect(() => {
    setEksAnywhereValues(
      i18n.language === Language.KO ? eksValueDatasKo : eksValueDatasEn
    );
  }, [i18n.language]);

  return (
    <div className="eks-anywhere-value-cont">
      <div className="value-txt-box">
        <span className="value-title-txt">
          {t('eksAnywhere.EksAnywhereValueArea.title')}
        </span>
      </div>
      <div className="value-contents">
        <div className="value-grid-area">
          {eksAnywhereValues.map((value, idx) => {
            return <EksAnywhereValueItem key={idx} value={value} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default EksAnywhereValueArea;
