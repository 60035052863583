import { Language } from '../../service/common/model/enum/Common.enum';

export const CHANGE_LANG = 'language/CHANGE_LANG' as const;

export const changeLang = (lang: Language) => ({
  type: CHANGE_LANG,
  lang: lang,
});

export type LanguageAction = ReturnType<typeof changeLang>;
