import './EksAnywherePageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';

import EksAnywhereSection from './components/EksAnywhereSection';

const EksAnywhereContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="EksAnywhereContainer">
        <EksAnywhereSection />
      </div>
    </Container>
  );
};

export default EksAnywhereContainer;
