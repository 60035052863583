import './CaseSection.scss';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Case } from '../../../service/case/model/Case';
import CaseService from '../../../service/case/CaseService';
import Footer from '../../../layouts/Footer';
import caseImageDefault from '../../../assets/case/caseImageDefault.png';
import CaseSectionBoxCaseItem from './CaseSectionBoxCaseItem';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  State,
  Language,
} from '../../../service/common/model/enum/Common.enum';
import { RootState } from '../../../redux/modules';
import { changeLoadingStatus } from '../../../redux/actions/loading';

import CaseSectionSkeletonPC from './CaseSectionSkeletonPC';
import CaseSectionSkeletonMobile from './CaseSectionSkeletonMobile';

const LIMIT_COUNT = 4;

const CaseSection: React.FC = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const caseService = new CaseService();
  const { i18n, t } = useTranslation();
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const caseRefs = useRef<any>([]);
  const routeIndex = location.state
    ? (location.state as { index: number }).index
    : -1;

  const innerWidth = window.innerWidth;
  let slidesToShow = 3; //default : 3 = full-window

  const [limit, setLimit] = useState(
    location.state ? (location.state as { limit: number }).limit : LIMIT_COUNT
  );

  const [caseList, setCaseList] = useState<Case[]>([
    {
      caseId: '0',
      caseUrl: 'default',
      caseOrderIdx: 0,
      caseState: State.ALL,
      registerAdmin: '관리자',
      registeredDate: '00000000000000',
      modifyAdmin: '관리자',
      modifiedDate: '00000000000000',
      caseBoard: {
        ko: {
          caseLang: Language.KO,
          caseTag: 'LG CNS',
          caseTitle: '클라우드네이티브 Launch 센터',
          caseThumbnail: caseImageDefault,
          caseDescription: '컨텐츠 준비 중입니다.',
          caseDetail: {
            caseTitleBg: '',
            caseOutline: '',
            caseDetailSummary: {
              aboutCompany: [],
              theBenefit: [],
              whatWeDid: [],
            },
            caseDetailContentList: [],
          },
        },
      },
    },
  ]);
  const MOBILE_SIZE = 767;
  const TABLET_SIZE = 1023;

  if (innerWidth <= MOBILE_SIZE) {
    slidesToShow = 1;
  } else if (innerWidth <= TABLET_SIZE) {
    slidesToShow = 2;
  }

  const showMoreDocuments = () => {
    setLimit(limit + 3);
  };

  const caseDetailOnClickHandler = (url: string, index?: number) => {
    // 모바일 버전에서 화면이동에 필요한 index 값을 넘겨주기 위한 optional 파라미터 추가
    navigator('/case/' + url, {
      state: {
        limit: limit,
        index: index !== undefined ? index : -1, // pc버전에서 최상단 case 클릭 시 routing되는 함수. 화면 최상단 고정을 위한 index : -1 처리
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCaseList();
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  const getAllCaseList = async () => {
    dispatch(changeLoadingStatus(true));
    const response = await caseService.getCaseList();
    if (response && response.length > 0) {
      setCaseList(
        response
          .filter((caseItem) => i18n.language in caseItem.caseBoard)
          .filter(
            (caseItem) =>
              caseItem.caseState == State.ALL &&
              caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en']
                ?.caseTitle &&
              caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en']
                ?.caseThumbnail
          )
      );
    }
    dispatch(changeLoadingStatus(false));
  };

  return (
    <div>
      <div className="fullpage-slide case-slide1 pc" data-cy="cy-case-pc">
        {loading ? (
          <CaseSectionSkeletonPC limit={limit} />
        ) : (
          <Fragment>
            <div className="h-wrap">
              {caseList.slice(0, 1).map((caseItem, index) => {
                const caseBoard =
                  caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en'];

                return (
                  <Fragment key={index}>
                    <Fragment>
                      <div
                        className="image"
                        key="div1"
                        data-testid="case1-img"
                        onClick={() =>
                          caseDetailOnClickHandler(caseItem.caseUrl)
                        }
                      >
                        <img
                          src={caseBoard?.caseThumbnail}
                          alt={caseBoard?.caseTitle}
                        />
                      </div>
                      <div
                        className="text"
                        key="div2"
                        onClick={() =>
                          caseDetailOnClickHandler(caseItem.caseUrl)
                        }
                      >
                        <span>{caseBoard?.caseTag}</span>
                        <div
                          className="caseItem"
                          onClick={() =>
                            caseDetailOnClickHandler(caseItem.caseUrl)
                          }
                        >
                          <h1 className="title1" data-testid="case1-text">
                            {caseBoard?.caseTitle}
                          </h1>
                        </div>
                      </div>
                    </Fragment>
                  </Fragment>
                );
              })}
            </div>
            <div className="end-page-caseStudy">
              <div className="v-wrap">
                {caseList.slice(1, limit).map((caseItem, index) => {
                  const caseBoard =
                    caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en'];

                  return (
                    <div
                      key={index}
                      ref={(element: any) => {
                        caseRefs.current[index] = element;
                        if (
                          routeIndex === index &&
                          caseRefs?.current[routeIndex]
                        ) {
                          caseRefs?.current[index].scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          });
                        }
                      }}
                    >
                      <CaseSectionBoxCaseItem
                        key={caseItem.caseId}
                        caseData={caseItem}
                        caseBoard={caseBoard}
                        limit={limit}
                        index={index}
                      />
                    </div>
                  );
                })}
              </div>
              {caseList.length > LIMIT_COUNT && caseList.length > limit ? (
                <button
                  className="more-btn"
                  data-testid="more-btn-pc"
                  onClick={() => {
                    showMoreDocuments();
                  }}
                >
                  {t('case.CaseSection.more')}
                </button>
              ) : null}
            </div>
          </Fragment>
        )}
        <Footer />
      </div>
      {/* 모바일 */}
      <div
        className="fullpage-slide case-slide1 mobile"
        data-cy="cy-case-mobile"
      >
        <div className="end-page">
          {caseList.slice(0, limit).map((caseItem, index) => {
            const caseBoard =
              caseItem.caseBoard[i18n.language == 'ko' ? 'ko' : 'en'];

            return (
              <Fragment key={index}>
                <div className="h-wrap" data-testid="mobile-case-hWrap">
                  {loading ? (
                    <CaseSectionSkeletonMobile />
                  ) : (
                    <Fragment>
                      <div
                        className="image"
                        key="div1"
                        data-testid="mobile-case-img"
                        onClick={() =>
                          caseDetailOnClickHandler(caseItem.caseUrl, index)
                        }
                        ref={(element: any) => {
                          caseRefs.current[index] = element;
                          if (
                            routeIndex === index &&
                            caseRefs?.current[routeIndex]
                          ) {
                            caseRefs?.current[routeIndex].scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            });
                          }
                        }}
                      >
                        <img
                          src={caseBoard?.caseThumbnail}
                          alt={caseBoard?.caseTitle}
                        />
                      </div>
                      <div
                        className="text"
                        key="div2"
                        onClick={() =>
                          caseDetailOnClickHandler(caseItem.caseUrl, index)
                        }
                      >
                        <span>{caseBoard?.caseTag}</span>
                        <h1 className="title1" data-testid="mobile-case-text">
                          {caseBoard?.caseTitle}
                        </h1>
                      </div>
                      {index != 0 &&
                      index % 3 === 0 &&
                      index + 1 === limit &&
                      caseList.length > LIMIT_COUNT &&
                      caseList.length > limit ? (
                        <button
                          className="more-btn"
                          data-testid="more-btn-mobile"
                          onClick={() => {
                            showMoreDocuments();
                          }}
                        >
                          {t('case.CaseSection.more')}
                        </button>
                      ) : null}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CaseSection;
