import './BlogSection.scss';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { Blog } from '../../../service/blog/model/Blog';
import BlogService from '../../../service/blog/BlogService';
import BlogItem from './BlogItem';
import DropdownBox from '../../common/DropdownBox';
import Footer from '../../../layouts/Footer';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/modules/index';
import { changeLoadingStatus } from '../../../redux/actions/loading';
import tag from '../../../../src/assets/common/tag.png';
import refresh from '../../../../src/assets/common/refresh.png';
import noresult from '../../../../src/assets/common/noresult.png';
import {
  BlogFilterKor,
  BlogFilterEn,
  BlogObjectKor,
  BlogObjectEn,
  BlogCategoryKor,
  BlogCategoryEn,
  Language,
} from '../../../service/common/model/enum/Common.enum';
import { getIsMobile } from '../../../service/utils/utils';
import BlogSectionSkeleton from './BlogSectionSkeleton';
import { useLocation } from 'react-router-dom';

type DropdownBoxType = {
  latest: string;
  view: string;
};

type tagFilter = {
  id: string;
  checked: boolean;
};

type tagCount = {
  index: number;
  value: string;
  count: number;
};

const BlogSection: React.FC = () => {
  const blogRefs = useRef<any>([]);
  const language = useSelector((state: RootState) => state.language.lang);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const location = useLocation();
  const index = location.state
    ? (location.state as { index: number }).index
    : -1;
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const LIMIT = isMobile ? 3 : 6;
  const [blogItemsKo, setBlogItemsKo] = useState<Blog[]>([]);
  const [blogItemsEn, setBlogItemsEn] = useState<Blog[]>([]);
  let filteredBlogUrl: string[] = [];
  const [filteredBlogItemsKo, setFilteredBlogItemsKo] = useState<Blog[]>([]);
  let filteredBlogItemsKobuf: Blog[] = [];
  const [filteredBlogItemsEn, setFilteredBlogItemsEn] = useState<Blog[]>([]);
  let filteredBlogItemsEnbuf: Blog[] = [];
  const [dropdownBoxOption, setDropdownBoxOption] = useState<DropdownBoxType>({
    latest: t('blog.blogSection.orderOption.latest'),
    view: t('blog.blogSection.orderOption.view'),
  });
  const [allBlogCount, setAllBlogCount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>('latest');
  const blogService = new BlogService();
  const [limit, setLimit] = useState<number>(LIMIT);

  const [blogFilter, setBlogFilter] = useState<string[]>([]);
  const [blogFilterEn, setBlogFilterEn] = useState<string[]>([]);
  const [filterObjectChecked, setFilterObjectChecked] = useState<tagFilter[]>([
    { id: '0', checked: false },
    { id: '1', checked: false },
    { id: '2', checked: false },
  ]);
  const [filterCategoryChecked, setFilterCategoryChecked] = useState<
    tagFilter[]
  >([
    { id: '0', checked: false },
    { id: '1', checked: false },
    { id: '2', checked: false },
    { id: '3', checked: false },
    { id: '4', checked: false },
  ]);
  const [objectKorTagCount, setObjectKorTagCount] = useState<tagCount[]>([
    { index: 0, value: '비용 절감', count: 0 },
    { index: 1, value: '최적화', count: 0 },
    { index: 2, value: '신기술', count: 0 },
  ]);
  const [categoryKorTagCount, setCategoryKorTagCount] = useState<tagCount[]>([
    { index: 0, value: '컴퓨팅', count: 0 },
    { index: 1, value: '컨테이너', count: 0 },
    { index: 2, value: '스토리지', count: 0 },
    { index: 3, value: '데이터베이스', count: 0 },
    { index: 4, value: '네트워킹', count: 0 },
  ]);

  const [objectEnTagCount, setObjectEnTagCount] = useState<tagCount[]>([
    { index: 0, value: 'Cost Reduction', count: 0 },
    { index: 1, value: 'Optimization Method', count: 0 },
    { index: 2, value: 'Cutting-edge Skill', count: 0 },
  ]);
  const [categoryEnTagCount, setCategryEnTagCount] = useState<tagCount[]>([
    { index: 0, value: 'Computing', count: 0 },
    { index: 1, value: 'Container', count: 0 },
    { index: 2, value: 'Storage', count: 0 },
    { index: 3, value: 'Database', count: 0 },
    { index: 4, value: 'Networking', count: 0 },
  ]);
  useEffect(() => {
    getAllBlogListUser();
  }, [selectedOption]);

  useEffect(() => {
    refreshFilter();
    getDropdownBoxOptions();
    setAllBlogCount(
      language === Language.KO
        ? filteredBlogItemsKo.length
        : filteredBlogItemsEn.length
    );
  }, [language]);

  useEffect(() => {
    setFilteredBlogItemsKo(blogItemsKo);
    getKorTagCount();
  }, [blogItemsKo]);

  useEffect(() => {
    setFilteredBlogItemsEn(blogItemsEn);
    getEnTagCount();
  }, [blogItemsEn]);

  useEffect(() => {
    setAllBlogCount(
      language === Language.KO
        ? filteredBlogItemsKo.length
        : filteredBlogItemsEn.length
    );
  }, [filteredBlogItemsKo]);

  useEffect(() => {
    if (blogFilter.length === 0) {
      filteredBlogUrl = [];
      filteredBlogItemsKobuf = [];
      setFilteredBlogItemsKo(blogItemsKo);
    } else {
      filteredBlogUrl = [];
      blogItemsKo.forEach((item) => {
        blogFilter.forEach((filter) => {
          item.blogBoard.ko.blogObject?.forEach((objTag) => {
            if (objTag === filter) {
              filteredBlogUrl.push(item.blogUrl);
            }
          });
        });
      });
      blogItemsKo.forEach((item) => {
        blogFilter.forEach((filter) => {
          item.blogBoard.ko.blogCategory?.forEach((objTag) => {
            if (objTag === filter) {
              filteredBlogUrl.push(item.blogUrl);
            }
          });
        });
      });
      if (filteredBlogUrl.length === 0) {
        setAllBlogCount(0);
        filteredBlogItemsKobuf = [];
        setFilteredBlogItemsKo(filteredBlogItemsKobuf);
        return;
      }
      blogItemsKo.forEach((item) => {
        const test = filteredBlogUrl.includes(item.blogUrl);
        if (test) {
          filteredBlogItemsKobuf.push(item);
          setFilteredBlogItemsKo(filteredBlogItemsKobuf);
        }
      });
    }
  }, [blogFilter]);

  useEffect(() => {
    if (blogFilterEn.length === 0) {
      filteredBlogUrl = [];
      filteredBlogItemsEnbuf = [];
      setFilteredBlogItemsEn(blogItemsEn);
    } else {
      filteredBlogUrl = [];
      blogItemsEn.forEach((item) => {
        blogFilterEn.forEach((filter) => {
          item.blogBoard.en?.blogObject?.forEach((objTag) => {
            if (objTag === filter) {
              filteredBlogUrl.push(item.blogUrl);
            }
          });
        });
      });
      blogItemsEn.forEach((item) => {
        blogFilterEn.forEach((filter) => {
          item.blogBoard.en?.blogCategory?.forEach((objTag) => {
            if (objTag === filter) {
              filteredBlogUrl.push(item.blogUrl);
            }
          });
        });
      });
      if (filteredBlogUrl.length === 0) {
        setAllBlogCount(0);
        filteredBlogItemsEnbuf = [];
        setFilteredBlogItemsEn(filteredBlogItemsEnbuf);
        return;
      }
      blogItemsEn.forEach((item) => {
        const test = filteredBlogUrl.includes(item.blogUrl);
        if (test) {
          filteredBlogItemsEnbuf.push(item);
          setFilteredBlogItemsEn(filteredBlogItemsEnbuf);
        }
      });
    }
  }, [blogFilterEn]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  const getAllBlogListUser = async () => {
    dispatch(changeLoadingStatus(true));
    const response = await blogService.getAllBlogListUser(selectedOption);
    if (response) {
      setBlogItemsKo(response);
      setBlogItemsEn(
        response
          .filter((res) => res.blogBoard.en?.blogText !== undefined)
          .filter((res) => res.blogBoard.en?.blogText !== '')
      );
      setLimit(
        location.state ? (location.state as { limit: number }).limit : LIMIT
      );
      setAllBlogCount(
        language === Language.KO
          ? response.length
          : response
              .filter((res) => res.blogBoard.en?.blogText !== undefined)
              .filter((res) => res.blogBoard.en?.blogText !== '').length
      );
    }
    dispatch(changeLoadingStatus(false));
  };

  const getDropdownBoxOptions = () => {
    setDropdownBoxOption((state: DropdownBoxType) => {
      return {
        ...state,
        latest: t('blog.blogSection.orderOption.latest'),
        view: t('blog.blogSection.orderOption.view'),
      };
    });
  };

  const showMoreDocuments = () => {
    setLimit(limit + LIMIT);
  };

  const getKorTagCount = () => {
    let cnt = 0;
    objectKorTagCount.forEach((ot, index) => {
      cnt = 0;
      blogItemsKo.forEach((item) => {
        item.blogBoard.ko.blogObject?.forEach((obj) => {
          if (ot.value === obj) {
            cnt += 1;
          }
        });
      });
      objectKorTagCount[index].count = cnt;
    });
    categoryKorTagCount.forEach((ct, index) => {
      cnt = 0;
      blogItemsKo.forEach((item) => {
        item.blogBoard.ko.blogCategory?.forEach((cg) => {
          if (ct.value === cg) {
            cnt += 1;
          }
        });
      });
      categoryKorTagCount[index].count = cnt;
    });
  };

  const getEnTagCount = () => {
    let cnt = 0;
    objectEnTagCount.forEach((ot, index) => {
      cnt = 0;
      blogItemsEn.forEach((item) => {
        item.blogBoard.en?.blogObject?.forEach((obj) => {
          if (ot.value === obj) {
            cnt += 1;
          }
        });
      });
      objectEnTagCount[index].count = cnt;
    });
    categoryEnTagCount.forEach((ct, index) => {
      cnt = 0;
      blogItemsEn.forEach((item) => {
        item.blogBoard.en?.blogCategory?.forEach((cg) => {
          if (ct.value === cg) {
            cnt += 1;
          }
        });
      });
      categoryEnTagCount[index].count = cnt;
    });
  };

  const refreshFilter = () => {
    setBlogFilter([]);
    setBlogFilterEn([]);
    filterObjectChecked.forEach((filter) => {
      filter.checked = false;
    });
    filterCategoryChecked.forEach((filter) => {
      filter.checked = false;
    });
  };

  const handleBlogObjectFilterBtn = (
    key: keyof typeof BlogFilterKor | keyof typeof BlogFilterEn,
    index: number
  ): void => {
    filterObjectChecked[index].checked = !filterObjectChecked[index].checked;
    if (language == Language.KO) {
      const keyEn = ('E' + key.slice(1)) as keyof typeof BlogFilterEn;
      const valueKor = BlogFilterKor[key as keyof typeof BlogFilterKor];
      const valueEn = BlogFilterEn[keyEn];
      setStateFilter(valueKor, valueEn);
    } else {
      const keyKor = ('K' + key.slice(1)) as keyof typeof BlogFilterKor;
      const valueKor = BlogFilterKor[keyKor];
      const valueEn = BlogFilterEn[key as keyof typeof BlogFilterEn];
      setStateFilter(valueKor, valueEn);
    }
  };

  const handleBlogCategoryFilterBtn = (
    key: keyof typeof BlogFilterKor | keyof typeof BlogFilterEn,
    index: number
  ): void => {
    filterCategoryChecked[index].checked =
      !filterCategoryChecked[index].checked;
    if (language == Language.KO) {
      const keyEn = ('E' + key.slice(1)) as keyof typeof BlogFilterEn;
      const valueKor = BlogFilterKor[key as keyof typeof BlogFilterKor];
      const valueEn = BlogFilterEn[keyEn];
      setStateFilter(valueKor, valueEn);
    } else {
      const keyKor = ('K' + key.slice(1)) as keyof typeof BlogFilterKor;
      const valueKor = BlogFilterKor[keyKor];
      const valueEn = BlogFilterEn[key as keyof typeof BlogFilterEn];
      setStateFilter(valueKor, valueEn);
    }
  };

  const setStateFilter = (
    valueKor: BlogFilterKor,
    valueEn: BlogFilterEn
  ): void => {
    if (blogFilter.includes(valueKor)) {
      setBlogFilter(blogFilter.filter((obj) => obj !== valueKor));
      setBlogFilterEn(blogFilterEn.filter((obj) => obj !== valueEn));
    } else {
      setBlogFilter([valueKor, ...blogFilter]);
      setBlogFilterEn([valueEn, ...blogFilterEn]);
    }
  };

  const blogObjectFilterListKor = (
    Object.keys(BlogObjectKor) as (keyof typeof BlogObjectKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="object">
        <Col>
          <input
            id={key}
            name="blogFilterKor"
            data-testid="blogFilterKor"
            value={BlogObjectKor[key]}
            type="checkbox"
            checked={filterObjectChecked[index].checked}
            onChange={() => handleBlogObjectFilterBtn(key, index)}
          ></input>
          <span>
            #{BlogObjectKor[key]} {objectKorTagCount[index].count}
          </span>
        </Col>
      </label>
    );
  });

  const blogObjectFilterListEn = (
    Object.keys(BlogObjectEn) as (keyof typeof BlogObjectEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="object">
        <Col>
          <input
            id="blogFilterEn"
            name="blogFilterEn"
            data-testid="blogFilterEn"
            value={BlogObjectEn[key]}
            type="checkbox"
            checked={filterObjectChecked[index].checked}
            onChange={() => handleBlogObjectFilterBtn(key, index)}
          ></input>
          <span>
            #{BlogObjectEn[key]} {objectEnTagCount[index].count}
          </span>
        </Col>
      </label>
    );
  });

  const blogCategoryFilterListKor = (
    Object.keys(BlogCategoryKor) as (keyof typeof BlogCategoryKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="category">
        <Col>
          <input
            id={key}
            name="blogFilterKor"
            data-testid="blogFilterKor"
            value={BlogCategoryKor[key]}
            type="checkbox"
            checked={filterCategoryChecked[index].checked}
            onChange={() => handleBlogCategoryFilterBtn(key, index)}
          ></input>
          <span>
            #{BlogCategoryKor[key]} {categoryKorTagCount[index].count}
          </span>
        </Col>
      </label>
    );
  });

  const blogCategoryFilterListEn = (
    Object.keys(BlogCategoryEn) as (keyof typeof BlogCategoryEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="category">
        <Col>
          <input
            id="blogFilterEn"
            name="blogFilterEn"
            data-testid="blogFilterEn"
            value={BlogCategoryEn[key]}
            type="checkbox"
            checked={filterCategoryChecked[index].checked}
            onChange={() => handleBlogCategoryFilterBtn(key, index)}
          ></input>
          <span>
            #{BlogCategoryEn[key]} {categoryEnTagCount[index].count}
          </span>
        </Col>
      </label>
    );
  });

  return (
    <div className="fullpage">
      <div className="h-wrap">
        {language === Language.KO ? (
          <div className="blog-section-title-area">
            <div className="tag-img">
              <img alt="tag" src={tag} />
            </div>
            <div className="radio-buttons">
              {blogObjectFilterListKor}
              {blogCategoryFilterListKor}
            </div>
            <div className="refresh-img">
              <img
                className=""
                alt="refresh"
                src={refresh}
                onClick={() => {
                  refreshFilter();
                }}
              />
            </div>
          </div>
        ) : (
          <div className="blog-section-title-area">
            <div className="tag-img">
              <img alt="tag" src={tag} />
            </div>
            <div className="radio-buttons">
              {blogObjectFilterListEn}
              {blogCategoryFilterListEn}
            </div>
            <div className="refresh-img">
              <img
                className=""
                alt="refresh"
                src={refresh}
                onClick={() => {
                  refreshFilter();
                }}
              />
            </div>
          </div>
        )}
        <div className="blog-section-header">
          <div id="total-number-of-blog">
            {language === Language.KO ? (
              <span className="tag-list">
                {blogFilter.length > 0 ? blogFilter.join(', ') : ''}
              </span>
            ) : (
              <span className="tag-list">
                {blogFilterEn.length > 0 ? blogFilterEn.join(', ') : ''}
              </span>
            )}
            {blogFilter.length > 0 ? t('blog.blogSection.about') : ''}
            {t('blog.blogSection.total')}
            <span id="total-number">{allBlogCount}</span>
            {t('blog.blogSection.count')}
          </div>
          <div id="view-condition">
            <DropdownBox
              optionList={dropdownBoxOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>
        <div className="contents-wrap">
          {loading ? (
            <BlogSectionSkeleton />
          ) : allBlogCount > 0 ? (
            <div className="box-wrap">
              {language === Language.KO
                ? filteredBlogItemsKo
                  ? filteredBlogItemsKo.slice(0, limit).map((element, key) => {
                      return (
                        <div
                          className="refDiv"
                          key={key}
                          ref={(element: any) => {
                            blogRefs.current[key] = element;
                            if (key === index && blogRefs?.current[index]) {
                              blogRefs?.current[index].scrollIntoView({
                                behaivor: 'smooth',
                                block: 'center',
                              });
                            }
                          }}
                        >
                          <BlogItem
                            key={key}
                            blogUrl={element.blogUrl}
                            blogRegisterDate={element.registeredDate}
                            blogView={element.blogView}
                            blogTitle={element.blogBoard.ko.blogTitle}
                            blogWriter={element.blogBoard.ko.blogWriter}
                            blogObject={element.blogBoard.ko.blogObject}
                            blogCategory={element.blogBoard.ko.blogCategory}
                            limit={limit}
                            index={key}
                          />
                        </div>
                      );
                    })
                  : ''
                : filteredBlogItemsEn
                ? filteredBlogItemsEn.slice(0, limit).map((element, key) => {
                    return (
                      <div
                        className="refDiv"
                        key={key}
                        ref={(element: any) => {
                          blogRefs.current[key] = element;
                          if (
                            key === index &&
                            blogRefs &&
                            blogRefs.current[index]
                          ) {
                            blogRefs.current[index].scrollIntoView({
                              behaivor: 'smooth',
                              block: 'center',
                            });
                          }
                        }}
                      >
                        <BlogItem
                          key={key}
                          blogUrl={element.blogUrl}
                          blogRegisterDate={element.registeredDate}
                          blogView={element.blogView}
                          blogTitle={
                            element.blogBoard.en
                              ? element.blogBoard.en.blogTitle
                              : ''
                          }
                          blogWriter={
                            element.blogBoard.en
                              ? element.blogBoard.en.blogWriter
                              : ''
                          }
                          blogObject={
                            element.blogBoard.en
                              ? element.blogBoard.en.blogObject
                              : ['empty']
                          }
                          blogCategory={
                            element.blogBoard.en
                              ? element.blogBoard.en.blogCategory
                              : ['empty']
                          }
                          limit={limit}
                          index={key}
                        />
                      </div>
                    );
                  })
                : ''}
            </div>
          ) : (
            <Fragment>
              <div>
                <div className="noresult-img">
                  <img alt="noresult" src={noresult} />
                </div>
                <p className="noresult-txt">{t('blog.blogSection.noresult')}</p>
              </div>
            </Fragment>
          )}
        </div>
        {!loading && limit < allBlogCount && (
          <button
            className="more-btn"
            data-testid="more-btn-mobile"
            onClick={() => {
              showMoreDocuments();
            }}
          >
            {t('blog.blogSection.more')}
          </button>
        )}
      </div>
      <div className="v-wrap">
        <Footer />
      </div>
    </div>
  );
};

export default BlogSection;
