import Enterprise01Img from '../../../../../src/assets/awsProductCard/awsDirectConnect/AwsDirectConnect01.jpg';
import Enterprise02Img from '../../../../../src/assets/awsProductCard/awsDirectConnect/AwsDirectConnect03.jpg';
import Enterprise03Img from '../../../../../src/assets/awsProductCard/awsDirectConnect/AwsDirectConnect02.jpg';

import {
  DirectConnectContentData,
  AwsDirectConnectContentLayout,
} from './AwsDirectConnectModel';

export const directConnectContentDatasKo: DirectConnectContentData[] = [
  {
    img: '',
    layout: AwsDirectConnectContentLayout.TXT_TXT,
    content: {
      title: [],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'LG CNS',
              options: {
                size: 'content-lg',
                bold: true,
                color: 'primary',
                //underline: 'underline',
              },
            },
            {
              text: ` has developed over 100 Amazon Direct Connect practices in South Korea, US and Europe for a global innovator in technology and consumer services.

              With Direct Connect,  `,
              options: {
                size: 'content-lg',
                //bold: true,
              },
            },
            {
              text: 'LG CNS',
              options: {
                size: 'content-lg',
                bold: true,
                color: 'primary',
                //underline: 'underline',
              },
            },
            {
              text: ` delivers differentiated cloud interconnect service through high-resilience datacenter interconnect infrastructure, one of richest experienced MPLS-VPN network and more. This helps enterprise customers reduce costs, enhance security and high-speed reliable connection for AWS.`,
              options: {
                size: 'content-lg',
                //bold: true,
              },
            },
          ],
        },
      ],
    },
    content2: {
      title: [],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'LG CNS',
              options: {
                size: 'content-lg',
                bold: true,
                color: 'primary',
                //underline: 'underline',
              },
            },
            {
              text: '  provides private AWS interconnection to customers in various industries such as LG Electronics, LG Chemical, LG H&H, LG U+, LG Innotek, LG Display, LX Hausys, LX Pantos, GS Retail, LF, Ourhome, S&I, D&O and Serveone. Based on the experience of building these services, LG CNS optimizes the customer value provided by Direct Connect. ',
              options: {
                size: 'content-lg',
                //bold: true,
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise01Img,
    layout: AwsDirectConnectContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Professional Service',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Since 1987, LG CNS have rich experience in building and operating datacenter networks. Based on these long-term technical experiences, you can establish private connect easily since LG CNS provides configuration supports. In addition, LG CNS can provide you optimized cloud connect service through network tuning based on over 30-years of experiences. ',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsDirectConnectContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'Flexible & Cost-optimized Direct Connection',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'With LG CNS’s Direct Connect service, you can establish reliable private connection to AWS suits perfectly your needs. You can create low-bandwidth connections for development or test to reduce costs. Or you can create high-bandwidth connections for production to maximize service availability. ',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsDirectConnectContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'Expandable Networks on Multiple Environment',
        },
      ],

      summary: [
        {
          type: 'default',
          contents: [
            // {
            //   text: 'EKS Anywhere can easily create and operate a Kubernetes cluster on an on-premise infrastructure. ',
            //   options: {
            //     bold: true,
            //   },
            // },
            {
              text: 'As a one of the earliest adaptors of MPLS and optical carrier network, LG CNS can provide you more expandable hybrid network service. Plus, you can establish multi-cloud environment more easily and reliably via LG CNS’s Cloud Interconnect service. ',
            },
          ],
        },
      ],
    },
  },
];
