import React from 'react';
import { Col, Row, Table, Form } from 'react-bootstrap';
import { Case } from '../../../../service/case/model/Case';
import { State } from '../../../../service/common/model/enum/Common.enum';
import { parseStringToDate } from '../../../../service/utils/utils';
import './CaseBoardSection.scss';

type Columns = {
  index: string;
  value: string;
  id?: string;
};

type TableProps = {
  columns: Columns[];
  boardList: Case[];
  itemStateFilter: State | 'default';
  textSearchFilter: string;
  handleSelectStateChange: React.ChangeEventHandler;
  handleSearchChange: React.ChangeEventHandler;
  clickHandler: React.MouseEventHandler;
};

const CaseTableSection = (props: TableProps) => {
  return (
    <div>
      <Row className="search">
        <Col xs="7">
          <span id="title">Case Study 목록</span>
        </Col>
        <Col xs="auto">
          <Form.Select className="filter" title="상태" onChange={props.handleSelectStateChange}>
            <option value={'default'} data-type="number">Default</option>
            <option value={State.ALL} data-type="number">All</option>
            <option value={State.ADMIN} data-type="number">Admin</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Control className="filter" defaultValue={""} placeholder="한/영 제목 또는 url 검색" onChange={props.handleSearchChange} />
        </Col>
      </Row>
      <Row className="list">
        <Table className="table">
          <thead>
            <tr>
              {props.columns.map((column) => (
                <th id={column.id} key={column.index}>
                  {column.value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.boardList.filter((board) => {
              if (props.itemStateFilter === 'default')
                return true;
              return board.caseState === props.itemStateFilter;
            }).filter((board) => {
              if (props.textSearchFilter !== '') {
                if (board.caseBoard.ko.caseTitle.toLowerCase().indexOf(props.textSearchFilter.toLowerCase()) !== -1
                  || (board.caseBoard.en
                    && board.caseBoard.en.caseTitle
                    && board.caseBoard.en.caseTitle.toLowerCase().indexOf(props.textSearchFilter.toLowerCase()) !== -1)
                  || (board.caseUrl.toLowerCase().indexOf(props.textSearchFilter.toLowerCase()) !== -1))
                  return true;
                return false;
              } else
                return true;
            }).map((board, idx) => (
              <tr
                key={board.caseId}
                id={board.caseUrl.toString()}
                onClick={props.clickHandler}
              >
                <td>{idx + 1}</td>
                <td id={board.caseUrl} className="case-align-left">
                  <p id={board.caseUrl + '-kr'} className="case-title">
                    KO : {board.caseBoard.ko.caseTitle}
                  </p>
                  <p id={board.caseUrl + '-en'} className="case-title">
                    EN : {board.caseBoard.en?.caseTitle || 'N/A'}
                  </p>
                </td>
                <td className="case-align-left">{board.caseUrl}</td>
                <td>{board.caseState || 'N/A'}</td>
                <td>{board.registerAdmin}</td>
                <td>
                  {parseStringToDate(board.registeredDate).substring(0, 10)}
                </td>
                <td>{board.modifyAdmin}</td>
                <td>
                  {parseStringToDate(board.modifiedDate).substring(0, 10)}
                </td>
                <td>{board.caseOrderIdx}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default CaseTableSection;
