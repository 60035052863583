import './MainPageContainer.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import { getIsMobile } from '../../service/utils/utils';
import MainSection from './components/MainSection';

const MainPageContainer: React.FC = () => {
  const isMobile = getIsMobile();
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="MainPageContainer" className={isMobile ? 'overflow-is' : ''}>
        <MainSection />
      </div>
    </Container>
  );
};

export default MainPageContainer;
