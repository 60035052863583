import React from 'react';

import { SummaryContent } from '../../../service/case/model/Case';

interface IProps {
  SummaryContentList: Array<SummaryContent> | undefined;
}

const SummaryItem = ({ SummaryContentList }: IProps) => {
  return (
    <ul>
      {SummaryContentList &&
        SummaryContentList?.map((cont, idx) => (
          <li className="detail-sub-cont" key={idx}>
            {cont.summaryInfo}
            {cont.summaryDescription ? <p>{cont.summaryDescription}</p> : ''}
          </li>
        ))}
    </ul>
  );
};

export default SummaryItem;
