import './NewsItem.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import logo from '../../../assets/common/headerLogo.png';
import { useTranslation } from 'react-i18next';
import { getStringDateFormat } from '../../../service/utils/utils';
import {
  DateFormat,
  StringDateFormat,
} from '../../../service/common/model/enum/Common.enum';

export interface NewsItemProps {
  newsUrl: string;
  newsThumbnail?: string;
  newsTitle: string;
  newsSubtitle?: string;
  newsText: string;
  newsRegisterDate: string;
  newsCategory: string;
  newsView: number;
  limit: number;
  index: number;
}

const NewsItem: React.FC<NewsItemProps> = (props: NewsItemProps) => {
  const navigator = useNavigate();
  const newsDetailUrl: string = '/news/' + props.newsUrl;
  const { t } = useTranslation();

  const handleNewsInfoOnClick = () => {
    navigator(newsDetailUrl, {
      state: {
        limit: props.limit,
        index: props.index,
      },
    });
  };

  return (
    <Row key={props.newsUrl} data-testid={'news-item-row-' + props.newsUrl}>
      <div
        id="news-section-item"
        className="news-item"
        onClick={handleNewsInfoOnClick}
        data-testid="news-item-area"
      >
        <div className="news-thumbnail-area">
          <img
            id="news-thumbnail"
            src={props.newsThumbnail || logo}
            alt={props.newsTitle}
          />
        </div>
        <div id="news-info-area" className="news-info-area">
          <p id="news-title">{props.newsTitle}</p>
          <div id="news-subtitle">{props.newsSubtitle}</div>
          <div id="news-modifiedDate">
            <span>
              {getStringDateFormat(
                props.newsRegisterDate,
                DateFormat.DATE,
                StringDateFormat.DATE
              )}
              <span> {props.newsCategory}</span>
            </span>
            <span className="news-view">
              {t('news.newsSection.view')} {props.newsView}
            </span>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default NewsItem;
