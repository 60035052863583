import React from 'react';
import './MainLaunch.scss';
import mainLaunchFlatIcon from '../../../assets/main/mainLaunch/mainLaunchFlatIcon.svg';
import mainLaunchIcon1 from '../../../assets/main/mainLaunch/mainLaunchIcon1.svg';
import mainLaunchIcon2 from '../../../assets/main/mainLaunch/mainLaunchIcon2.svg';
import mainLaunchIcon3 from '../../../assets/main/mainLaunch/mainLaunchIcon3.svg';
import { useTranslation } from 'react-i18next';

const MainLaunch = () => {
  const { t } = useTranslation();

  return (
    <div className="fullpage-slide main-launch">
      <div className="end-page">
        <span className="main-launch-title">{t('main.MainLaunch.title')}</span>
        <div className="main-launch-container">
          <div className="main-launch-item">
            <div className="main-launch-item-idx">
              <p>01</p>
              <img src={mainLaunchFlatIcon} />
            </div>
            <div className="main-launch-item-content">
              <img src={mainLaunchIcon1} />
              <span>{t('main.MainLaunch.txt1')} <br/>
              <span>{t('main.MainLaunch.txt2')}</span>
              </span>
            </div>
          </div>
          <div className="main-launch-item">
            <div className="main-launch-item-idx">
              <p>02</p>
              <img src={mainLaunchFlatIcon} />
            </div>
            <div className="main-launch-item-content">
              <img src={mainLaunchIcon2} />
              <span>{t('main.MainLaunch.txt3')}<br/>
              <span>{t('main.MainLaunch.txt4')}</span>
              </span>
            </div>
          </div>
          <div className="main-launch-item">
            <div className="main-launch-item-idx">
              <p>03</p>
              <img src={mainLaunchFlatIcon} />
            </div>
            <div className="main-launch-item-content">
              <img src={mainLaunchIcon3} />
              <span>{t('main.MainLaunch.txt5')}<br/>
              <span>{t('main.MainLaunch.txt6')}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="main-launch-line">
          <hr></hr>
        </div>
      </div>
    </div>
  );
};

export default MainLaunch;
