import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginSection.scss';

import React, { ReactElement, useState, KeyboardEvent, useEffect } from 'react';
import Loading from '../../../../layouts/Loading';
import MemberService from '../../../../service/member/MemberService';
// import { Image, Row } from 'react-bootstrap';
// import logo from '../../../../assets/common/headerLogo.png';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import { changeAuthorizationStatus } from '../../../../redux/actions/authorization';
import { responsiveFontSizes } from '@mui/material';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import ProgressBar from '../../../../layouts/ProgressBar';
import LoginRequestForm from './LoginRequestForm';
import QrRegistrationForm from './QrRegistrationForm';
import MfaRequestForm from './MfaRequestForm';
import {
  AuthenticateMode,
  AuthenticateState,
} from '../../../../service/common/model/enum/Login.enum';
import {
  AuthenticateRequest,
  MfaProps,
} from '../../../../service/common/model/Login';
import { isBlank, isFieldEmpty } from '../../../../service/utils/utils';
import { pseudoRandomBytes } from 'crypto';

const LoginSection: React.FC = (): ReactElement => {
  // const [username, setUsername] = useState<string>('');
  // const [password, setPassword] = useState<string>('');
  const [authenticateMode, setAuthenticateMode] = useState<AuthenticateMode>(
    AuthenticateMode.INIT_REQUEST
  );
  const [userInfo, setUserInfo] = useState<MfaProps>({
    username: '',
    password: '',
    uri: '',
  });

  const memberService = new MemberService();
  const navigator = useNavigate();

  const loading = useSelector((state: RootState) => state.loading.loading);

  const authorized = useSelector(
    (state: RootState) => state.authorization.authorized
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (authorized) {
      dispatch(changeLoadingStatus(false));
      dispatch(changeProgressStatus(100));
    }
  }, []);

  // const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key == 'Enter') {
  //     handleLogin();
  //   }
  // };

  const handleLogin = async (username: string, password: string) => {
    if (isFieldEmpty(username) || isBlank(username)) {
      alert('아이디를 입력하세요.');
      return;
    }

    if (isFieldEmpty(password)) {
      alert('비밀번호를 입력하세요.');
      return;
    }

    //const handleLogin = async () => {
    const authInfo = { username, password };
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));

    // const response = await memberService.handleLogin(
    //   AuthenticateMode.INIT_REQUEST,
    //   authInfo
    // );

    const response = await memberService.handleLogin(username, password);

    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));

    if (response.successOrNot == 'Y') {
      // dispatch(changeAuthorizationStatus(true));
      // dispatch(changeProgressStatus(50));
      // navigator('/admin/case');
      setUserInfo({
        username: username,
        password: password,
        uri: response.data.uri,
      });
      if (response.data.result === AuthenticateState.NOMFA) {
        alert('인증되지 않은 사용자입니다. MFA 할당이 필요합니다.');
        setAuthenticateMode(AuthenticateMode.QR_REGIST_REQUEST);
      } else if (response.data.result === AuthenticateState.MFA) {
        setAuthenticateMode(AuthenticateMode.MFA_REQUEST);
      } else if (response.data.sessionId) {
        dispatch(changeAuthorizationStatus(true));
        dispatch(changeProgressStatus(50));

        navigator('/admin/case'); //관리자용
      }
    } else {
      if (response.statusCode == 'UNAUTHORIZED.ERR') {
        alert(
          `계정 정보를 확인해주시기 바랍니다. (${response.data.attemptCount}/5)`
        );
      } else if (response.statusCode == 'LOGIN.ATTEMPTS.EXCEEDED') {
        alert(
          '로그인 실패 횟수 초과로 계정 사용이 중지됩니다.\n관리자에게 문의 부탁드립니다.'
        );
      } else if (
        response.statusCode == 'INIT.PASSWORD.ERR' ||
        response.statusCode == 'NEW.PASSWORD.REQUIRED'
      ) {
        alert(
          '비밀번호 초기화가 필요합니다.\n비밀번호 변경 페이지로 이동합니다.'
        );
        navigator('/admin/change-password');
      } else if (response.statusCode == 'EXPIRED.PASSWORD.ERR') {
        alert(
          '비밀번호 변경한지 90일 이상 지났습니다.\n비밀번호 변경 페이지로 이동합니다.'
        );
        navigator('/admin/change-password');
      } else {
        alert('서버 오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  const handleMfaRegister = async (authRequest: AuthenticateRequest) => {
    if (
      isFieldEmpty(authRequest.mfacode || '') ||
      isBlank(authRequest.mfacode || '')
    ) {
      alert('MFA 코드를 입력하세요');
      return;
    }

    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));

    // const response = await memberService.handleLogin(
    //   AuthenticateMode.QR_REGIST_REQUEST,
    //   authRequest
    // );

    const response = await memberService.handleMfaLogin(
      AuthenticateMode.QR_REGIST_REQUEST,
      authRequest
    );

    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));

    if (response.successOrNot == 'Y') {
      alert('MFA를 성공적으로 등록했습니다. 로그인 단계로 돌아갑니다.');

      setAuthenticateMode(AuthenticateMode.INIT_REQUEST);
    } else {
      if (response.statusCode == 'UNAUTHORIZED.ERR') {
        alert(`MFA 코드를 잘못 입력했습니다. 코드를 다시 확인하세요.`);
      } else if (response.statusCode == 'LOGGING.FAIL') {
        alert(`로그인에 실패했습니다. 계정정보를 확인하세요.`);
      } else {
        alert('서버 오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  const handleMfaLogin = async (authRequest: AuthenticateRequest) => {
    if (
      isFieldEmpty(authRequest.mfacode || '') ||
      isBlank(authRequest.mfacode || '')
    ) {
      alert('MFA 코드를 입력하세요');
      return;
    }

    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));

    const response = await memberService.handleMfaLogin(
      AuthenticateMode.MFA_REQUEST,
      authRequest
    );

    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));

    if (response.successOrNot == 'Y') {
      dispatch(changeAuthorizationStatus(true));
      dispatch(changeProgressStatus(50));

      navigator('/admin/case');
    } else {
      if (response.statusCode == 'UNAUTHORIZED.ERR') {
        alert(`MFA 코드를 잘못 입력했습니다. 코드를 다시 확인하세요.`);
      } else if (response.statusCode == 'LOGGING.FAIL') {
        alert(`로그인에 실패했습니다. 계정정보를 확인하세요.`);
      } else {
        alert('서버 오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  return (
    <>
      <ProgressBar />
      <Row>
        <div className="AdminLoginSection" id="loginSection">
          <div className="auth-wrapper">
            <div className="auth-inner">
              <LoginRequestForm handleLogin={handleLogin} />
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};
export default LoginSection;
