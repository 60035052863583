import React from 'react';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { Business } from '../../../../service/business/model/Business';
import {
  State,
  DateFormat,
  StringDateFormat,
} from '../../../../service/common/model/enum/Common.enum';
import { getStringDateFormat } from '../../../../service/utils/utils';

import './BusinessBoardSection.scss';

type Columns = {
  index: string;
  value: string;
};

type TableProps = {
  columns: Columns[];
  boardList: Business[];
  itemStateFilter: State | 'default';
  textSearchFilter: string;
  handleSelectStateChange: React.ChangeEventHandler;
  handleSearchChange: React.ChangeEventHandler;
  clickHandler: React.MouseEventHandler;
};

const BusinessTableSection = (props: TableProps) => {
  return (
    <div>
      <Row className="search">
        <Col xs="7">
          <span id="title">Business 목록</span>
        </Col>
        <Col xs="auto">
          <Form.Select
            className="filter"
            onChange={props.handleSelectStateChange}
          >
            <option value={'default'} data-type="number">
              Default
            </option>
            <option value={State.ALL} data-type="number">
              All
            </option>
            <option value={State.ADMIN} data-type="number">
              Admin
            </option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Control
            className="filter"
            defaultValue={''}
            placeholder="한/영 제목 입력"
            onChange={props.handleSearchChange}
          />
        </Col>
      </Row>
      <Row className="list">
        <Table className="table">
          <thead>
            <tr>
              {props.columns
                ? props.columns.map((column) => (
                    <th key={column.index}>{column.value}</th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {props.boardList
              ? props.boardList
                  .filter((board) => {
                    if (props.itemStateFilter === 'default') return true;
                    return board.businessState === props.itemStateFilter;
                  })
                  .filter((board) => {
                    if (props.textSearchFilter !== '') {
                      if (
                        board.businessBoard.ko.businessTitle
                          .toLowerCase()
                          .indexOf(props.textSearchFilter.toLowerCase()) !==
                          -1 ||
                        (board.businessBoard.en &&
                          board.businessBoard.en.businessTitle &&
                          board.businessBoard.en.businessTitle
                            .toLowerCase()
                            .indexOf(props.textSearchFilter.toLowerCase()) !==
                            -1)
                      )
                        return true;
                      return false;
                    } else return true;
                  })
                  .map((business, idx) => (
                    <tr
                      key={business.businessUrl.toString()}
                      id={business.businessUrl.toString()}
                      onClick={props.clickHandler}
                    >
                      <td>{idx + 1}</td>
                      {business.businessBoard.en?.businessTitle ? (
                        <td className="business-title">
                        KO: {business.businessBoard.ko.businessTitle}
                        <br/>
                        EN: {business.businessBoard.en?.businessTitle}
                      </td>
                      ):(
                        <td className="business-title">
                        KO: {business.businessBoard.ko.businessTitle}
                        <br/>
                        EN: N/A
                      </td>
                      )}

                      <td className="business-url">{business.businessUrl}</td>
                      <td className="business-state">{business.businessState}</td>
                      <td className="business-register-admin">
                        {business.registerAdmin}
                      </td>
                      <td className="business-registered-date">
                        {getStringDateFormat(
                          business.registeredDate,
                          DateFormat.DATE,
                          StringDateFormat.DATE
                        )}
                      </td>
                      <td className="business-modify-admin">{business.modifyAdmin}</td>
                      <td className="business-modified-date">
                        {getStringDateFormat(
                          business.modifiedDate,
                          DateFormat.DATE,
                          StringDateFormat.DATE
                        )}
                      </td>
                      <td className="business-view">{business.businessView}</td>
                    </tr>
                  ))
              : null}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default BusinessTableSection;
