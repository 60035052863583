import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginContainer.scss';

import React, { ReactElement, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';

import LoginSection from './component/LoginSection';

const LoginContainer: React.FC = (): ReactElement => {
  return (
    <div id="loginContainer">
      <Container fluid={true}>
        <LoginSection />
      </Container>
    </div>
  );
};

export default LoginContainer;
