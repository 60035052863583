import React, { useEffect, useState, useRef } from 'react';
import { Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CaseService from '../../../../service/case/CaseService';
import { Case, CaseOrderingData } from '../../../../service/case/model/Case';
import { State } from '../../../../service/common/model/enum/Common.enum';
import CaseOrderSection from './CaseOrderSection';
import CaseTableSection from './CaseTableSection';
import './CaseBoardSection.scss';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import CaseTempRegistSection from './CaseTempRegistSection';
import { changeAuthorizationStatus } from '../../../../redux/actions/authorization';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import ProgressBar from '../../../../layouts/ProgressBar';

const CaseBoardSection: React.FC = () => {
  const caseService: CaseService = new CaseService();
  const [caseStateFilter, setCaseStateFilter] = useState<State | 'default'>('default');
  const [textSearchFilter, setTextSearchFilter] = useState<string>("");
  const [boardList, setBoardList] = useState<Case[]>([]);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [tempRegistModalOpen, setTempRegistModalOpen] = useState(false);
  const [caseOrderList, setCaseOrderList] = useState<CaseOrderingData[]>([]);
  const [caseTempList, setCaseTempList] = useState<Case[]>([]);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  const openOrderModal = () => {
    setOrderModalOpen(true);
  };
  const closeOrderModal = () => {
    setOrderModalOpen(false);
  };

  const openTempRegistModal = () => {
    setTempRegistModalOpen(true);
  };
  const closeTempRegistModal = () => {
    setTempRegistModalOpen(false);
  };

  const columns = [
    {
      index: '0',
      value: 'No.',
      id: 'columnNo',
    },
    {
      index: '1',
      value: '제목',
      id: 'columnTitle',
    },
    {
      index: '2',
      value: 'URL',
      id: 'columnUrl',
    },
    {
      index: '3',
      value: '상태',
      id: 'columnStatus',
    },
    {
      index: '4',
      value: '등록자',
      id: 'columnRegisterAdmin',
    },
    {
      index: '5',
      value: '등록 일시',
      id: 'columnRegisteredDate',
    },
    {
      index: '6',
      value: '수정자',
      id: 'columnModifyAdmin',
    },
    {
      index: '7',
      value: '수정 일시',
      id: 'columnModifiedDate',
    },
    {
      index: '8',
      value: '순서',
      id: 'columnOrder',
    },
  ];

  const navigator = useNavigate();

  useEffect(() => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    caseService.getAllCaseList().then((response) => {
      if (response.successOrNot == 'Y') {
        setBoardList(response.data);
      } else {
        if (
          response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
        ) {
          dispatch(changeAuthorizationStatus(false));
      }
      }
      dispatch(changeLoadingStatus(false));
      dispatch(changeProgressStatus(100));
    });
    caseService.getCaseOrder().then((response) => {
      if (response.successOrNot == 'Y') {
        setCaseOrderList(response.data);
      } else {
        if (
          response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
        ) {
          dispatch(changeAuthorizationStatus(false));
        }
      }
    });

    caseService.getTempCaseList().then((response) => {
      if (response.successOrNot == 'Y') {
        setCaseTempList(response.data);
      } else {
        if (
          response.statusCode === 'BAD.REQUEST.ERR' ||
          response.statusCode === 'UNAUTHORIZED.ERR'
        ) {
          dispatch(changeAuthorizationStatus(false));
        }
      }
    });
    return () => {
      setBoardList([]);
      setCaseOrderList([]);
      setCaseTempList([]);
    };
  }, []);

  const caseHandler = (e: React.SyntheticEvent) => {
    const url = e.currentTarget.getAttribute('id');
    navigator('/admin/case/' + url);
  };

  const caseRegisterBtnHandler = () => {
    navigator('/admin/case/regist');
  };

  const caseOrderChangeBtnHandler = () => {
    openOrderModal();
  };

  const caseTempRegistBtnHandler = () => {
    openTempRegistModal();
  };

  const handleSelectStateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    if (value == State.ALL)
      setCaseStateFilter(State.ALL);
    else if (value == State.ADMIN)
      setCaseStateFilter(State.ADMIN);
    else
      setCaseStateFilter('default');
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setTextSearchFilter(value);

  };

  return (
    <div className="AdminCaseBoardSection">
      <div className="fullpage pc">
        <ProgressBar/>
        <CaseTableSection
          columns={columns}
          boardList={boardList}
          itemStateFilter={caseStateFilter}
          textSearchFilter={textSearchFilter}
          handleSelectStateChange={handleSelectStateChange}
          handleSearchChange={handleSearchChange}
          clickHandler={caseHandler} />
        <div className="btn-container">
          <Button
            className="btn btn-changeOrder"
            data-testid="order-button"
            onClick={caseOrderChangeBtnHandler}
          >
            case 순서변경
          </Button>
          <Button
            className="btn btn-temp-regist"
            data-testid="temp-regist-button"
            onClick={caseTempRegistBtnHandler}
          >
            case 임시저장 목록
          </Button>
          <Button
            className="btn btn-secondary"
            data-testid="register-button"
            onClick={caseRegisterBtnHandler}
          >
            case 등록
          </Button>
        </div>
      </div>
      <CaseOrderSection
        open={orderModalOpen}
        close={closeOrderModal}
        caseList={caseOrderList}
      />

      <CaseTempRegistSection
        open={tempRegistModalOpen}
        close={closeTempRegistModal}
        tempCaseList={caseTempList}
      />
    </div>
  );
};

export default CaseBoardSection;
