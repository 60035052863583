import './ErrorPageContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import ErrorPage from './components/ErrorPage';

const ErrorPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="ErrorPageContainer">
        <ErrorPage />
      </div>
    </Container>
  );
};

export default ErrorPageContainer;
