import React from 'react';
import { CloudFormationContentTextAreaProps } from '../../../../service/data/awsProductCard/awsCloudFormation/AwsCloudFormationModel';
import { getIsMobile } from '../../../../service/utils/utils';

interface Props {
  content?: CloudFormationContentTextAreaProps;
}

const AwsCloudFormationContentTextItem: React.FC<Props> = (props: Props) => {
  const content = props.content;
  const isMobile = getIsMobile();

  return (
    <div
      className="content-txt-box"
      data-testid="testAwsCloudFormationContentTextItem"
    >
      {isMobile && (content?.title[0]?.text || '') === '' ? (
        <></>
      ) : (
        <div className="content-title">
          <span className="content-title-txt">
            {content?.title.map((title, idx) => {
              return (
                <span
                  className={`${title?.options?.size === 'title-sm' ? 'title-sm' : ''
                    }`}
                  key={idx}
                >
                  {title.text}
                </span>
              );
            })}
          </span>
        </div>
      )}
      <div className="content-summary-box">
        {content?.summary.map((p, idx) => {
          return (
            <p
              className={`${p.type === 'definition'
                ? 'content-definition'
                : 'content-default'
                }`}
              key={idx}
            >
              {p.contents.map((content, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {content.options?.prevLine || false ? <br /> : ''}
                    <span
                      className={`${content?.options?.color === 'primary'
                        ? 'content-primary'
                        : ''
                        } ${content?.options?.bold || false ? 'content-bold' : ''}
                      ${content?.options?.underline === 'underline'
                          ? 'content-underline'
                          : content?.options?.underline === 'primary-underline'
                            ? 'content-primary-underline'
                            : ''
                        }
                      ${content?.options?.size === 'content-lg'
                          ? 'content-lg'
                          : ''
                        }
                  `}
                      key={idx}
                    >
                      {content.text}
                    </span>
                  </React.Fragment>
                );
              })}
            </p>
          );
        })}
      </div>
    </div>
  );
};
export default AwsCloudFormationContentTextItem;
