import './AwsProductSection.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AwsPartnerIntroPage from './AwsPartnerIntroPage';
import AwsProductTabPage from './AwsProductTabPage';
import ReactPageScroller from 'react-page-scroller';
import { getIsMobile } from '../../../service/utils/utils';

const AwsProductSection: React.FC = () => {
  const { i18n } = useTranslation();
  const [pageIdx, setPageIdx] = useState(Number);
  const isMobile = getIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  useEffect(() => {
    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = 'fixed absolute';
      document.getElementsByClassName('gnb')[0].className = 'gnb fixed';
    }
  }, []);

  return (
    <div id="AwsProductSection" data-testid="testAwsProductSection">
      <div className="aws-product fullpage-slide">
        <Fragment>
          <AwsPartnerIntroPage />
          <AwsProductTabPage />
        </Fragment>
        {/* {isMobile ? (
          <Fragment>
            <AwsPartnerIntroPage />
            <AwsProductTabPage />
          </Fragment>
        ) : (
          <ReactPageScroller
            onBeforePageScroll={(page) => {
              setPageIdx(page);
              if (page !== 0) {
                document.getElementsByTagName('header')[0].className = '';
                document.getElementsByClassName('gnb')[0].className = 'gnb';
              } else {
                document.getElementsByTagName('header')[0].className = 'fixed';
                document.getElementsByClassName('gnb')[0].className =
                  'gnb fixed';
              }
            }}
            renderAllPagesOnFirstRender={true}
            pageOnChange={(page) => setPageIdx(page < 2 ? page : 1)}
            customPageNumber={pageIdx}
            blockScrollDown={pageIdx === 1 ? true : false}
          >
            <AwsPartnerIntroPage />
            <AwsProductTabPage />
          </ReactPageScroller>
        )} */}
      </div>
    </div>
  );
};

export default AwsProductSection;
