import './AboutSection.scss';
import './AboutCaseStyle.scss';
import React, { Fragment, useEffect, useState } from 'react';
import { getIsMobile } from '../../../service/utils/utils';
import ReactPageScroller from 'react-page-scroller';
import About01 from './About01';
import About02 from './About02';
import About03 from './About03';
import About04 from './About04';

const AboutSection: React.FC = () => {
  const isMobile = getIsMobile();

  const [pageIdx, setPageIdx] = useState(Number);

  const handlePageChange = (page: number) => {
    page < 4 ? setPageIdx(page) : () => {};
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      document.getElementsByTagName('header')[0] !== undefined &&
      document.getElementsByClassName('gnb')[0] !== undefined
    ) {
      document.getElementsByTagName('header')[0].className = '';
      document.getElementsByClassName('gnb')[0].className = 'gnb';
    }
  }, []);

  return (
    <div id="AboutSection" className={isMobile ? '' : 'overflow-is'}>
      {isMobile ? (
        <Fragment>
          <About01 />
          <About02 />
          <About03 />
          <About04 />
        </Fragment>
      ) : (
        <Fragment>
          <ReactPageScroller
            onBeforePageScroll={(page) => {
              setPageIdx(page);
            }}
            renderAllPagesOnFirstRender={true}
            pageOnChange={(page) => handlePageChange(page)}
            customPageNumber={pageIdx}
            blockScrollDown={pageIdx >= 3 ? true : false}
          >
            <About01 />
            <About02 />
            <About03 />
            <About04 />
          </ReactPageScroller>
        </Fragment>
      )}
    </div>
  );
};

export default AboutSection;
