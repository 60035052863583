import './NewsDetailSection.scss';

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { ToastViewer } from '../../../../pages/common/ToastViewer';
import { Language } from '../../../../service/common/model/enum/Common.enum';
import { News } from '../../../../service/news/model/News';
import NewsService from '../../../../service/news/NewsService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import { getStringDateFormat } from '../../../../service/utils/utils';
import {
  DateFormat,
  StringDateFormat,
} from '../../../../service/common/model/enum/Common.enum';

const NewsDetailSection = () => {
  const { id } = useParams();
  const { state } = useLocation() as unknown as { state: boolean }; // true : 임시저장 데이터 조회, false|null : 등록된 뉴스데이터 조회
  const navigate = useNavigate();
  const [news, setNews] = useState<News>();
  const newsService = new NewsService();
  const [newsLang, setNewsLang] = useState<Language>(Language.KO);
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      state ? getTempNewsDetail(id) : getNewsDetail(id);
    }
  }, []);

  const getNewsDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await newsService.getNewsDetail(id);
    if (response) {
      setNews(response);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const getTempNewsDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await newsService.getTempNewsDetail(id);
    if (response) {
      if (response.newsBoard.ko.newsThumbnail) {
        response.newsBoard.ko.newsThumbnail =
          '/' + response.newsBoard.ko.newsThumbnail;
      }
      if (response.newsBoard.en) {
        if (response.newsBoard.en.newsThumbnail) {
          response.newsBoard.en.newsThumbnail =
            '/' + response.newsBoard.en.newsThumbnail;
        }
      }
      setNews(response);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const modifyBtnHandler = () => {
    state
      ? navigate('/admin/news/regist', { state: id })
      : navigate('/admin/news/edit/' + id);
  };

  const deleteBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (
      news &&
      confirm('삭제된 news는 복구 할 수 없습니다.\nnews를 삭제하시겠습니까?')
    ) {
      newsService.deleteNews(news).then((response) => {
        if (response.successOrNot == 'Y') {
          alert('news가 삭제되었습니다.');
          navigate('/admin/news');
        } else {
          alert('news 삭제 중 오류가 발생하였습니다.');
        }
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      });
    }
  };

  const newsListBtnHandler = () => {
    navigate('/admin/news');
  };

  const handleLanguageBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (value == Language.KO) {
      setNewsLang(Language.KO);
    } else if (value == Language.EN) {
      setNewsLang(Language.EN);
    }
  };

  return (
    <div id="AdminNewsDetailSection">
      <ProgressBar />
      <Row>
        <div className="radio-buttons">
          <label className="language">
            <Col>
              <input
                id="ko"
                value={Language.KO}
                name="language"
                type="radio"
                onChange={handleLanguageBtn}
                checked={newsLang == Language.KO}
              />
              <span>한국어</span>
            </Col>
          </label>
          <label className="language">
            <Col>
              <input
                id="en"
                value={Language.EN}
                name="language"
                type="radio"
                onChange={handleLanguageBtn}
                checked={newsLang == Language.EN}
              />
              <span>English</span>
            </Col>
          </label>
        </div>
      </Row>
      {news ? (
        newsLang == Language.KO ? (
          <>
            <div className="detail-header">
              <div className="category">{news.newsBoard.ko.newsCategory}</div>
              <div className="title" data-testid="title-text">
                {news.newsBoard.ko.newsTitle}
              </div>
              <div className="sub-title">{news.newsBoard.ko.newsSubtitle}</div>
              <div className="thumnail">
                썸네일 미리보기
                {news.newsBoard.ko.newsThumbnail ? (
                  <img
                    id="admin-news-thumnail"
                    src={news.newsBoard.ko.newsThumbnail}
                  />
                ) : null}
              </div>
              <div className="title-info">
                <div className="register-admin">
                  등록 : {news.registerAdmin}
                </div>
                <div className="registered-date">
                  {getStringDateFormat(
                    news.registeredDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="modify-admin">수정 : {news.modifyAdmin}</div>
                <div className="modified-date">
                  {getStringDateFormat(
                    news.modifiedDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="view">조회수 : {news.newsView}</div>
                <div className="state">공개범위 : {news.newsState}</div>
              </div>
            </div>
          </>
        ) : news.newsBoard.en ? (
          <>
            <div className="detail-header">
              <div className="category">{news.newsBoard.en.newsCategory}</div>
              <div className="title" data-testid="title-text">
                {news.newsBoard.en.newsTitle}
              </div>
              <div className="sub-title">{news.newsBoard.en.newsSubtitle}</div>
              <div className="thumnail">
                썸네일 미리보기
                {news.newsBoard.en.newsThumbnail ? (
                  <img
                    id="admin-news-thumnail"
                    src={news.newsBoard.en.newsThumbnail}
                  />
                ) : null}
              </div>
              <div className="title-info">
                <div className="register-admin">
                  등록 : {news.registerAdmin}
                </div>
                <div className="registered-date">
                  {getStringDateFormat(
                    news.registeredDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="modify-admin">수정 : {news.modifyAdmin}</div>
                <div className="modified-date">
                  {getStringDateFormat(
                    news.modifiedDate,
                    DateFormat.DATETIME,
                    StringDateFormat.DATETIME
                  )}
                </div>
                <div className="view">조회수 : {news.newsView}</div>
                <div className="state">공개범위 : {news.newsState}</div>
              </div>
            </div>
          </>
        ) : null
      ) : null}
      <hr />
      <div className="detail-body" data-testid="body-text">
        {news ? (
          newsLang == Language.KO ? (
            <ToastViewer text={news.newsBoard.ko.newsText} />
          ) : news.newsBoard.en ? (
            <ToastViewer text={news.newsBoard.en.newsText} />
          ) : null
        ) : null}
      </div>
      <hr />
      <div className="btn-container">
        <Button
          className="btn btn-light"
          data-testid="modify-button"
          onClick={modifyBtnHandler}
        >
          수정
        </Button>
        <Button
          className="btn btn-light"
          data-testid="delete-button"
          onClick={deleteBtnHandler}
        >
          삭제
        </Button>
        <Button
          className="btn btn-secondary"
          data-testid="list-button"
          onClick={newsListBtnHandler}
        >
          목록
        </Button>
      </div>
    </div>
  );
};

export default NewsDetailSection;
