import './AwsLambdaPageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';

import AwsLambdaSection from './components/AwsLambdaSection';

const AwsLambdaPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsLambdaPageContainer">
        <AwsLambdaSection />
      </div>
    </Container>
  );
};

export default AwsLambdaPageContainer;
