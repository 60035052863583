import Enterprise00Img from '../../../../assets/awsProductCard/awsRedShift/redshiftmain3.png';
import Enterprise01Img from '../../../../assets/awsProductCard/awsRedShift/redshift01.png';
import Enterprise02Img from '../../../../assets/awsProductCard/awsRedShift/redshift02.png';
import Enterprise03Img from '../../../../assets/awsProductCard/awsRedShift/redshift03.png';

import {
  RedShiftContentData,
  AwsRedShiftContentLayout,
} from './AwsRedShiftModel';

export const redShiftContentDatasEn: RedShiftContentData[] = [
  {
    img: Enterprise00Img,
    layout: AwsRedShiftContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'LG CNS supports the implementation of secure and scalable modern data platforms using Amazon Redshift',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Develop a secure, flexible data strategy and architecture to empower users across your organization to tap into diverse data and power business insights while applying real-time analytics and machine learning (ML) techniques. Harness data to its full potential—from ingesting, storing, and accessing data to analyzing, visualizing, and predicting data in all forms. Achieve all of this without requiring specialized data warehouse management experience. ',
              options: {
                //size: 'content-lg',
                //bold: true,
                //color: 'primary',
              },
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise01Img,
    layout: AwsRedShiftContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'EASY, SECURE AND RELIABLE ANALYTICS FOR EVERYONEZ',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Focus on getting to insights in seconds, securely and without worrying about managing your data warehouse.',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise02Img,
    layout: AwsRedShiftContentLayout.TXT_IMG,
    content: {
      title: [
        {
          text: 'ANALYZE ALL YOUR DATA',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Break through data silos and run real-time and predictive analytics on complex, scaled data across your operational databases, data lake, data warehouse and third-party data sets with built in governance',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsRedShiftContentLayout.IMG_TXT,
    content: {
      title: [
        {
          text: 'BEST PRICE-PERFORMANCE AT ANY SCALE',
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: 'Gain up to 5x better price performance than other cloud data warehouses, and dynamically scale to improve query speed for complex and critical workloads',
            },
          ],
        },
      ],
    },
  },
  {
    img: Enterprise03Img,
    layout: AwsRedShiftContentLayout.TXT_TXT_TXT,
    content: {
      title: [
        {
          text: `Amazon Redshift features at a glance

          `,
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `Amazon Redshift Serverless`,
              options: {
                bold: true,
              },
            },
            {
              text: ` makes it easy to run and scale analytics in seconds without the need to set up and manage data warehouse infrastructure.

              `,
            },
            {
              text: `With Redshift ML,`,
              options: {
                bold: true,
              },
            },
            {
              text: ` you can use SQL statements to create and train machine learning models on your data in Amazon Redshift and then use those models for predictions.`,
            },
          ],
        },
      ],
    },
    content2: {
      title: [
        {
          text: `

          `,
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `Query Editor v2`,
              options: {
                bold: true,
              },
            },
            {
              text: ` provides an easy way to create and browse database objects, run and share your queries, visualize query results, and load and unload data from web-based tool.

              `,
            },
            {
              text: `Data Sharing enables`,
              options: {
                bold: true,
              },
            },
            {
              text: ` you to share data across multiple clusters across different AWS accounts and across different AWS regions. There is no need move or duplicate datasets.`,
            },
          ],
        },
      ],
    },
    content3: {
      title: [
        {
          text: `

          `,
        },
      ],
      summary: [
        {
          type: 'default',
          contents: [
            {
              text: `RA3 instances`,
              options: {
                bold: true,
              },
            },
            {
              text: ` separates compute and storage, so you can scale each independently and only pay for the storage you need.

              `,
            },
            {
              text: `Automated performance tuning`,
              options: {
                bold: true,
              },
            },
            {
              text: ` allows you to get the best performance for your workloads without the undifferentiated heavy lifting of tuning your data warehouse. For example automatic optimal table design, Auto Materialized Views, Auto-Query re-write, auto workload management  and so on.`,
            },
          ],
        },
      ],
    },
  },
];

