export default class AdminService {
  /* eslint-disable */
  public async getIPAddress(): Promise<any> {
    try {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const locationIp = await ipData.json();

      return locationIp.IPv4;
    } catch (error: any) {}
  }

  public checkCloudIP(ip: string) {
    /*2022-11-07 / 사업부 요청에 따라 임시로 전체 ip 대역대 open*/
    // return true;

    if (process.env.REACT_APP_NODE_ENV == 'local') return true;

    var ipformat =
      /^(27)\.(122)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    return ipformat.test(ip);
  }

  /* eslint-enable */
}
