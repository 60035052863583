import '../../blogRegist/components/BlogBoardRegistForm.scss';
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  BlogWriterKor,
  BlogWriterEn,
  Language,
  State,
  BlogObjectKor,
  BlogObjectEn,
  BlogCategoryKor,
  BlogCategoryEn,
} from '../../../../service/common/model/enum/Common.enum';
import { Blog, BlogBoard } from '../../../../service/blog/model/Blog';
import BlogService from '../../../../service/blog/BlogService';
import S3UploadService from '../../../../service/S3Service';
import { useDispatch } from 'react-redux';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import { Editor } from '@tinymce/tinymce-react';
import { TinyMCE } from 'tinymce';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import { ContentDispositionType } from '../../../../service/common/model/enum/s3.enum';
import {
  S3FileCopyRequest,
  S3FileDeleteRequest,
  S3FileUploadRequest,
} from '../../../../service/common/model/s3BucketRequest.model';
declare const tinymce: TinyMCE;

const BlogBoardEditForm: React.FC = () => {
  const navigate = useNavigate();
  const { url } = useParams();

  const [blogTitle, setBlogTitle] = useState('');
  const [blogSubtitle, setBlogSubtitle] = useState('');
  const [blogWriterKorLnk, setBlogWriterKorLnk] = useState<string>('');
  const [blogWriterEnLnk, setBlogWriterEnLnk] = useState<string>('');
  const [blogWriter, setBlogWriter] = useState<string>(blogWriterKorLnk);
  const [blogText, setBlogText] = useState<any>('');
  const [blogObject, setBlogObject] = useState<string[]>([]);
  const [blogCategory, setBlogCategory] = useState<string[]>([]);

  const [blogTitleEn, setBlogTitleEn] = useState('');
  const [blogSubtitleEn, setBlogSubtitleEn] = useState('');
  const [blogWriterEn, setBlogWriterEn] = useState<string>(blogWriterEnLnk);
  const [blogTextEn, setBlogTextEn] = useState<any>('');
  const [blogObjectEn, setBlogObjectEn] = useState<string[]>([]);
  const [blogCategoryEn, setBlogCategoryEn] = useState<string[]>([]);

  const [blogState, setBlogState] = useState<State>(State.ALL);
  const [blogLang, setBlogLang] = useState<Language>(Language.KO);
  const blogService = new BlogService();

  const [uploadedFileKeyList, setUploadedFileKeyList] = useState<string[]>([]);
  const uploadList = useRef<string[]>([]);
  const uploadedList = useRef<string[]>([]);
  const deleteList = useRef<string[]>([]);

  const s3UploadService = new S3UploadService();

  const dispatch = useDispatch();
  const dirKey = useRef<string>(uuidv4());
  const s3Domain = process.env.REACT_APP_S3_BUCKET
    ? process.env.REACT_APP_S3_BUCKET
    : '';

  const uploadToS3 = async (blobInfo: any): Promise<string> => {
    // uploadKey = /image/blog/{dirKey}/{fileKey}
    const fileKey = uuidv4();
    const uploadRequest: S3FileUploadRequest = {
      dirName: 'blog',
      dirKey: dirKey.current,
      fileKey: fileKey,
      fileBody: blobInfo?.base64(),
      contentType: blobInfo?.blob()?.type,
      fileName: blobInfo?.blob()?.name,
      contentDispositionType: ContentDispositionType.IMAGE,
    };

    const uploadResponse = await s3UploadService.uploadToS3(uploadRequest);

    if (uploadResponse && uploadResponse.successOrNot == 'Y') {
      setUploadedFileKeyList((prevList) => [fileKey, ...prevList]);
      return uploadResponse.data?.uploadKey;
    } else {
      return '';
    }
  };

  const image_upload_handler = (
    blobInfo: any,
    progress: any
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      if (blobInfo?.blobUri().includes(s3Domain + '/image/blog')) {
        reject();
      } else {
        uploadToS3(blobInfo).then((val) => {
          resolve(s3Domain + val);
        });
      }
    });

  const handleBlogWriterBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (blogLang == Language.KO) {
      setBlogWriter(value);
      transLangWriter(String(event.target.attributes[1].nodeValue));
    } else {
      setBlogWriterEn(value);
      transLangWriter(String(event.target.attributes[1].nodeValue));
    }
  };

  const transLangWriter = (key: string) => {
    type userType = {
      [key: string]: string;
    };
    const BlogWriterKorList: userType = BlogWriterKor;
    const BlogWriterEnList: userType = BlogWriterEn;

    if (blogLang == Language.KO) {
      setBlogWriterEnLnk(BlogWriterEnList[key]);
      setBlogWriterEn(BlogWriterEnList[key]);
    } else {
      setBlogWriterKorLnk(BlogWriterKorList[key]);
      setBlogWriter(BlogWriterKorList[key]);
    }
  };

  const blogWriterListKor = (
    Object.keys(BlogWriterKor) as (keyof typeof BlogWriterKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="blogWriteKor"
            name={key}
            data-testid="blogWriterKor"
            value={BlogWriterKor[key]}
            type="radio"
            checked={blogWriter === BlogWriterKor[key]}
            onChange={handleBlogWriterBtn}
          ></input>
          <span>{BlogWriterKor[key]}</span>
        </Col>
      </label>
    );
  });

  const blogWriterListEn = (
    Object.keys(BlogWriterEn) as (keyof typeof BlogWriterEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="blogWriterEn"
            name={key}
            data-testid="blogWriterEn"
            value={BlogWriterEn[key]}
            type="radio"
            checked={blogWriterEn === BlogWriterEn[key]}
            onChange={handleBlogWriterBtn}
          ></input>
          <span>{BlogWriterEn[key]}</span>
        </Col>
      </label>
    );
  });

  const handleBlogObjectBtn = (
    key: keyof typeof BlogObjectKor | keyof typeof BlogObjectEn
  ): void => {
    if (blogLang == Language.KO) {
      const keyEn = ('E' + key.slice(1)) as keyof typeof BlogObjectEn;
      const valueKor = BlogObjectKor[key as keyof typeof BlogObjectKor];
      const valueEn = BlogObjectEn[keyEn];
      setStateObject(valueKor, valueEn);
    } else {
      const keyKor = ('K' + key.slice(1)) as keyof typeof BlogObjectKor;
      const valueKor = BlogObjectKor[keyKor];
      const valueEn = BlogObjectEn[key as keyof typeof BlogObjectEn];
      setStateObject(valueKor, valueEn);
    }
  };

  const setStateObject = (
    valueKor: BlogObjectKor,
    valueEn: BlogObjectEn
  ): void => {
    if (blogObject.includes(valueKor)) {
      setBlogObject(blogObject.filter((obj) => obj !== valueKor));
      setBlogObjectEn(blogObjectEn.filter((obj) => obj !== valueEn));
    } else {
      setBlogObject([valueKor, ...blogObject]);
      setBlogObjectEn([valueEn, ...blogObjectEn]);
    }
  };

  const blogObjectListKor = (
    Object.keys(BlogObjectKor) as (keyof typeof BlogObjectKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="blogObjectKor"
            name="blogObjectKor"
            data-testid="blogObjectKor"
            value={BlogObjectKor[key]}
            checked={blogObject.includes(BlogObjectKor[key]) || false}
            type="checkbox"
            onChange={() => handleBlogObjectBtn(key)}
          ></input>
          <span>{BlogObjectKor[key]}</span>
        </Col>
      </label>
    );
  });

  const blogObjectListEn = (
    Object.keys(BlogObjectEn) as (keyof typeof BlogObjectEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="blogObjectEn"
            name="blogObjectEn"
            data-testid="blogObjectEn"
            value={BlogObjectEn[key]}
            checked={blogObjectEn.includes(BlogObjectEn[key]) || false}
            type="checkbox"
            onChange={() => handleBlogObjectBtn(key)}
          ></input>
          <span>{BlogObjectEn[key]}</span>
        </Col>
      </label>
    );
  });

  const handleBlogCategoryBtn = (
    key: keyof typeof BlogCategoryKor | keyof typeof BlogCategoryEn
  ): void => {
    if (blogLang == Language.KO) {
      const keyEn = ('E' + key.slice(1)) as keyof typeof BlogCategoryEn;
      const valueKor = BlogCategoryKor[key as keyof typeof BlogCategoryKor];
      const valueEn = BlogCategoryEn[keyEn];

      setStateCategory(valueKor, valueEn);
    } else {
      const keyKor = ('K' + key.slice(1)) as keyof typeof BlogCategoryKor;
      const valueKor = BlogCategoryKor[keyKor];
      const valueEn = BlogCategoryEn[key as keyof typeof BlogCategoryEn];

      setStateCategory(valueKor, valueEn);
    }
  };

  const setStateCategory = (
    valueKor: BlogCategoryKor,
    valueEn: BlogCategoryEn
  ): void => {
    if (blogCategory.includes(valueKor)) {
      setBlogCategory(blogCategory.filter((obj) => obj !== valueKor));
      setBlogCategoryEn(blogCategoryEn.filter((obj) => obj !== valueEn));
    } else {
      setBlogCategory([valueKor, ...blogCategory]);
      setBlogCategoryEn([valueEn, ...blogCategoryEn]);
    }
  };

  const blogCategoryListKor = (
    Object.keys(BlogCategoryKor) as (keyof typeof BlogCategoryKor)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="blogCategoryKor"
            data-testid="blogCategoryKor"
            value={BlogCategoryKor[key]}
            type="checkbox"
            checked={blogCategory.includes(BlogCategoryKor[key]) || false}
            onChange={() => handleBlogCategoryBtn(key)}
          ></input>
          <span>{BlogCategoryKor[key]}</span>
        </Col>
      </label>
    );
  });

  const blogCategoryListEn = (
    Object.keys(BlogCategoryEn) as (keyof typeof BlogCategoryEn)[]
  ).map((key, index) => {
    return (
      <label key={index} className="language">
        <Col>
          <input
            id="blogCategoryEn"
            name="blogCategoryEn"
            data-testid="blogCategoryEn"
            value={BlogCategoryEn[key]}
            type="checkbox"
            checked={blogCategoryEn.includes(BlogCategoryEn[key]) || false}
            onChange={() => handleBlogCategoryBtn(key)}
          ></input>
          <span>{BlogCategoryEn[key]}</span>
        </Col>
      </label>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (url) {
      getBlogData(url);
    }
  }, []);

  const handleLanguageBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (value == Language.KO) {
      setBlogLang(Language.KO);
    } else if (value == Language.EN) {
      setBlogLang(Language.EN);
    }
  };

  const handleOnBlogTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (blogLang == Language.KO) {
      setBlogTitle(value);
    } else {
      setBlogTitleEn(value);
    }
  };

  const handleStateBtn = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value === State.ALL) {
      setBlogState(State.ALL);
    } else if (value === State.ADMIN) {
      setBlogState(State.ADMIN);
    }
  };

  const handleCancelBtn = () => {
    if (confirm('blog 수정을 취소하시겠습니까?')) {
      alert('blog 수정이 취소되었습니다.');
      navigate('/admin/blog');
    }
  };

  const setRequestImgData = async () => {
    const routeStr = 'tmp/image/blog/' + dirKey.current + '/';
    await uploadedFileKeyList.map(async (key) => {
      if (blogText.includes(key) || blogTextEn.includes(key)) {
        uploadList.current.push(routeStr + key);
      }
    });

    //deleteList에 기존 존재하던 이미지명 포함해서 보내기
    const precRouteStr = 'image/blog/' + dirKey.current + '/';
    await uploadedList.current.map(async (key) => {
      if (!blogText.includes(key) && !blogTextEn.includes(key)) {
        deleteList.current.push(precRouteStr + key);
      }
    });
  };

  const getRequestBlogData = () => {
    if (blogTitle == '') {
      alert('한글 제목을 입력해주세요.');
      return;
    }
    if (blogText == '') {
      alert('한글 내용을 입력해주세요.');
      return;
    }
    if (blogWriter == '') {
      alert('작성자를 입력해주세요.');
      return;
    }

    const blogBoard: BlogBoard = {
      blogLang: Language.KO,
      blogWriter: blogWriter,
      blogObject: blogObject,
      blogCategory: blogCategory,
      blogTitle: blogTitle,
      blogSubtitle: blogSubtitle,
      blogText: blogText.replaceAll('/tmp/image', '/image'),
    };

    const blogBoardEn: BlogBoard = {
      blogLang: Language.EN,
      blogWriter: blogWriterEn,
      blogObject: blogObjectEn,
      blogCategory: blogCategoryEn,
      blogTitle: blogTitleEn,
      blogSubtitle: blogSubtitleEn,
      blogText: blogTextEn
        ? blogTextEn.replaceAll('/tmp/image', '/image')
        : blogTextEn,
    };

    const requestData: Blog = {
      blogUrl: '', //백엔드에서 처리
      blogState: blogState,
      blogView: 0, //백엔드에서 처리
      registerAdmin: '', //백엔드에서 처리
      registeredDate: '', //백엔드에서 처리
      modifyAdmin: '', //백엔드에서 처리
      modifiedDate: '', //백엔드에서 처리
      blogBoard: { ko: blogBoard, en: blogBoardEn },
    };

    return requestData;
  };

  const handleEditBtn = async () => {
    const request = getRequestBlogData();
    await setRequestImgData();
    let copyResponse;

    if (uploadList.current.length > 0) {
      const fileCopyRequest: S3FileCopyRequest = {
        uploadFileKeyList: uploadList.current,
      };
      copyResponse = await s3UploadService.copyTmpFiles(fileCopyRequest);
    }

    if (copyResponse && copyResponse.successOrNot == 'N') {
      alert('Blog 수정 중 오류가 발생했습니다.');
    } else if (
      !copyResponse ||
      (copyResponse && copyResponse.successOrNot == 'Y')
    ) {
      if (request && url) {
        updateBlog(request, url);
      }
    }
  };

  const updateBlog = async (request: Blog, blogUrl: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await blogService.updateBlog(request, blogUrl);
    if (response.successOrNot == 'Y') {
      deleteImgFile();
      alert('Blog 수정이 완료 되었습니다.');
      navigate('/admin/blog/' + blogUrl);
    } else {
      alert('Blog 수정 중 오류가 발생하였습니다.');
    }

    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const deleteImgFile = () => {
    const deleteFileRequest: S3FileDeleteRequest = {
      dirName: 'blog',
      dirKey: dirKey.current,
      contentDispositionType: ContentDispositionType.IMAGE,
    };

    if (deleteList.current.length > 0) {
      deleteFileRequest.deleteFileKeyList = deleteList.current;
    }

    s3UploadService.deleteFiles(deleteFileRequest);
  };

  const getBlogData = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await blogService.getBlogDetail(url);
    if (response) {
      if (response.blogBoard.en) {
        setBlogLang(response.blogBoard.en.blogLang);
        setBlogWriterEn(response.blogBoard.en.blogWriter);
        setBlogTitleEn(response.blogBoard.en.blogTitle);

        if (response.blogBoard.en.blogSubtitle) {
          setBlogSubtitleEn(response.blogBoard.en.blogSubtitle);
        }
        if (response.blogBoard.en.blogObject) {
          setBlogObjectEn(response.blogBoard.en.blogObject);
        }
        if (response.blogBoard.en.blogCategory) {
          setBlogCategoryEn(response.blogBoard.en.blogCategory);
        }
        setBlogTextEn(response.blogBoard.en.blogText);

        setTextImgList(response.blogBoard.en.blogText);
      }
      setBlogState(response.blogState);
      setBlogLang(response.blogBoard.ko.blogLang);
      setBlogWriter(response.blogBoard.ko.blogWriter);
      setBlogTitle(response.blogBoard.ko.blogTitle);

      if (response.blogBoard.ko.blogSubtitle) {
        setBlogSubtitle(response.blogBoard.ko.blogSubtitle);
      }
      if (response.blogBoard.ko.blogObject) {
        setBlogObject(response.blogBoard.ko.blogObject);
      }
      if (response.blogBoard.ko.blogCategory) {
        setBlogCategory(response.blogBoard.ko.blogCategory);
      }
      setBlogText(response.blogBoard.ko.blogText);

      setTextImgList(response.blogBoard.ko.blogText);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const setTextImgList = (blogText: string) => {
    //업로드 완료된 이미지 리스트 만들기
    const html = document.createElement('html');
    html.innerHTML = blogText;
    const imgList: HTMLImageElement[] = Array.prototype.slice.call(
      html.getElementsByTagName('img')
    );
    imgList.map((img, idx) => {
      const tmp = img.getAttribute('src')?.split('/');
      if (tmp) {
        const key = tmp[tmp?.length - 1];
        if (idx === 0) {
          const prevDirKey = tmp[tmp?.length - 2];
          if (prevDirKey) dirKey.current = prevDirKey;
        }
        uploadedList.current.push(key);
      }
    });
  };

  return (
    <div id="BlogBoardRegist">
      <ProgressBar />
      <Form id="BlogBoardEditForm" name="BlogBoardEditForm">
        <div className="fullpage pc">
          <Row className="search">
            <Col>
              <span className="blog-admin-title">Blog 수정 (</span>
              <span className="red-star"></span>
              <span className="blog-admin-title"> 은 필수값 입니다.)</span>
            </Col>
          </Row>
          <div className="blog-info">
            <Row>
              <Col className="blog-col red-star">언어</Col>
              <div className="radio-buttons">
                <label className="language">
                  <Col>
                    <input
                      id="ko"
                      value={Language.KO}
                      name="language"
                      type="radio"
                      onChange={handleLanguageBtn}
                      checked={blogLang == Language.KO}
                    />
                    <span>한국어</span>
                  </Col>
                </label>
                <label className="language">
                  <Col>
                    <input
                      id="en"
                      value={Language.EN}
                      name="language"
                      type="radio"
                      onChange={handleLanguageBtn}
                      checked={blogLang == Language.EN}
                    />
                    <span>English</span>
                  </Col>
                </label>
              </div>
            </Row>
            {blogLang == Language.KO ? (
              <>
                <Row className="radio-area">
                  <Col className="blog-col red-star">공개 여부</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="blogState"
                          name="blogState"
                          data-testid="blogState"
                          value={State.ALL}
                          type="radio"
                          checked={blogState == State.ALL}
                          onChange={handleStateBtn}
                        ></input>
                        <span>모두공개</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="blogState"
                          name="blogState"
                          data-testid="blogState"
                          value={State.ADMIN}
                          type="radio"
                          checked={blogState == State.ADMIN}
                          onChange={handleStateBtn}
                        ></input>
                        <span>비공개</span>
                      </Col>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col className="blog-col red-star">작성자</Col>
                  <div className="radio-buttons">{blogWriterListKor}</div>
                </Row>
                <Row>
                  <Col className="blog-col">목적</Col>
                  <div className="radio-buttons">{blogObjectListKor}</div>
                </Row>
                <Row>
                  <Col className="blog-col">카테고리</Col>
                  <div className="radio-buttons">{blogCategoryListKor}</div>
                </Row>
                <Row>
                  <Col className="blog-col red-star">제목</Col>

                  <Form.Control
                    className="input"
                    type="text"
                    id="blogTitle"
                    name="blogTitle"
                    data-testid="blogTitle"
                    value={blogTitle}
                    onChange={handleOnBlogTitleChange}
                    maxLength={40}
                  ></Form.Control>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col className="blog-col red-star">State</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="blogState"
                          name="blogState"
                          data-testid="blogState"
                          value={State.ALL}
                          type="radio"
                          checked={blogState == State.ALL}
                          onChange={handleStateBtn}
                        ></input>
                        <span>ALL</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="blogState"
                          name="blogState"
                          data-testid="blogState"
                          value={State.ADMIN}
                          type="radio"
                          checked={blogState == State.ADMIN}
                          onChange={handleStateBtn}
                        ></input>
                        <span>ADMIN</span>
                      </Col>
                    </label>
                  </div>
                </Row>

                <Row>
                  <Col className="blog-col red-star">Writer</Col>
                  <div className="radio-buttons">{blogWriterListEn}</div>
                </Row>
                <Row>
                  <Col className="blog-col">Object</Col>
                  <div className="radio-buttons">{blogObjectListEn}</div>
                </Row>
                <Row>
                  <Col className="blog-col">Category</Col>
                  <div className="radio-buttons">{blogCategoryListEn}</div>
                </Row>
                <Row>
                  <Col className="blog-col red-star">Title</Col>
                  <Form.Control
                    className="input"
                    type="text"
                    id="blogTitleEn"
                    name="blogTitleEn"
                    data-testid="blogTitleEn"
                    value={blogTitleEn}
                    onChange={handleOnBlogTitleChange}
                    maxLength={40}
                  ></Form.Control>
                </Row>
              </>
            )}
          </div>

          <div className="regist-body">
            {blogLang == Language.KO ? (
              <Editor
                onEditorChange={(txt, editor) => {
                  setBlogText(txt);
                }}
                initialValue=""
                value={blogText}
                apiKey="gmmjng74ulc7k28dufw17avjz1eboa5744k1jacji2q6xard"
                init={{
                  height: 500,
                  menubar: 'file edit view insert format tools table help',
                  placeholder: '내용을 입력해주세요.',
                  plugins: [
                    'lists',
                    'advlist',
                    'anchor',
                    'autolink',
                    'charmap',
                    'code',
                    'codesample',
                    'directionality',
                    'emoticons',
                    'fullscreen',
                    'help',
                    'image',
                    'importcss',
                    'insertdatetime',
                    'link',
                    'media',
                    'nonbreaking',
                    'pagebreak',
                    'preview',
                    'save',
                    'searchreplace',
                    'table',
                    'template',
                    'visualblocks',
                    'visualchars',
                    'wordcount',
                  ],
                  textcolor_rows: '4',
                  toolbar: [
                    'undo redo | bold italic underline strikethrough | fontsize fontfamily forecolor backcolor removeformat | restoredraft | ltr rtl | help',
                    'link image media emoticons charmap  subscript superscript insertdatetime codesample | alignleft aligncenter alignright alignjustify lineheight | outdent indent | blockquote numlist bullist | pagebreak | fullscreen preview code wordcount | searchreplace',
                    'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                  ],
                  font_family_formats:
                    'Arial=arial,helvetica; sans-serif;NanumSquare;Noto Sans KR; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n',
                  font_size_formats:
                    '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                  line_height_formats: '1 1.2 1.4 1.6 2',
                  toolbar_sticky: true,
                  automatic_uploads: true,
                  file_browser_callback_types: 'image',
                  image_advtab: true,
                  image_title: true,
                  file_picker_types: 'image',
                  content_css: [
                    'https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css',
                    'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap',
                    'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
                  ],
                  forced_root_block_attrs: {
                    style: 'font-family: arial;font-size:12px',
                  },
                  content_style: 'body { font-family:arial,NanumSquare;}',
                  codesample_languages: [
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'CSS', value: 'css' },
                    { text: 'PHP', value: 'php' },
                    { text: 'Ruby', value: 'ruby' },
                    { text: 'Python', value: 'python' },
                    { text: 'Java', value: 'java' },
                    { text: 'C', value: 'c' },
                    { text: 'C#', value: 'csharp' },
                    { text: 'C++', value: 'cpp' },
                    { text: 'bash', value: 'bash' },
                    { text: 'git', value: 'git' },
                    { text: 'javadoc', value: 'javadoc' },
                    { text: 'sql', value: 'sql' },
                    { text: 'yaml', value: 'yaml' },
                  ],
                  relative_urls: false,
                  images_upload_handler: image_upload_handler,
                  file_picker_callback: function (callback, value, meta) {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = function () {
                      const file = (input as any)?.files[0];
                      const reader = new FileReader();

                      reader.onload = function () {
                        const id = 'blobid' + new Date().getTime();
                        const blobCache =
                          tinymce?.activeEditor?.editorUpload.blobCache;
                        const base64 = reader.result?.toString().split(',')[1];
                        const blobInfo = base64
                          ? blobCache?.create(id, file, base64)
                          : undefined;
                        blobInfo ? blobCache?.add(blobInfo) : {};
                        blobInfo
                          ? callback(blobInfo?.blobUri(), { title: file.name })
                          : {};
                      };
                      reader.readAsDataURL(file);
                    };

                    input.click();
                  },
                  invalid_elements: 'iframe'
                }}
              />
            ) : (
              <Editor
                onEditorChange={(txt, editor) => {
                  setBlogTextEn(txt);
                }}
                init={{
                  invalid_elements: 'iframe'
                }}
                initialValue=""
                value={blogTextEn}
                apiKey="gmmjng74ulc7k28dufw17avjz1eboa5744k1jacji2q6xard"
              />
            )}
          </div>

          <div className="btn-container">
            <Button
              className="btn btn-danger"
              onClick={handleCancelBtn}
              data-testid="cancel-button"
            >
              취소
            </Button>
            <Button
              className="btn btn-light"
              id="registBtn"
              onClick={handleEditBtn}
              data-testid="regist-button"
            >
              수정
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BlogBoardEditForm;
