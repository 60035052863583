import './BusinessBoardRegistContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import BusinessBoardRegistForm from './components/BusinessBoardRegistForm';

const BusinessBoardRegistContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0px' }}>
      <div id="BusinessBoardRegistContainer">
        <BusinessBoardRegistForm />
      </div>
    </Container>
  );
};

export default BusinessBoardRegistContainer;
