import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';

import Header from './layouts/Header';
import LoginContainer from './login/LoginContainer';
import BusinessBoardContainer from './businessBoard/BusinessBoardContainer';
import CaseBoardContainer from './caseBoard/CaseBoardContainer';
import CaseDetailContainer from './caseDetail/CaseDetailContainer';
import ErrorPageContainer from '../error/ErrorPageContainer';
import CaseBoardRegistContainer from './caseRegist/CaseBoardRegistContainer';
import CaseBoardEditContainer from './caseEdit/CaseBoardEditContainer';
import NewsBoardContainer from './newsBoard/NewsBoardContainer';
import NewsDetailContainer from './newsDetail/NewsDetailContainer';
import NewsBoardRegistContainer from './newsRegist/NewsBoardRegistContainer';
import NewsBoardEditContainer from './newsEdit/NewsBoardEditContainer';
import BlogBoardContainer from './blogBoard/BlogBoardContainer';
import BlogDetailContainer from './blogDetail/BlogDetailContainer';
import BlogBoardRegistContainer from './blogRegist/BlogBoardRegistContainer';
import BlogBoardEditContainer from './blogEdit/BlogBoardEditContainer';

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/modules';
import AdminService from '../../service/admin/AdminService';
import BusinessDetailContainer from './businessDetail/BusinessDetailContainer';
import BusinessBoardRegistContainer from './businessRegist/BusinessBoardRegistContainer';
import BusinessBoardEditContainer from './businessEdit/BusinessBoardEditContainer';
import ChangePasswordContainer from './changePassword/ChangePasswordContainer';

const AdminPage: React.FC = () => {
  const authorized = useSelector(
    (state: RootState) => state.authorization.authorized
  );
  const logout = useSelector((state: RootState) => state.logout.logout);
  const location = useLocation();
  const [pathname, setPathname] = useState('/admin/business');

  useEffect(() => {
    if (
      location.pathname !== '/admin' &&
      location.pathname !== '/admin/' &&
      location.pathname !== '/admin/login'
    ) {
      setPathname(location.pathname);
    }
  }, []);

  const adminService = new AdminService();

  const localIsCloudIp = localStorage.getItem('isCloudIp');
  const [isCloudIp, setIsCloudIp] = useState<string>(
    localIsCloudIp ? localIsCloudIp : 'N'
  );

  useEffect(() => {
    if (!isCloudIp || isCloudIp !== 'Y') {
      adminService.getIPAddress().then((response) => {
        if (adminService.checkCloudIP(response)) {
          localStorage.setItem('isCloudIp', 'Y');
        } else {
          localStorage.setItem('isCloudIp', 'N');
        }
      });
    }
  }, []);

  if (
    !authorized &&
    location.pathname !== '/admin' &&
    location.pathname !== '/admin/' &&
    location.pathname !== '/admin/login' &&
    location.pathname !== '/admin/change-password' &&
    !logout
  ) {
    alert('세션이 만료되었습니다. 다시 로그인해주세요.');
  }

  return (
    <>
      {location.pathname === '/admin/login' ||
      location.pathname === '/admin/change-password' ||
      isCloudIp === 'N' ? (
        <></>
      ) : (
        <Header />
      )}
      <Routes>
        {isCloudIp == 'N' ? (
          <Route path="*" element={<ErrorPageContainer />} />
        ) : !authorized ? (
          <>
            <Route
              path="/change-password"
              element={<ChangePasswordContainer />}
            />
            <Route path="/login" element={<LoginContainer />} />
            <Route path="*" element={<Navigate replace to="/admin/login" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate replace to={pathname} />} />
            <Route path="/login" element={<Navigate replace to={pathname} />} />
            <Route path="/business" element={<BusinessBoardContainer />} />
            <Route
              path="/business/:url"
              element={<BusinessDetailContainer />}
            />
            <Route
              path="/business/regist"
              element={<BusinessBoardRegistContainer />}
            />
            <Route
              path="/business/edit/:url"
              element={<BusinessBoardEditContainer />}
            />
            <Route path="/case" element={<CaseBoardContainer />} />
            <Route path="/case/:url" element={<CaseDetailContainer />} />
            <Route path="/case/regist" element={<CaseBoardRegistContainer />} />
            <Route
              path="/case/edit/:url"
              element={<CaseBoardEditContainer />}
            />
            <Route path="/news" element={<NewsBoardContainer />} />
            <Route path="/news/:id" element={<NewsDetailContainer />} />
            <Route path="/news/regist" element={<NewsBoardRegistContainer />} />
            <Route path="/news/edit/:id" element={<NewsBoardEditContainer />} />

            <Route path="/blog" element={<BlogBoardContainer />} />
            <Route path="/blog/:id" element={<BlogDetailContainer />} />
            <Route path="/blog/regist" element={<BlogBoardRegistContainer />} />
            <Route
              path="/blog/edit/:url"
              element={<BlogBoardEditContainer />}
            />
            <Route path="*" element={<ErrorPageContainer />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AdminPage;
