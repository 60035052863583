import React, { useState, useEffect } from 'react';
import './MainEntrance.scss';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import AWSBadge from '../../../assets/main/mainEntrance/AWS_partner_banner.png';

const MainEntrance = () => {
  const { t } = useTranslation();
  const settings = {
    slide: 'div',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false,
    fade: true,
    vertical: false,
    dotsClass: 'slick-dots',
    draggable: false,
  };

  return (
    <Slider {...settings}>
      <div className="fullpage-slide main-entrance" data-cy="MainEntrance">
        <h1 className="title1-main">{t('main.MainEntrance.subTitle')}</h1>
        <h2 className="title2-main">
          {t('main.MainEntrance.title.txt1')}
          <br />
          {t('main.MainEntrance.title.txt2')}
          {t('main.MainEntrance.title.txt3')}
          <br />
        </h2>
        <p className="txt-con-main">{t('main.MainEntrance.title.txt4')}</p>
        <div className="aws-partner-banner">
          <img src={AWSBadge} />
        </div>
        <img className="main-entrance-bg bg1"></img>
      </div>
      <div className="fullpage-slide main-entrance" data-cy="MainEntrance">
        <h1 className="title1-main">{t('main.MainEntrance.subTitle')}</h1>
        <h2 className="title2-main">
          {t('main.MainEntrance.title.txt1')}
          <br />
          {t('main.MainEntrance.title.txt2')}
          {t('main.MainEntrance.title.txt3')}
          <br />
        </h2>
        <p className="txt-con-main">
          {t('main.MainEntrance.title.txt4')}
        </p>
        <div className="aws-partner-banner">
          <img src={AWSBadge} />
        </div>
        <img className="main-entrance-bg bg2"></img>
      </div>
      <div className="fullpage-slide main-entrance" data-cy="MainEntrance">
        <h1 className="title1-main">{t('main.MainEntrance.subTitle')}</h1>
        <h2 className="title2-main">
          {t('main.MainEntrance.title.txt1')}
          <br />
          {t('main.MainEntrance.title.txt2')}
          {t('main.MainEntrance.title.txt3')}
          <br />
        </h2>
        <p className="txt-con-main">
          {t('main.MainEntrance.title.txt4')}
        </p>
        <div className="aws-partner-banner">
          <img src={AWSBadge} />
        </div>
        <img className="main-entrance-bg bg3"></img>
      </div>
    </Slider>
  );
};

export default MainEntrance;
