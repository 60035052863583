import { LoadingAction, CHANGE_LOADING_STATUS } from '../actions/loading';

type LoadingStatus = {
  loading: boolean;
};

const initialState: LoadingStatus = {
  loading: false,
};

const loading = (
  state: LoadingStatus = initialState,
  action: LoadingAction
): LoadingStatus => {
  switch (action.type) {
    case CHANGE_LOADING_STATUS:
      return { loading: action.loading };
    default:
      return state;
  }
};

export default loading;
