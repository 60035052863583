import './BusinessPageContainer.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import BusinessSection from './components/BusinessSection';

const BusinessPageContainer: React.FC = () => {
  // TO-DO : 메뉴별 개별 헤더 CSS 적용
  // window.onload = function () {
  //   document.getElementsByClassName('gnb')[0].className = 'gnb';
  // };
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="BusinessPageContainer">
        <BusinessSection />
      </div>
    </Container>
  );
};

export default BusinessPageContainer;
