import './CaseDetailSection.scss';

import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { parseStringToDate } from '../../../../service/utils/utils';

import { Case } from '../../../../service/case/model/Case';
import CaseService from '../../../../service/case/CaseService';
import {
  Language,
  State,
} from '../../../../service/common/model/enum/Common.enum';
import CustomRadioBtn from '../../../common/CustomRadioBtn';
import CaseDetailPageAdmin from './CaseDetailPageAdmin';
import CaseBoxPageAdmin from './CaseBoxPageAdmin';

// aws 페이지 링크를 위한 이미지 데이터
import CaseDetailSsangYong from '../../../../assets/caseDetail/CaseDetailFullImg/CaseDetailSsangYong.png';
import CaseDetailGSRetail from '../../../../assets/caseDetail/CaseDetailFullImg/CaseDetailGSRetail.png';
import CaseDetailDataHub from '../../../../assets/caseDetail/CaseDetailFullImg/CaseDetailDataHub.png';
import CaseDetailTwosome from '../../../../assets/caseDetail/CaseDetailFullImg/CaseDetailTwosome.png';
import CaseDetailHanwha from '../../../../assets/caseDetail/CaseDetailFullImg/CaseDetailHanwha.jpg';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import Loading from '../../../../layouts/Loading';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';

const CaseDetailSection = () => {
  const { url } = useParams();
  const { state } = useLocation() as unknown as { state: boolean }; // true : 임시저장 데이터 조회, false|null : 등록된 데이터 조회
  const navigate = useNavigate();
  const [caseData, setCaseData] = useState<Case>();
  const [caseLang, setCaseLang] = useState<string>(Language.KO);
  const [caseDataDetail, setCaseDataDetail] = useState<{
    caseDetailUrl: string;
    isFullImg: string;
    content?: string;
    component?: any;
  }>();

  const caseService: CaseService = new CaseService();

  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  // prettier-ignore
  const caseDetailHtml = [
    { caseDetailUrl: 'case-cloud-SsangYong', isFullImg: 'Y', content: CaseDetailSsangYong,},
    { caseDetailUrl: 'case-cloud-GSRetail', isFullImg: 'Y', content: CaseDetailGSRetail, },
    { caseDetailUrl: 'case-cloud-insuranceDataHub', isFullImg: 'Y', content: CaseDetailDataHub,},
    { caseDetailUrl: 'case-cloud-Twosome', isFullImg: 'Y', content: CaseDetailTwosome,},
    { caseDetailUrl: 'case-cloud-Hanwha', isFullImg: 'Y', content: CaseDetailHanwha,},
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (url) {
      state ? getTempCase(url) : getCase(url);
    } else {
      navigate('/admin/case');
    }
  }, []);

  const getCase = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await caseService.getCaseByUrl(url);
    if (response) {
      const data = response;
      setLoadedData(data);
    } else {
      navigate('/case');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const getTempCase = async (url: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await caseService.getTempCaseDetail(url);
    if (response) {
      const data = response;
      if (data.caseBoard.ko.caseThumbnail) {
        data.caseBoard.ko.caseThumbnail = '/' + data.caseBoard.ko.caseThumbnail;
      }
      if (data.caseBoard.ko.caseDetail.caseTitleBg) {
        data.caseBoard.ko.caseDetail.caseTitleBg =
          '/' + data.caseBoard.ko.caseDetail.caseTitleBg;
      }
      if (data.caseBoard.ko.caseDetail.caseCompanyLogo) {
        data.caseBoard.ko.caseDetail.caseCompanyLogo =
          '/' + data.caseBoard.ko.caseDetail.caseCompanyLogo;
      }

      if (data.caseBoard.en) {
        if (data.caseBoard.en.caseThumbnail) {
          data.caseBoard.en.caseThumbnail =
            '/' + data.caseBoard.en.caseThumbnail;
        }
        if (data.caseBoard.en.caseDetail.caseTitleBg) {
          data.caseBoard.en.caseDetail.caseTitleBg =
            '/' + data.caseBoard.en.caseDetail.caseTitleBg;
        }
        if (data.caseBoard.en.caseDetail.caseCompanyLogo) {
          data.caseBoard.en.caseDetail.caseCompanyLogo =
            '/' + data.caseBoard.en.caseDetail.caseCompanyLogo;
        }
      }
      setLoadedData(data);
    } else {
      navigate('/case');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  useEffect(() => {
    setLoadedData(caseData);
  }, [caseLang]);

  const setLoadedData = (data: Case | undefined) => {
    setCaseData(data);
    setCaseDataDetail(
      caseDetailHtml.find(
        (detail) => detail.caseDetailUrl === url && detail.isFullImg === 'Y'
      ) || {
        caseDetailUrl: url || '',
        isFullImg: 'N',
        component: (
          <CaseDetailPageAdmin
            caseBoard={data?.caseBoard[caseLang == 'ko' ? 'ko' : 'en']}
          />
        ),
      }
    );
  };

  const modifyBtnHandler = () => {
    state
      ? navigate('/admin/case/regist', { state: url })
      : navigate('/admin/case/edit/' + url);
  };

  const deleteBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (
      caseData &&
      confirm('삭제된 case는 복구 할 수 없습니다.\ncase를 삭제하시겠습니까?')
    ) {
      caseService.deleteCaseByUrl(caseData).then((response) => {
        if (response.successOrNot == 'Y') {
          alert('case가 삭제되었습니다.');
          navigate('/admin/case');
        } else {
          alert('case 삭제 중 오류가 발생하였습니다.');
        }
        dispatch(changeLoadingStatus(false));
        dispatch(changeProgressStatus(100));
      });
    }
  };

  const caseListBtnHandler = () => {
    navigate('/admin/case');
  };

  const handleCustomRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (value == Language.KO) {
      setCaseLang(Language.KO);
    } else if (value == Language.EN) {
      setCaseLang(Language.EN);
    }
  };

  return (
    <div id="AdminCaseDetailSection">
      <ProgressBar />
      {caseData ? (
        <CustomRadioBtn
          radioBtnValuesAndNames={[
            { value: Language.KO, name: '한국어', disabled: false },
            { value: Language.EN, name: 'English', disabled: false },
          ]}
          handleRadioBtn={handleCustomRadioChange}
          checkedCondition={caseLang}
        />
      ) : null}
      <div className="detail-header">
        <div className="title" data-testid="case-title">
          {caseLang === Language.KO
            ? caseData?.caseBoard.ko.caseTitle
            : caseData?.caseBoard.en?.caseTitle}
        </div>
        <div className="title-info">
          <div className="register-admin">
            등록자 : {caseData?.registerAdmin}
          </div>
          <div className="registered-date">
            등록 일시 :
            {caseData?.registeredDate &&
              parseStringToDate(caseData?.registeredDate)}
          </div>
          <div className="modify-admin">수정자 : {caseData?.modifyAdmin}</div>
          <div className="modified-date">
            수정 일시 :
            {caseData?.modifiedDate &&
              parseStringToDate(caseData?.modifiedDate)}
          </div>
        </div>
      </div>
      <hr />
      <div className="detail-info-container">
        <div className="detail-info-item">
          <p className="title-admin" data-testid="case-detail-info">
            상세정보
          </p>
          <div className="detail-info detail-caseId">
            ID : {caseData?.caseId}
          </div>
          <div className="detail-info detail-caseUrl">
            URL(중복값 불가) : {caseData?.caseUrl}
          </div>
          <div className="detail-info detail-caseOrderIdx">
            노출순서 : {caseData?.caseOrderIdx}
          </div>
          <div className="detail-info detail-caseState">
            공개범위 :{' '}
            {caseData?.caseState === State.ALL ? '전체공개' : '관리자'}
          </div>
        </div>
        <div className="detail-info-item gray-border-left">
          <p className="title-admin">Case 목록보기 정보</p>
          <div className="wrap-case-box-page-component">
            <CaseBoxPageAdmin
              caseBoard={caseData?.caseBoard[caseLang == 'ko' ? 'ko' : 'en']}
            />
          </div>
        </div>
      </div>
      <hr />
      <p className="title-admin" data-testid="case-detail-preview">
        미리보기
      </p>
      {/* AWS 레퍼런스 링크가 걸려있는 url의 경우, 상세 데이터로 FE에 저장된 fullPageImage 한 장을 보여준다. */}
      {caseDataDetail?.isFullImg === 'Y' ? (
        <div className="fullPage-slide" key={caseDataDetail?.caseDetailUrl}>
          <div className="endPage">
            <div className="title-wrap"></div>
            <img src={caseDataDetail?.content} />
          </div>
        </div>
      ) : (
        <Fragment key="0">{caseDataDetail?.component}</Fragment>
      )}
      <hr />
      <div className="btn-container">
        <Button
          className="btn btn-light"
          data-testid="modify-button"
          onClick={modifyBtnHandler}
        >
          수정
        </Button>
        <Button
          className="btn btn-light"
          data-testid="delete-button"
          onClick={deleteBtnHandler}
        >
          삭제
        </Button>
        <Button
          className="btn btn-secondary"
          data-testid="list-button"
          onClick={caseListBtnHandler}
        >
          목록
        </Button>
      </div>
    </div>
  );
};

export default CaseDetailSection;
