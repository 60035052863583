import './AwsRdsPageContainer.scss';
import React from 'react';
import { Container } from 'react-bootstrap';

import AwsRdsSection from './components/AwsRdsSection';

const AwsRdsPageContainer: React.FC = () => {
  return (
    <Container fluid={true} style={{ padding: '0' }}>
      <div id="AwsRdsPageContainer">
        <AwsRdsSection />
      </div>
    </Container>
  );
};

export default AwsRdsPageContainer;
