import { StatusCode } from './model/Error';

export default function S3ErrorAlert(error_msg: string) {
  let alert_msg = '';
  switch (error_msg) {
    case StatusCode.UNAUTHORIZED_ERR:
      alert_msg = '잘못된 접근 입니다.';
      break;
    case StatusCode.FAIL:
      alert_msg =
        '이미지 업로드 중 알 수 없는 오류가 발생하였습니다. 다시 시도하세요.';
      break;
    case StatusCode.NO_PRESIGNED_URL:
      alert_msg = '이미지 업로드 중 오류가 발생하였습니다. 다시 시도하세요.';
      break;
    case StatusCode.UPLOAD_ERROR:
      alert_msg =
        '이미지 업로드를 실패하였습니다. 페이지 새로고침 후 다시 시도하세요.';
      break;
    case StatusCode.UNKNOWN_S3_ERR:
      alert_msg = '이미지 서버 오류가 발생하였습니다. 관리자에게 문의하세요.';
      break;
    default:
      alert_msg =
        '이미지 업로드 중 오류가 발생하였습니다. 관리자에게 문의하세요.';
  }
  alert(alert_msg);
}
