import React from 'react';
import {
  AwsDirectConnectContentLayout,
  DirectConnectContentData,
} from '../../../../service/data/awsProductCard/awsConnect/AwsDirectConnectModel';
import { getIsMobile } from '../../../../service/utils/utils';
import AwsDirectConnectContentTextItem from './AwsDirectConnectContentTextItem';

interface Props {
  content: DirectConnectContentData;
}

const AwsDirectConnectContentArea: React.FC<Props> = (props: Props) => {
  const { content } = props;
  const isMobile = getIsMobile();

  const renderContents = () => {
    if (content.layout === AwsDirectConnectContentLayout.TXT_IMG) {
      return (
        <>
          <AwsDirectConnectContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      );
    } else if (content.layout === AwsDirectConnectContentLayout.IMG_TXT) {
      //mobile은 TXT-IMG 배치로 고정
      return isMobile ? (
        <>
          <AwsDirectConnectContentTextItem content={content.content} />
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
        </>
      ) : (
        <>
          <div className="content-img-box">
            {content.img && <img src={content.img} className="content-img" />}
          </div>
          <AwsDirectConnectContentTextItem content={content.content} />
        </>
      );
    } else if (content.layout === AwsDirectConnectContentLayout.TXT_TXT) {
      return (
        <>
          <AwsDirectConnectContentTextItem content={content.content} />
          <AwsDirectConnectContentTextItem content={content.content2} />
        </>
      );
    }
  };

  return (
    <div
      className="eks-anywhere-content-cont"
      data-testid="testAwsDirectConnectContentArea"
    >
      {renderContents()}
    </div>
  );
};

export default AwsDirectConnectContentArea;
