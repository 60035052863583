import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { Fragment, ReactElement } from 'react';
const businessList = [0, 0, 0, 0] ;

const BusinessSectionSkeleton: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <div className="end-page-business">
        <div className="business-tab-cont">
          {businessList.map((businessItem, index) => {
            return (
              <div key={index} className='product-box'>
                <div className="product-contents-area">
                  <Skeleton className="content-box" style={{backgroundColor: "#ebebeb"}}/>
                </div>
              </div>
            )})}
        </div>
      </div>
    </Fragment>
  );
};

export default BusinessSectionSkeleton;
