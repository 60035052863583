import './NewsBoardEditForm.scss';

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { ToastEditor } from '../../../common/ToastEditor';
import {
  Language,
  State,
  NewsCategory,
} from '../../../../service/common/model/enum/Common.enum';
import { News, NewsBoard } from '../../../../service/news/model/News';
import NewsService from '../../../../service/news/NewsService';
import S3UploadService from '../../../../service/S3Service';
import { CircularProgress } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/modules';
import { changeLoadingStatus } from '../../../../redux/actions/loading';
import ProgressBar from '../../../../layouts/ProgressBar';
import { changeProgressStatus } from '../../../../redux/actions/progress';
import { v4 as uuidv4 } from 'uuid';
import {
  S3FileCopyRequest,
  S3FileDeleteRequest,
  S3FileUploadRequest,
} from '../../../../service/common/model/s3BucketRequest.model';
import { ContentDispositionType } from '../../../../service/common/model/enum/s3.enum';

const NewsBoardEditForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [newsTitle, setNewsTitle] = useState<string>('');
  const [newsSubtitle, setNewsSubtitle] = useState<string>('');
  const [newsThumbnail, setNewsThumbnail] = useState('');
  const [newsCategory, setNewsCategory] = useState<NewsCategory>(
    NewsCategory.KP
  );
  const [newsText, setNewsText] = useState<string>('');

  const [newsTitleEn, setNewsTitleEn] = useState('');
  const [newsSubtitleEn, setNewsSubtitleEn] = useState('');
  const [newsThumbnailEn, setNewsThumbnailEn] = useState('');
  const [newsCategoryEn, setNewsCategoryEn] = useState<NewsCategory>(
    NewsCategory.EP
  );
  const [newsTextEn, setNewsTextEn] = useState<any>('');

  const [newsState, setNewsState] = useState<State>(State.ALL);
  const [newsLang, setNewsLang] = useState<Language>();
  const newsService = new NewsService();

  const s3UploadService = new S3UploadService();

  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();
  const dirKey = useRef<string>(uuidv4());
  const [uploadedThumbnailFileKeyList, setUploadedThumbnailFileKeyList] =
    useState<string[]>([]);
  const uploadList = useRef<string[]>([]);
  const uploadedList = useRef<string[]>([]);
  const deleteList = useRef<string[]>([]);

  useEffect(() => {
    if (id) {
      getNewsDetail(id);
    }
  }, []);

  const getNewsDetail = async (id: string) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await newsService.getNewsDetail(id);
    if (response.newsBoard.en) {
      setNewsLang(response.newsBoard.en.newsLang);
      setNewsCategoryEn(response.newsBoard.en.newsCategory);
      setNewsTitleEn(response.newsBoard.en.newsTitle);
      if (response.newsBoard.en.newsThumbnail) {
        setNewsThumbnailEn(response.newsBoard.en.newsThumbnail);
        //기등록된 key획득
        const strTmpEn = response.newsBoard.en.newsThumbnail.split('/');
        uploadedList.current.push(strTmpEn[4]);
      }
      if (response.newsBoard.en.newsSubtitle) {
        setNewsSubtitleEn(response.newsBoard.en.newsSubtitle);
      }
      setNewsTextEn(response.newsBoard.en.newsText);
    }
    if (response.newsBoard.ko.newsLang === Language.KO) {
      setNewsState(response.newsState);
      setNewsLang(response.newsBoard.ko.newsLang);
      setNewsCategory(response.newsBoard.ko.newsCategory);
      setNewsTitle(response.newsBoard.ko.newsTitle);
      if (response.newsBoard.ko.newsThumbnail) {
        setNewsThumbnail(response.newsBoard.ko.newsThumbnail);
        //기등록된 key획득
        const strTmp = response.newsBoard.ko.newsThumbnail.split('/');
        const prevDirKey = strTmp[3];
        dirKey.current = prevDirKey;
        uploadedList.current.push(strTmp[4]);
      }
      if (response.newsBoard.ko.newsSubtitle) {
        setNewsSubtitle(response.newsBoard.ko.newsSubtitle);
      }
      setNewsText(response.newsBoard.ko.newsText);
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const handleLanguageBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (value == Language.KO) {
      setNewsLang(Language.KO);
    } else if (value == Language.EN) {
      setNewsLang(Language.EN);
    }
  };

  const handleOnNewsTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (newsLang == Language.KO) {
      setNewsTitle(value);
    } else {
      setNewsTitleEn(value);
    }
  };

  const handleOnNewsSubtitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (newsLang == Language.KO) {
      setNewsSubtitle(value);
    } else {
      setNewsSubtitleEn(value);
    }
  };

  const handleOnNewsThumbnailChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : undefined;
      const fileKey = uuidv4();

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async function () {
          if (reader.result) {
            const baseString = String(reader.result).split(';base64,')[1];
            const uploadRequest: S3FileUploadRequest = {
              dirName: 'news',
              dirKey: dirKey.current,
              fileKey: fileKey,
              fileBody: baseString,
              contentType: file.type,
              fileName: file.name,
              contentDispositionType: ContentDispositionType.IMAGE,
            };
            try {
              const uploadResponse = await s3UploadService.uploadToS3(
                uploadRequest
              );
              if (uploadResponse && uploadResponse.successOrNot == 'Y') {
                setUploadedThumbnailFileKeyList((prevList) => [
                  fileKey,
                  ...prevList,
                ]);
                if (newsLang == Language.KO) {
                  setNewsThumbnail(uploadResponse.data?.uploadKey);
                } else {
                  setNewsThumbnailEn(uploadResponse.data?.uploadKey);
                }
                return uploadResponse.data?.uploadKey;
              } else {
                throw new Error();
              }
            } catch (err) {
              alert('이미지 첨부 중 오류가 발생하였습니다. 다시 시도하세요.');
            } finally {
              dispatch(changeLoadingStatus(false));
              dispatch(changeProgressStatus(100));
              event.target.value = '';
              event.target.files = null;
            }
          }
        };
      }
      return;
    },
    [loading, newsLang]
  );

  const setRequestImgData = async () => {
    const routeStr = 'tmp/image/news/' + dirKey.current + '/';
    await uploadedThumbnailFileKeyList.map(async (key) => {
      if (newsThumbnail.includes(key) || newsThumbnailEn.includes(key)) {
        uploadList.current.push(routeStr + key);
      }
    });

    //deleteList에 기존 존재하던 이미지명 포함해서 보내기
    const precRouteStr = 'image/news/' + dirKey.current + '/';
    await uploadedList.current.map(async (key) => {
      if (!newsThumbnail.includes(key) && !newsThumbnailEn.includes(key)) {
        deleteList.current.push(precRouteStr + key);
      }
    });
  };

  const handleStateBtn = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value === State.ALL) {
      setNewsState(State.ALL);
    } else if (value === State.ADMIN) {
      setNewsState(State.ADMIN);
    }
  };

  const handleCategoryBtn = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    if (newsLang == Language.KO) {
      if (value === NewsCategory.KP) {
        setNewsCategory(NewsCategory.KP);
      } else if (value === NewsCategory.KV) {
        setNewsCategory(NewsCategory.KV);
      } else if (value === NewsCategory.KW) {
        setNewsCategory(NewsCategory.KW);
      } else if (value === NewsCategory.KE) {
        setNewsCategory(NewsCategory.KE);
      }
    } else {
      if (value === NewsCategory.EP) {
        setNewsCategoryEn(NewsCategory.EP);
      } else if (value === NewsCategory.EV) {
        setNewsCategoryEn(NewsCategory.EV);
      } else if (value === NewsCategory.EW) {
        setNewsCategoryEn(NewsCategory.EW);
      } else if (value === NewsCategory.EE) {
        setNewsCategoryEn(NewsCategory.EE);
      }
    }
  };

  const handleOnClickUploadCancel = useCallback(() => {
    if (newsLang == Language.KO) {
      setNewsThumbnail('');
    } else {
      setNewsThumbnailEn('');
    }
  }, [newsLang]);

  const handleCancelBtn = () => {
    if (confirm('news 수정을 취소하시겠습니까?')) {
      alert('news 수정이 취소되었습니다.');
      navigate('/admin/news/' + id);
    }
  };

  const getRequestNewsData = () => {
    if (newsTitle == '') {
      alert('한글 제목을 입력해주세요.');
      return;
    }
    if (newsText == '') {
      alert('한글 내용을 입력해주세요.');
      return;
    }

    const newsBoard: NewsBoard = {
      newsLang: Language.KO,
      newsCategory: newsCategory,
      newsTitle: newsTitle,
      newsThumbnail: newsThumbnail.replaceAll('tmp/image', '/image'),
      newsSubtitle: newsSubtitle,
      newsText: newsText,
    };

    const newsBoardEn: NewsBoard = {
      newsLang: Language.EN,
      newsCategory: newsCategoryEn,
      newsTitle: newsTitleEn,
      newsThumbnail: newsThumbnailEn.replaceAll('tmp/image', '/image'),
      newsSubtitle: newsSubtitleEn,
      newsText: newsTextEn,
    };

    const requestData: News = {
      newsUrl: '', //백엔드에서 처리
      newsState: newsState,
      newsView: 0, //백엔드에서 처리
      registerAdmin: '', //백엔드에서 처리
      registeredDate: '', //백엔드에서 처리
      modifyAdmin: '', //백엔드에서 처리
      modifiedDate: '', //백엔드에서 처리
      newsBoard: { ko: newsBoard, en: newsBoardEn },
    };

    return requestData;
  };

  const handleEditBtn = async () => {
    const request = getRequestNewsData();
    await setRequestImgData();
    let copyResponse;

    if (uploadList.current.length > 0) {
      const fileCopyRequest: S3FileCopyRequest = {
        uploadFileKeyList: uploadList.current,
      };
      copyResponse = await s3UploadService.copyTmpFiles(fileCopyRequest);
    }

    if (copyResponse && copyResponse.successOrNot == 'N') {
      alert('News 수정 중 오류가 발생했습니다.');
    } else if (
      !copyResponse ||
      (copyResponse && copyResponse.successOrNot == 'Y')
    ) {
      if (request && id) {
        updateNews(request, id);
      }
    }
  };

  const updateNews = async (requestData: News, id: any) => {
    dispatch(changeLoadingStatus(true));
    dispatch(changeProgressStatus(50));
    const response = await newsService.updateNews(requestData, id);
    if (response.successOrNot == 'Y') {
      deleteImgFile();
      alert('News 수정이 완료 되었습니다.');
      navigate('/admin/news/' + id);
    } else {
      alert('News 수정 중 오류가 발생하였습니다.');
    }
    dispatch(changeLoadingStatus(false));
    dispatch(changeProgressStatus(100));
  };

  const deleteImgFile = () => {
    const deleteFileRequest: S3FileDeleteRequest = {
      dirName: 'news',
      dirKey: dirKey.current,
      contentDispositionType: ContentDispositionType.IMAGE,
    };

    if (deleteList.current.length > 0) {
      deleteFileRequest.deleteFileKeyList = deleteList.current;
    }

    s3UploadService.deleteFiles(deleteFileRequest);
  };

  return (
    <div id="NewsBoardEdit">
      <ProgressBar />
      <Form id="NewsBoardEditForm" name="NewsBoardEditForm">
        <div className="fullpage pc">
          <Row className="search">
            <Col>
              <span className="news-admin-title">News 수정 (</span>
              <span className="red-star"></span>
              <span className="news-admin-title"> 은 필수값 입니다.)</span>
            </Col>
          </Row>
          <div className="news-info">
            <Row>
              <Col className="news-col red-star">언어</Col>
              <div className="radio-buttons">
                <label className="language">
                  <Col>
                    <input
                      id="ko"
                      value={Language.KO}
                      name="language"
                      type="radio"
                      onChange={handleLanguageBtn}
                      checked={newsLang == Language.KO}
                    />
                    <span>한국어</span>
                  </Col>
                </label>
                <label className="language">
                  <Col>
                    <input
                      id="en"
                      value={Language.EN}
                      name="language"
                      type="radio"
                      onChange={handleLanguageBtn}
                      checked={newsLang == Language.EN}
                    />
                    <span>English</span>
                  </Col>
                </label>
              </div>
            </Row>
            {newsLang == Language.KO ? (
              <>
                <Row>
                  <Col className="news-col red-star">공개 여부</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="newsState"
                          name="newsState"
                          data-testid="newsState"
                          value={State.ALL}
                          type="radio"
                          checked={newsState == State.ALL}
                          onChange={handleStateBtn}
                        ></input>
                        <span>모두공개</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsState"
                          name="newsState"
                          data-testid="newsState"
                          value={State.ADMIN}
                          type="radio"
                          checked={newsState == State.ADMIN}
                          onChange={handleStateBtn}
                        ></input>
                        <span>비공개</span>
                      </Col>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col className="news-col red-star">카테고리</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategory"
                          name="newsCategory"
                          data-testid="newsCategory"
                          value={NewsCategory.KP}
                          type="radio"
                          checked={newsCategory == NewsCategory.KP}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>보도기사</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategory"
                          name="newsCategory"
                          data-testid="newsCategory"
                          value={NewsCategory.KV}
                          type="radio"
                          checked={newsCategory == NewsCategory.KV}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>비디오</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategory"
                          name="newsCategory"
                          data-testid="newsCategory"
                          value={NewsCategory.KW}
                          type="radio"
                          checked={newsCategory == NewsCategory.KW}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>웨비나</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategory"
                          name="newsCategory"
                          data-testid="newsCategory"
                          value={NewsCategory.KE}
                          type="radio"
                          checked={newsCategory == NewsCategory.KE}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>기타</span>
                      </Col>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col className="news-col red-star">제목</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="newsTitle"
                      name="newsTitle"
                      data-testid="newsTitle"
                      value={newsTitle}
                      onChange={handleOnNewsTitleChange}
                      maxLength={40}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="news-col">소제목</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="newsSubtitle"
                      name="newsSubtitle"
                      data-testid="newsSubtitle"
                      value={newsSubtitle}
                      onChange={handleOnNewsSubtitleChange}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="news-col">썸네일</Col>
                  <Col>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Form.Control
                          className="input"
                          type="text"
                          id="newsThumbnail"
                          name="newsThumbnail"
                          data-testid="newsThumbnail"
                          value={newsThumbnail}
                          readOnly
                          style={{ display: 'inline-block' }}
                        ></Form.Control>
                        <label
                          htmlFor="upload-newsThumbnail"
                          className="btn btn-primary"
                        >
                          업로드
                        </label>
                        <label
                          className="btn btn-secondary"
                          onClick={handleOnClickUploadCancel}
                        >
                          업로드 취소
                        </label>
                        <input
                          type="file"
                          id="upload-newsThumbnail"
                          onChange={handleOnNewsThumbnailChange}
                          style={{ display: 'none' }}
                        ></input>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col className="news-col red-star">State</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="newsState"
                          name="newsState"
                          data-testid="newsState"
                          value={State.ALL}
                          type="radio"
                          checked={newsState == State.ALL}
                          onChange={handleStateBtn}
                        ></input>
                        <span>ALL</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsState"
                          name="newsState"
                          data-testid="newsState"
                          value={State.ADMIN}
                          type="radio"
                          checked={newsState == State.ADMIN}
                          onChange={handleStateBtn}
                        ></input>
                        <span>ADMIN</span>
                      </Col>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col className="news-col red-star">Category</Col>
                  <div className="radio-buttons">
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategoryEn"
                          name="newsCategoryEn"
                          data-testid="newsCategoryEn"
                          value={NewsCategory.EP}
                          type="radio"
                          checked={newsCategoryEn == NewsCategory.EP}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>Press</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategoryEn"
                          name="newsCategoryEn"
                          data-testid="newsCategoryEn"
                          value={NewsCategory.EV}
                          type="radio"
                          checked={newsCategoryEn == NewsCategory.EV}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>Video</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategoryEn"
                          name="newsCategoryEn"
                          data-testid="newsCategoryEn"
                          value={NewsCategory.EW}
                          type="radio"
                          checked={newsCategoryEn == NewsCategory.EW}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>Webinar</span>
                      </Col>
                    </label>
                    <label className="language">
                      <Col>
                        <input
                          id="newsCategoryEn"
                          name="newsCategoryEn"
                          data-testid="newsCategoryEn"
                          value={NewsCategory.EE}
                          type="radio"
                          checked={newsCategoryEn == NewsCategory.EE}
                          onChange={handleCategoryBtn}
                        ></input>
                        <span>ETC</span>
                      </Col>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col className="news-col red-star">Title</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="newsTitleEn"
                      name="newsTitleEn"
                      data-testid="newsTitleEn"
                      value={newsTitleEn}
                      onChange={handleOnNewsTitleChange}
                      maxLength={40}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="news-col">Subtitle</Col>
                  <Col>
                    <Form.Control
                      className="input"
                      type="text"
                      id="newsSubtitleEn"
                      name="newsSubtitleEn"
                      data-testid="newsSubtitleEn"
                      value={newsSubtitleEn}
                      onChange={handleOnNewsSubtitleChange}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col className="news-col">Thumbnail</Col>
                  <Col>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Form.Control
                          className="input"
                          type="text"
                          id="newsThumbnailEn"
                          name="newsThumbnailEn"
                          data-testid="newsThumbnailEn"
                          value={newsThumbnailEn}
                          readOnly
                          style={{ display: 'inline-block' }}
                        ></Form.Control>
                        <label
                          htmlFor="upload-newsThumbnailEn"
                          className="btn btn-primary"
                        >
                          Upload
                        </label>
                        <label
                          className="btn btn-secondary"
                          onClick={handleOnClickUploadCancel}
                        >
                          cancel
                        </label>
                        <input
                          type="file"
                          id="upload-newsThumbnailEn"
                          onChange={handleOnNewsThumbnailChange}
                          style={{ display: 'none' }}
                        ></input>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
          <hr />
          <div className="edit-body">
            {newsLang == Language.KO ? (
              <ToastEditor
                setEditorText={setNewsText}
                text={newsText}
                language={Language.KO}
              />
            ) : (
              <ToastEditor
                setEditorText={setNewsTextEn}
                text={newsTextEn}
                language={Language.EN}
              />
            )}
          </div>
          <hr />
          <div className="btn-container">
            <Button
              className="btn btn-danger"
              onClick={handleCancelBtn}
              data-testid="cancel-button"
            >
              취소
            </Button>
            <Button
              className="btn btn-light"
              id="editBtn"
              onClick={handleEditBtn}
              data-testid="edit-button"
            >
              수정
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default NewsBoardEditForm;
