export enum EksContentLayout {
  TXT_TXT,
  TXT_IMG,
  IMG_TXT,
  IMG_IMG,
}

export interface EksContentData {
  img?: string;
  layout: EksContentLayout;
  content: EksContentTextAreaProps;
  content2?: EksContentTextAreaProps; //좌우가 txt 콘텐츠인 경우
}

export interface EksContentTextAreaProps {
  title: ContentAttrProps[];
  summary: ContentSummaryProps[];
}

interface ContentSummaryProps {
  type: 'default' | 'definition';
  contents: ContentAttrProps[];
}

interface ContentAttrProps {
  text: string;
  options?: {
    size?: 'title-sm' | 'content-lg'; //폰트 크기 (css 정의)
    color?: 'primary' | 'default';
    bold?: boolean;
    prevLine?: boolean; //해당 텍스트 전 줄바꿈처리 여부
    underline?: 'underline' | 'primary-underline';
  };
}

export interface EksValueData {
  img: string;
  index: string;
  main: EksValueTextProps[];
  detail: EksValueTextProps[];
}

interface EksValueTextProps {
  text: ValueAttrProps[];
}

interface ValueAttrProps {
  val: string;
  bold?: boolean;
}

export interface EksCaseData {
  index: string;
  title: EksCaseTitleProps[];
  summary: string;
}

interface EksCaseTitleProps {
  txt: string;
}
