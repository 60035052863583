import { useEffect, useState } from 'react';

/*
 * 스크롤 이벤트 발생 시 throttle을 이용해
 * 0.1초마다 한번씩만 callback 함수를 실행
 */

// throttle 구현
const throttle = (callback: () => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return () => {
    // timer에 setTimeout 이 있다면 바로 리턴
    if (timer) return;
    // timer에 setTimeout 이 없다면
    // callback 함수 싫행 후 timer가 delay 시간 후에 다시 실행되도록 timer를 비운다.
    timer = setTimeout(() => {
      callback();
      timer = null;
    }, delay);
  };
};

const useScrolling = (initState = false) => {
  const [scrollFlag, setScrollFlag] = useState(initState); // default: false

  const updateScroll = () => {
    const { scrollY } = window;
    scrollY > 10 ? setScrollFlag(true) : setScrollFlag(false);
  };
  // 100ms 단위로 스크롤 이동을 감지
  const handleScroll = throttle(updateScroll, 100);

  useEffect(() => {
    // scroll 이벤트리스너에 추가
    window.addEventListener('scroll', handleScroll);
    return () => {
      // cleanup function
      // DOM에 렌더링 시 부여한 이벤트리스너는 메모리 누수 및 리렌더 시 이벤트 중복 방지를 위해
      // 컴포넌트 삭제 시 같이 remove
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return scrollFlag;
};

export default useScrolling;
