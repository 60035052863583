import React from 'react';
import LoadingBar from 'react-top-loading-bar'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/modules';
import { changeProgressStatus } from '../redux/actions/progress';

const ProgressBar: React.FC = () => {
  const progress = useSelector((state:RootState) => state.progress.progress);
  const dispatch = useDispatch();
  return (
    <LoadingBar color='#bb0841' progress={progress}  onLoaderFinished={() => dispatch(changeProgressStatus(0))}/>
  );
};

export default ProgressBar;
