import React from 'react';
import AboutCaseArea from './AboutCaseArea';
import Footer from '../../../layouts/Footer';

const About04: React.FC = () => {
  return (
    <>
      <div
        id="about-slide4"
        className="fullpage-slide"
        data-testid="testAbout04Section"
        style={{ height: '100vh !important' }}
      >
        <div className="about-cases end-page">
          <div className="about-cases-body">
            <div className="about-cases-case-cont">
              <AboutCaseArea startIdx={6} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default About04;
